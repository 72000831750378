<app-navbar></app-navbar>
<mat-drawer-container class="example-container">

<mat-drawer mode="side" opened fxHide.sm  >

  <left-navbar></left-navbar>
</mat-drawer>
<mat-drawer-content>
    <div class="translation-area">
        <div class="container-fluid">
          <div class="white-space"></div>

          <div class="row">
          <div class="col-sm-12 dictionary-settingscontainer">
            <div class="card">
                <div class="card-header-tab card-header">
                    <h5>{{ 'SETTINGS.SETTINGS' | translate }}</h5>
                </div>
              <div class="card-body">
                  <mat-tab-group>

                      <mat-tab label="{{ 'SETTINGS.DICTIONARY_SETTINGS' | translate }}">
                        <div class="spacer"></div>

                        <form [formGroup]="langForm">
                            <div class="form-container">
                              <div class="title-container">
                                <p>{{ 'SETTINGS.SET_SCREENLANGUAGE' | translate }}</p>
                              </div>
                                <mat-form-field class="formfield">
                                    <mat-select appearance="outline" class="form-control" formControlName="screenlanguage">
                                        <mat-option *ngFor="let screenlang of languages" [value]="screenlang.name">{{screenlang.title}}</mat-option>
                                    </mat-select>

                                </mat-form-field>
                                <div class="title-container">
                                  <p>{{ 'SETTINGS.SET_SOURCELANGUAGE' | translate}}</p>
                                </div>
                                <mat-form-field class="formfield">
                                  <mat-select appearance="outline" class="form-control" formControlName="sourcelanguage">
                                      <mat-option *ngFor="let sourcelang of languages"[value]="sourcelang.name">{{sourcelang.title}}</mat-option>
                                  </mat-select>

                              </mat-form-field>
                              <div class="title-container">
                                <p>{{ 'SETTINGS.SET_TARGETLANGUAGE' | translate}}</p>
                              </div>
                              <mat-form-field class="formfield">
                                <mat-select appearance="outline" class="form-control" formControlName="targetlanguage">
                                    <mat-option *ngFor="let targetlang of languages"[value]="targetlang.name">{{targetlang.title}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="formfield"><mat-slide-toggle color="primary" formControlName="showHelp">Show instructional videos</mat-slide-toggle></div>



                            </div>




                        <div class="spacer"></div>
                        <div class="save-button-settings">

                           <button mat-stroked-button color="primary" (click)="setConfig()">{{ 'SETTINGS.SAVE' | translate }}</button>
                        </div>
                      </form>


                        <div class="spacer"></div>



                      </mat-tab>
                      <mat-tab label="{{ 'SETTINGS.SERVICES' | translate }}">
                         <mat-list>
                            <h3 mat-subheader>Available Dictionaries</h3>
                           <p class="available-dicts" *ngFor="let dictionary of dictionariesAllowed"> {{dictionaryDetailsPlaceholder(dictionary)}} </p>
                           <h3 mat-subheader>Machine Translation balance</h3>
                           <div *ngIf="hasBalance" class="mt-balance">
                            <mat-list-item> <b>{{mtBalance}}</b> characters left </mat-list-item>
                           </div>
                           <div *ngIf="!hasBalance" class="mt-balance">
                            <mat-list-item> <p>You don't have any Machine Translation characters yet </p> </mat-list-item>
                           </div>

                          </mat-list>

                      </mat-tab>
                     <!-- <mat-tab label="Your terminology">
                        <mat-list>
                          <div class="overview-table" *ngIf=showOverview>
                            <button mat-stroked-button color="primary" (click)="openTerminologyDialog()">New</button>

                            <table mat-table [dataSource]="dataSource" class="terminlogy-list">



                              <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                              </ng-container>

                              <ng-container matColumnDef="hash">
                                <th mat-header-cell *matHeaderCellDef> Hash</th>
                                <td mat-cell *matCellDef="let element"> {{element.hash}} </td>
                              </ng-container>


                              <ng-container matColumnDef="concepts">
                                <th mat-header-cell *matHeaderCellDef> Concepts </th>
                                <td mat-cell *matCellDef="let element"> {{element.concepts}} </td>
                              </ng-container>

                              <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef> Actions </th>
                                <td mat-cell *matCellDef="let element">
                                  <button mat-stroked-button color="primary" [routerLink]="['/dictionary-settings',element.hash]" (click)="addTerminology(element.hash)">Add</button>

                                  <button mat-stroked-button color="primary" (click)="deleteTerminology(element.hash)">Delete</button>
                                  <button mat-stroked-button color="primary" >Edit</button>

                                </td>
                              </ng-container>
                              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            </table>

                          </div>
                        </mat-list>

                        <div class="add-terminology"  *ngIf="!showOverview">
                          <mat-list>
                            <button mat-stroked-button (click)="newEntry()" color="primary">New entry</button>
                            <button mat-stroked-button color="primary">Upload</button>
                            <button mat-stroked-button color="primary">Import</button>
                            <button mat-stroked-button color="primary">Export</button>

                           <add-terminology [items]="items" *ngIf="showPanel"></add-terminology>
                          </mat-list>
                        </div>

                      </mat-tab> -->


                    </mat-tab-group>

              </div>
            </div>


          </div>
        </div>



        </div>

      </div>
</mat-drawer-content>


</mat-drawer-container>
