import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { ImportService, ConceptImportBody} from '../../../services/dicts-services/import.service'
import {ResourceService} from '../../../shared-services/resource.service';

export interface DialogData {
  conceptId: number;
  wordType: string;
  attributes: Array<string>;
}

@Component({
  selector: 'app-update-concept-fields',
  templateUrl: './update-concept-fields.component.html',
  styleUrls: ['./update-concept-fields.component.css']
})
export class UpdateConceptFieldsComponent implements OnInit {
  wordtypes: Array<string>;
  selectedWordType: number;
  wordtypetext: string;

  constructor(private resourceService: ResourceService, private importService: ImportService, private dialogRef: MatDialogRef<UpdateConceptFieldsComponent>, @Inject(MAT_DIALOG_DATA) public conceptDialogData: DialogData) { }

  ngOnInit() {
    this.getWordTypes();
  }

  onCloseConfirm(){
    let body: ConceptImportBody = {
      concept_id: this.conceptDialogData.conceptId,
      word_type: this.conceptDialogData.wordType,
      concept_attributes: this.conceptDialogData.attributes,
      translations: null
    }
    //console.log("conceptotupdate" + JSON.stringify(body))
    this.importService.updateConcept(body).subscribe(
      data =>  this.handleUpdateConcept(data),
      error => this.handleUpdateConceptError(error),
      () => {}
    );
    //console.log("Update this data" + JSON.stringify(this.conceptDialogData));
    this.dialogRef.close()
  }

  onCloseCancel(){
    //console.log("Cancel");
    this.dialogRef.close();
  }

  handleUpdateConcept(data){
    //console.log("UPDATE DATA" + JSON.stringify(data))

  }
  handleUpdateConceptError(error){
    //console.log("Error: " + JSON.stringify(error))
  }

  getWordTypes(){
    this.wordtypes = this.resourceService.getCachedWordTypes();
  }


}
