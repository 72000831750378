import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImportService, ConceptLemmaImportBody} from '../../../services/dicts-services/import.service'
import {ResourceService} from '../../../shared-services/resource.service';

export interface WordData {
  word: string,
  variant: number,
  focus: number,
  gender: string;
  word_type: string;
  attributes: string[];
  triggers: string;
  translation_groups: string[];
}
export interface DialogData {
  conceptId: number;
  conceptWordType: string;
  language: string;
  word: WordData;
  gender: string;
}

@Component({
  selector: 'app-update-lemma-fields',
  templateUrl: './update-lemma-fields.component.html',
  styleUrls: ['./update-lemma-fields.component.css']
})
export class UpdateLemmaFieldsComponent implements OnInit {
  lemmaWordType: string;
  wordtypes: Array<string>;
  genders: Array<string>;
  


  constructor(private resourceService: ResourceService, private importService: ImportService, private dialogRef: MatDialogRef<UpdateLemmaFieldsComponent>, @Inject(MAT_DIALOG_DATA) public lemmaDialogData: DialogData) { }

  ngOnInit() {
    this.getWordTypes();
    this.getGenders();
    this.lemmaWordType = this.lemmaDialogData.conceptWordType;
    if((this.lemmaDialogData.word.word_type != undefined) && (this.lemmaDialogData.word.word_type.length != 0) && (this.lemmaDialogData.word.word_type != this.lemmaWordType)) {
      this.lemmaWordType = this.lemmaDialogData.word.word_type;
    }
  }

  onCloseConfirm(){
    let triggerArray = [];
    triggerArray = this.lemmaDialogData.word.triggers.split(",");
    let wordType = this.lemmaWordType;
    if(wordType == this.lemmaDialogData.conceptWordType) {
      wordType = undefined;
    }
    let body: ConceptLemmaImportBody = {
      word: this.lemmaDialogData.word.word,
      variant: this.lemmaDialogData.word.variant,
      word_type: wordType,
      attributes: this.lemmaDialogData.word.attributes,
      focus: this.lemmaDialogData.word.focus,
      gender: this.lemmaDialogData.word.gender,
      triggers: triggerArray
    }
    console.log("lemmatoupdate" + JSON.stringify(body));

    this.importService.updateConceptLemma(this.lemmaDialogData.language, this.lemmaDialogData.conceptId, body).subscribe(
      data =>  this.handleUpdateConceptLemma(data),
      error => this.handleUpdateConceptLemmaError(error),
      () => {}
    );

    this.dialogRef.close();
  }

  onCloseCancel(){
    console.log("Cancel");
    this.dialogRef.close();
  }

  handleUpdateConceptLemma(data){
    console.log("UPDATE DATA" + JSON.stringify(data))

  }
  handleUpdateConceptLemmaError(error){
    console.log("Error: " + JSON.stringify(error))
  }

  getWordTypes(){
    this.wordtypes = this.resourceService.getCachedWordTypes();
  }

  getGenders(){
    this.genders = this.resourceService.getCachedGenders();

  }

}
