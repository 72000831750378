import {Component, OnInit } from '@angular/core';
import{AuthServiceEuroglot} from '../../services/auth.service';
import {Router} from '@angular/router';
//import {FlashMessagesService} from 'angular2-flash-messages';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
	user: Object;
  name: String;
  username: String;
  email: String;
  password: String;
  address: String;
  postalcode: String;
  city: String;

  constructor(
    private authService: AuthServiceEuroglot,
    private router: Router,
    //private flashMessage: FlashMessagesService
  ) {  }

  ngOnInit() {
  	this.authService.getProfile().subscribe(profile =>{
  		this.user = profile.user;

  		},
  		err => {
  			console.log(err);
  			return false;
  		});
    }


      onUpdateSubmit(){
        const user = {

          name: this.name,
          email: this.email,
          address: this.address,
          postalcode: this.postalcode,
          city: this.city,

        }

        console.log("user" + JSON.stringify(this.user));

         this.authService.updateProfile(this.user).subscribe(
          data => {
            user
            if(user !== null ){
              /*this.flashMessage.show('Your profile has been updated', {
                cssClass: 'alert-success',
                timeout: 3000});
              }*/
            }
          },


          err=> {
            console.log("ERR" + err)
            //this.flashMessage.show('Something went wrong', {cssClass: 'alert-danger', timeout: 3000})
          });


      };






    }
