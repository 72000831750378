import { Injectable, EventEmitter } from '@angular/core';
import { environment, varEnvironment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { Observable } from 'rxjs';


import 'rxjs/add/operator/map';
import 'rxjs/operator/delay';
import 'rxjs/operator/mergeMap';
import 'rxjs/operator/switchMap';

export interface DictionaryInfo {
 name: string;
 concepts: number;
 export_port: number;
 import_port: number;
}

export class DictionaryInfosEvent {
	data : DictionaryInfo[];
	constructor(data : DictionaryInfo[]) {
		this.data = data
	}
}

const API_URL =  varEnvironment.schema + '://' + environment.API_IP + ":"+ environment.PORT_MERGE2  + environment.ENDPOINT_MERGE2 


const ENDPOINT_DICTIONARY_INFO = API_URL + environment.ENDPOINT_DICTIONARY_INFO;


@Injectable()
export class Merger2Service  {
  cached_dictionary_infos: DictionaryInfo[];
  private dictionaryInfoURL: string = ENDPOINT_DICTIONARY_INFO;
  dictionaryInfosEmitter : EventEmitter<DictionaryInfosEvent> = new EventEmitter();

  requestOptions: {} = {withAuth: true};
  
  constructor(
    private http:HttpService,
  ) {
  }

  getImportPort(dictname):number{
    if(this.cached_dictionary_infos == undefined){
        this.getDictionaryInfos();
        return 0;
    }
    for(let dict_info of this.cached_dictionary_infos){
      if(dict_info.name == dictname){
          return dict_info.import_port;
      }
    }
    return 0;
     
 }
  
  getExportPort(dictname):number{
      if(this.cached_dictionary_infos == undefined){
          this.getDictionaryInfos();
          return 0;
      }
      for(let dict_info of this.cached_dictionary_infos){
        if(dict_info.name == dictname){
            return dict_info.export_port;

        }
      }
      return 0;
       
  }
  getDictionaryInfos(): void {
    this.http.get(this.dictionaryInfoURL, this.requestOptions ).subscribe(
        data =>  this.handleDictionaryInfos(data),
        error => this.handleDictionaryError(error),
        () => {}
      );
    
  }



handleDictionaryInfos(data){
  this.cached_dictionary_infos = data.data.dictionaries;
  var myEvent = new DictionaryInfosEvent(this.cached_dictionary_infos);
  this.dictionaryInfosEmitter.emit(myEvent);

  //console.log("DATA" + JSON.stringify(this.cached_dictionary_infos));
}

handleDictionaryError(error){
  console.log(error);
}


 
}
