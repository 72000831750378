import { Component, OnInit, Input } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'copy-clipboard',
  templateUrl: './copy-clipboard.component.html',
  styleUrls: ['./copy-clipboard.component.css']
})
export class CopyClipboardComponent implements OnInit {
  @Input() lemma: string;
  success: boolean = false;
  error: boolean = false;

  action: any;

  constructor(public snackBar: MatSnackBar) { }

  ngOnInit() {
  }


  public logError(error: Error, action:string){

      this.snackBar.open('Unable to copy' + this.lemma + '\" to clipboard', action,{duration: 2000})

    //console.group("Clipboard error");
    console.error(error)
    //console.groupEnd();
  }


  public logSuccess(value: string, action:string){
    this.snackBar.open('\"' + this.lemma + '\" has been copied to clipboard', action,{duration: 2000})
  }


}
