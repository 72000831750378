<mat-dialog-content>
  <div class="form-container">
      <mat-select [(ngModel)]="conceptDialogData.wordType">
          <mat-option *ngFor="let wordtype of wordtypes; let i = index" [value]="i">{{wordtype}}</mat-option>
      </mat-select>
      
      <input type="text" matInput placeholder="attributes" [(ngModel)]="conceptDialogData.attributes" [value]="conceptDialogData.attributes" class="form-control">
    
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button type= "submit" mat-raised-button (click)="onCloseConfirm()">CONFIRM</button>
    <button mat-raised-button (click)="onCloseCancel()">CANCEL</button>
</mat-dialog-actions>

