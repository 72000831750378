import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ContextMenuService } from 'ngx-contextmenu';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { AttributesComponent } from '../dictionary-components/attributes/attributes.component';

@Component({
  selector: 'the-context-menu',
  templateUrl: './menu-context.component.html',
  styleUrls: ['./menu-context.component.css']
})
export class MenuContextComponent implements OnInit {
  //@ViewChild(SouffleurComponent) souffleur: SouffleurComponent;
  //@ViewChild(GrammarComponent) grammar: GrammarComponent;
  //@ViewChild(CopyClipboardComponent) clipboard: CopyClipboardComponent;
  @ViewChild(AttributesComponent) attributes: AttributesComponent;


  @Input() contextMenu: ContextMenuComponent;

  conceptid: number;
  language: string;
  focus: number;
  dictionary: string;
  hasGrammar: boolean;
  hasPronounce: boolean;
  souffleurlink: string;
  hasCopyToClipboard: boolean;
  hasFindInDictionary: boolean;
  hasFindInTranslationMemory : boolean;
  lemma: string;
  attributeList: string[];
  divider: true;
  hasSpeechSynthesis: boolean = false;

  constructor(private contextMenuService: ContextMenuService) { }

  ngOnInit() {
  }


  onContextMenu($event, language, conceptid, conceptAttributes, dictionary, lemmaJSON) {
    //console.log("LANG" + language + "FoCUS" + lemmaJSON.focus + "CONCEPTID" + conceptid + "DICTIONARY" + dictionary + "ITEM"+ JSON.stringify(lemmaJSON))

    this.conceptid = conceptid
    this.language = language;
    this.focus = lemmaJSON.focus;
    this.dictionary = dictionary;
    this.hasGrammar = lemmaJSON.conjugations != null;
    this.hasPronounce = lemmaJSON.souffleur != null;
    if (this.hasPronounce) {
      this.souffleurlink = lemmaJSON.souffleur.links.self;
    }
    this.lemma = lemmaJSON.lemma;
    this.hasCopyToClipboard = true;
    this.hasFindInDictionary = true;
    this.hasFindInTranslationMemory = true;

    this.attributeList = [];
    this.attributeList.push('h'+ lemmaJSON.word_type);
    if(lemmaJSON.gender){
      this.attributeList.push('g'+ lemmaJSON.gender);
    }
    if(conceptAttributes){
      conceptAttributes.forEach( attribute => {
        this.attributeList.push(attribute);
      })
    }
    if(lemmaJSON.attributes){
      lemmaJSON.attributes.forEach( attribute => {
        this.attributeList.push(attribute);
      })

  }

    $event.preventDefault();

    setTimeout(() => {
      this.contextMenuService.show.next({
        event: $event,
        item: lemmaJSON,
      });
    }, 100 );

    $event.stopPropagation();
  }

}
