import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';

const API_URL = "http://localhost:2335/gdrive/gdrive"
@Injectable({
  providedIn: 'root'
})
export class GdriveService {
  requestOptions: {} = {withAuth: true, withContentType: 'application/x-www-form-urlencoded', withCharset: 'utf-8' };
  text: string;


  constructor(private http:HttpService) { }

  uploadToGoogleDrive(text){
    let body = {
      "text" : text
    }
    return this.http.post<JSON>(API_URL, body, this.requestOptions)

  }

}
