import { EditorState, EditorView } from 'prosemirror-state';
import { TextSelection, AllSelection } from "prosemirror-state"

import { ToolbarCustomMenuItem } from 'ngx-editor';

import { readOnlyEditor } from './flags';
import { MenuItemCreator, MenuItemProperties, MenuItemInitialVisibleFn, MenuItemCommandFn, MenuItemActiveFn } from './menu';
import { ContextMenuItem } from './contextmenu';
import { DispatchFn, KeyMap, MENU_ITEM_CLASSNAME, DISABLED_CLASSNAME, INVISIBLE_CLASSNAME, emptyTCR } from './root';


export const getClipboardKeymap = (): KeyMap => {
  const clipboardKeymap: KeyMap = {};

  if(copyToClipboardIsSupported()) {
    clipboardKeymap['Ctrl-x'] = clipboardCut();
    clipboardKeymap['Ctrl-c'] = clipboardCopy();
  }
  if(pasteToClipboardIsSupported()) {
    clipboardKeymap['Ctrl-v'] = clipboardPaste();
  }
  clipboardKeymap['Ctrl-a'] = selectAllText();

  return clipboardKeymap;
};

export const getDefaultClipboardLabels = {
  cut: 'Cut',
  copy: 'Copy',
  paste: 'Paste',
  select_all: 'Select all',
}


const clipboardCutMenuItemProperties : MenuItemProperties = {
  label: getDefaultClipboardLabels['cut'],
  iconName : 'cut',
  flags : {
    readonly : false,
  },
  initialVisibleFn : copyToClipboardIsSupported,
  commandFn: clipboardCut(),
}
const clipboardCutMenuItemCreator : MenuItemCreator = new MenuItemCreator(clipboardCutMenuItemProperties);
export const clipboardCutMenuItem : ToolbarCustomMenuItem = clipboardCutMenuItemCreator.createMenuItem();
export const clipboardCutContextMenuItem : ContextMenuItem = clipboardCutMenuItemCreator.createContextMenuItem();

const clipboardCopyMenuItemProperties : MenuItemProperties = {
  label: getDefaultClipboardLabels['copy'],
  iconName : 'copy',
  initialVisibleFn : copyToClipboardIsSupported,
  commandFn: clipboardCopy(),
}
const clipboardCopyMenuItemCreator : MenuItemCreator = new MenuItemCreator(clipboardCopyMenuItemProperties);
export const clipboardCopyMenuItem : ToolbarCustomMenuItem = clipboardCopyMenuItemCreator.createMenuItem();
export const clipboardCopyContextMenuItem : ContextMenuItem = clipboardCopyMenuItemCreator.createContextMenuItem();

const clipboardPasteMenuItemProperties : MenuItemProperties = {
  label: getDefaultClipboardLabels['paste'],
  iconName : 'paste',
  flags : {
    readonly : false,
  },
  initialVisibleFn : pasteToClipboardIsSupported,
  commandFn: clipboardPaste(),
}
const clipboardPasteMenuItemCreator : MenuItemCreator = new MenuItemCreator(clipboardPasteMenuItemProperties);
export const clipboardPasteMenuItem : ToolbarCustomMenuItem = clipboardPasteMenuItemCreator.createMenuItem();
export const clipboardPasteContextMenuItem : ContextMenuItem = clipboardPasteMenuItemCreator.createContextMenuItem();

const clipboardSelectAllMenuItemProperties : MenuItemProperties = {
  label: getDefaultClipboardLabels['select_all'],
  iconName : 'A',
  commandFn: selectAllText(),
  activeFn: allSelected,
}
const clipboardSelectAllMenuItemCreator : MenuItemCreator = new MenuItemCreator(clipboardSelectAllMenuItemProperties);
export const clipboardSelectAllMenuItem : ToolbarCustomMenuItem = clipboardSelectAllMenuItemCreator.createMenuItem();
export const clipboardSelectAllContextMenuItem : ContextMenuItem = clipboardSelectAllMenuItemCreator.createContextMenuItem();


/*export function handlePaste(editor) {
	return new Plugin({
		props: {
			clipboardTextParser: function(str, $context) {
				if (/^<iframe\s.*>.*<\/iframe>$/igm.test(str) == false) return;
				var doc = document.cloneNode(false);
				var dom = doc.createElement('div');
				dom.innerHTML = str;
				var parser = editor.someProp("clipboardParser") || editor.someProp("domParser") || Model.DOMParser.fromSchema(editor.state.schema);
				return parser.parseSlice(dom, {preserveWhitespace: true, context: $context});
			},
			transformPasted: function(pslice) {
				var frag = editor.utils.fragmentApply(pslice.content, function(node) {
					delete node.attrs.block_focused;
					var id = node.attrs.block_id;
					if (!id) return;
					var block = editor.blocks.get(id);
					if (!block) return;
					delete block.focused;
				});
				return new Model.Slice(frag, pslice.openStart, pslice.openEnd);
			}
		}
	});
}*/


function clipboardCut() : MenuItemCommandFn {
  return function(state : EditorState, dispatch : DispatchFn, view : EditorView) : boolean {
    let selection = state.tr.selection;

    if (dispatch) {
      let clipEvent = new ClipboardEvent("copy"); //cut doesn't work on firefox????
      //Chrome doesn't make clipboardData with the default construction of clipEvent...
      if(clipEvent.clipboardData == undefined) {
        var dT = null;
        try{ dT = new DataTransfer();} catch(e){}
        clipEvent = new ClipboardEvent('copy', {clipboardData: dT});
      }

      view.dispatchEvent(clipEvent);
//console.log("CLIP CUT")
//console.dir(clipEvent)
      copyToClipboard(clipEvent);

      dispatch(state.tr.deleteSelection(selection))
    }

    return (selection != null && !selection.empty);
  }
}

function clipboardCopy() : MenuItemCommandFn {
  return function(state : EditorState, dispatch : DispatchFn, view : EditorView) : boolean {
    let selection = state.tr.selection;

    if (dispatch) {
      let clipEvent = new ClipboardEvent("copy");
      //Chrome doesn't make clipboardData with the default construction of clipEvent...
      if(clipEvent.clipboardData == undefined) {
        var dT = null;
        try{ dT = new DataTransfer();} catch(e){}
        clipEvent = new ClipboardEvent('copy', {clipboardData: dT});
      }

      view.dispatchEvent(clipEvent);
//console.log("CLIP COPY")
//console.dir(clipEvent)
      copyToClipboard(clipEvent);
    }

    return (selection != null && !selection.empty);
  }
}

function clipboardPaste() : MenuItemCommandFn {
  return function(state : EditorState, dispatch : DispatchFn, view : EditorView) : boolean {

    if (dispatch) {
      pasteFromClipboard()
        .then(clipEvent => {
          console.log("Pasting clip into editor...")
          console.dir(clipEvent)
          view.dispatchEvent(clipEvent);
        });
    }

    return !readOnlyEditor(state) && pasteToClipboardIsSupported();
  }
}

function allSelected(state : EditorState) : boolean {
  let currentSelection = state.tr.selection;
  let allSelection = new AllSelection(state.doc);

  return currentSelection.eq(allSelection);
}

function selectAllText() : MenuItemCommandFn {
  return function(state : EditorState, dispatch : DispatchFn) : boolean  {
    let selection = state.tr.selection;

    if(allSelected(state)) {
      if (dispatch) {
        selection = new TextSelection(selection.$anchor, selection.$anchor)
        dispatch(state.tr.setSelection(selection));
      }
    } else {
      selection = new AllSelection(state.doc);
      if (dispatch) dispatch(state.tr.setSelection(selection));
    }

    return !selection.empty && !selection.content().empty && selection.content().content.toString() != "<paragraph>";
  }
}


declare class ClipboardItem {
  constructor(data: { [mimeType: string]: Blob });
  readonly types : string[];
  getType(type : string) : Promise<Blob>;
}

function copyToClipboard(ev : ClipboardEvent) {
  //Extract data from event
  const textType = 'text/plain';
  const htmlType = 'text/html';
  let text : string = ev.clipboardData.getData(textType);
  let html : string = ev.clipboardData.getData(htmlType);

  // In some browsers, Navigator only writes text, no HTML with writeText function -_- and write funtion doesn't work -_- -_-
  let clipboard : any = navigator.clipboard;
  if((clipboard != undefined) && (clipboard.write != undefined)) {
    //It seems in this browser we do have a write to clipboard function \o/
    const textBlob = new Blob([text], { type:textType });
    const htmlBlob = new Blob([html], { type:htmlType });
    var item : ClipboardItem = new ClipboardItem({
      [textType]: textBlob,
      [htmlType]: htmlBlob,
    })

    clipboard.write([item])
        .then()
        .catch(err => {
        console.error('Failed to write clipboard contents: ', err);
      });

    return;
  }

  //Try the old-fashioned method...

  //Create fake text area
  let ta = createTempTextArea(document, window);
  ta.value = text;
  ta.innerHTML = html;

  //Copy contents from text area to clipboard
console.dir(ta)
  copyFromInputElement(ta);
}


//Code of copyFromInputElement and createTempTextArea functions based on/copied from ngx-clipboard
function copyFromInputElement(elm) {
    try {
        elm.select();
        elm.setSelectionRange(0, elm.value.length);
        return document.execCommand('copy');
    }
    catch (error) {
      console.error('Failed to write clipboard contents: ', error);
      return false;
    }
}

function createTempTextArea(doc, window) {
    const isRTL = doc.documentElement.getAttribute('dir') === 'rtl';
    let ta;
    ta = doc.createElement('textarea');
    // Prevent zooming on iOS
    ta.style.fontSize = '12pt';
    // Reset box model
    ta.style.border = '0';
    ta.style.padding = '0';
    ta.style.margin = '0';
    // Move element out of screen horizontally
    ta.style.position = 'absolute';
    ta.style[isRTL ? 'right' : 'left'] = '-9999px';
    // Move element to the same position vertically
    const yPosition = window.pageYOffset || doc.documentElement.scrollTop;
    ta.style.top = yPosition + 'px';
    ta.setAttribute('readonly', '');
    return ta;
}

function copyToClipboardIsSupported() : boolean {
  let clipboard : any = navigator.clipboard;
  if((clipboard != undefined) && (clipboard.read != undefined)) {
    //It seems in this browser we do have a write to clipboard function \o/
    return true;
  }

  //Old style
  if(document.execCommand != undefined) {
    return true;
  }

  return false;
}

function pasteToClipboardIsSupported() : boolean {
  let clipboard : any = navigator.clipboard;
  if((clipboard != undefined) && (clipboard.read != undefined)) {
    //It seems in this browser we do have a read from clipboard function \o/
    let permissionRequest : any = {name:'clipboard-read', allowWithoutGesture: false};
    navigator.permissions.query(permissionRequest)
      .then(function(result) {
        if (result.state === 'denied') {
          console.log("CLIPBOARD READ DENIED BY BROWSER PERMISSIONS");
          return false;
        }
      });
    return true;
  }

  return false;
}

async function handleGetType(data : ClipboardItem, type : string, ev : ClipboardEvent) {
  console.dir(data)
  let blob : Blob = await data.getType(type);
  let text : string = await blob.text();

  if(ev.clipboardData == undefined)
    console.log("NULL clipboarddata!");
  else
    ev.clipboardData.setData(type, text);
}

async function pasteFromClipboard() : Promise<ClipboardEvent> {

  let clipEvent = new ClipboardEvent("paste");
  //Chrome doesn't make clipboardData with the default construction of clipEvent...
  if(clipEvent.clipboardData == undefined) {
    var dT = null;
    try{ dT = new DataTransfer();} catch(e){}
    clipEvent = new ClipboardEvent('paste', {clipboardData: dT});
  }

  let clipboard : any = navigator.clipboard;
  if((clipboard != undefined) && (clipboard.read != undefined)) {
    //It seems in this browser we do have a read from clipboard function \o/
    let data : ClipboardItem[] = await clipboard.read();
    console.log("CLIPDATA!!")
    console.dir(data)

    /*if(data.items != undefined) {
      for (let i=0; i<data.items.length; i++) {
        if (data.items[i].type == "text/plain") {
          await handleGetType(data.items[i], "text/plain", clipEvent);
        } /else if (data.items[i].type == "text/rtf") {
          await handleGetType(data.items[i], "text/rtf", clipEvent);
        }/ else if (data.items[i].type == "text/html") {
          await handleGetType(data.items[i], "text/html", clipEvent);
        }
      }
    } else*/ if(data.length > 0) {
      for (let i=0; i<data.length; i++) {
        for (let j=0; j<data[i].types.length; j++) {
          if (data[i].types[j] == "text/plain") {
            await handleGetType(data[i], "text/plain", clipEvent);
          } /*else if (data[i].types[j] == "text/rtf") {
            await handleGetType(data[i], "text/rtf", clipEvent);
          }*/ else if (data[i].types[j] == "text/html") {
            await handleGetType(data[i], "text/html", clipEvent);
          }
        }
      }
    }

  } else {
    clipEvent.clipboardData.setData("text/plain", "CLIPBOARD PASTE NOT SUPPORTED");
    clipEvent.clipboardData.setData("text/html", "<p>CLIPBOARD PASTE</p><p>NOT SUPPORTED</p>")
  }

  return clipEvent;
}

/*let pasteContent : string = null;
export function getPasteResponseFunction() : any {
  return function(clip : string) {
    console.log("CLIP PASTE: " + clip)
    pasteContent = clip;
  }
}*/
