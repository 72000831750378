import { OnInit, Output, AfterContentInit, AfterViewInit, EventEmitter, Input, ChangeDetectorRef, QueryList, Inject, Injector, HostListener, Directive } from '@angular/core';
import { AuthServiceEuroglot } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseTopLevel implements OnInit {

    protected configurationService: ConfigurationService;
    protected authService: AuthServiceEuroglot;
    protected snackBar: MatSnackBar;
    private cd: ChangeDetectorRef;

    private sourceLanguageChangedSubscription : Subscription;
    private targetLanguageChangedSubscription : Subscription;  

    @HostListener('window:beforeunload', [ '$event' ])
    beforeUnloadHander(event) {
        /*this.snackBar.open('Browser close detected. Logging out.', 'OK', {duration:3000});

        this.authService.logout().then(
            data => this.handleLogoutSuccess()
          ).catch(
            error => this.handleLogoutError(error)
          );*/
    }

    /*handleLogoutSuccess() {
        this.snackBar.open('You are now logged out', 'Undo', {duration:3000});
    }

    handleLogoutError(error) {
        this.snackBar.open('Something went wrong: ' + error, 'Undo', {duration: 3000});
    }*/

    constructor(cd: ChangeDetectorRef, private injector: Injector) {
        this.cd = cd;
        this.configurationService = injector.get(ConfigurationService);
        this.authService = injector.get(AuthServiceEuroglot);
        this.snackBar = injector.get(MatSnackBar);
        //console.log("Initializing top level component")

        this.sourceLanguageChangedSubscription = this.configurationService.sourceLanguageChangedEmitter.subscribe({
            next: () => {
                this.sourceLanguageChanged();
            }
        })
        this.targetLanguageChangedSubscription = this.configurationService.targetLanguageChangedEmitter.subscribe({
            next: () => {
                this.targetLanguageChanged();
            }
        })
    }

    ngOnInit() {
        this.configurationService.handleInitialConfig().then(resolved=>{
            this.onConfigLoaded();
        }).catch(err=>{
            this.snackBar.open('Error requesting your configuration: ' + err, 'OK', {duration:3000});
        });
    }

    abstract onConfigLoaded();
    abstract sourceLanguageChanged();
    abstract targetLanguageChanged();
    
    //refresh after changes occurred
    refresh(){
        this.cd.detectChanges();
    }
}
