import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Merger2Service, DictionaryInfosEvent} from '../../../services/dicts-services/merger2.service';

export interface DictionaryInfo {
  name: string;
  concepts: number;
}

@Component({
  selector: 'app-select-dict',
  templateUrl: './select-dict.component.html',
  styleUrls: ['./select-dict.component.css']
})


export class SelectDictComponent implements OnInit {
  dictionary_infos: any[];
  dictionary_data: DictionaryInfo[]
  displayedColumns: string[] = ['name', 'concepts', 'actions'];
  private dictionaryInfoEventSubscription;
  //dataSource = new MatTableDataSource<DictionaryInfo>(this.dictionary_data);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private merger2Service: Merger2Service) {
    this.dictionaryInfoEventSubscription = merger2Service.dictionaryInfosEmitter.subscribe({
			next: (event: DictionaryInfosEvent) => {
					this.handleDictionaryInfoEvent(event);
			}
		})
  }

  ngOnInit() {
    //this.dataSource.paginator = this.paginator;
    this.getDictionaryInfos();
  }

  handleDictionaryInfoEvent(event:DictionaryInfosEvent){
    this.dictionary_data = [];
    for(let dict_info of event.data){
      let d = <DictionaryInfo>{};
      d.name = dict_info.name;
      d.concepts = dict_info.concepts;
      this.dictionary_data.push(d)
    }
  }

  getDictionaryInfos(){

     this.merger2Service.getDictionaryInfos()

  }










}
