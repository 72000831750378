import { OnInit, NgModule, InjectionToken, ChangeDetectorRef, QueryList, Inject, Injector } from '@angular/core';
import { ThesaurusComponent } from './thesaurus/thesaurus.component';
import { MenuContextComponent } from '../menu-context/menu-context.component';
import {MatExpansionPanel} from '@angular/material/expansion';
import { ResourceService, ResourceMapInterface } from '../../shared-services/resource.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Subscription } from 'rxjs';


export const DICTIONARY: InjectionToken<string> = new InjectionToken<string>('DICTIONARY');

@NgModule({
  providers: [{provide: DICTIONARY, useValue: 'My Dictionary Title'}]
})

export abstract class BasePanel implements OnInit {
    abstract thesaurusChildren: QueryList<ThesaurusComponent>;
    abstract contextMenu: MenuContextComponent;
    abstract collapsibleChildren: QueryList<MatExpansionPanel>;

    //@Inject(ResourceService) private resourceService: ResourceService
    private resourceService: ResourceService;
    private configurationService: ConfigurationService;

    public sourceLanguage: string;
    public targetLanguage: string;

    private sourceLanguageChangedSubscription : Subscription;
    private targetLanguageChangedSubscription : Subscription;

    private cd: ChangeDetectorRef;
    dictionary: string;
    attributesStr: string;
    currentResourceMaps: ResourceMapInterface;
    sourceFlag: string;
    targetFlag: string;


    constructor(cd: ChangeDetectorRef, private injector: Injector, @Inject(DICTIONARY)  _dictionary : string,
    ) {
      this.cd = cd;
      this.dictionary = _dictionary;
      this.resourceService = injector.get(ResourceService);
      this.configurationService = injector.get(ConfigurationService);
      //console.log("Initializing base panel (" + dictionary + ")")

      this.sourceLanguageChangedSubscription = this.configurationService.sourceLanguageChangedEmitter.subscribe({
          next: () => {
            this.sourceLanguageChanged();
          }
      })
      this.targetLanguageChangedSubscription = this.configurationService.targetLanguageChangedEmitter.subscribe({
          next: () => {
            this.targetLanguageChanged();
          }
      })
    }

    ngOnInit() {
      //console.log("SUBSCRIBE\n")
      this.resourceService.currentResourceMaps.subscribe(currentResourceMap => this.currentResourceMaps = currentResourceMap);
      //console.log(JSON.stringify(this.currentResourceMaps))

      this.sourceLanguageChanged();
      this.targetLanguageChanged();
    }

    targetLanguageChanged(){
      this.targetLanguage = this.configurationService.getTargetLanguage()
      if(this.targetLanguage == "nl"){
        this.targetFlag = "assets/icons/nl.png";
      } else if(this.targetLanguage == "de"){
        this.targetFlag = "assets/icons/de.png";
      } else if(this.targetLanguage == "fr"){
        this.targetFlag = "assets/icons/fr.png";
      }else if(this.targetLanguage == "en"){
        this.targetFlag = "assets/icons/en.png";
      }else if(this.targetLanguage == "it"){
        this.targetFlag = "assets/icons/it.png";
      } else if(this.targetLanguage == "es"){
        this.targetFlag = "assets/icons/es.png";
      }
    }

    sourceLanguageChanged(){
      this.sourceLanguage = this.configurationService.getSourceLanguage()
      if(this.sourceLanguage == "nl"){
        this.sourceFlag = "assets/icons/nl.png";
      } else if(this.sourceLanguage == "de"){
        this.sourceFlag = "assets/icons/de.png";
      } else if(this.sourceLanguage == "fr"){
        this.sourceFlag = "assets/icons/fr.png";
      }else if(this.sourceLanguage == "en"){
        this.sourceFlag = "assets/icons/en.png";
      }else if(this.sourceLanguage == "it"){
        this.sourceFlag = "assets/icons/it.png";
      } else if(this.sourceLanguage == "es"){
        this.sourceFlag = "assets/icons/es.png";
      }
    }


    /*getWordTypeResources(concepts: any) {
      if (concepts == undefined) {
        return;
      }
      var screenLanguage = this.configurationService.getScreenLanguage();
      for(var concept of concepts) {
        var key = concept.data.lemmas[0].data.word_type;
        this.resourceService.resourceWordType(key, screenLanguage)
      }
    }*/

    refresh(){
      this.cd.detectChanges();
    }

    showContextMenu($event, language, conceptid, conceptAttributes, dictionary, lemmaitem){
       // console.log("EVENT" + $event + JSON.stringify(language) + JSON.stringify(lemmaitem)  )
        this.contextMenu.onContextMenu($event, language, conceptid, conceptAttributes,  dictionary, lemmaitem)

    }

    collapsibleOpened(conceptid : number) {
      //console.log("ID COLLAP OPENED" + conceptid);
      //console.dir(basePanelComponent.contextMenu)
       this.thesaurusChildren.forEach(function(element){
         if(element.conceptid == conceptid){
             element.loadThesaurus();
             return;
         }
       })
    }

    getCollapsibleOpenedCallback(conceptid : number) {
        //console.log("Creating callback for dict:" + this.dictionary)
        var basePanelComponent = this;

        let myCallback = function(el) {
          basePanelComponent.collapsibleOpened(conceptid);
        }

        return myCallback;
    }

    collapsibleClosed(conceptid : number) {
      //console.log("ID COLLAP CLOSED" + conceptid);
    }

    getCollapsibleClosedCallback(conceptid : number) {
      var basePanelComponent = this;
      let myCallback = function(el) {
        // console.log("Callback called for closing collapsible " + conceptid);
        basePanelComponent.collapsibleClosed(conceptid);
      }
      return myCallback;
    }

    closeCollapsible(collapsible : MatExpansionPanel, conceptid : number) {

      collapsible.close()

      /*console.log("ID CLOSE COLLAP" + conceptid);
      let elementId = "concept_card_" + conceptid;
      this.collapsibleChildren.forEach(function(element){
        if(element.items.first.elementRef.nativeElement.id == elementId){
          element.close()
        }
      })*/
    }



    parseAttributes(obj) {
        this.attributesStr = obj.data.word_type
        if (obj.data.gender !== undefined) {
          this.attributesStr = this.attributesStr+'<br>'+obj.data.gender
        }
        if (obj.data.attributes !== undefined) {
          var i
          for(i in obj.data.attributes){
            this.attributesStr = this.attributesStr+'<br>'+obj.data.attributes[i]
          }
        }
    }


}
