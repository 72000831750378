import { Component, OnInit, AfterViewInit } from '@angular/core';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {AuthServiceEuroglot, LoggedInEvent, IUser} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.css']
})

export class LoginSsoComponent implements OnInit {
  private loggedInSubscription;
  username: string;
  password: string;
  constructor(private route: ActivatedRoute, private router: Router, public snackBar:MatSnackBar, private authService: AuthServiceEuroglot) {
    this.loggedInSubscription = authService.loggedInEmitter.subscribe({
			next: (event: LoggedInEvent) => {
					this.handleLogin(event)
			}
		})
  }

  ngOnInit() {
    let username = this.route.snapshot.queryParams["username"];
    let password = this.route.snapshot.queryParams["password"];

  }

  ngAfterViewInit() {
    this.username = this.route.snapshot.queryParamMap.get("username");
    this.password = this.route.snapshot.queryParamMap.get("password");
    //console.log("params" + this.username + this.password)
    this.onLoginSubmit();


		this.isLoggedIn()
	}


	onLoginSubmit(){
		const user:IUser = {
			username: this.username,
			password: this.password,
      resetHash: ""
		}
    //console.log("User" + JSON.stringify(user))
		this.authService.authenticateUser(user);

  }

  handleLogin(event : LoggedInEvent) {
		if (event.loggedIn) {
			this.snackBar.open('You are now logged in', 'OK', {duration:3000});
			this.router.navigate(['dictionary']);
		} else {
			//console.log("event message" + event.data.data.message)
			this.snackBar.open(event.data.data.message, 'OK', {duration:3000});
			this.router.navigate(['login']);
		}
	}

  isLoggedIn(){
		if (this.authService.checkLogin()) {
			this.snackBar.open('You are already logged in', 'OK', {duration:3000});
			this.router.navigate(['dictionary']);
		}
	}

}
