import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {PaymentService, MethodDetails} from '../../../services/payment.service';


export interface PaymentData {
 name: string,
 email: string,
 method: string,
 issuer: string
}

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.css']
})
export class PaymentDialogComponent implements OnInit {
  acceptanceCheckbox = new FormControl(false);
  isLinear = false;
  redirectlink : string;
  detailsForm : FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);
  name = new FormControl('', [Validators.required])
  companyName = new FormControl;
  dutchBanks : number = 12;
  dutchBankNames : string[] = ['ABN AMRO', 'Rabobank', 'ASN Bank', 'bunq', 'Handelsbanken', 'ING', 'Knab', 'Money You', 'RegioBank', 'SNS Bank', 'Triodos Bank', 'Van Lanschot Bankiers']
  dutchBankValues : string[] = ['ideal_ABNANL2A', 'ideal_RABONL2U', 'ideal_ASNBNL21', 'ideal_BUNQNL2A', 'ideal_HANDNL2A', 'ideal_INGBNL2A', 'ideal_KNABNL2H', 'ideal_MOYONL21', 'ideal_RBRBNL21', 'ideal_SNSBNL2A', 'ideal_TRIONL24', 'ideal_FVLBNL22']
  kbcIssuers : string[] = ['KBC', 'CBC']
  paymentSelected = new FormControl('', [Validators.required]);
  paymentDescription = new FormControl;
  selectedEuCompany = new FormControl;
  paymentIssuer = new FormControl;
  vatNumber = new FormControl;
  providers : number = 9;
  providerNames : string[] = ['Creditcard', 'Bancontact', 'Ideal', 'Belfius', 'KBC', 'ING Homepay', 'Sofort Banking', 'Paypal', 'Apple' ];
  providerValues : string[] = ['creditcard', 'bancontact', 'ideal', 'belfius','kbc', 'inghomepay', 'sofort', 'paypal', 'apple' ];
  productData  = [];
  productArray = [];

  creditcardForm: FormGroup
  constructor(private paymentservice: PaymentService, private fb: FormBuilder, private dialogRef: MatDialogRef<PaymentDialogComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {
    this.selectedEuCompany.setValue(false);
    this.detailsForm = fb.group({
      "name": this.name,
      "companyName": this.companyName,
      "email": this.email,
      "selectedEuCompany": this.selectedEuCompany,
      "paymentSelected": this.paymentSelected,
      "paymentDescription": this.paymentDescription,
      "vatNumber": this.vatNumber,
      "paymentIssuer": this.paymentIssuer,
      "acceptanceCheckbox": this.acceptanceCheckbox
    })
  }

  ngOnInit() {
   this.getProducts();
  }


  getBankName(value : string): string{
    for(let i = 0; i < this.dutchBanks; i++ ){
      if(value == this.dutchBankValues[i]){
        return this.dutchBankNames[i];
      }
    }
    return value
  }

  getName(value : string): string{

    for(let i = 0; i < this.providers; i++ ){
      if(value == this.providerValues[i]){
        return this.providerNames[i];
      }
    }

    return value;
  }

  handleProductSuccess(data){

    this.productData.push(data);
    this.productArray = this.productData[0].data;


  }

  handleProductError(error){
    console.log(error)
  }

  getProducts(){
    this.paymentservice.getProducts().subscribe(
      data => this.handleProductSuccess(data),
      error => this.handleProductError(error)
    )
  }

  onCloseConfirm(){
    var paymentDetails: MethodDetails = {} as MethodDetails;
    var paymentMethod = this.paymentSelected.value;

    if(paymentMethod == 'ideal'){
      paymentDetails.ideal_issuer = this.paymentIssuer.value;
    }
    if(paymentMethod == 'kbc'){
      paymentDetails.kbc_issuer = this.paymentIssuer.value;
      paymentDetails.description = this.paymentDescription.value;

    }
    if(paymentMethod == 'paypal'){
      paymentDetails.description = this.paymentDescription.value;
    }

    //console.log("Confirm" + "name" + this.name.value + "email"+ this.email.value + "selected eu company" + this.selectedEuCompany.value  + "vatNumber" + this.vatNumber.value +  "payment selected" + this.paymentSelected.value + "payment issuer" + this.paymentIssuer.value + "description" + this.paymentDescription + "purchase" + JSON.stringify(this.modalData))
    if(this.modalData.subscription){
      //console.log("this is a subscription")
      this.paymentservice.buySubscription(this.name.value, this.email.value, paymentMethod, paymentDetails, this.vatNumber.value, this.selectedEuCompany.value).subscribe(
        data => this.handlePurchaseSuccess(data),
        error => this.handlePurchaseError(error)
      )
    } else {
      this.paymentservice.purchase(this.name.value, this.email.value, paymentMethod, paymentDetails, this.vatNumber.value, this.selectedEuCompany.value, this.modalData).subscribe(
        data => this.handlePurchaseSuccess(data),
        error => this.handlePurchaseError(error)
      )
    }



  }

  handlePurchaseSuccess(data){
    this.redirectlink = data.href
    window.location.href = this.redirectlink;

  }

  handlePurchaseError(error){
    console.log(error)
  }

  onCloseCancel(){
    this.dialogRef.close("Cancel")

  }
  providerDetailsPlaceholder(provider : string): string[]{

    switch(provider){

      case "ideal":
      return ["Bank"]

      case "kbc":
      return ["Bank", "Description"]

      case "paypal":
      return ["Description"]

      case "apple":
      return ["Apple Token"]
    }

    return null
  }
  providerDetailsFieldName(provider : string): string[]{

    switch(provider){
      case "ideal":
      return ["ideal_issuer"]

      case "kbc":
      return ["kbc_issuer", "description"]

      case "paypal":
      return ["description"]

      case "apple":
      return ["apple_token"]

    }

    return null

  }
  providerDetailsSecondFieldName(provider : string):string{
    switch(provider){

      case "KBC":
      return "description"
    }

    return null
  }

  providerDetailsSecondPlaceholder(provider : string): string{
    switch(provider){

      case "KBC":
      return "Description"
    }

    return null
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
        this.email.hasError('email') ? 'Not a valid email' :
            '';
  }


}
