import { Mark, Node, NodeType } from "prosemirror-model";

import { NgxEditorComponent } from 'ngx-editor';

import { colMarkType, default as schema } from './editor/schema';
import { ColMarkAttrs } from './editor/plugins/root';
import { SynonymClickedData } from './editor/plugins/events';
import { IJSONDocJSON, IJSONDocNode, JSONDocContent, compareDeep, AttrsType, emptyAttrs, MarkType, NEWLINE } from "./jsondoc_interface"
import { getTextFromJSONDoc, getHTMLFromJSONDoc, TextSections, TextSection, getTextSectionsFromJSONDoc } from "./jsondoc_convert"

function IJSONDocJSONtoJSONDocJSON(json : IJSONDocJSON) : JSONDocJSON {
  return new JSONDocJSON(json.type, json.attrs, json.content, json.text, json.marks)
}

function NodeToJSONDocJSON(node : Node) : JSONDocJSON {
  return Object.assign(new JSONDocJSON("x"), node.toJSON());
}

export class JSONDocJSON implements IJSONDocJSON {

    type: string;
    content?: JSONDocContent;
    text?: string;
    attrs?: AttrsType;
    marks?: MarkType;

    constructor(type : string, attrs? : AttrsType, content? : JSONDocContent, text? : string, marks? : MarkType) {
      this.type = type;
      this.content = JSONDocJSON.copyContent(content);
      this.text = text;
      this.attrs = Object.assign({}, attrs);
      this.marks = Mark.setFrom(marks);
    }

    copy() : JSONDocJSON {
      let marks = Mark.setFrom(this.marks);
      let attrs = Object.assign({}, this.attrs);

      let content = JSONDocJSON.copyContent(this.content);

      return new JSONDocJSON(this.type, attrs, content, this.text, marks);
    }

    private static copyContent(content : JSONDocContent) : JSONDocContent {
      if(content == null) {
        return null;
      }

      var c : JSONDocContent = new JSONDocContent();
      for(let i = 0; i < content.length; i++) {
        let n = JSONDocJSON.copyNode(content[i]);
        c.push(n);
      }

      return c;
    }

    private static copyNode(node : IJSONDocJSON) : IJSONDocJSON {
      if(node == null) {
        return null;
      }

      var n = null;
      if(node instanceof JSONDocJSON) {
        n = node.copy();
      } /*else if(node instanceof IJSONDocJSON) {
        n = node.copy();
      }*/ else {
        //console.log("Unsupported: " + node.constructor.name);
        let m = Mark.setFrom(node.marks);
        let a = Object.assign({}, node.attrs);
        let c = JSONDocJSON.copyContent(node.content);
        n = new JSONDocJSON(node.type, a, c, node.text, m)
      }

      return n;
    }

    valid() : boolean {
      return this.type.length != 0
    }


    hasContent() : boolean {
      if((this.text != undefined) && (this.text.length > 0)) {
        return true;
      }

      return (this.content != undefined) && this.content.hasContent();
    }

    sameMarkup(other : IJSONDocJSON) : boolean {
      return this.hasMarkup(other.type, other.attrs, other.marks);
    }

    hasMarkup(type, attrs, marks) {
      return this.type == type &&
        compareDeep(this.attrs, attrs /*|| type.defaultAttrs*/ || emptyAttrs) &&
        Mark.sameSet(this.marks, marks || Mark.none)
    }

    merge(next : IJSONDocJSON) : boolean {
      if(this.type != next.type) return false;
      if(!this.sameMarkup(next)) return false;

      if (this.text == undefined) {
        this.text == next.text;
      } else {
        if(next.text != undefined)
          this.text += next.text;
      }

      if(this.content == undefined) {
        this.content = next.content;
      } else {
        if(next.content != undefined)
          this.content.push(...next.content);
      }

      return true;
    }

    add(node : IJSONDocJSON) {
      let n : IJSONDocJSON = JSONDocJSON.copyNode(node)
      this.content.push(n);
    }

    //Attributes to primary node doesn't seem to work
    /*setReadOnly(flag : boolean) {
      if(this.attrs.disabled != undefined)
        this.attrs.disabled = flag;
    }
    setSource(flag : boolean) {
      if(this.attrs.source != undefined)
        this.attrs.source = flag;
    }*/
}




export class TranslatorSectionData {
  private data : any[];

  constructor(data : any[]) {
    //TODO store data correctly
console.log("TODO STORE DATA: ")
console.dir(data)
    this.data = data
  }

  copy() : TranslatorSectionData {
    var d : TranslatorSectionData = new TranslatorSectionData(this.data);
    return d;
  }

  length() : number {
    return this.data.length;
  }

  getSentence(i : number) : any {
    return this.data[i];
  }

  replaceAlt(col : number, sentence : number, alt : string, replace : string) {
    for(let i = 0; i < this.data[sentence].length; i++) {
      let node = this.data[sentence][i];
      if(node["col"] == col) {
//console.log("ReplaceAlt start: " + node["word"] + " | " + node["alts"])
        let pos : number = node["alts"].indexOf(alt);
        node["alts"].splice(0, 0, replace);
        node["alts"].splice(pos+1, 1);
        node["word"] = alt;
//console.log("ReplaceAlt end: " + node["word"] + " | " + node["alts"])
        return;
      }
    }
  }
}

export class JSONDoc {

    private originalDoc : JSONDocJSON;
    private sourceTextSections : TextSections;
    //private targetTextSections : JSONDocTextSection[];
    private sourceTranslatorSections : TranslatorSectionData[];
    private targetTranslatorSections : TranslatorSectionData[];

    constructor(j?:IJSONDocJSON){
      this.setOriginalDoc(j);
      //this.targetTextSections = [];
    }

    toString():string{
      if(!this.originalDoc.valid()){
          return "";
      }

      return getTextFromJSONDoc(this.originalDoc)
    }

    setOriginalDoc(j:IJSONDocJSON) {
      if(j == null) {
        this.originalDoc = null;
        return
      }
      this.originalDoc = IJSONDocJSONtoJSONDocJSON(j);
      removeColMarksFromJSONDoc(this.originalDoc);
      //console.dir(this.originalDoc)
      let found = getTextSectionsFromJSONDoc(this.originalDoc);
      if(typeof found === "string") {
        this.sourceTextSections = new TextSections();
        let seg = new TextSection();
        this.sourceTextSections.push(seg);
      } else {
        this.sourceTextSections = <TextSections>found;
      }
      //console.log("SOURCE TEXT SECTIONS AT CONSTRUCTION")
      //console.dir(this.sourceTextSections)
    }

    /*getSourceTextNodes() : JSONDocTextSection[] {
      return this.sourceTextNodes;
    }


    getTargetTextNodes() : JSONDocTextSection[] {
      return this.targetTextNodes;
    }*/

    getText():string {
      return getTextFromJSONDoc(this.originalDoc);
    }

    getHTML():string {
      return getHTMLFromJSONDoc(this.originalDoc);
    }

    getTextSections() : string[] {
      if(this.sourceTextSections == null){
        return null
      }
      return this.sourceTextSections.getText();
    }


    storeSourceSectionFromTranslator(section : number, sentenceData : any[]) {
      if(this.sourceTranslatorSections == null) {
        this.sourceTranslatorSections = new Array(this.sourceTextSections.length);
      }

      this.sourceTranslatorSections[section] = new TranslatorSectionData(sentenceData);
    }

    storeTargetSectionFromTranslator(section : number, sentenceData : any[]) {
      if(this.targetTranslatorSections == null) {
        this.targetTranslatorSections = new Array(this.sourceTextSections.length);
      }

      this.targetTranslatorSections[section] = new TranslatorSectionData(sentenceData);
    }

    hasAllSectionsFromTranslator() : boolean {
      for(let i = 0; i < this.sourceTranslatorSections.length; i++) {
        if(this.sourceTranslatorSections[i] == null)
          return false;
      }
      for(let i = 0; i < this.targetTranslatorSections.length; i++) {
        if(this.targetTranslatorSections[i] == null)
          return false;
      }

      return true;
    }

    createErrorSection(section : number, errorMessage : string) {
      if(this.sourceTranslatorSections == null) {
        this.sourceTranslatorSections = new Array(this.sourceTextSections.length);
      }
      if(this.targetTranslatorSections == null) {
        this.targetTranslatorSections = new Array(this.sourceTextSections.length);
      }

      let sel = this.sourceTextSections[section];
      let errorData = new Array<any>(1);
      errorData[0] = [{
        "word" : sel.toString()
      }];
      this.sourceTranslatorSections[section] = new TranslatorSectionData(errorData);
      errorData = new Array<any>(1);
      errorData[0] = [{
        "word" : "An error has occured translating a segment: '" + errorMessage + "'. Untranslated contents: \"" + sel.toString() + "\"."
      }];

      this.targetTranslatorSections[section] = new TranslatorSectionData(errorData);
    }

    getSourceEditorText(colClassName : string, hasIPuncMarks : boolean) : IJSONDocJSON {
      if(!this.sourceTranslatorSections || this.sourceTranslatorSections.length == 0 ) {
        return null;
      }

      var doc : JSONDocJSON = this.originalDoc.copy();
      var sections : TranslatorSectionData[] = [];
      for(let i = 0; i < this.sourceTranslatorSections.length; i++) {
        sections.push(this.sourceTranslatorSections[i].copy());
      }
      replaceTextSectionsInJSONDoc(doc, sections, colClassName, 0, hasIPuncMarks);

      return doc
    }

    getTranslateEditorText(colClassName : string, hasIPuncMarks : boolean) : IJSONDocJSON {
      if(!this.targetTranslatorSections || this.targetTranslatorSections.length == 0 ) {
        return null;
      }

      var doc : JSONDocJSON = this.originalDoc.copy();
      var sections : TranslatorSectionData[] = [];
      for(let i = 0; i < this.targetTranslatorSections.length; i++) {
        sections.push(this.targetTranslatorSections[i].copy());
      }
      replaceTextSectionsInJSONDoc(doc, sections, colClassName, 0, hasIPuncMarks);

      return doc
    }

    getTranslateAlts(col : number, sentence : number, section : number) : string[] {
      if(!this.targetTranslatorSections || this.targetTranslatorSections.length == 0 ) {
        return null;
      }

      let sectionData = this.targetTranslatorSections[section];
      let sentenceData = sectionData.getSentence(sentence);

      for(let i = 0; i < sentenceData.length; i++) {
        let node = sentenceData[i];
        if(node["col"] == col) {
          return node["alts"];
        }
      }

      return null;
    }

    replaceTranslateAlt(altData : SynonymClickedData, replace : string) {
      if(!this.targetTranslatorSections || this.targetTranslatorSections.length == 0 ) {
        return;
      }

      let sectionData = this.targetTranslatorSections[altData.section];
      sectionData.replaceAlt(altData.col, altData.sentence, altData.alt, replace);
    }
  }

  function removeColMark(node : IJSONDocJSON) {
    for(let j = node.marks.length; j > 0; j--) {
      if(node.marks[j-1].type == colMarkType) {
        node.marks.splice(j-1, 1)
      }
    }
    if(node.marks.length == 0)
      node.marks = Mark.none;
  }

  function markTest(node : IJSONDocJSON) {
    for(let i = 0; i < node.marks.length; i++) {
      let mark = node.marks[i];
      if(mark.eq == undefined) {
        //console.log("FAKE NODE MARK")
        //console.dir(mark)
        //console.log("ATTEMPT REPAIR")
        mark = new Mark(mark.type, mark.attrs);
        //console.dir(mark)
        node.marks[i] = mark;
      }
    }
  }

  function toggleMark(marks : Mark[], mark : Mark) {
    for(let j = marks.length; j > 0; j--) {
      if(marks[j-1].eq(mark)) {
        marks.splice(j-1, 1)
        return;
      }
    }

    marks.push(mark);
  }

  function marksCopy(marks : MarkType) : MarkType {
    let newMarks : MarkType = [];
    for(let i = 0; i < marks.length; i++) {
      let mark : Mark = new Mark(marks[i].type, marks[i].attrs);
      newMarks.push(mark);
    }
    return newMarks;
  }

  function mergeDocNodeDataForColMarks(doc : IJSONDocJSON[]) : IJSONDocJSON[] {
    if(doc == null || doc.length == 0) {
      return doc;
    }
    if(doc.length == 1) {
      removeColMark(doc[0]);
      return doc
    }

    var nodes : IJSONDocJSON[] = [];

    for(let i = 0; i < doc.length; i++) {
      let node = doc[i].copy()
      removeColMark(node);
      let success = false;

      markTest(node)

      if(nodes.length > 0) {
        markTest(nodes[nodes.length - 1])
      }



      if(nodes.length != 0 && nodes[nodes.length - 1].sameMarkup(node)) {
        success = nodes[nodes.length - 1].merge(node)
      }
      if(!success) {
        nodes.push(node);
      }
    }

    return nodes;
  }

  function removeColMarksFromJSONDoc(doc : IJSONDocJSON) {
    if(doc.content){
      let firstText = 0;
      let i = 0;
      for(; i < doc.content.length; i++){
        let node = doc.content[i];
        if((node.type == "text") || (node.type == "hard_break")){
          ;
        } else {
          if(i > firstText) {
            let newNodes = mergeDocNodeDataForColMarks(doc.content.slice(firstText, i));
            doc.content.splice(firstText, i - firstText, ...newNodes);
            i = i - firstText + newNodes.length;
          }
          removeColMarksFromJSONDoc(doc.content[i]);
          firstText = i + 1
        }
      }
      if(i > firstText) {
        let newNodes = mergeDocNodeDataForColMarks(doc.content.slice(firstText, i));
        doc.content.splice(firstText, i - firstText, ...newNodes);
      }
    }
  }

  function replaceDocNodeDataForTextSection(doc : IJSONDocJSON[], textSection : TranslatorSectionData, colClassName : string, sectionCount : number, hasIPuncMarks : boolean) : IJSONDocJSON[] {
    var nodes : IJSONDocJSON[] = [];

    if(textSection == null) {
      return nodes;
    }

    //Use doc attributes and marks for all nodes made through textSection if doc only contains one text segment
    let attrs = null;
    let marks = Mark.none
    if(doc.length == 1) {
      attrs = Object.assign({}, doc[0].attrs);
      marks = Mark.setFrom(doc[0].marks);
    }
    //TODO copy attrs when multiple doc text segments are involved.

    for(let i = 0; i < textSection.length(); i++) {
      let sentence = textSection.getSentence(i);
      let bIPUNC : boolean = false;
      for(let j = 0; j < sentence.length; j++) {

        //Check for lipunc/ripunc for spaces between words/ipunc
        if(hasIPuncMarks && (j != 0) && !bIPUNC && !sentence[j].eIPUNC) {
          //Space between words
          let space = new JSONDocJSON("text", attrs, null, " ", marks);
          nodes.push(space);
        }
        bIPUNC = sentence[j].bIPUNC;

        //No empty words alowed
        if(sentence[j].word == null || sentence[j].word == "") {
          sentence[j].word = " "; //All 'word's must have a contents. To hide empty words somewhat in the editor, replace them by spaces.
        }

        let newMarks : MarkType = marksCopy(marks);

        //Set COL mark
        if(sentence[j].col != null) {
          let alts : string | false = false;
          if(sentence[j].alts) {
              alts = sentence[j].alts.join('|');
          }
          let colMark : Mark = makeColMark(sentence[j].col, i, sectionCount, alts, colClassName);
          newMarks.push(colMark);
        }

        //Check for UNK
        let isUnk = unkCheck(sentence[j].word)
        if(isUnk != false) {
          sentence[j].word = isUnk;
          toggleMark(newMarks, new Mark("em", undefined))
        }

        let node = new JSONDocJSON("text", attrs, null, sentence[j].word, newMarks);
        nodes.push(node);
      }

      //Space between sentences
      if(sentence.eol_space != null && sentence.eol_space != "") {
        let eolSpaceText = new JSONDocJSON("text", attrs, null, sentence.eol_space.slice(1, sentence.eol_space.length-1), marks);
        nodes.push(eolSpaceText);
      }
    }

    return nodes;
  }

  function replaceTextSectionsInJSONDoc(doc : IJSONDocJSON, textSections : TranslatorSectionData[], colClassName : string, sectionCount : number, hasIPuncMarks : boolean) : number {
    //var addLevel : boolean = false;
    switch(doc.type){
      case "doc": {
        break;
      }
      case "paragraph": {
        //addLevel = true;
        break;
      }
      case "text": {
        doc.text = "[Error: Shouldn't handle text node here]";
        return sectionCount;
      }
      case "hard_break": {
        doc.text = "[Error: Shouldn't handle hard_break node here]";
        return sectionCount;
      }
      case "heading": {
        break;
      }
      case "bullet_list": {
        break;
      }
      case "ordered_list": {
        break;
      }
      case "list_item": {
        //addLevel = true;
        break;
      }
      case "horizontal_rule": {
        doc.text = "[Error: Shouldn't handle horizontal_rule node here]";
        return sectionCount;
      }
      case "image": {
        break;
      }
      default: {
        doc.text = "[Error: Unknown type (" + doc.type + ")]";
        return sectionCount;
      }
    }

    if(doc.content){
      let firstText = 0;
      let i = 0;
      for(; i < doc.content.length; i++){
        let node = doc.content[i];
        if((node.type == "text") || (node.type == "hard_break") || (node.type == "horizontal_rule")){
          ;
        } else {
          if(i > firstText) {
            let newNodes = replaceDocNodeDataForTextSection(doc.content.slice(firstText, i), textSections[0], colClassName, sectionCount, hasIPuncMarks);
            doc.content.splice(firstText, i - firstText, ...newNodes);
            textSections.shift()
            sectionCount++;
            i = i - firstText + newNodes.length;
          }
          sectionCount = replaceTextSectionsInJSONDoc(doc.content[i], textSections, colClassName, sectionCount, hasIPuncMarks);
          firstText = i + 1
        }
      }
      if(i > firstText) {
        let newNodes = replaceDocNodeDataForTextSection(doc.content.slice(firstText, i), textSections[0], colClassName, sectionCount, hasIPuncMarks);
        doc.content.splice(firstText, i - firstText, ...newNodes);
        textSections.shift()
        sectionCount++;
      }
    }

    return sectionCount;
  }


  export function emptyJSONDoc() : IJSONDocJSON {
    let paragraph : Node = schema.node("paragraph", emptyAttrs, null, Mark.none);
    let doc : Node = schema.node("doc", emptyAttrs, paragraph, Mark.none);
    return NodeToJSONDocJSON(doc);
  }

  export function isEmptyJSONDoc(doc : IJSONDocJSON) : boolean {
    if(doc.hasContent != undefined) {
      return !doc.hasContent;
    }

    if(doc.text != undefined && doc.text != "")
      return false;

    if(doc.content.length > 1)
      return false;

    if(doc.content.length == 0)
      return true;

    if(doc.content[0].text != undefined && doc.content[0].text != "")
      return false;

    if(doc.content[0].content != undefined && doc.content[0].content.length > 0)
      return false;

    return true;
  }

  function unkCheck(word : string) : string | boolean {
    let ix : number = -1;
    if((ix = word.indexOf("<unk:")) != -1) {
      let endPart = word.substr(ix+5);
      let ix2 = endPart.indexOf(">")
      if (ix2 == -1) {
        word = word.substr(0,ix) + endPart
      } else {
        word = word.substr(0,ix) + "<" + endPart.substr(0,ix2) + ">" + endPart.substr(ix2+1)
      }
      return word;
    }

    return false;
  }

  function makeColMark(col: number, sentence : number, section : number, alts : string | false, colClassName : string) : Mark {
    let colMarkAttrs : ColMarkAttrs = {
      "spanClass": colClassName,
      "col":col,
      "sentence":sentence,
      "section":section,
      "alt_translations":alts
    };

    return new Mark(colMarkType, colMarkAttrs);
  }

  function makeJSONDocFromTranslator(sentences : any[], colClassName : string) : IJSONDocJSON {
    let doc = emptyJSONDoc()

    let paragraph : JSONDocJSON = new JSONDocJSON("paragraph", null, new JSONDocContent(), "", null);

    let l = sentences.length;
    for(let i = 0; i < l; i++) {
      let sentence = sentences[i];
      let l3 = sentence.length;
      let lipunc : boolean = false;
      for(let j = 0; j < l3; j++) {
        if((j != 0) && !lipunc && !sentence[j].ripunc) {
          //Space between words
          let space = new JSONDocJSON("text", null, null, " ", null);

          paragraph.add(space);
        }
        lipunc = sentence[j].lipunc;

        let word = sentence[j].word;
        if(word == '') word = "*"; //TODO empty word nodes?

        //Check for UNK
        let isUnk = unkCheck(word);

        //Create marks
        let marks : MarkType = [];
        if(isUnk != false) {
          word = isUnk
          marks.push({type:"em"});
        }

        let alts : string | false = false;
        if(sentence[j].alts) {
            alts = sentence[j].alts.join('|');
        }
        marks.push(makeColMark(sentence[j].col, i, 0, alts, colClassName));

        let textNode = new JSONDocJSON("text", null, null, word, marks);

        paragraph.add(textNode);
      }

      //Space between sentences
      if(sentence.eol_space != null && sentence.eol_space != "") {
        let eolSpaceText = new JSONDocJSON("text", null, null, sentence.eol_space.slice(1, sentence.eol_space.length-1), null);
        paragraph.add(eolSpaceText);
      }
    }
    //console.log(JSON.stringify(paragraph.content))

    doc.content.push(paragraph);

    return doc;
  }
