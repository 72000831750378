   
   <div class="row">
    <div class="col">
       
     <img class="translation-img" src="assets/animation-reg.gif">
  
    </div>
    <div class="col">
      <div class="row">
        <div class="sign-in-box" *ngIf="hashSupplied">
  
            <h3>Set your password</h3>
            <p>After you have set your password you will redirected to the dashboard page</p>
  
            <form [formGroup]="setPasswordForm"  (submit)="submit()" action="" role="form" class="sign-in-box">
                <mat-form-field class="sign-in-field" appearance="outline">
                    <input matInput type="password" formControlName="password" placeholder="New password" text="text" name="password" >
                </mat-form-field>
            
                <mat-form-field class="sign-in-field" appearance="outline">
                    <input matInput type="password" formControlName="password" placeholder="Re-type password" text="text" name="retype_password">
                </mat-form-field>
                <div class="validation-rules">

                  <label class="col" [ngClass]="setPasswordForm.controls['password'].hasError('required') || setPasswordForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons">{{ setPasswordForm.controls['password'].hasError('required') ||
                      setPasswordForm.controls['password'].hasError('minlength') ? 'cancel' :
                      'check_circle' }}</i>
                    Must be at least 8 characters!
                  </label>
                  <label class="col" [ngClass]="setPasswordForm.controls['password'].hasError('required') || setPasswordForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons">{{ setPasswordForm.controls['password'].hasError('required') ||
                      setPasswordForm.controls['password'].hasError('hasNumber') ? 'cancel' :
                      'check_circle' }}</i>
                    Must contain at least 1 number!
                  </label>
                  <label class="col" [ngClass]="setPasswordForm.controls['password'].hasError('required') || setPasswordForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons">{{ setPasswordForm.controls['password'].hasError('required') ||
                      setPasswordForm.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
                      'check_circle' }}</i>
                    Must contain at least 1 in Capital Case!
                  </label>
                  <label class="col" [ngClass]="setPasswordForm.controls['password'].hasError('required') || setPasswordForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons">{{ setPasswordForm.controls['password'].hasError('required') ||
                      setPasswordForm.controls['password'].hasError('hasSmallCase') ? 'cancel' :
                      'check_circle' }}</i>
                    Must contain at least 1 Letter in Small Case!
                  </label>
                
                </div>
                    
                <div class="sign-in-button-box">
                  <button class="sign-in-button" mat-stroked-button color="primary">Send</button>
                </div>          
                        
               
              
            </form>
          
        </div>
      
      </div>
    </div>
  
  </div>
  
  
     
  
  
   