<div class="wrapper">

<div class="row">
    <div class="col">
        <mat-toolbar>
            <span class="logo"><img class="m-logo" src="/assets/img/euroglot-logo.png" width="250px" >Content Assistent</span>
        </mat-toolbar>
    </div>
</div>
<div class="row">
    <div class="col">
       
     <img class="translation-img" src="assets/img/frontimage.jpg" width="800px" height="500px">

    </div>
    <div class="col">
      <div class="row">
        <div class="sign-in-box">

            <h3 class="sign-in-heading">Sign in</h3>
            <p>Sign in with your credentials</p>

            <form class="sign-in-box">
                <mat-form-field class="sign-in-field" appearance="outline">
                    <input matInput placeholder="User name" [(ngModel)]="username" name="username">
                </mat-form-field>
            
                <mat-form-field class="sign-in-field" appearance="outline">
                    <input type="password" matInput placeholder="Password" [(ngModel)]="password" name="password">
                </mat-form-field>
                <!--<div class="sign-in-field">
                     <div class="login-google-box">
                         <a class="google-login" (click)="signInWithGoogle()"><img src="https://img.icons8.com/color/48/000000/google-logo.png" height="25px" width="25px"> Sign in with Google</a>
                    </div>
                      
                </div>-->
                    
            
                <div class="sign-in-field">
                    <button class="sign-in-button" (click)="onLoginSubmit()" class="sign-in-button" mat-stroked-button color="primary">Sign in</button>
                </div>

                <div class="row">
                 
                    <div class="col-md-5">
                     <div class="forgot-password-box">
                        <a [routerLink]="['/forgot-password']">Forgot your password</a>
      
                     </div>
                    </div>
                    
                  
                </div>
                <div class="row">
                 <div class="notification">
                  <h3>Don't have an account yet? Sign up</h3>
                  <div class="sign-up-link">
                   <a [routerLink]="['/register']">Register</a>
                  </div>
                 </div>
                </div>
                
               
              
            </form>
          
        </div>
    
      </div>
    </div>

</div>
</div>
<footer-component></footer-component>






