import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ResourceService} from '../../../shared-services/resource.service';
import { ImportService, ConceptLemmaImportBody} from '../../../services/dicts-services/import.service'


export interface WordData {
  word: string,
  lemma: string,
  variant: number,
  focus: number,
  gender: string;
  word_type: string;
  attributes: string[];
  triggers: string[];
  translation_groups: string[];
}
export interface DialogData {
  conceptId: number;
  conceptwordType: string;
  language: string;
}

@Component({
  selector: 'app-post-new-lemma',
  templateUrl: './post-new-lemma.component.html',
  styleUrls: ['./post-new-lemma.component.css']
})
export class PostNewLemmaComponent implements OnInit {
  newLemma: WordData;
  wordtypes: Array<string>;
  genders: Array<string>;

  constructor(private dialogRef: MatDialogRef<PostNewLemmaComponent>,  @Inject(MAT_DIALOG_DATA) public lemmaDialogData: DialogData, private resourceService: ResourceService, private importService: ImportService) {
    this.newLemma = {} as WordData;
    this.newLemma.word_type = lemmaDialogData.conceptwordType;
  }

  ngOnInit() {
    this.getWordTypes();
    this.getGenders();
  }

  onCloseConfirm(){
    //console.log("Send to the database" + JSON.stringify(this.newLemma));
    let body: ConceptLemmaImportBody = {
      word: this.newLemma.word,
      variant: this.newLemma.variant,
      word_type: this.newLemma.word_type,
      attributes: this.newLemma.attributes,
      gender: this.newLemma.gender,
      focus: null,
      triggers: this.newLemma.triggers
    }


    this.importService.newConceptLemma(this.lemmaDialogData.language, this.lemmaDialogData.conceptId, body).subscribe(
      data =>  this.handlePostConceptLemma(data),
      error => this.handlePostConceptLemmaError(error),
      () => {}
    );

    this.dialogRef.close();
  }

  handlePostConceptLemma(data){
    //console.log("POST DATA" + JSON.stringify(data))

  }
  handlePostConceptLemmaError(error){
    console.log("Error: " + JSON.stringify(error))
  }

  onCloseCancel(){
    //console.log("Cancel");
    this.dialogRef.close();
  }

  getWordTypes(){
    this.wordtypes = this.resourceService.getCachedWordTypes();
  }

  getGenders(){
    this.genders = this.resourceService.getCachedGenders();

  }


}
