//import { getIconSvg } from 'ngx-editor/lib/utils/icons/index';
declare function getIconSvg(name: string, width?: number, height?: number): string;

var DEFAULT_ICON_HEIGHT = "20";
var DEFAULT_ICON_WIDTH = "20";

var emptyIcon = '<path></path>';

// Helper function to create menu icons
export function getExtIconSvg(iconName, width?, height?) {
    if (iconName.length == 1) {
      return iconName;
    }

    var path = iconName || emptyIcon;
    var vbFactor = 24;
    if(iconName == "cut") {
      path = cut;
    } else if(iconName == "copy") {
      path = copy;
    } else if(iconName == "paste") {
      vbFactor = 650;
      path = paste;
    } else if(iconName == "next") {
      path = next;
    } else if(iconName == "prev") {
      path = prev;
    } else if (!iconName.startsWith('<path>')) {
      let icon = getIconSvg(iconName, width, height);
      if(icon != emptyIcon) {
        return icon;
      }
    }

    if (width === void 0) { width = DEFAULT_ICON_WIDTH; }
    if (height === void 0) { height = DEFAULT_ICON_HEIGHT; }

    return "\n  <svg\n    xmlns=\"http://www.w3.org/2000/svg\"\n    viewBox=\"0 0 " + vbFactor + " " + vbFactor + "\"\n  fill=\"black\"\n    height=" + height + "\n    width=" + width + "\n  >\n    " + path + "\n  </svg>\n  ";
}


export const copy = '<path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z"></path>';
export const next = '<path d="M16.76,7.51l-5.199-5.196c-0.234-0.239-0.633-0.066-0.633,0.261v2.534c-0.267-0.035-0.575-0.063-0.881-0.063c-3.813,0-6.915,3.042-6.915,6.783c0,2.516,1.394,4.729,3.729,5.924c0.367,0.189,0.71-0.266,0.451-0.572c-0.678-0.793-1.008-1.645-1.008-2.602c0-2.348,1.93-4.258,4.303-4.258c0.108,0,0.215,0.003,0.321,0.011v2.634c0,0.326,0.398,0.5,0.633,0.262l5.199-5.193C16.906,7.891,16.906,7.652,16.76,7.51 M11.672,12.068V9.995c0-0.185-0.137-0.341-0.318-0.367c-0.219-0.032-0.49-0.05-0.747-0.05c-2.78,0-5.046,2.241-5.046,5c0,0.557,0.099,1.092,0.292,1.602c-1.261-1.111-1.979-2.656-1.979-4.352c0-3.331,2.77-6.041,6.172-6.041c0.438,0,0.886,0.067,1.184,0.123c0.231,0.043,0.441-0.134,0.441-0.366V3.472l4.301,4.3L11.672,12.068z"></path>';
export const prev = '<path d="M3.24,7.51c-0.146,0.142-0.146,0.381,0,0.523l5.199,5.193c0.234,0.238,0.633,0.064,0.633-0.262v-2.634c0.105-0.007,0.212-0.011,0.321-0.011c2.373,0,4.302,1.91,4.302,4.258c0,0.957-0.33,1.809-1.008,2.602c-0.259,0.307,0.084,0.762,0.451,0.572c2.336-1.195,3.73-3.408,3.73-5.924c0-3.741-3.103-6.783-6.916-6.783c-0.307,0-0.615,0.028-0.881,0.063V2.575c0-0.327-0.398-0.5-0.633-0.261L3.24,7.51 M4.027,7.771l4.301-4.3v2.073c0,0.232,0.21,0.409,0.441,0.366c0.298-0.056,0.746-0.123,1.184-0.123c3.402,0,6.172,2.709,6.172,6.041c0,1.695-0.718,3.24-1.979,4.352c0.193-0.51,0.293-1.045,0.293-1.602c0-2.76-2.266-5-5.046-5c-0.256,0-0.528,0.018-0.747,0.05C8.465,9.653,8.328,9.81,8.328,9.995v2.074L4.027,7.771z"></path>';
export const paste = '<path d="M505.918,267.239c-3.809-9.137-8.378-16.371-13.706-21.701L375.726,129.053c-2.854-2.856-6.283-5.52-10.28-7.994V27.415c0-7.616-2.663-14.092-7.994-19.417c-5.325-5.332-11.8-7.994-19.411-7.994H27.406c-7.611,0-14.084,2.663-19.414,7.994C2.663,13.326,0,19.799,0,27.415v383.72c0,7.61,2.663,14.079,7.992,19.403c5.33,5.332,11.803,7.994,19.414,7.994h155.318v45.683c0,7.614,2.662,14.089,7.992,19.414c5.327,5.331,11.8,7.994,19.414,7.994h274.091c7.61,0,14.085-2.663,19.41-7.994c5.332-5.325,7.994-11.8,7.994-19.414v-191.86C511.626,284.744,509.724,276.37,505.918,267.239z M365.446,170.449l85.369,85.364h-85.369V170.449z M82.224,73.088c-2.474,0-4.615-0.903-6.423-2.712c-1.807-1.809-2.712-3.946-2.712-6.423V45.682c0-2.474,0.905-4.617,2.712-6.423c1.809-1.809,3.949-2.712,6.423-2.712h201.001c2.471,0,4.612,0.903,6.42,2.712c1.807,1.807,2.717,3.949,2.717,6.423v18.271c0,2.478-0.913,4.615-2.717,6.423c-1.808,1.809-3.949,2.712-6.427,2.712H82.224zM475.078,475.085H219.263V146.177h109.641v118.777c0,7.61,2.662,14.082,7.994,19.41c5.325,5.328,11.793,7.99,19.41,7.99h118.77V475.085L475.078,475.085z"/>';
export const cut = '<path d="M12.1409 9.34138L12.14 9.34274L7.37017 2.32828C7.13725 1.98575 6.67077 1.8969 6.32824 2.12982C5.98572 2.36273 5.89687 2.82922 6.12978 3.17174L11.2606 10.7169L8.86478 14.4604C8.30797 14.1664 7.67342 14 7 14C4.79086 14 3 15.7909 3 18C3 20.2091 4.79086 22 7 22C9.20914 22 11 20.2091 11 18C11 17.0088 10.6395 16.1018 10.0424 15.403L12.178 12.0661L14.2426 15.1023C13.4771 15.8309 13 16.8597 13 18C13 20.2091 14.7909 22 17 22C19.2091 22 21 20.2091 21 18C21 15.7909 19.2091 14 17 14C16.471 14 15.9659 14.1027 15.5037 14.2893L13.0575 10.6919L13.0588 10.6899L12.1409 9.34138ZM4.5 18C4.5 16.6193 5.61929 15.5 7 15.5C8.38071 15.5 9.5 16.6193 9.5 18C9.5 19.3807 8.38071 20.5 7 20.5C5.61929 20.5 4.5 19.3807 4.5 18ZM14.5 18C14.5 16.6193 15.6193 15.5 17 15.5C18.3807 15.5 19.5 16.6193 19.5 18C19.5 19.3807 18.3807 20.5 17 20.5C15.6193 20.5 14.5 19.3807 14.5 18Z" fill="#212121"/><path d="M13.9381 9.31594L17.8815 3.15426C18.1048 2.80538 18.003 2.34155 17.6541 2.11827C17.3053 1.89498 16.8414 1.9968 16.6181 2.34568L13.0202 7.96744L13.9381 9.31594Z" fill="#212121"/>';
