import { Component, OnInit } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';


@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.css']
})
export class RegisterSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
