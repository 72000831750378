import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { GrammarService, grammarOptionsInterface} from '../shared-services/grammar.service';
import { environment, dictionaries, varEnvironment } from '../../environments/environment'
import { Observable } from 'rxjs';

import 'rxjs/add/operator/map';
import 'rxjs/operator/delay';
import 'rxjs/operator/mergeMap';
import 'rxjs/operator/switchMap';

//config 
const PORTS = environment.PORTS_CONJUGATIONS.split(',');
const NAMES = environment.DB_NAMES.split(',');


const API_URL: Array<string> = [
     '://' + environment.API_IP + ":" + PORTS[dictionaries.MAINDB] + environment.ENDPOINT_CONJUGATION + "/" + NAMES[dictionaries.MAINDB],
     '://' + environment.API_IP + ":" + PORTS[dictionaries.CHEMICDB] + environment.ENDPOINT_CONJUGATION + "/" + NAMES[dictionaries.CHEMICDB] ,
     '://' + environment.API_IP + ":" + PORTS[dictionaries.COMPUTDB] + environment.ENDPOINT_CONJUGATION + "/" + NAMES[dictionaries.COMPUTDB] ,
     '://' + environment.API_IP + ":" + PORTS[dictionaries.LEGALDB] + environment.ENDPOINT_CONJUGATION + "/" + NAMES[dictionaries.LEGALDB] ,
     '://' + environment.API_IP + ":" + PORTS[dictionaries.MEDICDB] + environment.ENDPOINT_CONJUGATION + "/" + NAMES[dictionaries.MEDICDB] ,
     '://' + environment.API_IP + ":" + PORTS[dictionaries.TECHNIDB] + environment.ENDPOINT_CONJUGATION + "/" + NAMES[dictionaries.TECHNIDB] ,
     '://' + environment.API_IP + ":" + PORTS[dictionaries.TRADEDB] + environment.ENDPOINT_CONJUGATION + "/" + NAMES[dictionaries.TRADEDB] ,
     '://' + environment.API_IP + ":" + PORTS[dictionaries.PROVERBDB] + environment.ENDPOINT_CONJUGATION + "/" + NAMES[dictionaries.PROVERBDB] ,  
  ]

@Injectable()
export class ConjugationService {
   
    //private TranslateURL: string = ENDPOINT_CONJUGATION
    public grammarOptions:grammarOptionsInterface

    requestOptions: {} = {withAuth: true};
    
    constructor(private http:HttpService) { }


    getConjugation(options: grammarOptionsInterface): Observable<JSON> {
        //console.log(options)
        let language = options.language;
        let conceptid = options.conceptid;
        let focusnr = options.focus;
        let dictionary = options.dictionary;
        return this.http.get(varEnvironment.schema + API_URL[this.dictPos(dictionary)]+ environment.FORMS + language + "/" + conceptid + "/" + focusnr + "?hide_double_alternatives", this.requestOptions );
    }

    private dictPos(dictionaryName: string): number {
        for (let n = 0; n < NAMES.length; n++) {
          if (NAMES[n] == dictionaryName)
            return n;
        }
    
        return -1
    }

    getTranslatedConjugations(dictionary: string, sourceLanguage: string, targetLanguage: string, conceptid: number, focusnr: number, sourceForm: string, caseSensitive: boolean, diacriticsSensitive: boolean): Observable<JSON> {
        
        var args: string = "";
        if (!caseSensitive) {
          args += "&" + environment.CASE_INSENSITIVE;
        }
        if (!diacriticsSensitive) {
          args += "&" + environment.DIACRITIC_INSENSITIVE;
        }
        
        return this.http.get(varEnvironment.schema + API_URL[this.dictPos(dictionary)]+ environment.TRANSLATED_FORMS + sourceLanguage + "/" + targetLanguage + "/" + conceptid + "/" + focusnr + "/" + sourceForm + "?hide_double_alternatives" + "&" + "full_data"  + args, this.requestOptions );
    }

}