import { EditorState, EditorView } from 'prosemirror-state';

import { ToolbarItem, ToolbarCustomMenuItem, MenuItemViewSpec } from 'ngx-editor';
import { renderMenu } from 'ngx-editor/esm2015/lib/prosemirror/plugins/menu/menu.js';
import { getExtIconSvg } from '../ngx-editor/icon';

import { defaultFlags, readOnlyEditor } from './flags';
import { MENU_ITEM_CLASSNAME, DISABLED_CLASSNAME, INVISIBLE_CLASSNAME, ACTIVE_MENU_ITEM_CLASSNAME, DROPDOWN_ITEM_CLASSNAME, DROPDOWN_OPEN_CLASSNAME, emptyTCR, setDomInvisible, DispatchFn } from './root';
import { ContextMenuItem, CONTEXT_MENU_ITEM_CLASSNAME, ACTIVE_CONTEXT_MENU_ITEM_CLASSNAME, SIDE_ITEM_CLASSNAME, SIDE_ITEM_OPEN_CLASSNAME, ACTIVE_SIDE_ITEM_CLASSNAME } from './contextmenu';
import { EditorFlags } from './flags';
import { TCR } from './root';



export type MenuItemInitialVisibleFn = () => boolean;
export type MenuItemCommandFn = (state : EditorState, dispatch : DispatchFn, view? : EditorView, parentDom? : HTMLElement)  => boolean;
export type MenuItemActiveFn = (state : EditorState)  => boolean;
type UpdateFn = (state: EditorState) => void;

export interface MenuItemProperties {
  //key : string;
  //type : string;
  //i18nKey: string;
  label : string;
  iconName? : string; //Single letter, name of built-in icon or SVG path
  flags? : EditorFlags;
  initialVisibleFn? : MenuItemInitialVisibleFn;
  commandFn : MenuItemCommandFn;
  activeFn? : MenuItemActiveFn;
}

export class MenuItemCreator {
  protected prop : MenuItemProperties;

  constructor(prop : MenuItemProperties) {
    this.prop = prop;
  }

  createMenuItem() : ToolbarCustomMenuItem {
    const tcmi : ToolbarCustomMenuItem = (editorView : EditorView) => {
      if((this.prop.initialVisibleFn != undefined) && (!this.prop.initialVisibleFn())) {
        return emptyTCR;
      }

      let dom : HTMLElement = this.createMenuItemDom();
      this.createMouseDownListener(dom, editorView);

      const update = this.createUpdateFn(dom, editorView);

      return {
        dom,
        update
      };
    }

    return tcmi;
  }

  createContextMenuItem() : ContextMenuItem {
    const cmi : ContextMenuItem = (editorView : EditorView) => {
      if((this.prop.initialVisibleFn != undefined) && (!this.prop.initialVisibleFn())) {
        return emptyTCR;
      }

      let dom : HTMLElement = this.createContextMenuItemDom();
      this.createMouseDownListener(dom, editorView);


      const update = this.createUpdateFn(dom, editorView);

      return {
        dom,
        update
      };
    }

    return cmi;
  }

  protected createMouseDownListener(dom : HTMLElement, editorView : EditorView) {
    dom.addEventListener('mousedown', (e: MouseEvent) => {
      e.preventDefault();

      // don't execute if not left click
      if (e.buttons !== 1) {
        return;
      }

      this.prop.commandFn(editorView.state, editorView.dispatch, editorView, dom);
    });
  }

  protected createUpdateFn(dom : HTMLElement, editorView : EditorView) : UpdateFn {
    return (state: EditorState): void => {
      const canExecute = this.prop.commandFn(state, null, editorView, dom);
      dom.classList.toggle(DISABLED_CLASSNAME, !canExecute);

      if(this.prop.flags != undefined){
        const isVisible = flagsMatch(editorView.state, this.prop.flags)
        setDomInvisible(dom, !isVisible)
      }

      if(this.prop.activeFn != undefined) {
        let contextMenu : boolean = dom.classList.contains(CONTEXT_MENU_ITEM_CLASSNAME);
        const isActive = this.prop.activeFn(state);
        if(contextMenu)
          dom.classList.toggle(ACTIVE_CONTEXT_MENU_ITEM_CLASSNAME, isActive);
        else
          dom.classList.toggle(ACTIVE_MENU_ITEM_CLASSNAME, isActive);
      }
    };
  }

  protected createMenuItemDom() : HTMLElement {
    const dom : HTMLElement = document.createElement('div');
    dom.classList.add(MENU_ITEM_CLASSNAME);

    if(this.prop.iconName != undefined) {
      dom.innerHTML = getExtIconSvg(this.prop.iconName)
      dom.classList.add(`${MENU_ITEM_CLASSNAME}--Icon`);
    }

    dom.setAttribute("title", this.prop.label);

    return dom;
  }

  protected createContextMenuItemDom() {
    const dom : HTMLElement = document.createElement('div');
    dom.classList.add(CONTEXT_MENU_ITEM_CLASSNAME);

    if(this.prop.iconName == undefined) {
      dom.innerHTML = this.prop.label;
    } else {
      dom.innerHTML = getExtIconSvg(this.prop.iconName) + " " + this.prop.label;
    }

    return dom;
  }
}

export class DropdownMenuCreator extends MenuItemCreator {
  private isDropdownOpen : boolean = false;

  constructor(prop : MenuItemProperties) {
    super(prop);
  }

  protected createMouseDownListener(dom : HTMLElement, editorView : EditorView) {
    dom.addEventListener('mousedown', (e: MouseEvent) => {
      e.preventDefault();

      // don't execute if not left click
      if (e.buttons !== 1) {
        return;
      }

      let contextMenu : boolean = dom.classList.contains(CONTEXT_MENU_ITEM_CLASSNAME);
      var oldItemsDom = null;
      if(contextMenu)
        oldItemsDom = dom.getElementsByClassName(`${SIDE_ITEM_CLASSNAME}__SideMenu`)[0] as HTMLElement;
      else
        oldItemsDom = dom.getElementsByClassName(`${DROPDOWN_ITEM_CLASSNAME}__DropdownMenu`)[0] as HTMLElement;

      if(oldItemsDom)
        dom.removeChild(oldItemsDom);

      //this.prop.commandFn(editorView.state, editorView.dispatch, editorView, dom);

      if(this.isDropdownOpen) {
        if(contextMenu)
          dom.classList.remove(SIDE_ITEM_OPEN_CLASSNAME);
        else
          dom.classList.remove(DROPDOWN_OPEN_CLASSNAME);
        this.isDropdownOpen = false;
      } else {

        this.prop.commandFn(editorView.state, editorView.dispatch, editorView, dom);

        this.isDropdownOpen = true;
        if(contextMenu)
          dom.classList.add(SIDE_ITEM_OPEN_CLASSNAME);
        else
          dom.classList.add(DROPDOWN_OPEN_CLASSNAME);
      }
    });
  }

  protected createUpdateFn(dom : HTMLElement, editorView : EditorView) : UpdateFn {
    let sfn = super.createUpdateFn(dom, editorView);
    return (state: EditorState): void => {
      let contextMenu : boolean = dom.classList.contains(SIDE_ITEM_CLASSNAME);
      var oldItemsDom;
      if(contextMenu)
        oldItemsDom = dom.getElementsByClassName(`${SIDE_ITEM_CLASSNAME}__SideMenu`)[0] as HTMLElement;
      else
        oldItemsDom = dom.getElementsByClassName(`${DROPDOWN_ITEM_CLASSNAME}__DropdownMenu`)[0] as HTMLElement;

      if(oldItemsDom)
        dom.removeChild(oldItemsDom);

      sfn(state);

      //Todo??

    }
  }

  protected createMenuItemDom() : HTMLElement {
    const dom : HTMLElement = document.createElement('div');
    dom.classList.add(DROPDOWN_ITEM_CLASSNAME);

    const textDom : HTMLElement = document.createElement('div');
    textDom.innerHTML = this.prop.label;
    textDom.classList.add(`${DROPDOWN_ITEM_CLASSNAME}__Text`);
    dom.append(textDom);

    const itemsDom : HTMLElement = ToolbarCustomDropdownGroup.EmptyDom();
    itemsDom.innerHTML = "empty";
    dom.append(itemsDom);

    return dom;
  }

  protected createContextMenuItemDom() {
    const dom : HTMLElement = document.createElement('div');
    dom.classList.add(SIDE_ITEM_CLASSNAME);

    const textDom : HTMLElement = document.createElement('div');
    if(this.prop.iconName == undefined) {
      textDom.innerHTML = this.prop.label;
    } else {
      textDom.innerHTML = getExtIconSvg(this.prop.iconName) + " " + this.prop.label;
    }
    textDom.classList.add(`${SIDE_ITEM_CLASSNAME}__Text`);
    dom.append(textDom);

    const itemsDom : HTMLElement = ContextMenuCustomSideShowGroup.EmptyDom();
    itemsDom.innerHTML = "empty";
    dom.append(itemsDom);

    //TODO
    return dom;
  }
}

export class ToolbarCustomDropdownGroup extends Array<ToolbarCustomMenuItem> {
  render(view: EditorView) : TCR {
    const updates = [];
    const dom = ToolbarCustomDropdownGroup.itemsDom();
    for(let item of this) {
      let tcr : TCR = item(view);
      dom.appendChild(tcr.dom);
      updates.push(tcr.update);
    }

    //TODO
    //const combinedUpdates = flatDeep(updates, Infinity);
    return {
        update(state) {
            updates.forEach((update) => {
                update(state);
            });
        },
        dom
    };
  }

  private static itemsDom() : HTMLElement {
    const itemsDom : HTMLElement = document.createElement('div');
    itemsDom.classList.add(`${DROPDOWN_ITEM_CLASSNAME}__DropdownMenu`);
    itemsDom.style.minWidth="100%";
    itemsDom.style.width="auto";
    return itemsDom;
  }

  static EmptyDom() : HTMLElement {
    return ToolbarCustomDropdownGroup.itemsDom();
  }
}

export class ContextMenuCustomSideShowGroup extends Array<ContextMenuItem> {
  render(view: EditorView) : TCR {
    const updates = [];
    const dom = ContextMenuCustomSideShowGroup.itemsDom();
    for(let item of this) {
      let tcr : TCR = item(view);
      dom.appendChild(tcr.dom);
      updates.push(tcr.update);
    }

    //TODO
    //const combinedUpdates = flatDeep(updates, Infinity);
    return {
        update(state) {
          updates.forEach((update) => {
              update(state);
          });
        },
        dom
    };
  }

  private static itemsDom() : HTMLElement {
    const itemsDom : HTMLElement = document.createElement('div');
    itemsDom.classList.add(`${SIDE_ITEM_CLASSNAME}__SideMenu`);
    itemsDom.style.display = "none";
    return itemsDom;
  }

  static EmptyDom() : HTMLElement {
    return ContextMenuCustomSideShowGroup.itemsDom();
  }
}


export class DropdownMenuItemCreator extends MenuItemCreator {
  constructor(prop : MenuItemProperties) {
    super(prop);
  }

  protected createMenuItemDom() : HTMLElement {
    const dom : HTMLElement = document.createElement('div');
    dom.classList.add(`${DROPDOWN_ITEM_CLASSNAME}__Item`);
    dom.innerHTML = this.prop.label;
    dom.setAttribute("title", this.prop.label);

    return dom;
  }

  protected createContextMenuItemDom() : HTMLElement {
    const dom : HTMLElement = document.createElement('div');
    dom.classList.add(`${SIDE_ITEM_CLASSNAME}__Item`);
    dom.innerHTML = this.prop.label;
    dom.setAttribute("title", this.prop.label);

    return dom;
  }
}



export const getDefaultStdLabels = {
  bold: 'Bold',
  italics: 'Italics',
  code: 'Code',
  ordered_list: 'Ordered List',
  bullet_list: 'Bullet List',
  heading: 'Header',
};

export function filterDefaultMenuItems(menuItems : ToolbarItem[], flags) : ToolbarItem[] {

  var newMenuItems : ToolbarItem[] = [];

  for(const item of menuItems) {
    newMenuItems.push(filterDefaultMenuItem(item, flags));
  }

  return newMenuItems;
}

function filterDefaultMenuItem(item : ToolbarItem, flags) : ToolbarCustomMenuItem {
  const tcmi : ToolbarCustomMenuItem = (editorView : EditorView) => {
    const isVisible = flagsMatch(editorView.state, flags);
//console.log("FLAGS MATCH: " + isVisible);
    if(!isVisible) {
      return emptyTCR;
    }

    let options = {
      toolbar:[
        [item],
      ],
      labels:{
        ...getDefaultStdLabels,
      },
    }

    const dom: HTMLElement = document.createElement('div');
    let updateObj = renderMenu(options, editorView, dom);
    const innerDom : HTMLElement = dom.firstElementChild as HTMLElement;

    const update = (state: EditorState): void => {
      updateObj.update(state);

      setDomInvisible(innerDom, readOnlyEditor(state));
    };

    return {
      update,
      dom:innerDom
    };
  };

  return tcmi;
}

function flagsMatch(state : EditorState, flags) : boolean {
  if(flags == undefined) return true;

  let activeFlags = defaultFlags;
  for(const plugin of state.plugins) {
    if(plugin.key.startsWith('flags')) {
      let pstate = plugin.getState(state)
      if(pstate)
        activeFlags = pstate;
    }
  }

  return ((flags.readonly == undefined || flags.readonly == activeFlags.readonly) && (flags.source == undefined || flags.source == activeFlags.source))
}
