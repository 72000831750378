<app-navbar></app-navbar>
<mat-drawer-container class="example-container">
  <mat-drawer mode="side" opened fxHide.sm  >
    <left-navbar></left-navbar>
  </mat-drawer>
  <mat-drawer-content>
    <div id="wrapper">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <mat-card>
              <div class="button-container">
                  <div class="row">
                    <div class="col-sm-3">
                      <h3>{{dictname}}</h3>
                      <a class="btn blue darken-1 white-text" (click)="addNewConcept()">New word concept</a>
                    </div>
                    <div class="col-sm-3">
                        <input matInput placeholder="Search dictionary" value="">
                        <div class="search-icon">
                            <mat-icon>search</mat-icon>
                        </div>
                    </div>
                 
                  </div>
      
                </div>
          </mat-card>
    
          <mat-card *ngFor="let concept of concepts">
          
            <mat-card-header>
                  <mat-card-title>Concept ID {{concept.concept_id}}</mat-card-title><mat-icon (click)="editConceptFields(concept.concept_id)">edit</mat-icon><mat-icon (click)="deleteConcept(concept.concept_id)">delete</mat-icon>
              <p class="header_wordtype_p" (click)="editConceptFields(concept.concept_id)">{{currentResourceMaps.wordTypeResources.get(concept.word_type)}}</p>
              <p *ngFor="let attribute of concept.concept_attributes" (click)="editConceptFields(concept.concept_id)" class="header_wordtype_p">{{currentResourceMaps.attributeResources.get(attribute)}}</p>
    
            </mat-card-header>
            <mat-card-content>
                <div *ngFor="let wordGroup of concept.translation">
                    <ul> {{getLanguageName(wordGroup.language)}} </ul>
                    <li *ngFor="let word of wordGroup.words">
                      {{word.word}}<mat-icon (click)="editLemmaFields(concept.concept_id, wordGroup.language, word.focus)">edit</mat-icon>  <mat-icon (click)="deleteLemma(concept.concept_id, wordGroup.language, word.focus)">delete</mat-icon>
                    </li> <mat-icon (click)="postNewLemma(concept.concept_id, wordGroup.language)">add</mat-icon>
                </div>
    
              <!--<div class="button-container">
                <div class="row">
                  <div class="col-sm-3">
                      <a class="btn blue darken-1 white-text" (click)="postNewLemma()">New lemma</a>
                  </div>
    
                </div>
              </div>-->
    
                <!--<table mat-table [dataSource]="getConceptData(concept.concept_id)">
    
                    <div *ngFor="let wordGroup of concept.translation">
                        <ng-container matColumnDef="{{wordGroup.language}}">
                            <th mat-header-cell *matHeaderCellDef> {{getLanguageName(wordGroup.language)}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.word}} <mat-icon (click)="editLemmaFields(element.word, 2)">edit</mat-icon> </td>
                          </ng-container>
                    </div>
    
                    <!- Modified by Column ->
                        <ng-container matColumnDef="modified_by">
                           <th mat-header-cell *matHeaderCellDef> Modified By </th>
                           <td mat-cell *matCellDef="let element"> {{element.modified_by}}</td>
                         </ng-container>
    
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>-->
                
            </mat-card-content>
            
          </mat-card>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" [length]="numberOfConcepts" showFirstLastButtons (page)="pageEvent = changePage($event)"></mat-paginator> 
    
        </div>
        <div class="col-sm-1"></div>
    
      </div>
    </div>

  </mat-drawer-content>

</mat-drawer-container>


