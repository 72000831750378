import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

interface IWindow extends Window {
  webkitSpeechRecognition: any;
  speechSynthesis: any;
}

@Injectable({
  providedIn: 'root'
})
export class SpeechSyntesisService {
  voices: any;
  synthlang: string;


  constructor(private zone: NgZone) { }

  populateVoices(){
     //get voices from API
     this.voices = speechSynthesis.getVoices();
     /*for(let i = 0; i < this.voices.length ; i++) {
      console.log(this.voices[i])
     }*/
  }

  sayIt(lemma: string, language: string){
    //console.log("SYNTH LANG" + language)

    if(language == "fr"){
      this.synthlang = "fr-FR";
    } else if(language == "nl"){
      this.synthlang = "nl-NL";
    } else if(language == "en"){
      this.synthlang = "en-US";
    } else if(language == "es"){
      this.synthlang = "es-ES";
    } else if (language == "de"){
      this.synthlang = "de-DE";
    } else if (language == "it"){
      this.synthlang = "it-IT";
    }
   
    if(speechSynthesis.onvoiceschanged !== undefined){
      speechSynthesis.onvoiceschanged = this.populateVoices;
    }

    //Speak the selected lemma
    var msg = new SpeechSynthesisUtterance(lemma);
    msg.lang = this.synthlang;
    (<any>window).speechSynthesis.speak(msg)
  }

}
