import { Component, OnInit, Input, Output, ViewChild, forwardRef, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Node, Mark } from 'prosemirror-view';

import { NgxEditorComponent } from './ngx-editor/ngx-editor.component';
//import { NgxEditorComponent } from 'ngx-editor';

import { IJSONDocJSON } from '../jsondoc_interface';

import { EditorFlags, EditorFlagsPluginKey } from './plugins/flags';
import { SynonymClickedData, ColMouseData, EventsPluginKey } from './plugins/events';


@Component({
  selector: 'euroglot-editor',
  templateUrl: './euroglot-editor.component.html',
  providers: [{
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(function () { return EuroglotEditorComponent; }),
          multi: true
      }],
})

export class EuroglotEditorComponent implements OnInit {
  @Input() readonly : boolean = false;
  @Input() source : boolean = true;

  @Output() onUIAction = new EventEmitter<SynonymClickedData | null>(); //Add future types ;)

  @ViewChild('ngxEditor') private ngxEditor: NgxEditorComponent;

  editorText : IJSONDocJSON;

  private onChange = null;
  private onTouched = null;

  private synonymClickedEventEmitter = new EventEmitter<SynonymClickedData>();
  private colMouseEventEmitter = new EventEmitter<ColMouseData>();

  extraNgxEditorStyles = {

  }
  //./ngx-editor/extra.scss

  constructor() {
    this.synonymClickedEventEmitter.subscribe(this.synonymClicked());
    this.colMouseEventEmitter.subscribe(this.colMouseEvent());
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.setFlags();
  }

  private setFlags() {
    //Set editor flags and event emitters. This needs NgxEditorComponent view and handleTransactions to not be private.
    let tr = this.ngxEditor.view.state.tr;
    let newFlags : EditorFlags = {
      readonly: this.readonly,
      source: this.source,
    }

    tr.setMeta(EditorFlagsPluginKey, newFlags);

    let eventEmitters = {
      synonymClicked: this.synonymClickedEventEmitter,
      colMouse: this.colMouseEventEmitter,
    }

    tr.setMeta(EventsPluginKey, eventEmitters);

    this.ngxEditor.handleTransactions(tr);

//console.dir(this.ngxEditor)
  }

  writeValue(value: IJSONDocJSON) {
    this.editorText = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  editorTextChanged($event : any) {
    if(this.onChange) {
      this.onChange($event);
    }
  }

  uiAction($event : any) {
    if(this.onUIAction) {
      this.onUIAction.emit($event);
    }
  }

  private synonymClicked() {
    return ($event : SynonymClickedData) => {
      this.uiAction($event);
    };
  }

  private colMouseEvent() {
    return ($event : ColMouseData) => {
      this.uiAction($event);
    };
  }

  findElement(nodeType : string, attributes : any) : Element {

    let docView = this.ngxEditor.view.docView;

    let n = this.findNode(nodeType, attributes, docView);
    if(n == null)
      return null;
    if(n.contentDOM)
      return n.contentDOM;
    if(n.dom)
      return n.dom;
    return null;
  }

  private findNode(nodeType : string, attributes : any, docView) : Node | Mark {
    if(docView == null) {
      return null;
    }

    let checkAttr = null;
    if((docView.node) && (docView.node.type.name == nodeType)) {
      checkAttr = docView.node.attrs;
    }
    if((docView.mark) && (docView.mark.type.name == nodeType)) {
      checkAttr = docView.mark.attrs;
    }

    let found = false;
    if(checkAttr) {
      found = true;
      for(let attrKey in attributes) {
        if(attributes[attrKey] != checkAttr[attrKey]) {
          found = false;
          break;
        }
      }
    }

    if(found) {
      console.log("FOUND ELEMENT!")
      return docView;
    }


    for(let n of docView.children) {
      let node = this.findNode(nodeType, attributes, n)
      if(node != null)
        return node;
    }

    return null;
  }

}
