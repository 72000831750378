import { Component, OnInit } from '@angular/core';
import {AuthServiceEuroglot, LoggedInEvent} from '../../services/auth.service';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { ActivateService} from '../../services/activate.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  username: string;
  password: string;
  retype_password: string;
  private loggedInSubscription;
  hashSupplied: boolean = false;
  setPasswordForm: FormGroup;



  constructor(private authService: AuthServiceEuroglot,
    private router:Router,
    private validator: ActivateService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar) {
      this.setPasswordForm = this.createSignupForm();

      this.loggedInSubscription = authService.loggedInEmitter.subscribe({
        next: (event: LoggedInEvent) => {
            this.handleLogin(event)
        }
      })
    }

  ngOnInit() {
    this.isLoggedIn();
    let username = this.route.snapshot.queryParams["user"];
    let hash = this.route.snapshot.queryParams["registration_hash"];
    if(username != "") {
      if ((hash == undefined) || (hash == "")) {
        this.hashSupplied = false;
      } else {
        this.hashSupplied = true;
      }
      this.username = username;
    }
  }


  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            this.validator.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            this.validator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            this.validator.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            this.validator.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: this.validator.passwordMatchValidator
      }
    );
  }


  submit(){

    this.password = this.setPasswordForm.value.password

    if((this.password == undefined) || this.password == "") {
      return
    }

    if(this.password != this.retype_password) {
      this.snackBar.open('Please supply equal passwords', 'OK', {duration: 3000});
    }

    let hash = this.route.snapshot.queryParams["registration_hash"];
    let username = this.route.snapshot.queryParams["user"];
    const user = {
      username: this.username,
      password: this.password,
      registrationHash: hash
    }
    //console.log("USER" + JSON.stringify(user))

    this.authService.registerUser(user)

    //console.log("USERNAME" + this.username + "New Password" + this.password + hash)


  }



  isLoggedIn(){
    if (this.authService.checkLogin()) {
			this.snackBar.open('You are already logged in', 'OK', {duration:3000});
			this.router.navigate(['dictionary']);
    }
	}

  handleLogin(event : LoggedInEvent) {
		if (event.loggedIn) {
			this.snackBar.open('You are logged in successfully', 'OK', {duration:3000});
			this.router.navigate(['dictionary']);
		} else {
			this.snackBar.open('Cannot log in, credentials are invalid', 'OK', {duration:3000});
			this.router.navigate(['/register-login']);
		}
  }

}
