import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {PaymentService} from '../../../services/payment.service';
@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.css']
})
export class ThankYouPageComponent implements OnInit {
  transactionid: string;
  orderStatus: string;
  constructor(private route:ActivatedRoute, private paymentService:PaymentService) { }


  ngOnInit() {
    this.route.queryParams.subscribe( params => this.setRouteParams(params));
    this.getOrders(this.transactionid);
  }

  getOrders(orderid){
    this.paymentService.getOrders(orderid).subscribe(
      data => this.handleGetOrdersSuccess(data),
      error => this.handleGetOrdersError(error)
    )
  }
  handleGetOrdersSuccess(data){
    //console.log("SUCCESS" + JSON.stringify(data.data.status))
    this.orderStatus = data.data.status;
  }

  handleGetOrdersError(error){
    console.log("ERROR" + JSON.stringify(error))
  }


  setRouteParams(params) {
    // console.log("PARAMS: " + JSON.stringify(params))

     if (params['order_id']) {
       this.transactionid = params['order_id'];
     }

   }
}
