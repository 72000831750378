import { Component,ChangeDetectorRef, Injector } from '@angular/core';
import { SupplementPanelComponent } from '../supplement-panel/supplement-panel.component';

const dictionary: string = "chemicdb"

@Component({
  selector: 'chemical-panel',
  //templateUrl: './chemical-panel.component.html',
  //styleUrls: ['./chemical-panel.component.css']
  templateUrl: '../supplement-panel/supplement-panel.component.html',
  styleUrls: ['../supplement-panel/supplement-panel.component.css']
})

export class ChemicalPanelComponent extends SupplementPanelComponent {

  constructor(cd:ChangeDetectorRef, injector: Injector) { 

    super(cd, injector, dictionary);

  }

}
