<app-navbar (open)="navOpen($event)"></app-navbar>

<div class="app-wrapper">

    <mat-drawer-container class='app-drawer-container' fullscreen>
      <mat-drawer #sidenav mode="side">
         <left-navbar></left-navbar>
      </mat-drawer>
      <mat-drawer-content>
          <div class="row no-gutters">
            <div class="col-sm">
              <div class="set-language">

                <select id="sl" [(ngModel)]="sourceLanguage" (ngModelChange)="sourceLanguageChanged($event)" data-show-content="true" class="form-control">
                    <option *ngFor="let language of sourceLanguages" [value]=language.name>{{language.title}}</option>
                </select>
              </div>


            <div class="text-input">
              <euroglot-editor #sourceEditor [(ngModel)]="sourceEditorText" (ngModelChange)="sourceEditorTextChanged($event)" (onUIAction)="sourceEditorUIAction($event)"></euroglot-editor>
              <button class="translate-button" mat-stroked-button color="primary"  (click)="translateButtonClicked()">{{translateButtonText}}</button>
              <button class="clear-button" mat-stroked-button color="primary" (click)="clear()">{{clearButtonText}}</button>
            </div>
            </div>
            <div class="col-sm">
              <div class="set-language">

               <select  id="tl" [ngModel]="targetLanguage" (ngModelChange)="targetLanguageChanged($event)" data-show-content="true" class="form-control">
                    <option *ngFor="let language of targetLanguages" [value]=language.name>{{language.title}}</option>
                </select>
              </div>
              <div class="text-input" >
                <euroglot-editor #targetEditor height="300px" [(ngModel)]="targetEditorText" (ngModelChange)="targetEditorTextChanged($event)" (onUIAction)="targetEditorUIAction($event)" [readonly]="true" [source]="false"></euroglot-editor>
              </div>

            </div>

          </div>


      </mat-drawer-content>
    </mat-drawer-container>
</div>
