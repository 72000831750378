import { Component, Input, OnInit, ViewChild, forwardRef, Inject, ChangeDetectorRef } from '@angular/core';
import { GrammarService, grammarOptionsInterface} from '../../../shared-services/grammar.service';
import { ConjugationService } from '../../../services/conjugation.service';
import { ResourceService, ResourceMapInterface } from '../../../shared-services/resource.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { KeyValue } from '../../../interfaces/keyvalue';


@Component({
  selector: 'grammar-panel',
  templateUrl: './grammar-panel.component.html',
  styleUrls: ['./grammar-panel.component.css']
})



export class GrammarPanelComponent {
  @Input() screenLanguage: string;
  //language: string = "nl";
  //conceptid: number = 1963;
  //focusnr: number = 1;
  conjugations: any[];
  grammarOptions: grammarOptionsInterface;
  currentResourceMaps: ResourceMapInterface;

  constructor(
  private cd: ChangeDetectorRef,
  @Inject(GrammarService) private grammarService: GrammarService,
  @Inject(ResourceService) private resourceService: ResourceService,
  private conjugationService: ConjugationService,
  private configurationService: ConfigurationService) {
    //super()
  }

  ngOnInit() {
    this.grammarService.currentGrammar.subscribe(grammarOptions => this.grammarOptions = grammarOptions);
    this.callGrammar(this.grammarOptions)
    this.resourceService.currentResourceMaps.subscribe(currentResourceMap => this.currentResourceMaps = currentResourceMap);
  }

  callGrammar(grammarOptionsInterface){

  return this.conjugationService.getConjugation(this.grammarOptions).subscribe(
    data => this.handleConjugationSuccess(data),
    error => this.handleConjugationError(error),
    () => 0
  )


  }

  handleConjugationSuccess(data){
  //console.log("CONJUGATION CALL" + JSON.stringify(data));

    this.conjugations = data.data.conjugations.variants;
    this.getResources()
  }

  handleConjugationError(error){
    console.log(error)
  }

  getResources() {
    var screenLanguage = this.configurationService.getScreenLanguage();
    for(var value of this.conjugations) {
      this.resourceService.resource(value.variant_name, screenLanguage);
    }
  }

  //refresh after changes occurred
  refresh(){
    this.cd.detectChanges();
  }

 
    
}
