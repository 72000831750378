import { menu } from 'ngx-editor';

import { Plugin } from 'prosemirror-state';
import { splitListItem, liftListItem, sinkListItem } from 'prosemirror-schema-list';
import { toggleMark, baseKeymap } from 'prosemirror-commands';
import { keymap } from 'prosemirror-keymap';

import { clipboardCutMenuItem, clipboardCopyMenuItem, clipboardPasteMenuItem, clipboardSelectAllMenuItem,
  clipboardCutContextMenuItem, clipboardCopyContextMenuItem, clipboardPasteContextMenuItem, clipboardSelectAllContextMenuItem,
  getClipboardKeymap, getDefaultClipboardLabels } from './clipboard'
import { contextMenu } from './contextmenu';
import { synonymMenu, synonymContextMenu, getDefaultSynonymMenuLabels } from './synonyms';
import { flags } from './flags';
import { events } from './events';
import { placeholder } from './placeholder';
import { filterDefaultMenuItems, getDefaultStdLabels } from './menu';
//import { historyPrevMenuItem, historyNextMenuItem, getHistoryKeymap, getDefaultHistoryLabels } from './history';
//import codemirrorMenu from './codemirror';

import schema from '../schema';
import { buildInputRules } from './import-rules';

export type KeyMap = { [key: string]: any };

const getListKeyMap = (): KeyMap => {
  const listMap: KeyMap = {};

  listMap.Enter = splitListItem(schema.nodes.list_item);
  listMap['Mod-['] = liftListItem(schema.nodes.list_item);
  listMap['Mod-]'] = sinkListItem(schema.nodes.list_item);
  listMap.Tab = sinkListItem(schema.nodes.list_item);

  return listMap;
};

const getMarksKeyMap = (): KeyMap => {
  const marksMap: KeyMap = {};

  marksMap['Mod-b'] = toggleMark(schema.marks.strong);
  marksMap['Mod-i'] = toggleMark(schema.marks.em);

  return marksMap;
};


const getPlugins = (): Plugin[] => {
  const listKeyMap = getListKeyMap();
  const marksKeyMap = getMarksKeyMap();
  const clipboardKeymap = getClipboardKeymap();
  //const historyKeymap = getHistoryKeymap();

  const plugins = [
    keymap(listKeyMap),
    keymap(marksKeyMap),
    keymap(clipboardKeymap),
    /*keymap(historyKeymap),*/
    keymap(baseKeymap),
    /*handlePaste,*/
    buildInputRules(schema),
    menu({
      toolbar: [
        [clipboardCutMenuItem, clipboardCopyMenuItem, clipboardPasteMenuItem, clipboardSelectAllMenuItem],
        /*[historyPrevMenuItem, historyNextMenuItem],*/
        ['bold', 'italic', 'code'],
        filterDefaultMenuItems(['ordered_list', 'bullet_list'], {readonly:false}),
        filterDefaultMenuItems([{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }], {readonly:false}),
        [synonymMenu],
        /*[codemirrorMenu],*/
      ],
      labels: {
        ...getDefaultClipboardLabels,
        /*...getDefaultHistoryLabels,*/
        ...getDefaultStdLabels,
        ...getDefaultSynonymMenuLabels,
      }
    }),
    contextMenu({
      items: [
        [clipboardCutContextMenuItem, clipboardCopyContextMenuItem, clipboardPasteContextMenuItem, clipboardSelectAllContextMenuItem],
        [synonymContextMenu],
      ],
      labels: {
        ...getDefaultClipboardLabels,
        ...getDefaultSynonymMenuLabels,
      }
    }),
    events(),
    flags(),
    placeholder('Type Something here...'),
  ];

  return plugins;
};

export default getPlugins();
