import { Component, Input, OnInit } from '@angular/core';
import { Howl } from 'howler';


declare var AudioContext;
declare var webkitAudioContext;

@Component({
  selector: 'souffleur',
  templateUrl: './souffleur.component.html',
  styleUrls: ['./souffleur.component.css']
})
export class SouffleurComponent implements OnInit {
  @Input() link: string;
  @Input() lemma: string;
  @Input() language: string;

  pronounce: any[];

  audioStrm: any[];

  constructor() { }

  ngOnInit() {
    //this.speechSynthesis.sayIt(this.lemma);
  }

  onClick(){


    var stream = new Howl({
      src: [this.link],
      //xhrWithCredentials: true,
      html5: true,
      format: ['mp3']
    })
    stream.play()

  }



}
