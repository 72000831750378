import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ResourceService} from '../../../shared-services/resource.service';
import { ImportService, ConceptImportBody} from '../../../services/dicts-services/import.service';


export interface ConceptData {
  word_type: string;
  attributes: string[];
}

export interface DialogData {
  conceptId: number;
  conceptwordType: string;
}

@Component({
  selector: 'app-post-new-concept',
  templateUrl: './post-new-concept.component.html',
  styleUrls: ['./post-new-concept.component.css']
})

export class PostNewConceptComponent implements OnInit {
  newConcept: ConceptData;
  wordtypes: Array<string>;


  constructor(private dialogRef: MatDialogRef<PostNewConceptComponent>, private resourceService: ResourceService, @Inject(MAT_DIALOG_DATA) public conceptDialogData: DialogData, private importService: ImportService) {
    this.newConcept = {} as ConceptData;

  }

  ngOnInit() {
    this.getWordTypes();
  }

  getWordTypes(){
    this.wordtypes = this.resourceService.getCachedWordTypes();
  }


  onCloseConfirm(){
    //console.log("Send to the database" + JSON.stringify(this.newConcept));
    let body: ConceptImportBody = {
      concept_id: null,
      word_type: this.newConcept.word_type,
      concept_attributes: this.newConcept.attributes,
      translations: null
    }


    this.importService.newConcept(body).subscribe(
      data =>  this.handlePostConcept(data),
      error => this.handlePostConceptError(error),
      () => {}
    );

    this.dialogRef.close();
  }

  onCloseCancel(){
    //console.log("cancel")
  }

  handlePostConcept(data){
    //console.log("POST CONCEPT DATA" + JSON.stringify(data))

  }

  handlePostConceptError(error){
    console.log(error)
  }

}
