import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { environment, varEnvironment } from '../../environments/environment';
import { catchError,  } from 'rxjs/operators'; 
import {throwError} from 'rxjs';

const API_AUTH = '://' + environment.API_IP + ":" + environment.PORT_AUTH + "/version" 
const API_DICTIONARY = '://' + environment.API_IP + ":" + environment.PORT_MERGE + "/version"
const API_MLPROXY = '://' + environment.API_IP + ":"  + environment.PORT_MLPROXY + "/version"

@Injectable({
  providedIn: 'root'
})
export class StatusPageService {
  responseStatus: number;

  constructor(private http: HttpService) { }

  getApiStatusAuth(){
    return this.http.get(varEnvironment.schema + API_AUTH, {observe: 'response'}).pipe(
      catchError((err) => {
        return throwError(err.statusText);    //Rethrow it back to component
      })
    )

  }

  getApiStatusDictionaries(){
    return this.http.get(varEnvironment.schema + API_DICTIONARY, {observe: 'response'}).pipe(
      catchError((err) => {
        return throwError(err.statusText);
      })
    );
  }

  getApiStatusMlProxy(){
    return this.http.get(varEnvironment.schema + API_MLPROXY, {observe:'response'}).pipe(
      catchError((err) => {
        return throwError(err.statusText);
      })
    )
  }

}
