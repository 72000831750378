import { Component, OnInit, Input, Inject } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-video-panel-dictionary',
  templateUrl: './video-panel-dictionary.component.html',
  styleUrls: ['./video-panel-dictionary.component.css']
})
export class VideoPanelDictionaryComponent {
  @Input() fromHelpPanel: boolean;

  constructor(public dialogRef: MatDialogRef<VideoPanelDictionaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private configurationService: ConfigurationService) {
    //super();
    

  }

  ngOnInit() {
    console.log("From help" + this.fromHelpPanel)
  }

  disableInstructionalVideos(){
    this.configurationService.setShowHelp(false);
  }

}
