import { Component, OnInit } from '@angular/core';
import {ValidateService} from '../../services/validate.service'
import {AuthServiceEuroglot} from '../../services/auth.service'
import { Router } from '@angular/router';
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import {MatSnackBar} from '@angular/material/snack-bar';
import 'hammerjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  socialuserToken: string;
  email: string;
  username: string;
  maillink: string;
  password: string;


  constructor(
    private validateService: ValidateService,
    private authService:AuthServiceEuroglot,
    private router: Router,
    private socialAuth: AuthService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  isLoggedIn(){
		if (this.authService.checkLogin()) {
			this.snackBar.open('You are already logged in', 'OK', {duration:3000});
			this.router.navigate(['dictionary']);
		}
	}

  registerWithGoogle(){
    this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      this.socialuserToken = user.idToken;
      if(user!=null){
        //try to register the user
        this.authService.registerWithGoogle(this.socialuserToken).subscribe(
          data => this.handleRegWithGoogleSuccess(data),
          error => this.handleRegWithGoogleError(error)
        )
      }

    })

  }


 handleRegWithGoogleSuccess(data){
  //console.log("REGISTER DATA" + JSON.stringify(data))
  if(data.success == true){
    this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      this.socialuserToken = user.idToken
      if(user != null){
       //try to log the user in
       this.authService.loginWithGoogle(this.socialuserToken);
       this.isLoggedIn();

     }

    })

  }
 if(data.data.message == undefined){
   return;
 }

 if(data.data.message){
    if(data.data.message === "Authentication error: your account is already registered" ){
      this.snackBar.open('You are already registered, please log in', "OK" ,{duration:3000})
      this.router.navigate(['/login']);
     }
      //console.log("DATA" + JSON.stringify(data.data.message))
 }




 }


 handleRegWithGoogleError(error){

   console.log("ERROR" + error.data.message)
 }


  onRegisterSubmit(){
    const user = {
      username: this.username.trim(),
      email: this.email.trim()
  }
    //console.log("THE USER TO REGISTER" + JSON.stringify(user))



    // Validate Email
    if(!this.validateService.validateEmail(user.email)){
      //console.log("Fill in email")
      return false;
    }

    // Register user
    this.authService.registerUserMail(user).subscribe(
      data => this.handleRegisterSuccess(data),
		  error => this.handleRegisterError(error),
		  () => null
    );

  }

  handleRegisterSuccess(data) {
    //console.log("Register response" + JSON.stringify(data))
      if(data.success){
        this.router.navigate(['/register-success']);
      } else {
        this.router.navigate(['/register']);
      }

  }

  handleRegisterError(error) {
    console.log(error);
  }

}
