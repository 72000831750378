import { Component, OnInit, Input, Inject } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration.service';
import { ConjugationService } from '../../../services/conjugation.service';
import { ResourceService, ResourceMapInterface } from '../../../shared-services/resource.service';


@Component({
  selector: 'conjugated-translations',
  templateUrl: './conjugated-translation.component.html',
  styleUrls: ['./conjugated-translation.component.css']
})
export class ConjugatedTranslationComponent implements OnInit {
  currentResourceMaps: ResourceMapInterface;

  @Input() conceptid: number
  @Input() dictionary: string
  @Input() sourceForm: string
  @Input() focus: number
  form: string;
  lemma: string;
  translations: any[];

  constructor(@Inject(ResourceService) private resourceService: ResourceService,
  private configurationService: ConfigurationService, private conjugationService: ConjugationService) { }

  ngOnInit() {
    this.resourceService.currentResourceMaps.subscribe(currentResourceMap => this.currentResourceMaps = currentResourceMap);

  }

  loadConjugatedTranslations() {
    let sourceLang = this.configurationService.getSourceLanguage();
    let targetLang = this.configurationService.getTargetLanguage();
    let caseSensitive = this.configurationService.getCaseSensitive();
    let diacriticSensitive = this.configurationService.getDiacriticSensitive();

    this.conjugationService.getTranslatedConjugations(this.dictionary, sourceLang, targetLang, this.conceptid, this.focus, this.sourceForm, caseSensitive, diacriticSensitive).subscribe(
    data => this.handleTranslationSuccess(data),
    error => this.handleTranslationError(error),
    () => {}
    )
  }

  //handle translation data
  handleTranslationSuccess(data){
    //console.log("data " + JSON.stringify(data.data));
    this.form = data.data.form;
    this.lemma = data.data.lemma;
    this.translations = data.data.translations;
    this.getResources();

  }
  //handle translation error
  handleTranslationError(error){
    console.log(error)
  }

  getResources() {
    var screenLanguage = this.configurationService.getScreenLanguage();
    for(var translation of this.translations) {
      //console.log("translation" + JSON.stringify(translation))
      if(translation.source_variant_name != undefined){
        this.resourceService.resource(translation.source_variant_name, screenLanguage);
      }

      if(translation.conjugations != undefined && translation.conjugations.variants != undefined){
        for(var variant of translation.conjugations.variants){
          this.resourceService.resource(variant.variant_name, screenLanguage)
        };
      }
      
    }
  }


}
