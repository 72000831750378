import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ContextMenuService } from 'ngx-contextmenu';

@Component({
  selector: 'deepl-contextmenu',
  templateUrl: './deepl-contextmenu.component.html',
  styleUrls: ['./deepl-contextmenu.component.scss']
})
export class DeeplContextmenuComponent implements OnInit {
  @Output() wordClickedEvent = new EventEmitter();


  constructor(private contextMenuService: ContextMenuService) { }
  

  ngOnInit(): void {
  }

  onContextMenu($event){
    

    $event.preventDefault();

    setTimeout(() => {
      this.contextMenuService.show.next({
        event: $event,
        item: null
      });
    }, 100 );
    
    $event.stopPropagation();
  

  }


  wordClicked($event) {
    console.log("word clicked")
  }

}
