import { EditorState, Plugin } from 'prosemirror-state';
import { DecorationSet } from 'prosemirror-view';

import { placeholder as defaultPlaceholder } from 'ngx-editor';

import { readOnlyEditor } from './flags';

export function placeholder(text? : string) : Plugin<any, any> {
  let plugin = defaultPlaceholder(text);

  //Code below based on default placeholder in ngx-editor
  let oldfn = plugin.props.decorations;
  plugin.props.decorations = function(state : EditorState) {
      if (readOnlyEditor(state))
        return DecorationSet.empty;

      return oldfn(state);
  }

  return plugin;
}
