import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment, varEnvironment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SegmenterService {

  requestOptions: {} = {withContentType: 'application/x-www-form-urlencoded', withCharset: 'utf-8' };

  constructor(private http: HttpClient) { }

  segmentText(textToSegment:string, sourceLang:string){

    let body = {
      "text": textToSegment,
      "language": sourceLang,
    }

    return this.http.post(varEnvironment.schema +'://' + environment.API_IP + ":" + environment.PORT_SEGMENTER + environment.ENDPOINT_SEGMENT, body, this.requestOptions)

  }


}
