<mat-dialog-content>
  <div class="form-container">
      <input type="text" matInput placeholder="lemma" [(ngModel)]="newLemma.word" [value]="newLemma.lemma" class="form-control">
    
      <mat-select [(ngModel)]="newLemma.word_type">
        <mat-option *ngFor="let wordtype of wordtypes; let i = index" [value]="i">{{wordtype}}</mat-option>
     </mat-select>

     <mat-select [(ngModel)]="newLemma.gender">
        <mat-option *ngFor="let gender of genders; let i = index" [value]="i">{{gender}}</mat-option>
     </mat-select>
    
      <input type="text" matInput placeholder="attributes" [(ngModel)]="newLemma.attributes" [value]="newLemma.attributes" class="form-control">
  </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button type= "submit" mat-raised-button (click)="onCloseConfirm()">CONFIRM</button>
    <button mat-raised-button (click)="onCloseCancel()">CANCEL</button>
</mat-dialog-actions>
