import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { GrammarService, grammarOptionsInterface } from '../../../shared-services/grammar.service';
import { GrammarPanelComponent } from '../grammar-panel/grammar-panel.component';
import {MatDialog} from '@angular/material/dialog';



@Component({
  selector: 'grammar',
  templateUrl: './grammar.component.html',
  styleUrls: ['./grammar.component.css']
})


export class GrammarComponent implements OnInit {
  @ViewChild(GrammarPanelComponent) grammarpanel: GrammarPanelComponent;
  @Input() language: string;
  @Input() conceptid: number;
  @Input() focus: number;
  @Input() dictionary: string;
  showLink: boolean = true;
  showGrammarPanel: boolean = false;


  message: string;


  constructor(
    private grammarService: GrammarService, public dialog: MatDialog) {
     }

  grammarOptions:grammarOptionsInterface

  ngOnInit() {
    this.grammarService.currentGrammar.subscribe(grammarOptions => this.grammarOptions = grammarOptions);
    //console.log("Concept id from grammar component" + this.conceptid)
    //console.log("LAnguage from grammar component" + this.language)
    //console.log("Focus from grammar component" + this.focus)
    //console.log("Dictionary from grammar component" + this.dictionary)


  }

  changeGrammar() {
    var grammarOptions = {
      language: this.language,
      conceptid: this.conceptid,
      focus: this.focus,
      dictionary: this.dictionary
    }
    this.grammarService.changeGrammar(grammarOptions)

  }

  public openGrammar() {
   // console.log("FILLED CONCEPTID?" + this.conceptid +this.focus + this.language)
    this.changeGrammar();
    const dialogRef = this.dialog.open(GrammarPanelComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }




}
