<app-navbar (open)="navOpen($event)"></app-navbar>

<div class="app-wrapper">
  <mat-sidenav-container class="example-container" >
    <mat-sidenav #sidenav mode="side" opened>
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Reading">
        
       
          <div class="text-length">
            <mat-progress-spinner
            class="example-margin"
            mode="determinate" [value]='noOfSourceWords / 4'> 
            </mat-progress-spinner> 
            <div style="position:relative; top: -67px; left: 35px; font-size:20px;">{{noOfSourceWords}}</div>

            <div class="reading">
            <p><b>Reading ease:</b><br> Flesh-Kincaid: {{sourceStats.readibilityscore}}</p>
            <p *ngIf="sourceStats.readibilityscore !==0 && sourceStats.readibilityscore > 0 && sourceStats.readibilityscore < 10">Your text is very<br>difficult to read.Best understood<br>by university graduates. For SEO, aim at a score between <b>60 and 70</b> </p>
            <p *ngIf="sourceStats.readibilityscore !==0 && sourceStats.readibilityscore > 10 && sourceStats.readibilityscore < 30">Your text is extremely<br>difficult to read. For SEO, aim at a score between <b>60 and 70</b> </p>
            <p *ngIf="sourceStats.readibilityscore > 30 && sourceStats.readibilityscore < 50">Your text is <br>difficult to read. For SEO, aim at a score between <b>60 and 70</b></p>
            <p *ngIf="sourceStats.readibilityscore !==0 && sourceStats.readibilityscore > 50 && sourceStats.readibilityscore < 60">Your text is <br>fairly difficult to read, for SEO aim at a score between <b>60 and 70</b> </p>
            <p *ngIf="sourceStats.readibilityscore > 60 && sourceStats.readibilityscore < 70">Your text is <br>written in plain language an your readability level is optimized for SEO</p>
            <p *ngIf="sourceStats.readibilityscore !==0 && sourceStats.readibilityscore > 70 && sourceStats.readibilityscore < 80">Your text is <br>fairly easy to read, but for SEO, aim at a score between <b>60 and 70</b> </p>
            <p *ngIf="sourceStats.readibilityscore !==0 && sourceStats.readibilityscore > 80 && sourceStats.readibilityscore < 90">Your text is <br> easy to read. Conversational <br>language for consumers <br>For SEO, aim at a score between <b>60 and 70</b>  </p>
            <p *ngIf="sourceStats.readibilityscore !==0 && sourceStats.readibilityscore > 90 && sourceStats.readibilityscore < 100">Your text is <br> Very easy to read. For SEO, aim at a score between <b>60 and 70</b>  </p>
            </div>
            <p><b>Your text contains {{sourceStats.difficultwords}} difficult words.</b> Using many difficult words can decrease your readability score.</p>
            <p><b>No of sentences:</b> {{sourceStats.no_sentences}}</p>
            <p><b>Transition words:</b>You are using x transition words. Try to use a couple. </p>
            <b>Avg sentence length:</b> {{sourceStats.avgsentlength}} words
            <p class="improve" *ngIf="sourceStats.avgsentlength !==0 && sourceStats.avgsentlength > 20">A concise sentence ideal for SEO is below 20 words. You don't have to make all your sentences short - this would sound robotic - but it's a good rule of thumb to follow. If a lot of your sentences are above 20 words, your content is hard to read</p>
            <p class="well-done" *ngIf="sourceStats.avgsentlength !==0 && sourceStats.avgsentlength < 20">Well done, the length of your sentences is optimized for SEO</p>

            <b>Text length:</b>
            <p class="improve" *ngIf="noOfSourceWords !==0 && noOfSourceWords<400">Your text is <b>{{noOfSourceWords}}</b> words. Recommended are more than <b>400</b>. Write a bit more. It can help <br>to get your content indexed<br>better by search engines</p>
            <p class="well-done" *ngIf="noOfSourceWords>400">Well done your text is long enough. Continue to write a bit more. Longer texts rank better</p> 
         
          </div>
           
  
        </mat-tab>
        <mat-tab label="SEO">
         
          <div class="focus-keyword">
            <i class="info-icon material-icons" width="60px" height="60px" [matTooltipPosition]="position.value" matTooltip="Click for full screen">info</i><b>Focus keyword:</b>
            <mat-form-field appearance="outline" [style.width.%]=78>
              <mat-label>Focus Keyword</mat-label>
              <input matInput type="text">
            </mat-form-field>
          </div>
     
          <div class="focus-keyword">
            <div class="sem-keywords">
              <i class="info-icon material-icons" width="60px" height="60px" [matTooltipPosition]="position.value" matTooltip="Click for full screen">info</i><b>Semantic keywords:</b>
            <mat-form-field appearance="outline" [style.width.%]=78>
              <mat-label>Find topic info. Enter a keyword</mat-label>
              <input matInput type="text" [(ngModel)]="sourceFocusKeyword" (ngModelChange)="findSemanticKeywordsSource('sourceLanguage', 'sourceCountry', sourceFocusKeyword)">
            </mat-form-field>
          
    
            <mat-chip-list *ngFor="let keyword of sourceKeywordsArray" aria-label="Fish selection">
              <mat-chip>{{keyword}}</mat-chip>
              </mat-chip-list>
            </div>
    
          </div>
        </mat-tab>
      </mat-tab-group>
     
     
    

    </mat-sidenav>
    <mat-sidenav #sidenav2 mode="side" position="end">
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Reading">
          <div class="text-length">
            <mat-progress-spinner
            class="example-margin"
            mode="determinate" [value]='noOfTargetWords / 4'>
            </mat-progress-spinner>
            <div class="reading">
              <p><b>Reading ease:</b><br> Flesh-Kincaid: {{targetStats.readibilityscore}}</p>
              <b>Readability score</b><br> Flesh-Kincaid: {{targetStats.readibilityscore}}
              <p class="improve" *ngIf="targetStats.readibilityscore !==0 && targetStats.readibilityscore > 0 && targetStats.readibilityscore < 10">Your text is very<br>difficult to read.Best understood<br>by university graduates. For SEO, aim at a score between 60 and 70 </p>
              <p class="improve" *ngIf="targetStats.readibilityscore !==0 && targetStats.readibilityscore > 10 && targetStats.readibilityscore < 30">Your text is extremely<br>difficult to read. For SEO, aim at a score between 60 and 70 </p>
              <p class="improve" *ngIf="targetStats.readibilityscore > 30 && targetStats.readibilityscore < 50">Your text is <br>difficult to read. For SEO, aim at a score between 60 and 70</p>
              <p class="improve"*ngIf="targetStats.readibilityscore !==0 && targetStats.readibilityscore > 50 && targetStats.readibilityscore < 60">Your text is <br>fairly difficult to read, for SEO aim at a score between 60 and 70 </p>
              <p class="improve" *ngIf="targetStats.readibilityscore > 60 && targetStats.readibilityscore < 70">Your text is <br>written in plain language an your readability level is optimized for SEO</p>
              <p class="improve" *ngIf="targetStats.readibilityscore !==0 && targetStats.readibilityscore > 70 && targetStats.readibilityscore < 80">Your text is <br>fairly easy to read, but for SEO, aim at a score between 60 and 70 </p>
              <p class="improve" *ngIf="targetStats.readibilityscore !==0 && targetStats.readibilityscore > 80 && targetStats.readibilityscore < 90">Your text is <br> easy to read. Conversational <br>language for consumers <br>For SEO, aim at a score between 60 and 70  </p>
              <p class="improve"*ngIf="sourceStats.readibilityscore !==0 && sourceStats.readibilityscore > 90 && targetStats.readibilityscore < 100">Your text is <br> Very easy to read. For SEO, aim at a score between 60 and 70  </p>
      
            </div>
            <p><b>Your text contains {{targetStats.difficultwords}} difficult words.</b> Using many difficult words can decrease your readability score.</p>
            <p><b>No of sentences:</b> {{targetStats.no_sentences}}</p>
            <p><b>Transition words:</b>You are using x transition words. Try to use a couple. </p>
            <b>Avg sentence length:</b> {{targetStats.avgsentlength}} words
            <p class="improve" *ngIf="targetStats.avgsentlength !==0 && targetStats.avgsentlength>21">Make your sentences shorter,<br> it makes your content better to understand</p>
            <p class="well-done" *ngIf="targetStats.avgsentlength !==0 && targetStats.avgsentlength<20">Well done, your sentences are short enough.<br></p>  

          </div>

        </mat-tab>
        <mat-tab label="SEO">
          <div class="focus-keyword">
            <i class="info-icon material-icons" width="60px" height="60px" [matTooltipPosition]="position.value" matTooltip="Click for full screen">info</i><b>Focus keyword:</b>
            <mat-form-field appearance="outline" [style.width.%]=78>
              <mat-label>Focus Keyword</mat-label>
              <input matInput type="text">
            </mat-form-field>
          </div>
          <div class="text-length">
            <b>Text length:</b>
            <p class="improve" *ngIf="noOfTargetWords !==0 && noOfTargetWords<400">Your text is <b>{{noOfTargetWords}}</b> words. Recommended are more than <b>400</b>. Write a bit more. It can help <br>to get your content indexed<br>better by search engines</p>
            <p class="well-done" *ngIf="noOfTargetWords>400">Well done your text is long enough. Continue to write a bit more. Longer texts rank better</p> 
          </div> 
          <div class="focus-keyword">
            <div class="sem-keywords">
              <i class="info-icon material-icons" width="60px" height="60px" [matTooltipPosition]="position.value" matTooltip="Click for full screen">info</i><b>LSI keywords:</b>
          <mat-form-field appearance="outline" [style.width.%]=78>

            <mat-label>Find topic info. Enter a keyword</mat-label>
            <input matInput type="text" [(ngModel)]="targetFocusKeyword" (ngModelChange)="findSemanticKeywordsTarget('targetLanguage', 'targetCountry', targetFocusKeyword)">
          </mat-form-field>
        
  
          <mat-chip-list *ngFor="let keyword of targetKeywordsArray" aria-label="keyword selection">
            <mat-chip>{{keyword}}</mat-chip>
            </mat-chip-list>
            </div>
    
          </div>
        </mat-tab>
      
      </mat-tab-group>



      

    </mat-sidenav>
    
      <button class="source-button" mat-button (click)="sidenav.toggle()"><mat-icon>keyboard_arrow_right</mat-icon></button>
      <button class="target-button" *ngIf="isStartTranslationButtonClicked" mat-button (click)="sidenav2.toggle()"><mat-icon>keyboard_arrow_left</mat-icon></button>

      
      <div class="start-translation-button" >
        <button mat-stroked-button color="primary" (click)="pickTargetLanguage()" *ngIf="!isStartTranslationButtonClicked"><mat-icon>translate</mat-icon></button>
        <button mat-stroked-button color="primary" (click)="stopTranslationRequest()" *ngIf="isStartTranslationButtonClicked">Hide translation</button>

      
      </div>
      <div class="scrollable-container">
        <div class="row no-gutters">
          <div class="col">
            
            <div class="set-language">
              <mat-form-field appearance="outline">
                <mat-label>Language</mat-label>
                <mat-select [(ngModel)]="sourceLanguage" (ngModelChange)="sourceLanguageSelected(sourceLanguage)">
                  <mat-option *ngFor="let language of languages" [value]="language.name">{{language.title}}</mat-option>
                  
                </mat-select>
              </mat-form-field>
            </div>

          </div>
          <div class="col">
            <div class="translation-requested" *ngIf="isStartTranslationButtonClicked">
        
              <div class="set-target-language">
                <mat-form-field class="target-language" appearance="outline"  >
                  <mat-label>Language</mat-label>
                  <mat-select [(ngModel)]="targetLanguage" (ngModelChange)="targetLanguageSelected(targetLanguage)">
                    <mat-option *ngFor="let language of languages" [value]="language.name">{{language.title}}</mat-option>
                  </mat-select>
                </mat-form-field>
               <!-- <mat-form-field appearance="outline">
                  <mat-label>Country</mat-label>
                  <mat-select [(ngModel)]="targetCountry"  (ngModelChange)="targetCountrySelected(targetCountry)">
                    <mat-option *ngFor="let country of countries" [value]="country.name">{{country.title}}</mat-option>
                  </mat-select>
                </mat-form-field> -->
               
              </div>
              <div class="translate-buttons">
                <button class="translate-button" mat-stroked-button color="primary"  (click)="translateButtonClicked()" [disabled]="translateButtonDisabled">{{translateButtonText}}</button>
                <button class="clear-button" mat-stroked-button color="primary" (click)="clear()" [disabled]="clearButtonDisabled">{{clearButtonText}}</button>
        
              </div>
            
            </div>

          </div>


        </div>

      
        
         <div class="row no-gutters">
          <div class="col">
            <div class="text-editor mono">
              <euroglot-editor #sourceEditor [(ngModel)]="sourceEditorText" (ngModelChange)="sourceEditorTextChanged($event)" (onUIAction)="sourceEditorUIAction($event)"></euroglot-editor>
             </div>
          </div>
          <div class="col" *ngIf="isTranslated">
            <div class="text-editor">
              <euroglot-editor #targetEditor height="300px" [(ngModel)]="targetEditorText" (ngModelChange)="targetEditorTextChanged($event)" (onUIAction)="targetEditorUIAction($event)" [readonly]="false" [source]="false"></euroglot-editor>
            </div>
          </div>
           
        
        </div>
       
      </div>
      <div class="footer-info">
        <div class="row no-gutters">
          <div class="word-count">
            <p class="card-text">Amount of words: <b>{{noOfSourceWords}}</b></p>
          </div>
          <div class="word-count">
            <p class="card-text">Reading time: <b>{{readingtime}}</b></p>
          </div>
          <div *ngIf="deeplcredits !=undefined" class="character-count">
            <p class="card-text"> <b>{{deeplcredits}}</b> translation characters</p>
          </div>
          <div *ngIf="deeplcredits == undefined" class="character-count">
            <p class="card-text">You don't have any Machine Translation characters</p>
          </div>
        
        </div>
      </div>
  
    
  </mat-sidenav-container>
  

</div>
