import { Injectable, EventEmitter } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import {AuthServiceEuroglot, LoggedInEvent} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class JwtDecoderService {

  private loggedInSubscription;
  private refreshedSubscription;

  tokenChangedEmitter : EventEmitter<null> = new EventEmitter();

  decodedToken : string = "";

  constructor(private authService:AuthServiceEuroglot) {
    this.decodeAuthToken();
    this.loggedInSubscription = authService.loggedInEmitter.subscribe({
      next: (event: LoggedInEvent) => {
          this.handleLogin(event)
      }
    })
    this.refreshedSubscription = authService.refreshedEmitter.subscribe({
      next: (event: LoggedInEvent) => {
          this.handleRefresh(event)
      }
    })
  }

  handleLogin(event : LoggedInEvent) {
    if (event.loggedIn) {
      this.decodeAuthToken();
    } else {
      this.clearAuthToken();
    }
  }

  handleRefresh(event : LoggedInEvent) {
    if (event.loggedIn) {
      this.decodeAuthToken();
    } else {
      this.clearAuthToken();
    }
  }

  decodeAuthToken() {
    var authToken: string = localStorage.getItem('id_token');

    this.decodedToken = jwt_decode(authToken);

    this.tokenChangedEmitter.emit();
  }

  clearAuthToken() {
    this.decodedToken = "";
    this.tokenChangedEmitter.emit();
  }

  getDecodedAuthToken(){
    return this.decodedToken;
  }

  getDecodedAuthTokenField(fieldname:string) : any {
    if (this.decodedToken.length == 0) {
      return null;
    }

    return this.decodedToken[fieldname];
  }

  
}
