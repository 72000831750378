import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, varEnvironment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  requestOptions: {} = {
    withContentType: 'application/x-www-form-urlencoded',
    withCharset: 'utf-8',
    /*withAuth: true*/
 };


  constructor(private http: HttpClient) { }

  translateText(sourceLang: string, targetLang: string, sourceText:Array<string>){

    let body =  {
      "source_language": sourceLang,
      "target_language": targetLang,
      "source_sentences": sourceText
    }

    return this.http.post(varEnvironment.schema + '://' + environment.API_IP + ":" + environment.PORT_TRANSLATE + environment.ENDPOINT_TRANSLATOR, body, this.requestOptions)
  }
}
