
export interface LanguageData {
    icon: string,
    name: string,
    title: string
  }

  export interface CountryData {
    name: string,
    title: string
  }

export const Languages: Array<LanguageData> = [
    {icon:"assets/icons/nl.png", name:"nl", title:"Dutch"},
    {icon:"assets/icons/en.png", name:"en", title:"English"},
    {icon:"assets/icons/fr.png", name:"fr", title:"French"},
    {icon:"assets/icons/de.png", name:"de", title:"German"},
    {icon:"assets/icons/es.png", name:"es", title:"Spanish"},
    {icon:"assets/icons/it.png", name:"it", title:"Italian"}
  ]

export const Countries: Array<CountryData> = [
  {name: "nl", title: "Netherlands"},
  {name: "de", title: "Germany"},
  {name: "fr", title: "France"},
  {name: "en-US", title: "USA"},
  {name: "en-UK", title: "UK"},
  {name: "ch", title: "Switzerland"},
  {name: "at", title: "Austria"},
  {name: "be", title: "Belgium"},
  {name: "lu", title: "Luxemburg"},
  {name: "it", title: "Italy"},
  {name: "es", title: "Spain"}

]