import { Injectable, Input } from '@angular/core';

//insures that every component consuming the service receives the most
//up to date data
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

export interface searchOptionsInterface {
    lemma: string
    language: string
}

@Injectable()
export class SearchService {

    private messageSource = new BehaviorSubject<searchOptionsInterface>(null)
    
    //variable set to an observable that can be used by the components, which can subscribe to it.
    currentSearch = this.messageSource.asObservable();

    constructor() {}

    changeSearch(searchOptionsInterface){
        //console.log("update search options " + JSON.stringify(searchOptionsInterface) )
        this.messageSource.next(searchOptionsInterface);
    }


}