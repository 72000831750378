import { Component, OnInit,Input, /*ChangeDetectorRef,*/ ViewChild  } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SegmenterService } from '../../../services/segmenter.service';
import { TranslateService } from '../../../services/translate.service';

import { JSONDoc, emptyJSONDoc } from '../../../classes/jsondoc';
import { IJSONDocJSON } from '../../../classes/jsondoc_interface';

import { EuroglotEditorComponent } from '../../../classes/editor/euroglot-editor.component';
import { SynonymClickedData, instanceOfSynonymClickedData, ColMouseData, instanceOfColMouseData } from '../../../classes/editor/plugins/events';
import { ColPosition } from '../../../classes/editor/plugins/root'
import { colMarkType } from '../../../classes/editor/schema';



export interface SegmenterSentence {
  eol_space: string;
  sentence: string;
}

export interface LanguageData {
  icon: string,
  name: string,
  title: string
}
export const TargetLanguages: Array<LanguageData> = [
  {icon:"assets/icons/icon-nl.png", name:"ned", title:"Dutch"},
  {icon:"assets/icons/icon-en.png", name:"eng", title:"English"},
]
export const SourceLanguages: Array<LanguageData> = [
  {icon:"assets/icons/icon-nl.png", name:"dui", title:"German"},

]

@Component({
  selector: 'drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})

export class DrawerComponent implements OnInit {
  //pasteResponseFunction: any;

  @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
  @ViewChild('sourceEditor') private sourceEditor: EuroglotEditorComponent;
  @ViewChild('targetEditor') private targetEditor: EuroglotEditorComponent;
  @Input() openNav: boolean;

  //Language fields
  targetLanguages = TargetLanguages;
  sourceLanguages = SourceLanguages;
  sourceLanguage: string = "dui";
  targetLanguage: string = "eng";

  //Translate/clear button
  translateButtonText: string = "Translate";
  clearButtonText: string = "Clear";

  //Editor stuff
  private sourceSegmentsClassName: string = 'sourceSegments'
  private targetSegmentsClassName: string = 'targetSegments'
  sourceEditorText : IJSONDocJSON;
  targetEditorText : IJSONDocJSON;
  sourceEditorDoc : JSONDoc;
  hoveredCol: any;
  hoveredSentence: any;

  constructor(private snackbar: MatSnackBar,
    private segmenter: SegmenterService,
    private translate: TranslateService,
  /*private cd: ChangeDetectorRef*/) {
  }

  //ng
  ngOnInit(): void {
    this.clear();
  }

  ngAfterViewInit(){
    /*this.pasteResponseFunction = getPasteResponseFunction();
    console.dir(this.pasteResponseFunction)*/
  }

  ngOnChanges(): void {
  }

  //Nav
  navOpen(event):any {
    this.sidenav.toggle();
    console.log(this.sidenav.opened)
  }

  //Editor
  clear(){
    this.sourceEditorText = emptyJSONDoc();
    this.targetEditorText = emptyJSONDoc();
  }

  sourceEditorTextChanged(event){
  }

  targetEditorTextChanged(event){
  }

  //Languages
  sourceLanguageChanged(event){
  }

  targetLanguageChanged(event){
  }

  //Translate
  translateButtonClicked(){
    this.getSourceText();
  }

  /*makeTranslationOnEnter(event){
    if(event.keyCode == 13){
      this.getSourceText();
    }
  }*/

  getSourceText(){
    this.sourceEditorDoc = new JSONDoc(this.sourceEditorText)
console.dir(this.sourceEditorDoc)
    let sections = this.sourceEditorDoc.getTextSections();
console.log("SOURCE TEXT SEGMENTS BEFORE SEGMENTER")
console.dir(sections)
    for(let i = 0; i < sections.length; i++) {
      this.segmenter.segmentText(sections[i].toString(), "de").subscribe(
        data => this.getSegmentTextSuccess(data, i),
        error => this.getSegmentTextError(error, i)
      )
    }
  }

  getSegmentTextSuccess(data, section : number){
    let segmenterSentences = data.data.sentences;
    console.log("SOURCE SECTION SENTENCES: " + JSON.stringify(segmenterSentences))
    this.translateText(segmenterSentences, section);
  }

  getSegmentTextError(error, section : number){
    this.snackbar.open("An error occurred" + error.message,'OK', {duration:3000})
    console.log("ERROR" + JSON.stringify(error.message))
    this.createErrorSection(section, error.message);
  }

  translateText(originalSentences : SegmenterSentence[], section : number){
    let sourceText = []
    for(let sentence of originalSentences){
      sourceText.push(sentence.sentence)
    }

    this.translate.translateText(this.sourceLanguage, this.targetLanguage, sourceText).subscribe(
      data => this.getTranslateTextSuccess(data, originalSentences, section),
      error =>  this.getTranslateTextError(error, section)
    )
  }

  getTranslateTextSuccess(data, originalSentences : SegmenterSentence[], section : number){
    console.log("Translate data for segment " + section + ": " + JSON.stringify(data))

    let l2 = originalSentences.length;

    let sourceSentences = data.data.source_segments;

    let ls = sourceSentences.length;
    for(let i = 0; i < ls && i < l2; i++) {
      let eolSpace = originalSentences[i].eol_space;
      sourceSentences[i].eol_space = eolSpace;
    }
    //let sourceEditorDoc : IJSONDocNode = makeJSONDocFromTranslator(sourceSentences, this.sourceSegmentsClassName)
    this.sourceEditorDoc.storeSourceSectionFromTranslator(section, sourceSentences);

    let targetSentences = data.data.target_segments;

    let lt = targetSentences.length;
    for(let i = 0; i < lt && i < l2; i++) {
      let eolSpace = originalSentences[i].eol_space;
      targetSentences[i].eol_space = eolSpace;
    }
    //let targetEditorDoc : IJSONDocNode = makeJSONDocFromTranslator(targetSentences, this.targetSegmentsClassName)
    this.sourceEditorDoc.storeTargetSectionFromTranslator(section, targetSentences);

    if(this.sourceEditorDoc.hasAllSectionsFromTranslator()) {

      this.sourceEditorText = this.sourceEditorDoc.getSourceEditorText(this.sourceSegmentsClassName, true);
      console.log("SOURCE EDITOR DOC")
      console.dir(this.sourceEditorText);
      this.targetEditorText = this.sourceEditorDoc.getTranslateEditorText(this.targetSegmentsClassName, true);
      console.log("TARGET EDITOR DOC")
      console.dir(this.targetEditorText);
    }
  }


  getTranslateTextError(error, section : number){
    this.snackbar.open("An error occurred" + error.message,'OK', {duration:3000})
    console.log("ERROR" + JSON.stringify(error.message))
    this.createErrorSection(section, error.message);
  }

  createErrorSection(section : number, error : string) {
    this.sourceEditorDoc.createErrorSection(section, error);
  }


  sourceSegmentClicked(event : any) {

  }

  targetSegmentClicked(event : any) {

  }

  sourceEditorUIAction(event : any) {
    if(instanceOfColMouseData(event)) {
      let e : ColMouseData = event as ColMouseData;
      let colPosition : ColPosition = {
        col:e.col,
        section:e.section,
        sentence:e.sentence,
      };
      if(e.mouseEvent.type == 'mouseenter') {
        this.highlightHovered(e.mouseEvent.target, colPosition, true, true);
      } else if(e.mouseEvent.type == 'mouseleave') {
        this.highlightHovered(e.mouseEvent.target, colPosition, true, false);
      } else if(e.mouseEvent.type == 'click') {
        this.sourceSegmentClicked(event);
      } else {
          console.dir(e.mouseEvent)
      }

    }
  }

  targetEditorUIAction(event : any) {
    if(instanceOfSynonymClickedData(event)) {
      this.menuSynonymClicked(event as SynonymClickedData)
    }
    if(instanceOfColMouseData(event)) {
      let e : ColMouseData = event as ColMouseData;
      let colPosition : ColPosition = {
        col:e.col,
        section:e.section,
        sentence:e.sentence,
      };
      if(e.mouseEvent.type == 'mouseenter') {
        this.highlightHovered(e.mouseEvent.target, colPosition, false, true);
      } else if(e.mouseEvent.type == 'mouseleave') {
        this.highlightHovered(e.mouseEvent.target, colPosition, false, false);
      } else if(e.mouseEvent.type == 'click') {
        this.targetSegmentClicked(event);
      } else {
          console.dir(e.mouseEvent)
      }

    }
  }

  private highlightHovered(target : EventTarget, colPosition : ColPosition, source : boolean, enter : boolean) {
    if(target instanceof Element) {
      //Highlight original element
      let ele : Element = target as Element;
      if(enter) {
        ele.classList.add('span-hovered-highlight');
      } else {
        ele.classList.remove('span-hovered-highlight');
      }

      //Find element in opposite editor and highlight
      let editor = this.sourceEditor;
      if(source)
        editor = this.targetEditor;

      let ele2 = editor.findElement(colMarkType, colPosition)
      if(ele2)
        if(enter) {
          ele2.classList.add('span-hovered-highlight');
        } else {
          ele2.classList.remove('span-hovered-highlight');
        }
    }
  }

  private menuSynonymClicked(event : SynonymClickedData) {
    console.dir(event);
    let altData = event
    let found = this.JSONDocChecker(this.targetEditorText, altData);
    if(found) {
      this.sourceEditorDoc.replaceTranslateAlt(altData, found.text);
      found.text = altData.alt;
    }

    //Reload targetEditorText from sourceEditorDoc
    this.targetEditorText = this.sourceEditorDoc.getTranslateEditorText(this.targetSegmentsClassName, true);
  }

  private JSONDocChecker(json : IJSONDocJSON, altdata : SynonymClickedData) : IJSONDocJSON {
    if(json){
      if((json.type == "text") && (json.marks)) {
        for(let i = 0; i < json.marks.length; i++) {
          let mark = json.marks[i];
          if((mark.type == colMarkType) && (mark.attrs.col == altdata.col) && (mark.attrs.sentence == altdata.sentence) && (mark.attrs.section == altdata.section)) {
            return json;
          }
        }
      }

      if(json.content) {
        for(let node of json.content){
          let success = this.JSONDocChecker(node, altdata)
          if(success) {
            return success;
          }
        }
      }
    }

    return null;
  }
}
