import { EditorState, EditorView } from 'prosemirror-state';
import { Selection } from "prosemirror-state";

import { ToolbarCustomMenuItem } from 'ngx-editor';

import { DispatchFn, DROPDOWN_ITEM_CLASSNAME } from './root';
import { ColPosition } from './root';
import { synonymClickedEventEmitter, SynonymClickedData/*, colMouseEventEmitter, ColMouseData*/ } from "./events";
import { colMarkType } from '../schema';
import { DropdownMenuCreator, DropdownMenuItemCreator, MenuItemProperties, ToolbarCustomDropdownGroup, ContextMenuCustomSideShowGroup } from './menu';
import { ContextMenuItem } from './contextmenu';

export const getDefaultSynonymMenuLabels = {
  synonyms: 'Synonyms',
}

function synonymMenuCreator() : DropdownMenuCreator {
  const synonymMenuProperties : MenuItemProperties = {
    label : getDefaultSynonymMenuLabels['synonyms'],
    flags : {
      source : false,
    },
    commandFn: showSynonyms(),
    //updateFn: synonymMenuUpdate(),
  }

  return new DropdownMenuCreator(synonymMenuProperties);
}
let smc = synonymMenuCreator();

export const synonymMenu: ToolbarCustomMenuItem = smc.createMenuItem();
export const synonymContextMenu: ContextMenuItem = smc.createContextMenuItem();

  /* Nice idea, but it doesn't really work out....
function synonymMenuUpdate() {
  return function(dom : HTMLElement, editorView : EditorView) : void {
    //Listen to col mouse hovers
    let emt = colMouseEventEmitter(editorView.state)
    if (emt != null) {
      let colMouseEvent : any = () => {
        return ($event : ColMouseData) => {
          if($event.mouseEvent.type == 'mouseenter') {
            //console.log("ENTER " + $event.col + " " + data.synonymWords)
            const canExecute = command(state, null, editorView);
            dom.classList.toggle(DISABLED_CLASSNAME, !canExecute);
            setDomInvisible(dom, sourceEditor(state));
          }
        }
      }
      emt.subscribe(colMouseEvent());
    }
  };
}*/

/*function getColDataFromEvent(event : ColMouseData) {
  var synonymWords : string | false = event.synonymWords;

  var position : ColPosition = {
    sentence:event.sentence,
    section:event.section,
    col:event.col,
  };

  return {
    synonymWords,
    position
  }
}*/

function getColDataFromSelection(selection : Selection) {
  var synonymWords : string | false;
  var position : ColPosition = null;

  if(selection.$anchor != null) {
    let marks = selection.$anchor.marks()
    for(let mark of marks) {
      if(mark.type.name == colMarkType) {
        if(mark.attrs.alt_translations) {
          synonymWords = mark.attrs.alt_translations;
        }
        position = {
          sentence:mark.attrs.sentence,
          section:mark.attrs.section,
          col:mark.attrs.col,
        }
      }
    }
  }

  return {
    synonymWords,
    position
  }
}

function synonymMenuItemCreator(synonymWord : string, position : ColPosition) : DropdownMenuItemCreator {
  const synonymMenuItemProperties : MenuItemProperties = {
    label: synonymWord,
    commandFn: exchangeSynonym(synonymWord, position),
  }

  return new DropdownMenuItemCreator(synonymMenuItemProperties);
}

function synonymMenuItem(synonymWord : string, position : ColPosition) : ToolbarCustomMenuItem {
  let smic = synonymMenuItemCreator(synonymWord, position);
  return smic.createMenuItem();
}

function synonymContextMenuItem(synonymWord : string, position : ColPosition) : ToolbarCustomMenuItem {
  let smic = synonymMenuItemCreator(synonymWord, position);
  return smic.createContextMenuItem();
}


function showSynonyms() {
  return function(state : EditorState, dispatch : DispatchFn, view : EditorView, parentDom : HTMLElement) : boolean {
    let contextMenu : boolean = parentDom.classList.contains("NgxEditor__Side");
    let data = getColDataFromSelection(state.selection);
    if(data.synonymWords == null) {
      if(contextMenu) {
        parentDom.append(ContextMenuCustomSideShowGroup.EmptyDom());
      } else {
        parentDom.append(ToolbarCustomDropdownGroup.EmptyDom());
      }
      return false;
    }

    if(data.synonymWords != false) {
      let sw = data.synonymWords.split('|');

      var items = null;
      if(contextMenu) {
        items = new ContextMenuCustomSideShowGroup();
      } else {
        items = new ToolbarCustomDropdownGroup();
      }
      for(let i = 0; i < sw.length; i++) {
        let word = sw[i];
        if(contextMenu) {
          let item = synonymContextMenuItem(word, data.position);
          items.push(item);
        } else {
          let item = synonymMenuItem(word, data.position);
          items.push(item);
        }
      }
      let tcr = items.render(view);
      console.log(parentDom)
      if(contextMenu) {
        var rect = parentDom.getBoundingClientRect();
        tcr.dom.style.left = rect.right + 4 + "px";
        //tcr.dom.style.top = rect.top ?? + "px";
      }

      parentDom.append(tcr.dom);
    }

    if(dispatch) {
      //TODO
    }

    return data.synonymWords != false;
  }
}

function exchangeSynonym(synonymWord : string, position : ColPosition) {
  return function(state : EditorState, dispatch : DispatchFn) : boolean {
    if(dispatch) {
      if(state.selection.$anchor != null) {
console.log("EXCHANGE " + synonymWord);
        let synonymData : SynonymClickedData =  position as SynonymClickedData;
        synonymData.alt = synonymWord;

        let evh = synonymClickedEventEmitter(state);
        if(evh != null) {
          evh.next(synonymData);
        }
      }
    }
    return false;
  }
}
