import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormsModule, FormGroup, ReactiveFormsModule, FormBuilder, FormControl, Validators} from '@angular/forms';
import {TerminologyService} from '../../../services/terminology.service';



@Component({
  selector: 'app-create-terminology-dialog',
  templateUrl: './create-terminology-dialog.component.html',
  styleUrls: ['./create-terminology-dialog.component.css']
})
export class CreateTerminologyDialogComponent implements OnInit {

  form: FormGroup;
  name = new FormControl('', [Validators.required])
  file = new FormControl('', [Validators.required])


  constructor(private terminologyService: TerminologyService,private dialogRef: MatDialogRef<CreateTerminologyDialogComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private fb: FormBuilder) { 
    this.form = fb.group({
     "name": this.name,
     "file": this.file
    })


  }

  ngOnInit() {
    //this.getList();
  }

 
  getList(){
    this.terminologyService.getList().subscribe(
      data => this.getListSuccess(data),
      error => this.getListError(error)
    )
  }
  getListSuccess(data){
    console.log("data" + JSON.stringify(data))
  }

  getListError(error){

  }

  createTerminology(name:string){
    console.log(name)
    this.terminologyService.createTerminologyDB(name).subscribe(
      data => this.createTerminologySuccess(data),
      error => this.createTerminologyError(error)
    )
  }

  createTerminologySuccess(data){
    console.log("response" + JSON.stringify(data))
    if(data){
      this.getList()
    }
  }

  createTerminologyError(error){
    console.log(error)
  }



}
