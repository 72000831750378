import { Component, OnInit,Input, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ResourceService } from '../../../shared-services/resource.service';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.css']
})
export class AttributesComponent implements OnInit {
  @Input() attributeData: string[]; 
  contents: string;

  private screenLanguageChangedSubscription: Subscription;

  constructor(
    private configurationService: ConfigurationService,
    private resourceService: ResourceService,
    private cd: ChangeDetectorRef,
  ) { 
    this.screenLanguageChangedSubscription = configurationService.screenLanguageChangedEmitter.subscribe({
      next: () => {
        this.parseAttributeData();
      }
    })
  }

  ngOnInit() {}


  ngAfterViewInit(){
    this.parseAttributeData();
  }

  ngOnDestroy() {
   this.screenLanguageChangedSubscription.unsubscribe();
  }

  parseAttributeData() {
    if(this.attributeData == undefined) {
      return;
    }

    let screenLanguage = this.configurationService.getScreenLanguage();
   // console.log("ATTR DATA" + JSON.stringify(this.attributeData))
    this.resourceService.attributes(this.attributeData, screenLanguage).then(
      resourcesResult=>{
        this.showResources(resourcesResult);
      }
    ).catch(
      //TODO
    )
  }

  showResources(resources: string[]) { 
   // console.log("ATTR" + JSON.stringify(resources))
    this.contents = '';
    resources.forEach(resource => {
      if(this.contents != '') {
        this.contents += ', ';
      }
      this.contents += resource;

    })
  //this.refresh();

  }



  //refresh after changes occurred
  refresh(){
    this.cd.detectChanges();
  }

}
