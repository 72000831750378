<div class="row">
    <div class="col">
       
     <img class="translation-img" src="assets/animation-reg.gif">

    </div>
    <div class="col">
      <div class="row">
        <div class="sign-in-box">

            <h3>Registration successful</h3>
            <p>Please check your e-mail for the verification link,<br> set your password and start translating!</p>

           
          
        </div>
        <div class="sign-up-notification">
            <h3>Log in to your account</h3>
            <p><a [routerLink]="['/login']">Log in to your account</a></p>
        </div>
     
      </div>
    </div>

</div>

