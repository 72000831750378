import { Component, OnInit } from '@angular/core';
import {AuthServiceEuroglot, LoggedInEvent} from '../../services/auth.service';
import {ConfigurationService} from '../../services/configuration.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import * as jwt_decode from 'jwt-decode';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	socialuserToken: string;

	username: string;
	password: string;
	isFreeUser: boolean = true;
	decodedToken: string;
	allowedDictionaries: string;


	private loggedInSubscription;
	private screenLanguageChangedSubscription;

	constructor(
		private authService: AuthServiceEuroglot,
		private configurationService: ConfigurationService,
		private router:Router,
		private snackBar: MatSnackBar,
		private socialAuth: AuthService,
	) {
		this.loggedInSubscription = authService.loggedInEmitter.subscribe({
			next: (event: LoggedInEvent) => {
					this.handleLogin(event)
			}
		})
		this.screenLanguageChangedSubscription = configurationService.screenLanguageChangedEmitter.subscribe({
			next: () => {
					;//TODO
			}
		})
	}

	ngOnInit() {


	}


	ngAfterViewInit() {
		this.isLoggedIn()
	}


   signInWithGoogle(){
	 this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
		 this.socialuserToken = user.idToken
		 if(user != null){
		  //try to log the user in
		  this.authService.loginWithGoogle(this.socialuserToken);
		  this.isLoggedIn();

		}

	 })
	}

	signOutWithGoogle(){
		this.socialAuth.signOut();
	}



	onLoginSubmit(){
		const user = {
			username: this.username.trim(),
			password: this.password.trim(),
            resetHash: ""
		}

		this.authService.authenticateUser(user);

	}

	isLoggedIn(){
		if (this.authService.checkLogin()) {
			this.snackBar.open('You are already logged in', 'OK', {duration:3000});
			this.router.navigate(['dictionary']);
		}
	}

	handleLogin(event : LoggedInEvent) {
		if (event.loggedIn) {
			this.snackBar.open('You are now logged in', 'OK', {duration:3000});
			var token = event.data.token
			this.decodedToken = jwt_decode(token);
			//console.log("decoded token" + JSON.stringify(this.decodedToken))
			this.allowedDictionaries = this.getDecodedAuthTokenField('ego_dictionaries_allowed');
			//console.log("allowedDictionaries" + JSON.stringify(this.allowedDictionaries))
			this.isFreeUser = this.getFreeUser();
			//console.log("Is this user free" + this.isFreeUser)
			if(this.isFreeUser){
				this.router.navigate(['translate']);
			} else{
				this.router.navigate(['translate']);
			}

		} else {
			//console.log("event message" + event.data.data.message)

			if(event.data.data.message == "Token creation error: not logged in"){
				this.snackBar.open("Password mismatch", 'OK', {duration:3000});
				this.router.navigate(['login']);
			}

		}
	}

	getDecodedAuthTokenField(fieldname:string) : any {
		if (this.decodedToken.length == 0) {
		  return null;
		}

		return this.decodedToken[fieldname];
	}
	getDictionaryAllowed(dictionaryName : string) : boolean {
		return this.allowedDictionaries.includes(dictionaryName);
	}
	getFreeUser(){
		var chemicdb = this.getDictionaryAllowed("chemicdb");
		var legaldb = this.getDictionaryAllowed("legaldb");
		var technidb = this.getDictionaryAllowed("technidb");
		var medicdb = this.getDictionaryAllowed("medicdb");
		var tradedb = this.getDictionaryAllowed("tradedb");
		var proverbdb = this.getDictionaryAllowed("proverbdb");
		var computdb = this.getDictionaryAllowed("computdb");

		if((chemicdb) && (legaldb) && (technidb) && (medicdb) && (tradedb) && (proverbdb) && (computdb)){
		  return false;
		}
		return true;
	  }

}
