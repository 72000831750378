import { EditorState, Transaction } from 'prosemirror-state';

export type KeyMap = { [key: string]: any };
export type DispatchFn = (tr: Transaction) => void;

export const EDITOR_CONTENT = "NgxEditor__Content";

export const SEPERATOR_CLASSNAME = 'NgxEditor__Seperator';

export const MENU_ITEM_CLASSNAME = "NgxEditor__MenuItem";
export const DISABLED_CLASSNAME = "NgxEditor--Disabled";
export const INVISIBLE_CLASSNAME = "NgxEditor--Hidden";
export const ACTIVE_MENU_ITEM_CLASSNAME = `${MENU_ITEM_CLASSNAME}--Active`;

export const DROPDOWN_ITEM_CLASSNAME = 'NgxEditor__Dropdown';
export const DROPDOWN_OPEN_CLASSNAME = `${DROPDOWN_ITEM_CLASSNAME}--Open`;
export const ACTIVE_DROPDOWN_ITEM_CLASSNAME = `${DROPDOWN_ITEM_CLASSNAME}--Active`;
//export const SELECTED_DROPDOWN_ITEM_CLASSNAME = `${DROPDOWN_ITEM_CLASSNAME}--Selected`;


export declare type TCR = {
    dom: HTMLElement;
    update: (state: EditorState) => void;
};

export const emptyTCR = {
  dom: document.createElement('div'),
  update: (state: EditorState): void => {},
}

export function setDomInvisible(dom : HTMLElement, isInvisible : boolean) {
  dom.classList.toggle(INVISIBLE_CLASSNAME, isInvisible)
  if(isInvisible == true) {
    dom.style.display = "none";
  } else {
    dom.style.display = "";
  }
}


export interface ColPosition {
  sentence: number,
  section: number,
  col: number,
}

export function instanceOfColPosition(object: any): object is ColPosition {
    return 'sentence' in object && 'section' in object && 'col' in object;
}

export interface ColMarkAttrs extends ColPosition {
    spanClass: string,
    alt_translations: string | false,
}
