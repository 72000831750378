import { Component, OnInit } from '@angular/core';
import {UserStatusService} from '../../../services/user-status.service';
import {MatDialog} from '@angular/material/dialog';
import {PaymentDialogComponent} from '../payment-dialog/payment-dialog.component';
import {PaymentService, PurchaseProduct, Purchase} from '../../../services/payment.service';

const euroglotSubscriptionProductId = 3;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  freeUser: boolean;
  customerName: string;
  customerEmail: string;
  subscription: boolean = true;

  constructor(private paymentService: PaymentService, private userStatusService: UserStatusService, private dialog: MatDialog) {
    this.freeUser = this.userStatusService.getFreeUser();
  }

  ngOnInit() {
  this.getCustomer();
  }

  getCustomer(){
    this.paymentService.getCustomer().subscribe(
      data => this.handleGetCustomerSuccess(data),
      error => this.handleGetCustomerError(error),
    )
  }

  handleGetCustomerSuccess(data){
   // console.log("CUSTOMER DATA" + JSON.stringify(data))
    let customers = data;
    this.customerName = customers.name;
    this.customerEmail = customers.email;


  }
  handleGetCustomerError(error){
    console.log(error)
  }


  openPaymentDialog(productnr, productdescription){
    let myPurchase: Purchase = []
    let myProduct: PurchaseProduct = {amount:1, id: productnr, product: productdescription}
    myPurchase.push(myProduct)
    let dialogRef = this.dialog.open(PaymentDialogComponent, {
      width: "600px",
      data: myPurchase
    })
  }


}
