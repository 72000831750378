import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, Injector } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

import { Packer } from 'docx';
import { DocumentCreator } from '../../../classes/docx/generator';

import {DeeplService} from '../../../services/deepl.service';
import {UserStatusService} from '../../../services/user-status.service';
import {LoggedInEvent} from '../../../services/auth.service';
import {PaymentService, PurchaseProduct, Purchase} from '../../../services/payment.service';
//import { SegmenterService } from '../../../services/segmenter.service';
import {TextstatService} from '../../../services/textstat.service';
import {KeywordService} from '../../../services/keyword.service';
import {GdriveService} from '../../../services/gdrive.service';
import { BaseTopLevel } from '../../dictionary-components/base-toplevel';
import { Languages, Countries } from '../../../interfaces/languages';

import { JSONDoc, emptyJSONDoc, isEmptyJSONDoc } from '../../../classes/jsondoc';
import { IJSONDocJSON } from '../../../classes/jsondoc_interface';

import { EuroglotEditorComponent } from '../../../classes/editor/euroglot-editor.component';
import { SynonymClickedData, instanceOfSynonymClickedData, ColMouseData, instanceOfColMouseData } from '../../../classes/editor/plugins/events';
import { ColPosition } from '../../../classes/editor/plugins/root'
import { colMarkType } from '../../../classes/editor/schema';

import { filterDeeplData } from '../../../classes/deepl_splitter';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {TooltipPosition} from '@angular/material/tooltip';
import {FormControl} from '@angular/forms';

import { Directive,ElementRef } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';


class Stats {
  difficultwords:number;
  no_sentences: number;
  avgsentlength: number;
  readibilityscore: number;
  passivevoicecount: number;

  Stats() {
    this.difficultwords = 0;
    this.no_sentences = 0;
    this.avgsentlength = 0;
    this.readibilityscore = 0;  
    this.passivevoicecount = 0;
  }
};



@Component({
  selector: 'deepl-mt',
  templateUrl: './deepl-mt.component.html',
  styleUrls: ['./deepl-mt.component.css'],
  //encapsulation: ViewEncapsulation.None
})

export class DeeplMtComponent extends BaseTopLevel implements OnInit {
  @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
  @ViewChild('sourceEditor') private sourceEditor: EuroglotEditorComponent;
  @ViewChild('targetEditor') private targetEditor: EuroglotEditorComponent;
  //@ViewChild(SynonymTableComponent) synonymTableComponent: SynonymTableComponent;
  @Input() openNav: boolean;

  //tooltips
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new FormControl(this.positionOptions[3]);

  //boolean to load translation screen
  isTranslated:boolean = false

  //boolean to choose language and country when translate button is clicked
  isStartTranslationButtonClicked: boolean = false;

  //focus keywords
  sourceFocusKeyword: string;
  targetFocusKeyword: string;
  sourceKeywordsArray: [];
  targetKeywordsArray: [];

  //Text from jsondoc
  gluedSourcetext: string;
  gluedTargetText: string;

  //amount of words
  noOfSourceWords: number = 0;
  noOfTargetWords: number = 0;

  //readingTime
  readingtime:string;

  //textstat stuff
  sourceStats : Stats;
  targetStats : Stats;

  //User status stuff
  private refreshedSubscription : Subscription;
  deeplcredits: number;
  freeUser: boolean;

  //Language fields
  languages = Languages;
  countries = Countries;
  sourceLanguage: string;
  targetLanguage: string;
  sourceCountry: string;
  targetCountry: string;

  //Translate/clear button
  translateButtonText: string = "Translate";
  clearButtonText: string = "Clear";

  translateButtonDisabled : boolean;
  clearButtonDisabled : boolean;

  opened: boolean;
  sidenav1: any;
  
  //Editor stuff
  private sourceSegmentsClassName: string = 'sourceSegments'
  private targetSegmentsClassName: string = 'targetSegments'
  sourceEditorText : IJSONDocJSON;
  targetEditorText : IJSONDocJSON;
  sourceEditorDoc : JSONDoc;
  targetEditorDoc : JSONDoc;
  hoveredCol: any;
  hoveredSentence: any;

  //Translate results
  detectedSourceLang: string;

  //Searching bar
  searching: boolean = false;

  //Synonym table

  sourceWord: string;
  //Synonym results
  //synonyms: any = [];

  //products loading completed
  isCompleted: boolean = false;

  //array to push products in
  arrayProduct = [];

  constructor(cd: ChangeDetectorRef,
    injector: Injector,
    private deeplService: DeeplService,
    private userStatusService: UserStatusService,
    private paymentservice: PaymentService,
    private textStat: TextstatService,
    private googleDrive: GdriveService,
    private keyWordService: KeywordService,
    private el: ElementRef
  ) {
    super(cd, injector)
    this.sourceStats = new Stats();
    this.targetStats = new Stats();
    

    this.refreshedSubscription = this.authService.refreshedEmitter.subscribe({
      next: (event: LoggedInEvent) => {
            this.handleRefresh(event)
        }
    })

    this.deeplcredits = this.userStatusService.getMTBalance();
    this.freeUser = this.userStatusService.getFreeUser();
    this.getProduct(6);
    if(this.freeUser){
      this.freeCreditsPurchase(6, "1.200 machine translation credits for free to test MT features")
    } else {
      console.log("you are not a free user")
    }
  }

  handleRefresh(event : LoggedInEvent) {
    if(event.loggedIn){
      this.deeplcredits = this.userStatusService.getMTBalance();
      this.freeUser = this.userStatusService.getFreeUser();
      if(this.deeplcredits == 0){
        this.snackBar.open('You are out of Machine Translation characters, please buy more', 'OK', {duration:3000});
      }
    } else {
      this.deeplcredits = 0;
      this.freeUser = true;
    }

  }

  //ng & super
  ngOnInit() : void {
   super.ngOnInit()
   this.getProduct(6);
   this.clear();
   console.log("no words oninit" + this.noOfSourceWords)

  }

  ngAfterViewInit(){
    /*this.pasteResponseFunction = getPasteResponseFunction();
    console.dir(this.pasteResponseFunction)*/
    this.translateButtonDisabled = true;
    this.clearButtonDisabled = true;
  }

  onConfigLoaded(){
    this.sourceLanguage = this.configurationService.getSourceLanguage();
    this.targetLanguage = this.configurationService.getTargetLanguage();

    this.deeplService.setSourceLang(this.sourceLanguage);
    this.deeplService.setTargetLang(this.targetLanguage);

    this.refresh();
  }

  //Nav
  navOpen(event):any {
    //this.sidenav.toggle();
    console.log(this.sidenav.opened)
  }


  //DeepL credits product / shop
  getProduct(id){
    this.paymentservice.getProduct(id).subscribe(
      data => this.handleGetProductSuccess(data),
      error => this.handleGetProductError(error),
      () => this.isCompleted = true
    )
  }

  handleGetProductSuccess(data){
    this.arrayProduct.push(data.data);
    console.log("PRODUCT DATA" + JSON.stringify(this.arrayProduct))


  }

  handleGetProductError(error){
    console.log(error.message);
  }

  freeCreditsPurchase(productnr : number, productdescription : string){
    //console.log("productnr" + productnr + 6)
    let myPurchase: Purchase = [];
    let myProduct: PurchaseProduct = {amount: 1, id: productnr, product:productdescription}
    myPurchase.push(myProduct)
    //console.log("purchase"+ JSON.stringify(myPurchase))
    this.paymentservice.purchaseFreeCredits(myPurchase).subscribe(
      data => this.handlePurchaseFreeCreditsSuccess(data),
      error => this.handlePurchaseFreeCreditsError(error)
    )
  }
  handlePurchaseFreeCreditsSuccess(data){
    this.authService.refreshTheToken();
    /*if(data.data.message == "Error while creating payment data: Purchase of product %!d(MISSING), '%!s(MISSING)' is not allowed due to purchase limit"){
      this.snackBar.open("You can use this product only once", 'OK', {duration: 3000});
    }*/
  }
  handlePurchaseFreeCreditsError(error){
    console.log(error)
  }

  //Editor
  clear(){
    //this.sourceEditorText = emptyJSONDoc();
    this.targetEditorText = emptyJSONDoc();
    this.isTranslated = false;
    this.isStartTranslationButtonClicked = false;
  }

  countWords(str){
    //console.log("string" + str)
    let matches = str.match(/[\w\d\’\'-]+/gi);
    return matches ? matches.length : 0;
   
  }

  calculateWords(numberofwords): string{
    let rTime = numberofwords / 250;
    let timeString = rTime.toString();
    let splittedStrArr = timeString.split('.')
    let min = parseInt(splittedStrArr[0]) + " min";
    let sec = parseInt(splittedStrArr[1]) + " sec";
    console.log(min + sec)
    let readTime = min + " " + sec
    readTime.toString()
    
    return readTime
  }

  findSemanticKeywordsSource(country, language, query){
    console.log("query" + query + language + country)
    this.keyWordService.getKeywords(country, language, query).subscribe(
      data => this.findSemanticKeywordsSourceSuccess(data),
      error => this.findSemanticKeywordsSourceError(error)
    )

  }
  findSemanticKeywordsSourceSuccess(data){
    for(let i=0; i<data.length; i++){
      console.log(data[1]);
      this.sourceKeywordsArray = data[1]
    }
  }
  findSemanticKeywordsSourceError(error){
    console.log(error)
  }

  findSemanticKeywordsTarget(country, language, query){
   this.keyWordService.getKeywords(country, language, query).subscribe(
     data => this.findSemanticKeywordsTargetSuccess(data),
     error => this.findSemanticKeywordsTargetError(error)
   )
  }

  findSemanticKeywordsTargetSuccess(data){
    for(let i = 0; i<data.length; i++){
      this.targetKeywordsArray = data[1]

    }
  }
  findSemanticKeywordsTargetError(error){
    console.log(error)
  }

  pickTargetLanguage(){
    this.isStartTranslationButtonClicked = true;
    this.isTranslated = true;
    let tag = this.el.nativeElement.querySelector("div")
    if(tag.classList.contains('mono')){
      tag.classList.remove('mono')
    }
  }
  stopTranslationRequest(){
    this.isStartTranslationButtonClicked = false;
    this.isTranslated = false;
  }

  sourceEditorTextChanged(event){
    this.changeButtons();
    this.sourceEditorDoc = new JSONDoc(this.sourceEditorText)
    let sections = this.sourceEditorDoc.getTextSections();
    if(sections == null){
      this.noOfSourceWords = 0;
      this.readingtime = "0";
      this.sourceStats.no_sentences = 0;
      this.sourceStats.difficultwords = 0;
      this.sourceStats.readibilityscore = 0;
      this.sourceStats.avgsentlength = 0;
      this.sourceStats.passivevoicecount = 0;

      return
    }
    let sectionsString = sections.toString()
    this.noOfSourceWords = this.countWords(sectionsString)
    this.readingtime = this.calculateWords(this.noOfSourceWords)
    this.showSourceText();
  
  }

  targetEditorTextChanged(event){
    this.changeButtons();
    this.targetEditorDoc = new JSONDoc(this.targetEditorText)
    let sections = this.targetEditorDoc.getTextSections();
    if(sections == null){
      this.noOfTargetWords = 0;
      this.readingtime = "";
      this.targetStats.no_sentences = 0;
      this.targetStats.difficultwords = 0;
      this.targetStats.readibilityscore = 0;
      this.targetStats.avgsentlength = 0;

      return
    }
    let sectionString = sections.toString()
    this.noOfTargetWords = this.countWords(sectionString);
    this.showTargetText();
  }

  changeButtons() {
    this.translateButtonDisabled = isEmptyJSONDoc(this.sourceEditorText);
    this.clearButtonDisabled = isEmptyJSONDoc(this.targetEditorText);
  }


  //Languages
  sourceCountrySelected(sourceLang){
    //save source country in config
    this.configurationService.setSourceCountry(sourceLang);
  }
  
  sourceCountryChanged(){
    let newCountry = this.configurationService.getSourceCountry();
    this.sourceCountry = newCountry
    return newCountry;
  }

  targetCountrySelected(targetLang){
    this.configurationService.setTargetCountry(targetLang);
  }
  targetCountryChanged(targetLang){
    let newCountry = this.configurationService.getTargetCountry();
    this.targetCountry = newCountry
    return newCountry
  }

  sourceLanguageSelected(sourceLang){
    //Save source lamguage in configuration
    this.configurationService.setSourceLanguage(sourceLang);
  }



  sourceLanguageChanged() {
    let newLang = this.configurationService.getSourceLanguage()

    this.deeplService.setSourceLang(newLang);
    this.sourceLanguage = newLang;

    return newLang;
  }

  targetLanguageSelected(targetLang){
    //Save target lamguage in configuration
    this.configurationService.setTargetLanguage(targetLang);
  }

  targetLanguageChanged() {
    let newLang = this.configurationService.getTargetLanguage()

    this.deeplService.setTargetLang(newLang);
    this.targetLanguage = newLang;

    return newLang;
  }



  //Translate
  translateButtonClicked(){
    this.getSourceText();
  }

  makeTranslationOnEnter(event){
    if(event.keyCode == 13){
      this.getSourceText();
    }
  }

  showSourceText(){

    this.gluedSourcetext = "";
    this.sourceEditorDoc = new JSONDoc(this.sourceEditorText)
    let sections = this.sourceEditorDoc.getTextSections();
    for(let i = 0; i < sections.length; i++) {
      let text = sections[i].trim();
      this.gluedSourcetext += text
    }
      this.getReadingInfoSourceText(this.gluedSourcetext);
      this.getPassiveVoiceSourceText(this.gluedSourcetext);

      //this.sidenav.toggle()

  }
  
  showTargetText(){
    this.gluedTargetText = "";
    this.targetEditorDoc = new JSONDoc(this.targetEditorText)
    let sections = this.targetEditorDoc.getTextSections();
    for(let i = 0; i < sections.length; i++){
      let text = sections[i].trim();
      this.gluedTargetText += text
    }
    this.getReadingInfoTargetText(this.gluedTargetText)
  }

  uploadToDrive(){
    this.targetEditorDoc = new JSONDoc(this.targetEditorText)
    let sections = this.targetEditorDoc.getTextSections();
    for(let i = 0; i < sections.length; i++) {
      let text = sections[i].trim();
      this.gluedTargetText += text
    }
    this.googleDrive.uploadToGoogleDrive(this.gluedTargetText).subscribe(
      data => this.uploadToDriveSuccess(data),
      error => this.uploadToDriveError(error)
    )
  }
  uploadToDriveSuccess(data){
    console.log("upload data" + JSON.stringify(data))
  }
  uploadToDriveError(error){
    console.log("upload error" + JSON.stringify(error))
  }

  

  getReadingInfoTargetText(plaintext){
    this.textStat.getReadingInfo(this.targetLanguage, plaintext).subscribe(
      data => this.getReadingInfoTargetTextSuccess(data),
      error => this.getReadingInfoTargetTextError(error)
    )
  }
  getReadingInfoTargetTextSuccess(data){
    this.targetStats.difficultwords = data.data.word.difficult_words_count;
    this.targetStats.no_sentences = data.data.sentence.sentencecount;
    this.targetStats.avgsentlength = data.data.sentence.avg_sentence_length
    this.targetStats.readibilityscore = data.data.text.readibility;
  }
  getReadingInfoTargetTextError(error){
    console.log(error);
  }

  getPassiveVoiceSourceText(plaintext){
    this.textStat.getPassiveVoiceCount(this.sourceLanguage, plaintext).subscribe(
      data => this.getPassiveVoiceSourceTextSuccess(data),
      error => this.getPassiveVoiceSourceTextError(error)
    )
  }

  getPassiveVoiceSourceTextSuccess(data){
   console.log("data passive voice" + JSON.stringify(data))
  }
  getPassiveVoiceSourceTextError(error){
    console.log(error)
  }

  getReadingInfoSourceText(plaintext){
 
    this.textStat.getReadingInfo(this.sourceLanguage, plaintext).subscribe(
      data => this.getReadingInfoSourceTextSuccess(data),
      error => this.getReadingInfoSourceTextError(error)
    )
    
  }
  getReadingInfoSourceTextSuccess(data){
    console.log("data" + JSON.stringify(data))
    this.sourceStats.difficultwords = data.data.word.difficult_words_count
    this.sourceStats.no_sentences = data.data.sentence.sentencecount
    this.sourceStats.avgsentlength = data.data.sentence.avg_sentence_length
    this.sourceStats.readibilityscore = data.data.text.readibility
    console.log("avtext" + this.sourceStats.avgsentlength)

    //this.sourceStats.readingtime = data.data.text.reading_time
  }
  getReadingInfoSourceTextError(error){
    console.log(error)
  }


  getSourceText(){
    if(isEmptyJSONDoc(this.sourceEditorText) || this.searching){
      return false;
    }
    this.searching = true;
    this.sourceEditorDoc = new JSONDoc(this.sourceEditorText)

//console.dir(this.sourceEditorDoc)
    let sections = this.sourceEditorDoc.getTextSections();
//console.log("SOURCE TEXT SEGMENTS BEFORE SEGMENTER")
//console.dir(sections)
    for(let i = 0; i < sections.length; i++) {
      /*this.segmenter.segmentText(sections[i].toString(), this.sourceLanguage).subscribe(
        data => this.getSegmentTextSuccess(data, i),
        error => this.getSegmentTextError(error, i)
      )*/
      let text = sections[i].trim();
      if(text == "") {
        this.processEmptyTextSection(i);
      } else {
        //let elements = deeplSplit(text, i);
        let filteredText = DeeplService.deeplTextFilter(text);
        this.deeplService.translateTextAlignment(filteredText).subscribe(
          data => this.translateTextSuccess(data, i),
          error => this.translateTextError(error, i)
        )
      }
    }
  }

  processEmptyTextSection(section : number) {
    this.sourceEditorDoc.storeSourceSectionFromTranslator(section, []);
    this.sourceEditorDoc.storeTargetSectionFromTranslator(section, []);

    this.testSectionsComplete();
  }

  translateTextSuccess(data, section : number){
    //let sourceText = data.data.sentences[0].source_sentence;
//console.log("SOURCE RETURN FOR SECTION: " + JSON.stringify(sourceText))
    //let targetText = data.data.sentences[0].target_sentence;
//console.log("TRANSLATIONS FOR SECTION: " + JSON.stringify(targetText))

    let deeplData = filterDeeplData(data.data);
    this.sourceEditorDoc.storeSourceSectionFromTranslator(section, deeplData.sourceData);
    this.sourceEditorDoc.storeTargetSectionFromTranslator(section, deeplData.targetData);

    this.testSectionsComplete();
  }

  translateTextError(error, section : number){
    if(error){
      if(error.error.data && (error.error.data.message == "Error while calulating credits use: requested number of credits larger than allowance available on token")) {
        this.snackBar.open("It seems you don't have enough characters to translate, go Premium, or buy more characters", 'OK', {duration:3000});
        this.createErrorSection(section, "Untranslated due to insufficient credits");
      } else {
        this.snackBar.open("An error occurred" + error.message,'OK', {duration:3000})
        console.log("ERROR" + JSON.stringify(error.message))
        this.createErrorSection(section, error.message);
      }
    }
  }

  createErrorSection(section : number, error : string) {
    this.sourceEditorDoc.createErrorSection(section, error);

    this.testSectionsComplete();
  }

  testSectionsComplete() {
    if(this.sourceEditorDoc.hasAllSectionsFromTranslator()) {

      this.sourceEditorText = this.sourceEditorDoc.getSourceEditorText(this.sourceSegmentsClassName, false);
      //console.log("SOURCE EDITOR DOC")
      //console.dir(this.sourceEditorText);
      this.targetEditorText = this.sourceEditorDoc.getTranslateEditorText(this.targetSegmentsClassName, false);
      //console.log("TARGET EDITOR DOC")
      //console.dir(this.targetEditorText);

      this.searching = false;

      //refresh the token credits have changed
      //console.log("refreshing token...")
      this.authService.refreshTheToken();
    }
  }

  //UI interaction
  sourceSegmentClicked(event : any) {

  }

  targetSegmentClicked(event : any) {

  }

  sourceEditorUIAction(event : any) {
    if(instanceOfColMouseData(event)) {
      let e : ColMouseData = event as ColMouseData;
      let colPosition : ColPosition = {
        col:e.col,
        section:e.section,
        sentence:e.sentence,
      };
      if(e.mouseEvent.type == 'mouseenter') {
        this.highlightHovered(e.mouseEvent.target, colPosition, true, true);
      } else if(e.mouseEvent.type == 'mouseleave') {
        this.highlightHovered(e.mouseEvent.target, colPosition, true, false);
      } else if(e.mouseEvent.type == 'click') {
        this.sourceSegmentClicked(event);
      } else {
          console.dir(e.mouseEvent)
      }

    }
  }

  targetEditorUIAction(event : any) {
    if(instanceOfSynonymClickedData(event)) {
      this.menuSynonymClicked(event as SynonymClickedData)
    }
    if(instanceOfColMouseData(event)) {
      let e : ColMouseData = event as ColMouseData;
      let colPosition : ColPosition = {
        col:e.col,
        section:e.section,
        sentence:e.sentence,
      };
      if(e.mouseEvent.type == 'mouseenter') {
        this.highlightHovered(e.mouseEvent.target, colPosition, false, true);
      } else if(e.mouseEvent.type == 'mouseleave') {
        this.highlightHovered(e.mouseEvent.target, colPosition, false, false);
      } else if(e.mouseEvent.type == 'click') {
        this.targetSegmentClicked(event);
      } else {
          console.dir(e.mouseEvent)
      }

    }
  }

  private highlightHovered(target : EventTarget, colPosition : ColPosition, source : boolean, enter : boolean) {
    if(target instanceof Element) {
      //Highlight original element
      let ele : Element = target as Element;
      if(enter) {
        ele.classList.add('span-hovered-highlight');
      } else {
        ele.classList.remove('span-hovered-highlight');
      }

      //Find element in opposite editor and highlight
      let editor = this.sourceEditor;
      if(source)
        editor = this.targetEditor;

      let ele2 = editor.findElement(colMarkType, colPosition)
      if(ele2)
        if(enter) {
          ele2.classList.add('span-hovered-highlight');
        } else {
          ele2.classList.remove('span-hovered-highlight');
        }
    }
  }

  private menuSynonymClicked(event : SynonymClickedData) {
    console.dir(event);
    let altData = event
    let found = this.JSONDocChecker(this.targetEditorText, altData);
    if(found) {
      this.sourceEditorDoc.replaceTranslateAlt(altData, found.text);
      found.text = altData.alt;
    }

    //Reload targetEditorText from sourceEditorDoc
    this.targetEditorText = this.sourceEditorDoc.getTranslateEditorText(this.targetSegmentsClassName, false);
  }

  private JSONDocChecker(json : IJSONDocJSON, altdata : SynonymClickedData) : IJSONDocJSON {
    if(json){
      if((json.type == "text") && (json.marks)) {
        for(let i = 0; i < json.marks.length; i++) {
          let mark = json.marks[i];
          if((mark.type == colMarkType) && (mark.attrs.col == altdata.col) && (mark.attrs.sentence == altdata.sentence) && (mark.attrs.section == altdata.section)) {
            return json;
          }
        }
      }

      if(json.content) {
        for(let node of json.content){
          let success = this.JSONDocChecker(node, altdata)
          if(success) {
            return success;
          }
        }
      }
    }

    return null;
  }

  downloadDocx(){
    if(this.targetEditorText){
      let plainText = new JSONDoc(this.targetEditorText).toString();
      const documentCreator = new DocumentCreator();
      console.log("target input" + plainText)
      const doc = documentCreator.create(plainText);
      //const packer = new Packer();

      Packer.toBlob(doc).then(blob => {
        console.log(blob);
        //let id = Date.now() + Math.random().toString(36).substr(2, 9);
        //saveAs(blob, this.targetLanguage + id +".docx");
        console.log("Document created successfully");
      });

    } else {
      this.snackBar.open('There is no translation to export', 'OK', {duration:3000});

    }
  }

  openSideNav1() {
    this.sidenav1.open();
  }
  openSideNav2() {}

  getTransitionWords(text, language){
   let arrayOfDutchTransitionWords = ['als gevolg van', 'behalve', 'bovendien', 'daarom', 'bijvoorbeeld', 'aan de ene kant', 'daardoor', 'daarna', 'door middel van', 'doordat', 'namelijk']
  
  }
}
