
import { Component,ChangeDetectorRef, Injector } from '@angular/core';
import { SupplementPanelComponent } from '../supplement-panel/supplement-panel.component';

const dictionary: string = "medicdb"

@Component({
  selector: 'medical-panel',
  templateUrl: '../supplement-panel/supplement-panel.component.html',
  styleUrls: ['../supplement-panel/supplement-panel.component.css']
})

export class MedicalPanelComponent extends SupplementPanelComponent {

  constructor(cd:ChangeDetectorRef, injector: Injector) { 

    super(cd, injector, dictionary);    
  }

}