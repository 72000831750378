import { Component, OnInit, Input, Inject } from '@angular/core';
import { SearchService, searchOptionsInterface} from '../../../shared-services/search.service';

@Component({
  selector: 'dictionary-search',
  templateUrl: './dictionary-search.component.html',
  styleUrls: ['./dictionary-search.component.css']
})
export class DictionarySearchComponent implements OnInit {
  @Input() lemma: string;
  @Input() language: string;
  success: boolean = false;
  error: boolean = false;
  searchService = this.SearchService;
  searchOptions: searchOptionsInterface;

  constructor(@Inject(SearchService) private SearchService) {
  }

  ngOnInit() {
    this.searchService.currentSearch.subscribe(searchOptions => this.searchOptions = searchOptions);
  }

  onClick() {
    //this.dictionary.search(this.lemma, this.language);
    //this.searchOptions = {'lemma': this.lemma, 'language': this.language};
    this.searchService.changeSearch({'lemma': this.lemma, 'language': this.language})
    //console.log("Set search options: " + JSON.stringify(this.searchOptions))
  }

}
