import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import {HttpClient} from '@angular/common/http';

const API_URL = "https://suggestqueries.google.com/complete/search?"
//const API_URL = "https://suggestqueries.google.com/complete/search?output=toolbar&hl=fr&gl=be&q=hotel"

@Injectable({
  providedIn: 'root'
})
export class KeywordService {

  constructor(private http: HttpClient) { }

  getKeywords(language, country, query){
    return this.http.jsonp(API_URL + "client=youtube&" + "hl="+ language + "&gl=" + country + "&q=" + query, 'callback')
  }

}
