
    
<app-navbar></app-navbar>


<mat-drawer-container class="example-container">
    <mat-drawer mode="side" opened fxHide.sm  >

        <left-navbar></left-navbar>
    </mat-drawer>
<mat-drawer-content>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 dashboard-container"> 

     <div class="mb-3 card">
      <div class="card-header-tab card-header"><h5>Euroglot Online</h5></div>
        <p class="intro-text">Euroglot Online is a platform with multiple translation tools. Right now, you will find here the Euroglot dictionaries and a Machine Translation editor, but a lot more is to come. At the moment we are working on the following features:</p>
          <h5 class="intro-text">In Progress:</h5>
          <ul class="intro-text">
            <li><b>CAT-Tool:</b>Work with your translations in a productive and structured way.</li>
            <li><b>Upload your own terminology:</b>Upload your own (company specific) terminology and glossaries that integrate with the dictionaries and the Machine Translations.</li>

          </ul>
        
    </div>
  </div>
  </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="mb-3 card">
          <div class="card-header-tab card-header">Multilingual Dictionary</div>
          <div class="p-0 card-body"><p class="intro-text">Euroglot is a conceptual dictionary for English, German, Dutch,
            French, Spanish and Italian. Look up translations of words, expressions, proverbs and technical, medical, financial, it legal and
            chemical concepts. For each word grammar and a multilingual thesaurus can be consulted.</p>
            <p class="intro-text" *ngIf="isFreeUser">With a free account you can only look up a limited number of terms. Go Premium if you want to get more hits.</p> 
            <div class="intro-text">
              <a [routerLink]="['/dictionary']">Go to dictionary</a>
            </div>  
          </div>
        </div>
        
      </div>
      <div class="col-sm-12 col-lg-6">
        <div class="mb-3 card">
          <div class="card-header-tab card-header">Machine Translation Editor</div>
          <div class="p-0 card-body"><p class="intro-text">Translate sentences and paragraphs of text using machine translation
            based on neural networks. Check and find alternative translations of words by clicking them in the source text. For
            the best results make sure each sentence begins with a capital letter and ends with a period.</p>
          <p class="intro-text" *ngIf="isFreeUser">With a free subscription you can translate 1200 characters for free. Go Premium in case you want to translate more.</p>
          <div class="intro-text">
            <a [routerLink]="['/translate']">Go to Machine Translation</a>
          </div>  
        </div>
        </div>
      </div>
    </div>
  </div>
    <!--<div class="grid-container">
        <h1 class="mat-h1">Welcome to Euroglot Online</h1>
        <mat-grid-list cols="2" rowHeight="350px">
          <mat-grid-tile *ngFor="let card of cards | async" [colspan]="card.cols" [rowspan]="card.rows">
            <mat-card class="dashboard-card">
              <mat-card-header>
                <mat-card-title><mat-icon>info</mat-icon>
                  {{card.title}}
                  <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>Expand</button>
                    <button mat-menu-item>Remove</button>
                  </mat-menu>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content *ngIf="isFreeUser" class="dashboard-card-content">
                <div>{{card.contentfree}}</div>
              </mat-card-content>
              <mat-card-content *ngIf="!isFreeUser" class="dashboard-card-content">
                <div>{{card.contentpremium}} </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </div> -->


</mat-drawer-content>
</mat-drawer-container>
