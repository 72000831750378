import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment, varEnvironment } from '../../environments/environment';
import { HttpService } from '../services/http.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

const API_URL = '://'+ environment.API_IP + ":"+ environment.PORT_PAY + environment.ENDPOINT_PAY;


export type Purchase = PurchaseProduct[]

export interface PurchaseProduct {
  amount: number,
  id: number,
  product: string,
}

export interface MethodDetails {
  email: string,
  due_date: string,
  locale: string,
  ideal_issuer: string,
  kbc_issuer: string,
  description: string,
  apple_token: string,
}

@Injectable({
  providedIn: 'root'
})



export class PaymentService {
  requestOptions: {} = {withAuth: true};

  constructor(private http: HttpService, private route:ActivatedRoute) { }

  getCustomer(): Observable<JSON>{
    return this.http.get(varEnvironment.schema + API_URL + environment.ENDPOINT_CUSTOMER, this.requestOptions)
  }

  getProducts():Observable<JSON>{
    return this.http.get(varEnvironment.schema + API_URL + environment.ENDPOINT_PRODUCTS, this.requestOptions)
  }

  getProduct(productid):Observable<JSON>{
    return this.http.get(varEnvironment.schema + API_URL + environment.ENDPOINT_PRODUCT + productid + "/", this.requestOptions)
  }

  getOrders(orderid):Observable<JSON>{
    return this.http.get(varEnvironment.schema + API_URL + environment.ENDPOINT_ORDER + orderid + "/", this.requestOptions)
  }

  purchase(name, email, paymentmethod, paymentmethodDetails:MethodDetails, vat_id, eu_company, purchase:Purchase ):Observable<JSON>{
    //console.log("PURCHASE" + JSON.stringify(purchase))
    let test = environment.TEST_PURCHASE;
    let currentURL = window.location.href;
    let newURL = currentURL.replace('translate', 'payment-result')
    //console.log("NEW" + JSON.stringify(newURL))
    let redirect_url = newURL
    let body = {
      'name':name,
      'email': email,
      'test': false,
      'redirect_url': redirect_url,
      'vat_id': vat_id,
      'eu_company': eu_company,
      'products': purchase,
      'payment_method': paymentmethod,
      'method_details': paymentmethodDetails
    }



    //console.log("BODY" + JSON.stringify(body))



    return this.http.post(varEnvironment.schema + API_URL + environment.ENDPOINT_BUY, body, this.requestOptions)
  }

  purchaseFreeCredits(purchase:Purchase):Observable<JSON>{

    let currentURL = window.location.href;
    let newURL = currentURL.replace('translate', 'payment-result')
    let redirect_url = newURL;
    let body = {
      'test': false,
      'redirect_url': redirect_url,
      'products': purchase,
      'order_name' :  '<!--ORDER_ID-->'
    }
    return this.http.post(varEnvironment.schema + API_URL + environment.ENDPOINT_BUY, body, this.requestOptions  )

  }

  buySubscription(name, email, paymentmethod, paymentmethodDetails:MethodDetails, vat_id, eu_company): Observable<JSON>{
    let test = environment.TEST_PURCHASE;
    let currentURL = window.location.href;
    let newURL = currentURL.replace('account', 'payment-result')
    //console.log("NEW" + JSON.stringify(newURL))
    let redirect_url = newURL
    let body = {
      'name': name,
      'email': email,
      'test': test,
      'redirect_url': redirect_url,
      'vat_id': vat_id,
      'eu_company': eu_company,
      'payment_method': paymentmethod,
      'method_details': paymentmethodDetails
    }

    return this.http.post(varEnvironment.schema + API_URL + environment.ENDPOINT_SUBSCRIBE, body, this.requestOptions)
  }






}
