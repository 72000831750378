import { Component, OnInit, OnChanges,SimpleChanges, Input } from '@angular/core';
import { DictionaryService } from '../../../services/dictionary.service';
import {MergerService} from '../../../services/merger.service';
import {FormControl} from '@angular/forms';
import { SearchLemmas } from '../../../interfaces/searchlemmas';
import {ConjugationService} from '../../../services/conjugation.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface ConceptLemma {
  dictionary: string;
  conceptid: number;
  focus: number;
  synonyms: string[];
}

export interface ConjugatedTranslations {
  synonyms: string[];
  translations: string[];
}

export interface Lemma {
  lemma: string;
}

export interface ConceptGroup {
  disabled?: boolean;
  conceptID: number
  lemmaname: string,
  lemmas: Lemma[];
}

@Component({
  selector: 'synonym-table',
  templateUrl: './synonym-table.component.html',
  styleUrls: ['./synonym-table.component.css']
})

export class SynonymTableComponent implements OnInit {
  @Input() sourceWord: string;
  @Input() sourceLanguage: string;
  @Input() targetLanguage: string;

  conceptGroup = [];
  concepts = [];
  lemmas:Lemma[] = [];
  //sourceForm: string;
  conceptControl = new FormControl();
  selectedWord = "";
  recognizedLemmas = [];
  conceptGroups: ConceptGroup[] = this.conceptGroup;
  inputWord:string = "";
  conjugatedTranslations:ConjugatedTranslations[] = [];
  searching: boolean = false;
  constructor(private conjugationService: ConjugationService, private dictionaryService:DictionaryService, private mergerService:MergerService, private snackBar:MatSnackBar) { }
  translationsEnabled:Array<string> = [];
  ngOnInit() {
    //console.log("ngmodel select" + this.selectedWord)
  }

  selectionChanged(position){
    if(position == -1){
      this.translationsEnabled = new Array<string>(this.conjugatedTranslations.length);
      this.translationsEnabled.fill("enabled");
    } else {
      this.selectedWord = position;
     // console.log("event value" + position);

      this.enableTranslation(position)
    }


  }

  enableTranslation(position){
    this.translationsEnabled = new Array<string>(this.conjugatedTranslations.length);
    this.translationsEnabled.fill("disabled");
    this.translationsEnabled[position] = "enabled";
  }


  ngOnChanges(changes: SimpleChanges){
   // console.log(changes.sourceWord )
    if(changes.sourceWord != undefined && changes.sourceWord.previousValue != this.sourceWord) {
      //console.log(changes.sourceWord.previousValue +"changed to + " + changes.sourceWord.currentValue);

      this.getSynonyms();

    }
  }

  getSynonyms(){
      this.searching = true;
      if(this.sourceWord == null || this.sourceWord.length == 0){
        return;
      }
      let stringword = this.sourceWord;
      let trimmedword = stringword.trim();
      this.inputWord = trimmedword;
      this.clear();


    return this.mergerService.recognizeForm(this.sourceLanguage, this.inputWord).subscribe(
      data => this.getRecognizeSuccess(data),
      error => this.getRecognizeError(error),
      () => {this.searching = false}

    )
  }

  getRecognizeSuccess(data){
   // console.log("GET Recognized success" + JSON.stringify(data))
    if(data.data.length == 0){
      this.snackBar.open('This lookup could not be found', 'OK', {duration:3000});

    }
    this.recognizedLemmas = data.data.lemmas;
    if(this.recognizedLemmas == undefined){
      this.searching = false;
      return
    }
    var searchLemmas = new SearchLemmas();
    for(let lemma of this.recognizedLemmas){
      searchLemmas.lemmas.push(lemma.data.lemma);
      searchLemmas.form_texts.push(this.inputWord)
    }
    return this.mergerService.getConceptIDsPost(this.sourceLanguage,searchLemmas).subscribe(
      data => this.getConceptIDsSuccess(data),
      error => this.getConceptIDsError(error)
    )
  }

  getRecognizeError(error){
    console.log(error)
  }

  getConceptIDsSuccess(data){
    this.conjugatedTranslations = [];
    this.conceptGroup = data.data.concepts;
    let conceptLemmas = [];
    for(let concept of this.conceptGroup){
      let conceptID = concept.data.id;
      let dictionary = concept.data.dictionary;
      let focus = concept.data.lemmas[0].data.focus;
      let synonyms: string[] = [];

      for(let i = 0; i < concept.data.lemmas.length; i++) {
        synonyms.push(concept.data.lemmas[i].data.lemma)
      }
      let conceptLemma: ConceptLemma = {dictionary: dictionary, conceptid:conceptID, focus: focus, synonyms: synonyms}
      conceptLemmas.push(conceptLemma);
      let conjugatedTranslations: ConjugatedTranslations = {synonyms: synonyms, translations: []}
      this.conjugatedTranslations.push(conjugatedTranslations);
    }
    this.translationsEnabled = new Array<string>(this.conjugatedTranslations.length);
    this.translationsEnabled.fill("enabled");
   // console.log("CONCEPT ID's" + JSON.stringify(conceptLemmas) )
    for(let cl of conceptLemmas){
     // console.log("CL" + JSON.stringify(cl))
      this.conjugationService.getTranslatedConjugations(cl.dictionary, this.sourceLanguage, this.targetLanguage, cl.conceptid, cl.focus, this.inputWord, false, false).subscribe(
        data => this.getTranslatedConjugationsSuccess(data, cl),
        error => this.getTranslatedConjugationsError(error)
      )
    }
  }

  getTranslatedConjugationsSuccess(data, cl){

    //console.log("CONJ TRA" + JSON.stringify(data.data.form) + "synonyms" + JSON.stringify(cl))
    //this.sourceForm = data.data.form

    data.data.translations.forEach(element => {
      for(let i = 0; i < this.conjugatedTranslations.length; i++) {

        //console.log("TRANSLATIONS 2 " + this.conjugatedTranslations[i].synonyms + " VS " + cl.synonyms)
        if(this.conjugatedTranslations[i].synonyms == cl.synonyms) {
          if(element.conjugations == undefined){
            this.conjugatedTranslations[i].translations.push(element.lemma)
          } else {
            element.conjugations.variants.forEach(variant => {
              variant.forms.forEach(element => {
                let alt = element.form[0];
                //element.form.forEach(alt => {
                  if(this.conjugatedTranslations[i].translations.indexOf(alt) == -1){
                    this.conjugatedTranslations[i].translations.push(alt)
                  }
                //});
              });
            });

          }

        }
      }


     // console.log("translatedConjugations" +  JSON.stringify(this.conjugatedTranslations))



    });


  }

  getTranslatedConjugationsError(error){
    console.log(error)
  }

  getConceptIDsError(error){
    console.log(error)

  }

  getSynonymsSuccess(data){
    this.conceptGroup = data.data.concepts;
    for(let concept in this.conceptGroup){
      this.concepts = this.conceptGroup[concept].data.lemmas;
     // console.log("CONCEPTS" + JSON.stringify(this.concepts))

      for(let lemma in this.concepts){
        this.lemmas = this.concepts[lemma].data.lemmas;
        //console.log("THE LEMMAS" + JSON.stringify(this.concepts[lemma].data.lemma) )
      }
    }

  }

  getSynonymsError(error){
    console.log(error)
  }

  clear(){
    this.conjugatedTranslations = [];
  }



}
