<h2 mat-dialog-title>{{ 'CONTEXTMENU.GRAMMAR' | translate }}</h2>
<mat-dialog-content class="mat-typography" *ngFor="let conjugation of conjugations">
  <h5 class="variant-name">{{ currentResourceMaps.resources.get(conjugation.variant_name) }}</h5>
  <div class="forms" *ngFor="let variantForm of conjugation.forms"  >
    <div class="alternatives" *ngFor="let alternative of variantForm.form; let i = index;">
        <p *ngIf="(i==0) && variantForm.form.length > 1" class="alternative_first">{{variantForm.context[i]}} {{alternative}}</p>
        <p *ngIf="(i != 0) && (i<variantForm.form.length-1)" class="alternative_middle">{{variantForm.context[i]}} {{alternative}}</p>
        <p *ngIf="i==variantForm.form.length-1" class="alternative_last">{{variantForm.context[i]}} {{alternative}}</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'CONTEXTMENU.CLOSE' | translate }}</button>
</mat-dialog-actions>


<!--<mz-modal [fixedFooter]="true">
  <mz-modal-header>
    <h4>{{ 'CONTEXTMENU.GRAMMAR' | translate }} </h4>
  </mz-modal-header>
  <mz-modal-content *ngFor="let conjugation of conjugations">
    
      <h5>{{ currentResourceMaps.resources.get(conjugation.variant_name) }}</h5>

      <div class="forms" *ngFor="let variantForm of conjugation.forms"  >
          <div class="alternatives" *ngFor="let alternative of variantForm.form; let i = index;">
              <p *ngIf="(i==0) && variantForm.form.length > 1" class="alternative_first">{{variantForm.context[i]}} {{alternative}}</p>
              <p *ngIf="(i != 0) && (i<variantForm.form.length-1)" class="alternative_middle">{{variantForm.context[i]}} {{alternative}}</p>
              <p *ngIf="i==variantForm.form.length-1" class="alternative_last">{{variantForm.context[i]}} {{alternative}}</p>
          </div>
       </div>
  </mz-modal-content>
  <mz-modal-footer>
    <a class="btn blue darken-1 white-text"  mz-modal-close >{{ 'CONTEXTMENU.CLOSE' | translate }} </a> 

  </mz-modal-footer>
</mz-modal>-->