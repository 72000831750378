<app-navbar (open)="navOpen($event)"></app-navbar>

<mat-drawer-container class="example-container">
    <mat-drawer #sidenav mode="side" opened fxHide.sm  >

      <left-navbar></left-navbar>
    </mat-drawer>

    <mat-drawer-content>
        <div class="translation-area">
            <div class="container-fluid">
                <div class="white-space"></div>

              <div class="row">

                    <div class="col-sm-12 dictionary-container">

                        <div class="card">
                            <div class="card-header-tab card-header">
                                <h5>{{ 'DICTIONARY.DICTIONARY' | translate }}</h5>
                            </div>
                            <div class="card-body">
                             <!-- <h5 class="card-title">{{'DICTIONARY.CARD-TITLE' | translate}}</h5> -->
                              <h6 class="card-subtitle mb-2 text-muted">{{'DICTIONARY.CARD-SUBTITLE' | translate}}</h6>
                              <div class="row no-gutters">
                              <div class="col-md-5">
                                <mat-form-field appearance="outline" [style.width.%]=100>
                                  <mat-label>From</mat-label>

                                  <mat-select [(ngModel)]="sourceLanguage" (ngModelChange)="sourceLanguageSelected(sourceLanguage)">
                                    <mat-option *ngFor="let language of languages" [value]="language.name">{{language.title}}</mat-option>
                                  </mat-select>
                                </mat-form-field>

                              </div>
                              <div class="col-custom-1">
                                <div class="input-group-prepend">
                                  <button mat-raised-button color="primary" class="switch-languages"  (click)="switchLanguages()" id="switchlang-button" type="button"><></button>
                                </div>
                              </div>
                              <div class="col-md-5">
                                <mat-form-field class="target-language" appearance="outline" [style.width.%]=100 >
                                  <mat-label>To</mat-label>

                                  <mat-select [(ngModel)]="targetLanguage" (ngModelChange)="targetLanguageSelected(targetLanguage)">
                                    <mat-option *ngFor="let language of languages" [value]="language.name">{{language.title}}</mat-option>
                                  </mat-select>
                                </mat-form-field>

                              </div>
                            </div>


                                <div class="row">
                                    <div class="col-12">

                                      <div class="search-field-box">
                                       <mat-form-field  class="search-field" [style.width.px]=500>
                                        <input (keyup)="triggerAutocomplete(surroundingsValue)" (keyup.enter)="searchTranslations()" type="text" matInput [matAutocomplete]="auto" class="search-input-field"  name="search" id="search" [(ngModel)]="surroundingsValue" label="search a word">
                                        <mat-autocomplete #auto="matAutocomplete">
                                          <mat-option *ngFor="let form of forms"(keypress)="selectItem(form)" (click)="selectItem(form)" [value]="form">
                                              {{form.data.form}}
                                          </mat-option>
                                        </mat-autocomplete>

                                       </mat-form-field>
                                     </div>
                                     <div class="translate-button-box">
                                      <div class="button-translate">
                                        <button (click)="searchTranslations()" id="search-button" class="translate-button" mat-raised-button color="primary">{{ 'DICTIONARY.TRANSLATE' | translate }}</button>
                                      <!--<a class="btn blue darken-1 white-text" (click)="searchTranslations()" id="search-button" >{{ 'DICTIONARY.TRANSLATE' | translate }} </a> -->
                                      </div>
                                     </div>
                                     <div class="row" *ngIf="notPresent">
                                      <p>{{ 'DICTIONARY.SEND_SUGGESTION' | translate }}   </p>
                                      <a class="btn blue darken-1 white-text"  (click)="sendToEuroglot()" id="search-button" >Send as a suggestion </a>

                                      </div>
                                    </div>



                                  </div>

                              </div>


                          <div class="row">
                              <mat-progress-bar *ngIf="searching" mode="query" value="40"></mat-progress-bar>
                          </div>

                          <!--<mat-tab-group formControlName="model" [(selectedIndex)]="model">
                            <form [formGroup]="radioGroupForm" ></form>
                            <mat-tab class="btn-primary" (selectedTabChange)="setShowPanel($event)" label="{{ 'DICTIONARY.RESULTS' | translate }}" *ngIf="showRadioGroupOverview">
                              <overview-panel [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage" [concepts]="concepts" [translations]="translations"  *ngIf="showPanel==1"></overview-panel>
                            </mat-tab>
                            <mat-tab class="btn-primary"  (selectedTabChange)="setShowPanel($event)" label=" {{ 'DICTIONARY.RECOGNIZED' | translate }}" *ngIf="showRadioGroupRecognized">
                              <recognized [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage" [concepts]="concepts" [translations]="translations" [sourceForm]="surroundingsValue" *ngIf="showPanel==2" ></recognized>
                            </mat-tab>
                            <mat-tab class="btn-primary"  (selectedTabChange)="setShowPanel($event)" label="{{ 'DICTIONARY.MORE_RESULTS' | translate }}" *ngIf="showRadioGroupExpression">
                              <expressions-panel [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage"  [expressions]="reference_concepts" [translations]="reference_translations" *ngIf="showPanel==3"></expressions-panel>
                            </mat-tab>
                            <mat-tab class="btn-primary" label="{{ 'DICTIONARY.PROVERBS' | translate }}" *ngIf="showRadioGroupProverb">
                              <proverbs-panel [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage"  [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==4"></proverbs-panel>
                            </mat-tab>
                            <mat-tab class="btn-primary" label="{{ 'DICTIONARY.CHEMISTRY' | translate }}" *ngIf="showRadioGroupChemical">
                              <chemical-panel [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage" [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==5" ></chemical-panel>
                            </mat-tab>
                            <mat-tab class="btn-primary" label="{{ 'DICTIONARY.MEDICAL' | translate }}" *ngIf="showRadioGroupMedical">
                              <medical-panel [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage" [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==6" ></medical-panel>
                            </mat-tab>
                            <mat-tab class="btn-primary" label="{{ 'DICTIONARY.TECHNICAL' | translate }}" *ngIf="showRadioGroupTechnical">
                              <technical-panel [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage" [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==7"></technical-panel>
                            </mat-tab>
                            <mat-tab class="btn-primary" label="{{ 'DICTIONARY.LEGAL' | translate }}" *ngIf="showRadioGroupLegal">
                              <legal-panel [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage" [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==8" ></legal-panel>
                            </mat-tab>
                            <mat-tab class="btn-primary" label="{{ 'DICTIONARY.FINANCIAL' | translate }}" *ngIf="showRadioGroupFinancial">
                              <financial-panel [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage" [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==9"></financial-panel>
                            </mat-tab>
                            <mat-tab class="btn-primary" label="{{ 'DICTIONARY.IT' | translate }}" *ngIf="showRadioGroupIt">
                              <it-panel [sourceLanguage]="currentSearchSourceLanguage" [targetLanguage]="currentSearchTargetLanguage" [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==10"></it-panel>
                            </mat-tab>
                          </mat-tab-group> -->

                          <grammar-panel *ngIf="showGrammarPanel"></grammar-panel>


                          <div class="dictionary-tabs">
                            <form [formGroup]="radioGroupForm">
                              <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                                <mat-label *ngIf="showRadioGroupOverview">
                                  <mat-button-toggle [value]="1" (click)="setShowPanel(1)">{{ 'DICTIONARY.RESULTS' | translate }}</mat-button-toggle>
                                </mat-label>
                                <mat-label *ngIf="showRadioGroupRecognized">
                                  <mat-button-toggle [value]="2" (click)="setShowPanel(2)">{{ 'DICTIONARY.RECOGNIZED' | translate }}</mat-button-toggle>
                                </mat-label>
                                <mat-label *ngIf="showRadioGroupExpression">
                                  <mat-button-toggle [value]="3" (click)="setShowPanel(3)">{{ 'DICTIONARY.MORE_RESULTS' | translate }}</mat-button-toggle>
                                </mat-label>
                                <mat-label *ngIf="showRadioGroupProverb">
                                  <mat-button-toggle [value]="4" (click)="setShowPanel(4)">{{ 'DICTIONARY.PROVERBS' | translate }}</mat-button-toggle>
                                </mat-label>
                                <mat-label *ngIf="showRadioGroupChemical">
                                  <mat-button-toggle [value]="5" (click)="setShowPanel(5)">{{ 'DICTIONARY.CHEMISTRY' | translate }}</mat-button-toggle>
                                </mat-label>
                                <mat-label *ngIf="showRadioGroupMedical">
                                  <mat-button-toggle [value]="6" (click)="setShowPanel(6)">{{ 'DICTIONARY.MEDICAL' | translate }}</mat-button-toggle>
                                </mat-label>
                                <mat-label *ngIf="showRadioGroupTechnical">
                                  <mat-button-toggle [value]="7" (click)="setShowPanel(7)">{{ 'DICTIONARY.TECHNICAL' | translate }}</mat-button-toggle>
                                </mat-label>
                                <mat-label *ngIf="showRadioGroupLegal">
                                  <mat-button-toggle [value]="8" (click)="setShowPanel(8)">{{ 'DICTIONARY.LEGAL' | translate }}</mat-button-toggle>
                                </mat-label>
                                <mat-label *ngIf="showRadioGroupFinancial">
                                  <mat-button-toggle [value]="9" (click)="setShowPanel(9)">{{ 'DICTIONARY.FINANCIAL' | translate }}</mat-button-toggle>
                                </mat-label>
                                <mat-label *ngIf="showRadioGroupIt">
                                  <mat-button-toggle [value]="10" (click)="setShowPanel(10)">{{ 'DICTIONARY.IT' | translate }}</mat-button-toggle>
                                </mat-label>
                              </mat-button-toggle-group>

                            </form>


                             <!--- <form [formGroup]="radioGroupForm" >
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="panel-radio" formControlName="model">
                                <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupOverview">
                                 <mat-radio-button class="example-radio-button" [value]="1" (click)="setShowPanel(1)" ><p class="tab-text"> {{ 'DICTIONARY.RESULTS' | translate }}</p></mat-radio-button>
                                </mat-label>
                                <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupRecognized">
                                  <mat-radio-button class="example-radio-button" [value]="2" (click)="setShowPanel(2)"><p class="tab-text" > {{ 'DICTIONARY.RECOGNIZED' | translate }}</p></mat-radio-button>
                                 </mat-label>
                                 <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupExpression">
                                  <mat-radio-button class="example-radio-button" [value]="3" (click)="setShowPanel(3)"> <p class="tab-text" >{{ 'DICTIONARY.MORE_RESULTS' | translate }}</p></mat-radio-button>
                                 </mat-label>
                                 <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupProverb">
                                  <mat-radio-button class="example-radio-button" [value]="4" (click)="setShowPanel(4)"><p class="tab-text">{{ 'DICTIONARY.PROVERBS' | translate }}</p></mat-radio-button>
                                 </mat-label>
                                 <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupChemical">
                                  <mat-radio-button class="example-radio-button" [value]="5" (click)="setShowPanel(5)"> <p class="tab-text">{{ 'DICTIONARY.CHEMISTRY' | translate }}</p></mat-radio-button>
                                 </mat-label>
                                 <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupMedical">
                                  <mat-radio-button class="example-radio-button" [value]="6" (click)="setShowPanel(6)"><p class="tab-text" >{{ 'DICTIONARY.MEDICAL' | translate }}</p></mat-radio-button>
                                 </mat-label>
                                 <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupTechnical">
                                  <mat-radio-button class="example-radio-button" [value]="7" (click)="setShowPanel(7)"><p class="tab-text" >{{ 'DICTIONARY.TECHNICAL' | translate }}</p></mat-radio-button>
                                 </mat-label>
                                 <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupLegal">
                                  <mat-radio-button class="example-radio-button" [value]="8" (click)="setShowPanel(8)"><p class="tab-text" >{{ 'DICTIONARY.LEGAL' | translate }}</p></mat-radio-button>
                                 </mat-label>
                                 <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupFinancial">
                                  <mat-radio-button class="example-radio-button" [value]="9" (click)="setShowPanel(9)"><p class="tab-text" >{{ 'DICTIONARY.FINANCIAL' | translate }}</p></mat-radio-button>
                                 </mat-label>
                                 <mat-label ngbButtonLabel class="btn-primary" *ngIf="showRadioGroupIt">
                                  <mat-radio-button class="example-radio-button" [value]="10" (click)="setShowPanel(10)"><p class="tab-text" > {{ 'DICTIONARY.IT' | translate }}</p></mat-radio-button>
                                 </mat-label>
                                </mat-radio-group>

                                </form> -->

                             </div>
                             <div class="no_dictionary_results" *ngIf="!haveDictionaryResults">
                              <h5>{{'DICTIONARY.NO_RESULTS' | translate}}</h5>
                             </div>
                             <mat-progress-bar  mode="indeterminate" *ngIf="searching"></mat-progress-bar>

                                   <overview-panel [concepts]="concepts" [translations]="translations"  *ngIf="showPanel==1"></overview-panel>

                                    <expressions-panel [expressions]="reference_concepts" [translations]="reference_translations" *ngIf="showPanel==3"></expressions-panel>

                                    <proverbs-panel [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==4"></proverbs-panel>

                                    <chemical-panel [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==5" ></chemical-panel>

                                    <medical-panel [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==6" ></medical-panel>

                                    <technical-panel [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==7"></technical-panel>

                                    <legal-panel [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==8" ></legal-panel>

                                    <financial-panel [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==9"></financial-panel>

                                    <it-panel [concepts]="concepts" [referenceConcepts]="reference_concepts" [translations]="translations" [referenceTranslations]="reference_translations" *ngIf="showPanel==10"></it-panel>

                                    <recognized [concepts]="concepts" [translations]="translations" [sourceForm]="surroundingsValue" *ngIf="showPanel==2" ></recognized>

                                    <grammar-panel *ngIf="showGrammarPanel"></grammar-panel>

                        </div>
                      </div>



              </div>



            </div>


          </div>

    </mat-drawer-content>
</mat-drawer-container>
