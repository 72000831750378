import { Component, OnInit, Input } from '@angular/core';
import { Languages } from '../../../interfaces/languages';

export interface myDictionary {
  concept: string;
  synonyms: string[];
}

@Component({
  selector: 'add-terminology',
  templateUrl: './add-terminology.component.html',
  styleUrls: ['./add-terminology.component.css']
})
export class AddTerminologyComponent implements OnInit {
  @Input() items:any[];
  panelOpenState:boolean = false;
  sourceLanguage: string;
  targetLanguage: string;
  languages = Languages;
  //items:Array<myDictionary> = [{concept:"test" , synonyms:["examen","proef"]}];


  constructor() {
    //console.log("items" + JSON.stringify(this.items))
  }

  ngOnInit() {

  }



}
