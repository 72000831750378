import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';

export const EditorFlagsPluginKey = 'flags';

export interface EditorFlags {
  readonly? : boolean;
  source? : boolean;
}

export const defaultFlags : EditorFlags = {
  readonly : false,
  source : true,
}

export function flags(startFlags? : EditorFlags) : Plugin<any, any> {
  if(startFlags == undefined)
    startFlags = defaultFlags;
  return new Plugin({
    key: new PluginKey(EditorFlagsPluginKey),
    state: {
      init() {
        return startFlags;
      },
      apply(tr : Transaction, prev : EditorFlags) {
        if(tr.getMeta(EditorFlagsPluginKey)) {
          let newFlags = tr.getMeta(EditorFlagsPluginKey);
          if(newFlags) {
            return newFlags;
          }
        }
        return prev;
      },
    },
    props: {
      editable(state : EditorState) {
        return !readOnlyEditor(state);
      }
    },
  });
}



export function sourceEditor(state : EditorState) : boolean {
  //Trouble setting attributes on primary node.... seems not to update properly...
  //return state.doc.attrs.source;

  for(const plugin of state.plugins) {
    if(plugin.key.startsWith(EditorFlagsPluginKey)) {
      let pstate = plugin.getState(state)
      if(pstate)
        return pstate.source;
    }
  }

  return defaultFlags.source;
}

export function readOnlyEditor(state : EditorState) : boolean {
  //Trouble setting attributes on primary node.... seems not to update properly...
  //return state.doc.attrs.disabled;

  for(const plugin of state.plugins) {
    if(plugin.key.startsWith(EditorFlagsPluginKey)) {
      let pstate = plugin.getState(state)
      if(pstate)
        return pstate.readonly;
    }
  }
  return defaultFlags.readonly
}
