import { Injectable, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment, varEnvironment } from '../../environments/environment';
import { HttpService } from '../services/http.service';

const API_URL = '://' + environment.API_IP + ":" + environment.PORT_MLPROXY + environment.ENDPOINT_MLPROXY + environment.ENDPOINT_DEEPLAPI + environment.ENDPOINT_V2 + environment.ENDPOINT_TRANSLATE;
const API_URL_EUROGLOT = '://' + environment.API_IP + ":" + environment.PORT_MLPROXY + environment.ENDPOINT_MLPROXY + environment.ENDPOINT_EUROGLOT + environment.ENDPOINT_LM;

@Injectable()
export class DeeplService {

  //private headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded',});

  requestOptions: {} = {withAuth: true, withContentType: 'application/x-www-form-urlencoded', withCharset: 'utf-8' };

  sourceLanguage: string;
  targetLanguage: string;
  text: string;
  //ENDPOINT = 'https://www.deepl.com/jsonrpc';


  constructor(private http: HttpService) { }


  setSourceLang(sourcelang: string): string{
    this.sourceLanguage = sourcelang;
    return this.sourceLanguage

  }

  setTargetLang(targetlang: string): string{
    this.targetLanguage = targetlang;
    return this.targetLanguage

  }

  translateTextAlignment(textToTranslate: string){
    let langFrom = this.sourceLanguage;
    let langTo = this.targetLanguage;

    return this.http.get(varEnvironment.schema + API_URL_EUROGLOT + langFrom + "/" + langTo + "/" + encodeURIComponent(textToTranslate), this.requestOptions);

  }

  translateText(textToTranslate:string){
   // console.log("TEXT TO TRANSLATE FROM SERVICE" + textToTranslate)

    let langFrom = this.sourceLanguage;
    let langTo = this.targetLanguage;

   // console.log("LANGFROM" + langFrom + "LANGTO" + langTo)

    //let body=`target_lang=${langTo}&text=${textToTranslate}`;

    let body = new HttpParams();

    body = body.append('target_lang', langTo);
    body = body.append('text', encodeURIComponent(textToTranslate));

    //console.log("PARAMS" + body)


    /*let body  = {
      "target_lang": langTo,
      "text": textToTranslate
    }*/


    return this.http.post(varEnvironment.schema + API_URL, body, this.requestOptions)

  }

  static deeplTextFilter(stringDoc : string) : string {
    let htmlregex = /<[^>]*>/g ;
    let lbrregex = /(\r\n|\n|\r)/gm;
    let textWithoutHtml = stringDoc.replace(htmlregex, "");
    return textWithoutHtml.replace(lbrregex, "");
  }

}
