import { EventEmitter } from '@angular/core';
import { EditorView, EditorState, Plugin, PluginKey, Transaction, Decoration } from 'prosemirror-state';
import { DOMSerializer, Mark } from "prosemirror-model";
//import { NodeView } from 'prosemirror-view';
import { ColPosition, instanceOfColPosition } from './root';
import { default as schema } from "../schema";

declare interface NodeView {
  dom,
  contentDOM,
};


export const EventsPluginKey = 'events';

export interface ColMouseData extends ColPosition {
  mouseEvent: MouseEvent,
  synonymWords: string | false,
};

export function instanceOfColMouseData(object: any): object is ColMouseData {
    return 'mouseEvent' in object && instanceOfColPosition(object);
}


export interface SynonymClickedData extends ColPosition {
  alt : string,
};

export function instanceOfSynonymClickedData(object: any): object is SynonymClickedData {
    return 'alt' in object && instanceOfColPosition(object);
}


export interface EditorEvents {
  synonymClicked : EventEmitter<SynonymClickedData>;
  colMouse : EventEmitter<ColMouseData>;
}

export function events(startEvents? : EditorEvents) : Plugin<any, any> {
  return new Plugin({
    key: new PluginKey(EventsPluginKey),
    state: {
      init() {
        return startEvents;
      },
      apply(tr : Transaction, prev : EditorEvents) {
        if(tr.getMeta(EventsPluginKey)) {
          let newEvents = tr.getMeta(EventsPluginKey);
          if(newEvents) {
            return newEvents;
          }
        }
        return prev;
      },
    },
    props: {
      nodeViews: {
        col(mark: Mark, view: EditorView, getPos: any, decorations: [Decoration]) : NodeView {
          //console.dir(mark)

          let domSerializer = DOMSerializer.fromSchema(schema);
          let nv : NodeView = domSerializer.serializeMark(mark, true, {});

          makeColDomEvents(view.state, mark, nv.contentDOM);

          return nv;
        }
      },
    },
  });
}

export function synonymClickedEventEmitter(state : EditorState) : EventEmitter<SynonymClickedData> {
  for(const plugin of state.plugins) {
    if(plugin.key.startsWith('events')) {
      let pstate = plugin.getState(state)
      if(pstate)
        return pstate.synonymClicked;
    }
  }

  return null;
}

export function colMouseEventEmitter(state : EditorState) : EventEmitter<ColMouseData> {
  for(const plugin of state.plugins) {
    if(plugin.key.startsWith('events')) {
      let pstate = plugin.getState(state)
      if(pstate)
        return pstate.colMouse;
    }
  }

  return null;
}


function makeColDomEvents(state : EditorState, mark: Mark, dom : HTMLElement) {
  let evh = colMouseEventEmitter(state);
  if((mark.attrs.col == -1) || (evh == null)) {
      return dom;
  }

  dom.addEventListener('mouseenter', (e: MouseEvent) => {
    //e.preventDefault();
    //console.log("ENTER " + mark.attrs.col)
    let colMouseData : ColMouseData = {
      sentence: mark.attrs.sentence,
      section: mark.attrs.section,
      col: mark.attrs.col,
      mouseEvent: e,
      synonymWords: mark.attrs.alt_translations,
    }

    evh.next(colMouseData);
  });

  dom.addEventListener('mouseleave', (e: MouseEvent) => {
    //e.preventDefault();
    //console.log("LEAVE " + mark.attrs.col)
    let colMouseData : ColMouseData = {
      sentence: mark.attrs.sentence,
      section: mark.attrs.section,
      col: mark.attrs.col,
      mouseEvent: e,
      synonymWords: mark.attrs.alt_translations,
    }

    evh.next(colMouseData);
  });

  dom.addEventListener('click', (e: MouseEvent) => {
    //e.preventDefault();
    //console.log("CLICK " + mark.attrs.col)
    let colMouseData : ColMouseData = {
      sentence: mark.attrs.sentence,
      section: mark.attrs.section,
      col: mark.attrs.col,
      mouseEvent: e,
      synonymWords: mark.attrs.alt_translations,
    }

    evh.next(colMouseData);
  });

  return dom;
}
