import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Observable } from 'rxjs';

//const API_URL = "http://api.euroglot.nl:2341/tmproxy/gapi/tm?";
const API_URL = "http://api.euroglot.nl:2341/tmproxy/euroglot/tm";



@Injectable({
  providedIn: 'root'
})
export class TranslationMemoryService {
  requestOptions: {} = {withAuth: true};


  constructor(private http:HttpService ) { }

  makeTranslation(sourcelang: string, targetlang: string, searchString ): Observable<JSON>{
    //console.log("SL" + sourcelang, "TL" + targetlang, "search" + searchString)
        
    return this.http.get( API_URL + "/" + sourcelang + "/" + targetlang + "/" + searchString, this.requestOptions);
  }
}
