<h2 mat-dialog-title>Instructional video Euroglot Dictionary</h2>
<mat-dialog-content class="mat-typography">
  <div id="video-content" class="text-center">
    <video width="600" controls>
      <source src="assets/video/video_v3.mp4" type="video/mp4">
      <source src="assets/video/video_v3.ogg" type="video/ogg">
      Your browser does not support HTML5 video.
   </video> 
  </div>
  <div id="description" class="text-center">
    <p>Learn how to use the dictionary</p>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'CONTEXTMENU.CLOSE' | translate }}</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial *ngIf="!fromHelpPanel" (click)="disableInstructionalVideos()">Disable instructional videos</button>
</mat-dialog-actions>

<!--<mz-modal>
  
  <mz-modal-header>
      <div class="action-buttons">
            <a class="btn blue darken-1 white-text"  mz-modal-close >{{ 'CONTEXTMENU.CLOSE' | translate }} </a> 
       
            <a *ngIf="!fromHelpPanel" class="btn blue darken-1 white-text"  (click)="disableInstructionalVideos()" mz-modal-close>Disable instructional videos</a> 


  
      </div>
    <div id="title" class="text-center">
      <h5>Instructional video Euroglot Dictionary</h5>
    </div>

  </mz-modal-header>
  <mz-modal-content>
      <div id="video-content" class="text-center">
        <video width="600" controls>
          <source src="assets/video/video_v3.mp4" type="video/mp4">
          <source src="assets/video/video_v3.ogg" type="video/ogg">
          Your browser does not support HTML5 video.
       </video> 
      </div>
      <div id="description" class="text-center">
          <p>Learn how to use the dictionary</p>

      </div>
     
  </mz-modal-content>
  <mz-modal-footer>

  </mz-modal-footer>
 
</mz-modal> -->

