import { Component, ChangeDetectorRef, Input, QueryList, ViewChild, ViewChildren, Injector } from '@angular/core';
import { BasePanel } from '../base-panel';
import { ConjugatedTranslationComponent } from '../conjugated-translation/conjugated-translation.component';
import { ThesaurusComponent } from '../thesaurus/thesaurus.component';
import { MenuContextComponent } from '../../menu-context/menu-context.component';
import {MatExpansionPanel} from '@angular/material/expansion';


const dictionary: string = "maindb";

@Component({
  selector: 'recognized',
  templateUrl: './recognized.component.html',
  styleUrls: ['./recognized.component.css']
})
export class RecognizedComponent extends BasePanel {

  @ViewChild(MenuContextComponent) contextMenu: MenuContextComponent;
  @ViewChildren(ThesaurusComponent) thesaurusChildren: QueryList<ThesaurusComponent>;
  @ViewChildren(ConjugatedTranslationComponent) conjugatedTranslationChildren: QueryList<ConjugatedTranslationComponent>;
  @ViewChildren('collapsibleProgrammatically') collapsibleChildren: QueryList<MatExpansionPanel>;

  @Input() concepts: any[];
  @Input() translations: any[];
  @Input() sourceForm: string;

  evokeShowGrammar;
  evokeUpdateGrammar;

  constructor(cd:ChangeDetectorRef, injector: Injector) {
    //console.log("Initializing recognized panel")
    super(cd, injector, dictionary);
  }

  ngOnInit() {
    super.ngOnInit()
    //this.getWordTypeResources(this.concepts);
  }

  collapsibleOpened(conceptid) {
      //console.log("recognized callback called for " + conceptid);
     // console.dir(recognizedPanelComponent.contextMenu)
     // console.dir(recognizedPanelComponent.thesaurusChildren)
     // console.dir(recognizedPanelComponent.conjugatedTranslationChildren)
     super.collapsibleOpened(conceptid)
     this.conjugatedTranslationChildren.forEach(function(element){
       if(element.conceptid == conceptid){
           element.loadConjugatedTranslations();
           return;
       }
     })
  }
}
