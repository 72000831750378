import { Injectable, EventEmitter } from '@angular/core';
import { environment, varEnvironment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { Observable } from 'rxjs';
import {Merger2Service, DictionaryInfosEvent} from './merger2.service';

import 'rxjs/add/operator/map';
import 'rxjs/operator/delay';
import 'rxjs/operator/mergeMap';
import 'rxjs/operator/switchMap';

 
 export class ExporterConnectedEvent {
   data : string;
   constructor(data : string) {
     this.data = data
   }
 }

@Injectable({
  providedIn: 'root'
})

export class ExportServiceService {
  requestOptions: {} = {withAuth: true};
  private dictionaryInfosEventSubscription;
  connectedEmitter : EventEmitter<ExporterConnectedEvent> = new EventEmitter();

  dictName: string;
  apiURL: string;

  constructor(private merger2Service: Merger2Service, private http:HttpService,) {
    this.dictionaryInfosEventSubscription = merger2Service.dictionaryInfosEmitter.subscribe({
			next: (event: DictionaryInfosEvent) => {
					this.handleDictionaryInfosEvent(event);
			}
    })
   }

  connect(dictName: string){
    this.dictName = dictName;
    let port = this.merger2Service.getExportPort(this.dictName);
    if(port!=0){
      this.setApiURL(port);
    }
  }

  handleDictionaryInfosEvent(event){
    let port = this.merger2Service.getExportPort(this.dictName);
    if(port!=0){
      this.setApiURL(port);
    }
  }

  setApiURL(port: number) {
    this.apiURL =  varEnvironment.schema + '://' + environment.API_IP + ":"+ port + environment.ENDPOINT_EXPORT + "/" + this.dictName
    //console.log("URL" + this.apiURL)
    var myEvent = new ExporterConnectedEvent(this.apiURL);
    this.connectedEmitter.emit(myEvent)
  }


  getDictionaryInfo(): Observable<JSON>{
    if(this.apiURL == undefined){
      return null;
    }
    let URL = this.apiURL + environment.ENDPOINT_DICTIONARY_INFO;
    return this.http.get(URL, this.requestOptions )
  }

  getConcepts(first: number, last: number): Observable<JSON>{
    if(this.apiURL == undefined){
      return null;
    }
    let URL = this.apiURL + environment.ENDPOINT_CONCEPT_LIST + "/" + first + "/" + last +  "/?" + environment.EXPORT_ARGS;
    return this.http.get(URL, this.requestOptions )
  }

 
  


}
