<div class="container">
  <div class="row" *ngFor="let translation of translations">
    <div class="col">
        <div class="variant-name">
          {{ currentResourceMaps.resources.get(translation.source_variant_name)}}
          
        </div>
        <div class="conjugated-forms">
            {{translation.source_context}} {{form}} 
        </div>
    </div>
    <div class="col">
     <div *ngIf="translation.conjugations != undefined">
      <div class="conjugated-forms-translations" *ngFor="let variant of translation.conjugations.variants ">
        <div class="variant-name">
            {{currentResourceMaps.resources.get(variant.variant_name)}}
         </div>
         <div class="forms" *ngFor="let variantForm of variant.forms"  >
            <div class="alternatives" *ngFor="let alternative of variantForm.form; let i = index;">
                <p *ngIf="(i==0) && variantForm.form.length > 1" class="alternative_first">{{variantForm.context[i]}} {{alternative}}</p>
                <p *ngIf="(i != 0) && (i<variantForm.form.length-1)" class="alternative_middle">{{variantForm.context[i]}} {{alternative}}</p>
                <p *ngIf="i==variantForm.form.length-1" class="alternative_last">{{variantForm.context[i]}} {{alternative}}</p>
            </div>
          </div>
      </div> 
     </div>
    </div>
  </div>

</div>



