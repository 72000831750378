import { Injectable, EventEmitter  } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import {tokenNotExpired} from 'angular2-jwt';
import {HttpService, TokenNeedsRefreshingEvent} from '../services/http.service';
import {Observable} from 'rxjs';
import { environment, varEnvironment } from '../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';


const API_URL = '://' + environment.API_IP + ":" + environment.PORT_AUTH + environment.ENDPOINT_AUTH
//const LOGIN_URL = API_URL + '/testauth/';
const EGO_LOGIN_URL = API_URL + environment.ENDPOINT_EGO_LOGIN;
const LOGIN_LOGIN_URL = API_URL + environment.ENDPOINT_LOGIN_LOGIN;
const GLOGIN_URL = API_URL + environment.ENDPOINT_GAUTH_LOGIN;
const REGISTER_URL = API_URL + environment.ENDPOINT_REGISTER_GAUTH;
const REGISTER_URL_UP = API_URL + environment.ENDPOINT_REGISTER;

export interface IUser {
	username: string,
	password: string,
	resetHash: string
}

export interface IRegisterUser {
	username: string,
	password: string,
	registrationHash: string
}

export class LoggedInEvent {
	loggedIn : boolean
	data : any
	user = {} as IUser;

	constructor(data : any, loggedIn : boolean) {
		this.data = data
		this.loggedIn = loggedIn
	}
}

@Injectable()
export class AuthServiceEuroglot {
	authToken : string;
	refreshToken : string;
	user : IUser;
	busyRefreshingToken : boolean;

	loggedInEmitter : EventEmitter<LoggedInEvent> = new EventEmitter();
	refreshedEmitter : EventEmitter<LoggedInEvent> = new EventEmitter();
	private tokenNeedsRefreshingSubscription;

	constructor(private http:HttpService, private httpClient:HttpClient, private snackbar:MatSnackBar) {
		this.tokenNeedsRefreshingSubscription = http.tokenNeedsRefreshingEmitter.subscribe({
			next: (event: TokenNeedsRefreshingEvent) => {
				//console.log("Received request for token refresh")
				this.handleTokenNeedsRefreshingEvent(event)
			}
		})
	}


	//Token handling section
	public refreshTheToken() {
		if (this.busyRefreshingToken) {
			return
		}

		this.busyRefreshingToken = true

		if (typeof this.authToken === 'undefined' || this.authToken === null || this.authToken == 'undefined') {
			//console.log('TOKEN loading for refresh.')
			this.loadToken()
			this.loadRefreshToken()
		}
		if(typeof this.authToken === 'undefined' || this.authToken === null || this.authToken == 'undefined'){
			this.busyRefreshingToken = false;
			return
		}

		let body = {
			"token" : this.authToken,
			"refresh_token" : this.refreshToken
		}
		//console.log('Request refresh with PATCH data: ' + JSON.stringify(body))
		this.httpClient.patch(varEnvironment.schema + API_URL+'/refresh_token/', body).subscribe(
			data => this.handleRefreshSuccess(data),
			error => this.handleRefreshError(error),
			() => {
				this.busyRefreshingToken = false
			},
		);
	}

	private refreshTheTokenPromised() : Promise<void> {

		return new Promise<void>((resolve, reject) => {
			if(tokenNotExpired() == true) {
				resolve()
				return
			}

			var refreshedSubscription = this.refreshedEmitter.subscribe({
				next: (event: LoggedInEvent) => {
					resolve()
				}
			})
			this.refreshTheToken()
		})
	}

	private handleTokenNeedsRefreshingEvent(event : TokenNeedsRefreshingEvent) {
		this.refreshTheTokenPromised().then(() => {
			event.new_token = this.authToken
			//console.log('Call resolve of promise')
			event.resolve(event.new_token)
		})
	}

	private handleRefreshSuccess(data) {
		//console.log("Refreshed data: " + JSON.stringify(data));

		var refreshed : boolean = false

		if ((data['data']) && (data.data.type == 'error')) {
			//console.log(data.data.message);
		} else {
			this.authToken = data.token
			localStorage.setItem('id_token', this.authToken);
			refreshed = true
		}

		var myEvent = new LoggedInEvent(data, refreshed)
		this.refreshedEmitter.emit(myEvent);

	}

	private handleRefreshError(error) {
		console.log(error);

		var myEvent = new LoggedInEvent(error, false)
		this.refreshedEmitter.emit(myEvent);
	}

	public logout() : Promise<any> {
		//console.log("TOKEN ON LOGOUT" + this.authToken)
		if (typeof this.authToken === 'undefined' || this.authToken === null || this.authToken == 'undefined') {
			//console.log('TOKEN loading for logout.')
			this.loadToken()
			this.loadRefreshToken()

		}
		if(typeof this.authToken === 'undefined' || this.authToken === null || this.authToken == 'undefined'){
			return
		}

		let body = {
			"token": this.authToken,
			"refresh_token": this.refreshToken
		}
		localStorage.clear();

		return new Promise<any>((resolve, reject) => {
			this.http.post<JSON>(varEnvironment.schema + API_URL+'/logout/', body).subscribe(
				data => {
					if(data.success) {
						//alert("You have been logged out");
						resolve()
					} else if((data.data.type == "error") && (data.data.message == "Token logout error: sql: no rows in result set")) {
						resolve()
					} else {
						reject("Failed to logout: " + JSON.stringify(data))
					}
				},
				error => {
					console.log(error);
					reject(error)
				},
				() => null
			);
		});
	}

	private loadToken(){
		this.authToken = localStorage.getItem('id_token');
		//console.log("TOKEN" + this.authToken + "refreshTOken" + this.refreshToken)
	}

	private loadRefreshToken(){
		this.refreshToken = localStorage.getItem('refresh_token');
	}

	private storeTokenData(token : string, refresh_token : string, user : IUser){
		localStorage.setItem('id_token', token);
		localStorage.setItem('refresh_token', refresh_token);
		localStorage.setItem('user', JSON.stringify(user));

		this.authToken = token;
		this.refreshToken = refresh_token;
		this.user = user;

		//console.log("TOKEN:" + this.authToken);
		//console.log("REFRESH_TOKEN" + this.refreshToken);
	}

	public checkLogin() : boolean {
		if (typeof this.authToken === 'undefined' || this.authToken === null || this.authToken == 'undefined') {
			//console.log('TOKEN loading.')
			this.loadToken()
			this.loadRefreshToken()
		}

		if (typeof this.authToken === 'undefined' || this.authToken === null || this.authToken == 'undefined') {
			//console.log('TOKEN doesnt exist.')
			return false
		}

		var notExpired = tokenNotExpired()

		if (notExpired) {
			//console.log('TOKEN not expired. So we are already logged in...')
			return true
		} else {
			//console.log('TOKEN is expired')
		}

		//console.log('TOKEN expired. Refresh the token!')
		this.refreshTheToken()

		return false
	}


	//Google authentication section
	public loginWithGoogle(gjwt){
		this.http.post<JSON>(varEnvironment.schema + GLOGIN_URL, gjwt).subscribe(
			data => this.handleLogInWithGoogleSuccess(data),
			error => this.handleLogInWithGoogleError(error),
			() => null
		)

	}

	private handleLogInWithGoogleSuccess(data){
		var loggedIn : boolean = false;
		if(data.token) {
			this.storeTokenData(data.token, data.refresh_token, data.user);
			loggedIn = true;
		}
		var myEvent = new LoggedInEvent(data, loggedIn)
		this.loggedInEmitter.emit(myEvent);
	}

	private handleLogInWithGoogleError(error){
		console.log(error)
	}


	//Google registration section
	public registerWithGoogle(gjwt){
	 return this.http.post<JSON>(varEnvironment.schema + REGISTER_URL, gjwt);
	}


	//EgO & Login authentication section
	public authenticateUser(user : IUser) {
		this.user = user;
		this.authenticateEgOUser();
	}

	private authenticateEgOUser() {
		//working authentication to Euroglot Online API
		const formData = new FormData();

		formData.append('username', this.user.username.trim());
		formData.append('password', this.user.password.trim());
		if(this.user.resetHash != "") {
			formData.append('resetHash', this.user.resetHash.trim());
		}

		this.http.post<JSON>(varEnvironment.schema + EGO_LOGIN_URL, formData).subscribe(
			data => this.handleEgOAuthSuccess(data),
			error => this.handleAuthError(error),
			() => null
		)
	}

	private handleEgOAuthSuccess(data : any) {
		//console.log("DATA LOGIN" + JSON.stringify(data));

		if(data.token) {
			this.storeTokenData(data.token, data.refresh_token, data.user);

			var myEvent = new LoggedInEvent(data, true)
			this.loggedInEmitter.emit(myEvent);
		}

		if(data.data && data.data.type == "error") {
			if(data.data.message == "Authentication error: sql: no rows in result set"){
				//console.log(this.user);
				this.authenticateLoginUser();
			} else {
				this.handleAuthError2(data);
			}
		}
	}

	private handleAuthError(error : HttpErrorResponse) {
		console.log(error);

		this.snackbar.open(error.error.message, 'OK', {duration:3000});

		var myEvent = new LoggedInEvent(error, false)
		this.loggedInEmitter.emit(myEvent);
	}

	private handleAuthError2(data : any) {
		if(data.data && data.data.type == "error") {
			this.snackbar.open(data.data.message, 'OK', {duration:3000});
		}

		var myEvent = new LoggedInEvent(data, false)
		this.loggedInEmitter.emit(myEvent);
	}

	private authenticateLoginUser(){
		//console.log(JSON.stringify("USER" + JSON.stringify(this.user)))
		const formData = new FormData();

		formData.append('username', this.user.username.trim());
		formData.append('password', this.user.password.trim());
		if(this.user.resetHash != "") {
			formData.append('resetHash', this.user.resetHash.trim());
		}

		this.http.post<JSON>(varEnvironment.schema + LOGIN_LOGIN_URL, formData).subscribe(
			data => this.handleLoginAuthSuccess(data),
			error => this.handleAuthError(error),
			() => null
		)
	}

	private handleLoginAuthSuccess(data : any){
		//console.log("DATA handleloginauthsuccess" + JSON.stringify(data))

		if(data.token) {
			this.storeTokenData(data.token, data.refresh_token, data.user);

			var myEvent = new LoggedInEvent(data, true)
			this.loggedInEmitter.emit(myEvent);
		}

		if(data.data && data.data.type == "error") {
  		this.handleAuthError2(data);
		}
	}


	//Login registration section
	public registerUserMail(newUser) : Observable<JSON> {
		 const formData = new FormData();
		 formData.append('username', newUser.username.trim());
		 formData.append('email', newUser.email.trim());
		 formData.append('maillink', varEnvironment.schema + environment.REGISTER_MAILLINK)
		 return this.http.post<JSON>(varEnvironment.schema + REGISTER_URL_UP, formData)
	}

	public registerUser(newUser : IRegisterUser) {
		this.user = {username: newUser.username, password: newUser.password, resetHash: ""};

		//console.log("registrationhash" + JSON.stringify(user.hash))
		const formData = new FormData();

		formData.append('username', newUser.username.trim());
		formData.append('password', newUser.password.trim());
		formData.append('registrationHash', newUser.registrationHash);

		this.http.post<JSON>(varEnvironment.schema + LOGIN_LOGIN_URL, formData).subscribe(
				data => this.handleRegisterUserSuccess(data),
				error => this.handleAuthError(error),
				() => null
		)
	}

	private handleRegisterUserSuccess(data : any) {
		//console.log("DATA REGISTRATION" + JSON.stringify(data));

		if(data.token) {
			this.storeTokenData(data.token, data.refresh_token, data.user);

			var myEvent = new LoggedInEvent(data, true)
			this.loggedInEmitter.emit(myEvent);
		}

		if(data.data && data.data.type == "error") {
			this.handleAuthError2(data);
		}
	}

	//EgO & Login forgot password section
	public forgotPasswordEgOAuthMail(username: string, email: string) : Observable<JSON> {
		const formData = new FormData();

		formData.append('username', username.trim());
		formData.append('email', email.trim());
		formData.append('maillink', varEnvironment.schema + environment.RESET_PASSWORD_MAILLINK);

		return this.http.post<JSON>(varEnvironment.schema+ API_URL+ environment.RESET_PASSWORD_EGO , formData);
	}

	public forgetPasswordLoginAuthMail(username: string, email: string): Observable<JSON> {
		const formData = new FormData();

		formData.append('username', username.trim());
		formData.append('email', email.trim());
		formData.append('maillink',  varEnvironment.schema + environment.RESET_PASSWORD_MAILLINK);

		return this.http.post<JSON>(varEnvironment.schema + API_URL + environment.RESET_PASSWORD, formData);
	}

	public resetPassword(resetUser : IUser) {
		this.user = resetUser
		this.authenticateEgOUser();
	}

















	updateProfile(user){
		//console.log('TODO');
		return null
		/*let headers = new Headers();

		let id = user._id

		console.log("ID" + id)
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', this.authToken);

		return this.http.put('http://localhost:3000/users/profile/'+ id, user, {headers: headers});*/

	}


	/*getPicture(){
		let headers = new Headers();

		headers.append('Content-Type', 'application/json');
  		headers.append('Authorization', this.authToken);

  		console.log("TOKEN" + this.authToken);
		return this.http.get('http: //localhost:3000/users/upload', {headers: headers});


	}*/


	getProfile(){
		//console.log('TODO');
		return null
		/*let headers = new Headers();

		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', this.authToken);

		console.log("TOKEN" + this.authToken)

		return this.http.get('http://lanyocalhost:3000/users/profile', {headers: headers});*/
	}



	/*async loggedIn() : Promise<boolean> {
		if (typeof this.authToken === 'undefined' || this.authToken === null || this.authToken == 'undefined') {
			console.log('TOKEN loading.')
			this.loadToken()
			this.loadRefreshToken()
		}

		if (typeof this.authToken === 'undefined' || this.authToken === null || this.authToken == 'undefined') {
			console.log('TOKEN doesnt exist.')
			return new Promise<boolean>(function(resolve, reject){ resolve(false)})
		}

		var notExpired = tokenNotExpired()

		if (notExpired) {
			console.log('TOKEN not expired.')
			return new Promise<boolean>(function(resolve, reject){ resolve(true)})
		}

		console.log('TOKEN expired. Refresh the token!')
		await this.refreshTheToken()
		notExpired = tokenNotExpired()
		console.log('Token refreshed? ' + (notExpired ? 'true' : 'false'))
		return new Promise<boolean>(function(resolve, reject){ resolve(notExpired)})
	}*/





}
