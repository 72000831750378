<div class="row">
  <div class="col">
    <mat-toolbar>
      <span class="logo"><img class="m-logo" src="/assets/img/multiglot.png" width="70px" height="70px"><b>MULTI</b>GLOT</span>
  </mat-toolbar>
  </div>
</div>
<div class="row">
  <div class="col">
       
    <img class="translation-img" src="assets/img/frontimage.jpg" width="800px" height="500px">

   </div>
  <div class="col">
    <div class="row">
      <div class="sign-in-box" *ngIf="!hashSupplied">

          <h3>Forgot your password?</h3>
          <p>No problem. Fill out your registered e-mail and your user name. You will receive an e-mail to reset your password.</p>

          <form class="sign-in-box">
              <mat-form-field class="sign-in-field" appearance="outline">
                  <input matInput placeholder="E-mail" [(ngModel)]="email" name="email">
              </mat-form-field>
          
              <mat-form-field class="sign-in-field" appearance="outline">
                  <input type="text" matInput placeholder="User name" [(ngModel)]="username" name="username">
              </mat-form-field>
                  
              <div class="sign-in-field">
                <button (click)="ForgotPasswordSubmit()" class="forget-password-button" mat-stroked-button color="primary">Send</button>
              </div>  
              <div class="row">
                 
                <div class="col-md-5">
                 <div class="forgot-password-box">
                    <a [routerLink]="['/login']">Log in</a>
  
                 </div>
                </div>
              
            </div>        
                      
             
            
          </form>
        
      </div>
      <div class="sign-in-box" *ngIf="hashSupplied">

        <h3>Set a new password</h3>

        <form class="sign-in-box">
            <mat-form-field class="sign-in-field" appearance="outline">
                <input matInput placeholder="User name" [(ngModel)]="username" name="username">
            </mat-form-field>
        
            <mat-form-field class="sign-in-field" appearance="outline">
                <input type="text" matInput placeholder="New password" [(ngModel)]="new_password" name="new_password">
            </mat-form-field>

            <mat-form-field class="sign-in-field" appearance="outline">
              <input type="text" matInput placeholder="Re-type password" [(ngModel)]="retype_password" name="retype_password">
          </mat-form-field>
              
            <div class="sign-in-button-box">
              <button (click)="changePassword()" class="sign-in-button" mat-stroked-button color="primary">Change password</button>
            </div>          
                    
           
          
        </form>
      
    </div>
  
    </div>
  </div>

</div>

