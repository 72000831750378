import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auth.guard';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClientJsonpModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';



import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxEditorModule } from 'ngx-editor';
import { EuroglotEditorComponent } from './classes/editor/euroglot-editor.component';
import plugins from './classes/editor/plugins';
import schema from './classes/editor/schema';
import nodeViews from './classes/editor/nodeviews';
import { ClipboardModule } from 'ngx-clipboard';
import { ClipboardDirective } from './directives/clipboard.directive';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CookieModule } from 'ngx-cookie';

import { ValidateService } from './services/validate.service';
import { AuthServiceEuroglot } from './services/auth.service';
import { ConfigurationService } from './services/configuration.service';
import { DictionaryService } from './services/dictionary.service';
import { MergerService } from './services/merger.service';
import { Merger2Service } from './services/dicts-services/merger2.service';
import { ConjugationService } from './services/conjugation.service';
import { SpeechSyntesisService } from './services/speech-syntesis.service';
import { GrammarService } from './shared-services/grammar.service';
import { GrammarCheckerService } from './services/grammarchecker.service';
import { SearchService } from './shared-services/search.service';
import { ClipboardService } from './shared-services/copytoclipboard.service';
import { ActivateService } from './services/activate.service';
import { ResourceService } from './shared-services/resource.service';
import { SouffleurService } from './services/souffleur.service';
import { DeeplService } from './services/deepl.service';
import { HttpService } from './services/http.service';
import { PaymentService } from './services/payment.service';
import { TranslationMemoryService } from './services/translation-memory.service';
import { JwtDecoderService } from './services/jwtdecoder.service';
import { UserStatusService } from './services/user-status.service';
//import { SegmenterService } from './services/segmenter.service';

import { SocialLoginModule, GoogleLoginProvider, FacebookLoginProvider, AuthServiceConfig } from 'angularx-social-login';
import { AdsenseModule } from 'ng2-adsense';
import { TextInputHighlightModule } from 'angular-text-input-highlight';

import { LeftNavbarComponent } from './components/left-navbar/left-navbar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { LoginSsoComponent } from './components/login-sso/login-sso.component';
import { RegisterSuccessComponent } from './components/register-success/register-success.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HelpPanelComponent } from './components/help-panel/help-panel.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MenuContextComponent } from './components/menu-context/menu-context.component';
import { FooterComponent } from './components/footer/footer.component';

import { AccountComponent } from './components/account-components/account/account.component';
import { PaymentDialogComponent } from './components/account-components/payment-dialog/payment-dialog.component';
import { ThankYouPageComponent } from './components/account-components/thank-you-page/thank-you-page.component';

import { DictionaryComponent } from './components/dictionary-components/dictionary/dictionary.component';
import { OverviewPanelComponent } from './components/dictionary-components/overview-panel/overview-panel.component';
import { ThesaurusComponent } from './components/dictionary-components/thesaurus/thesaurus.component';
import { RecognizedComponent } from './components/dictionary-components/recognized/recognized.component';
import { ExpressionsPanelComponent } from './components/dictionary-components/expressions-panel/expressions-panel.component';
import { ConjugatedTranslationComponent } from './components/dictionary-components/conjugated-translation/conjugated-translation.component';
import { GrammarPanelComponent } from './components/dictionary-components/grammar-panel/grammar-panel.component';
import { SupplementPanelComponent } from './components/dictionary-components/supplement-panel/supplement-panel.component';
import { ChemicalPanelComponent } from './components/dictionary-components/chemical-panel/chemical-panel.component';
import { FinancialPanelComponent } from './components/dictionary-components/financial-panel/financial-panel.component';
import { ItPanelComponent } from './components/dictionary-components/it-panel/it-panel.component';
import { LegalPanelComponent } from './components/dictionary-components/legal-panel/legal-panel.component';
import { MedicalPanelComponent } from './components/dictionary-components/medical-panel/medical-panel.component';
import { ProverbsPanelComponent } from './components/dictionary-components/proverbs-panel/proverbs-panel.component';
import { TechnicalPanelComponent } from './components/dictionary-components/technical-panel/technical-panel.component';

import { DictionarySearchComponent } from './components/dictionary-components/dictionary-search/dictionary-search.component';
import { DictionarySettingsComponent } from './components/dictionary-components/dictionary-settings/dictionary-settings.component';
import { VideoPanelDictionaryComponent } from './components/dictionary-components/video-panel-dictionary/video-panel-dictionary.component';
import { AttributesComponent } from './components/dictionary-components/attributes/attributes.component';
import { GrammarComponent } from './components/dictionary-components/grammar/grammar.component';

import { DeeplMtComponent } from './components/deepl-components/deepl-mt/deepl-mt.component';
import { DrawerComponent } from './components/deepl-components/drawer/drawer.component';
import { SynonymTableComponent } from './components/deepl-components/synonym-table/synonym-table.component';

import { AddTerminologyComponent } from './components/terminology-components/add-terminology/add-terminology.component';
import { CreateTerminologyDialogComponent } from './components/terminology-components/create-terminology-dialog/create-terminology-dialog.component';

import { EditDictComponent } from './components/private-dicts/edit-dict/edit-dict.component';
import { PostNewConceptComponent } from './components/private-dicts/post-new-concept/post-new-concept.component';
import { PostNewLemmaComponent } from './components/private-dicts/post-new-lemma/post-new-lemma.component';
import { SelectDictComponent } from './components/private-dicts/select-dict/select-dict.component';
import { UpdateConceptFieldsComponent } from './components/private-dicts/update-concept-fields/update-concept-fields.component';
import { UpdateLemmaFieldsComponent } from './components/private-dicts/update-lemma-fields/update-lemma-fields.component';

import { CopyClipboardComponent } from './components/copy-clipboard/copy-clipboard.component';
import { SyntheticSoundComponent } from './components/synthetic-sound/synthetic-sound.component';
import { SouffleurComponent } from './components/souffleur/souffleur.component';
import { DeeplContextmenuComponent } from './components/deepl-components/deepl-contextmenu/deepl-contextmenu.component';
import { StatusPageComponent } from './components/status-page/status-page.component';


export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


const config: AuthServiceConfig = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('249925618269-5nno8vfi022fvh7lfcl4cl4vsg8jlicd.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("2143675282398214")
  }
])

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    EuroglotEditorComponent,
    //DrawerComponent,
    NavbarComponent,
    LeftNavbarComponent,
    RegisterComponent,
    LoginComponent,
    LoginSsoComponent,
    RegisterSuccessComponent,
    SetPasswordComponent,
    ForgotPasswordComponent,
    HelpPanelComponent,
    DashboardComponent,
    ProfileComponent,
    MenuContextComponent,
    FooterComponent,

    AccountComponent,
    PaymentDialogComponent,
    ThankYouPageComponent,

    DictionaryComponent,
    OverviewPanelComponent,
    ThesaurusComponent,
    RecognizedComponent,
    ExpressionsPanelComponent,
    GrammarPanelComponent,
    ConjugatedTranslationComponent,
    SupplementPanelComponent,
    ChemicalPanelComponent,
    FinancialPanelComponent,
    ItPanelComponent,
    LegalPanelComponent,
    MedicalPanelComponent,
    ProverbsPanelComponent,
    TechnicalPanelComponent,

    DictionarySearchComponent,
    DictionarySettingsComponent,
    VideoPanelDictionaryComponent,
    AttributesComponent,
    GrammarComponent,

    DeeplMtComponent,
    DrawerComponent,
    SynonymTableComponent,

    AddTerminologyComponent,
    CreateTerminologyDialogComponent,

    EditDictComponent,
    PostNewConceptComponent,
    PostNewLemmaComponent,
    SelectDictComponent,
    UpdateConceptFieldsComponent,
    UpdateLemmaFieldsComponent,

    CopyClipboardComponent,
    ClipboardDirective,
    SyntheticSoundComponent,
    SouffleurComponent,
    DeeplContextmenuComponent,
    StatusPageComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    CookieModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSidenavModule,
    MatDialogModule,
    MatExpansionModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatCardModule,
    MatChipsModule,
    MatTabsModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatInputModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,

    HttpModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxEditorModule.forRoot({
      schema,
      plugins,
      nodeViews: nodeViews
    }),
    ClipboardModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    SocialLoginModule,
    TextInputHighlightModule,
    TooltipModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    AdsenseModule.forRoot({
        adClient: 'ca-pub-8597993753944059',
        adSlot: 1456124119,
    }),
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: (provideConfig)
    },
    //{ provide: CookieOptions, useValue:false  },

    JwtDecoderService,
    UserStatusService,
    ValidateService,
    AuthServiceEuroglot,
    AuthGuard,
    DictionaryService,
    MergerService,
    ConjugationService,
    GrammarService,
    GrammarCheckerService,
    SearchService,
    //SegmenterService,
    ClipboardService,
    ActivateService,
    ResourceService,
    SouffleurService,
    PaymentService,
    DeeplService,
    HttpService,
    ConfigurationService,
    SpeechSyntesisService,
    TranslationMemoryService,
    Merger2Service,
    //CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
