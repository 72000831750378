import { Component, Inject, InjectionToken, Input, ViewChildren, ViewChild, ChangeDetectorRef, QueryList, Injector } from '@angular/core';
import { BasePanel } from '../base-panel';
import { ThesaurusComponent } from '../thesaurus/thesaurus.component';
import { MenuContextComponent } from '../../menu-context/menu-context.component';
import { MatExpansionPanel } from '@angular/material/expansion';


export const DICTIONARY: InjectionToken<string> = new InjectionToken<string>('DICTIONARY');


@Component({
  selector: 'supplement-panel',
  templateUrl: './supplement-panel.component.html',
  styleUrls: ['./supplement-panel.component.css'],
})

export class SupplementPanelComponent extends BasePanel {
  @ViewChild(MenuContextComponent) contextMenu: MenuContextComponent;
  @ViewChildren(ThesaurusComponent) thesaurusChildren: QueryList<ThesaurusComponent>;
  @ViewChildren('collapsibleProgrammatically') collapsibleChildren: QueryList<MatExpansionPanel>;

  @Input() concepts: any[]
  @Input() referenceConcepts: any[];
  @Input() translations: any[];
  @Input() referenceTranslations: any[];

  evokeUpdateGrammar;
  evokeShowGrammar


  constructor(cd: ChangeDetectorRef, injector: Injector, @Inject(DICTIONARY) _dictionary : string) {
    //console.log("Initializing supplemental panel (" + dictionary + ")")
    super(cd, injector, _dictionary);
  }

  ngOnInit() {
    super.ngOnInit()
    //this.getWordTypeResources(this.concepts);
  }


  //get translation of a concept
  getTranslation(dictionary, id){
    //console.log("DICTIONARY: " + dictionary)
    for(var i=0; i < this.translations.length; i++){
      var translation = this.translations[i]
      if(translation == undefined) {
        break;
      }
      if((translation.data.id == id ) && (translation.data.dictionary == dictionary)){
        return translation
      }
    }
    for(var i=0; i < this.referenceTranslations.length; i++){
      var translation = this.referenceTranslations[i]
      if(translation == undefined) {
        break;
      }
      if((translation.data.id == id ) && (translation.data.dictionary == dictionary)){
        return translation
      }
    }
    return null
  }

  filtered(dictionary, id): boolean{

    for(var i=0; i < this.translations.length; i++){
      let translation = this.translations[i]
      if(translation == undefined) {
        continue;
      }

      if((translation.data.id == id ) && (translation.data.dictionary == dictionary)){
        return this.filtered2(translation.data.lemmas)
      }
    }
    for(var i=0; i < this.referenceTranslations.length; i++){
      let translation = this.referenceTranslations[i]
      if(translation == undefined) {
        continue;
      }

      if((translation.data.id == id ) && (translation.data.dictionary == dictionary)){
        return this.filtered2(translation.data.lemmas)
      }
    }

    return true;
  }

  filtered2(translationLemmas: any[]): boolean {
    if((translationLemmas == undefined) || (translationLemmas.length == 0)){
      return true;
    }
    if((translationLemmas.length == 1) && (translationLemmas[0].data.lemma == "xxx")){
      return true;
    }
    if((translationLemmas.length == 1) && (translationLemmas[0].data.lemma == "")){
      return true;
    }
    return false;
  }

}
