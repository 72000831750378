import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

import { AccountComponent } from './components/account-components/account/account.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { LoginSsoComponent } from './components/login-sso/login-sso.component';
import { RegisterSuccessComponent } from './components/register-success/register-success.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ThankYouPageComponent } from './components/account-components/thank-you-page/thank-you-page.component';
//import { TranslationProjectComponent } from './components/translation-projects/translation-project/translation-project.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DeeplMtComponent } from './components/deepl-components/deepl-mt/deepl-mt.component';
import { DictionarySettingsComponent } from './components/dictionary-components/dictionary-settings/dictionary-settings.component';
import { HelpPanelComponent } from './components/help-panel/help-panel.component';
import { SelectDictComponent } from './components/private-dicts/select-dict/select-dict.component';
import { EditDictComponent } from './components/private-dicts/edit-dict/edit-dict.component';
import { DictionaryComponent } from './components/dictionary-components/dictionary/dictionary.component';
import {StatusPageComponent} from './components/status-page/status-page.component';

const appRoutes: Routes =  [
  {path:'', component: LoginComponent},
  //{path:'account', component: AccountComponent},
  //{path:'register', component: RegisterComponent},
  {path:'login', component: LoginComponent},
  {path:'status', component: StatusPageComponent},
  {path:'login-sso', component: LoginSsoComponent },
  //{path:'register-success', component: RegisterSuccessComponent},
  //{path:'register-login', component: SetPasswordComponent},
  //{path:'forgot-password', component: ForgotPasswordComponent},
  //{path:'payment-result', component: ThankYouPageComponent},
  //{path: 'project', component: TranslationProjectComponent},
  //{path:'dashboard', component: DashboardComponent, canActivate:[AuthGuard]},
  //{path:'overlay-editor', component: WebEditorComponent , canActivate:[AuthGuard]},
  {path:'translate', component: DeeplMtComponent, canActivate:[AuthGuard]},
  //{path:'dictionary-settings', component: DictionarySettingsComponent , canActivate:[AuthGuard]},
  //{path:'dictionary-settings/:hash', component: DictionarySettingsComponent, canActivate:[AuthGuard]},
  //{path:'tm', component: TranslationMemoryComponent , canActivate:[AuthGuard]},
  //{path:'help', component: HelpPanelComponent , canActivate:[AuthGuard]},
  //{path:'dicts', component: SelectDictComponent , canActivate:[AuthGuard]},
  //{path:'concepts/:dictname', component: EditDictComponent, canActivate:[AuthGuard]},
  /*{
    path:'dictionary',
    children: [
      {
        path: '',
        component: DictionaryComponent,
        canActivate:[AuthGuard]
      }

    ]
  }*/

]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
