<the-context-menu
(evokeShowGrammar)="evokeShowGrammar"
(evokeUpdateGrammar)="evokeUpdateGrammar"
id="context_menu">
</the-context-menu>


  <div id="expressions-panel">
    <div *ngFor="let expression of expressions; let cindex = index">
      <div *ngIf="expression.data.dictionary == dictionary">
        <mat-accordion>
          <mat-expansion-panel #collapsibleProgrammatically id="concept_card_{{expression.data.id}}"
            (opened)="collapsibleOpened(expression.data.id)" (closed)="collapsibleClosed(expression.data.id)">
          <mat-expansion-panel-header>
            <mat-panel-title style="padding:8px">
              <div class="header_wordtype">
                <p class="header_wordtype_p">{{currentResourceMaps.wordTypeResources.get(expression.data.lemmas[0].data.word_type)}}</p>
              </div>
              <div class="lang-icon"><img [src]="sourceFlag" /> </div>
              <div class="header_synonyms header_synonym_{{hi}}" *ngFor="let lemmaObj of expression.data.lemmas; let hi = index">
                  <p *ngIf="(hi==0) && expression.data.lemmas.length > 1" class="header_synonym_p_first header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
                  <p *ngIf="(hi != 0) && (hi<expression.data.lemmas.length-1)" class="header_synonym_p_middle header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
                  <p *ngIf="hi==expression.data.lemmas.length-1" class="header_synonym_p_last header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
              </div>
              <div *ngIf="expression.data.lemmas.length > 1" class="header_synonyms_end_bracket">)</div>
              <br>
              <div class="lang-icon"><img [src]="targetFlag" /> </div>
              <div class="header_translations header_translation_{{hi}}" *ngFor="let transObj of translations[cindex].data.lemmas; let hi = index">
                  <p *ngIf="(hi==0) && translations[cindex].data.lemmas.length > 1" class="header_translation_p_first header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                  <p *ngIf="(hi != 0) && (hi<translations[cindex].data.lemmas.length-1)" class="header_translation_p_middle header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                  <p *ngIf="hi==translations[cindex].data.lemmas.length-1" class="header_translation_p_last header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
              </div>
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="translations">
            <p class="best-translations-title">{{'DICTIONARY.BEST-TRANSLATIONS' | translate}}</p>

                <div class="best-translations" *ngFor="let lemmaObj of getTranslationExpression(expression.data.dictionary, expression.data.id, translations[cindex].data.trans_groups).data.lemmas">
                    {{parseAttributes(lemmaObj)}}
                    <i class="info-icon material-icons" (click)="showContextMenu($event, targetLanguage, expression.data.id, expression.data.concept_attributes, expression.data.dictionary, lemmaObj.data)"  style="cursor:pointer">info</i>
                    {{lemmaObj.data.lemma}}
                </div>
          </div>
          <thesaurus id="thesaurus_{{expression.data.id}}" [sourceLanguage]="sourceLanguage" [targetLanguage]="targetLanguage" [conceptid]="expression.data.id" [dictionary]="expression.data.dictionary" [contextMenu]="contextMenu"></thesaurus>
          <button mat-raised-button color="primary" (click)="closeCollapsible(collapsibleProgrammatically, expression.data.id)">Close</button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
