import { EditorView, EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';

import { Toolbar, ToolbarItem } from 'ngx-editor';

import { TCR, SEPERATOR_CLASSNAME, setDomInvisible } from './root';

export const ContextMenuPluginKey = 'context_menu';

export const CONTEXT_MENU_CLASSNAME = "NgxEditor__ContextMenu";
export const ACTIVE_CONTEXT_MENU_ITEM_CLASSNAME = "NgxEditor__ContextMenu--Active";
export const CONTEXT_MENU_ITEM_CLASSNAME = "NgxEditor__ContextMenuItem";
export const CONTEXT_MENU_SEPERATOR_CLASSNAME = "NgxEditor__ContextMenu_Seperator";
export const SIDE_ITEM_CLASSNAME = "NgxEditor__Side";
export const SIDE_ITEM_OPEN_CLASSNAME = `${SIDE_ITEM_CLASSNAME}--Open`;
export const ACTIVE_SIDE_ITEM_CLASSNAME = `${SIDE_ITEM_CLASSNAME}--Active`;

export enum ContextMenuClickType {
  SingleClickLeft = 0,
  SingleClickRight,
  DoubleClickLeft,
  DoubleClickRight,
  TripleClickLeft,
  TripleClickRight,
}

export interface ContextMenuProperties {
  items : Toolbar;
  labels : {};
  onClickType? : ContextMenuClickType;
}

export const defaultContextMenuProperties : ContextMenuProperties = {
  items : null,
  labels : null,
  onClickType : ContextMenuClickType.SingleClickLeft,
}

export function contextMenu(startProperties : ContextMenuProperties) : Plugin<any, any> {
  if(startProperties == undefined)
    startProperties = defaultContextMenuProperties;
  if(startProperties.onClickType == undefined)
    startProperties.onClickType = ContextMenuClickType.SingleClickLeft;

  return new Plugin({
    key: new PluginKey(ContextMenuPluginKey),
    view: function (editorView) {
      return new ContextMenuView(editorView, startProperties);
    },
    state: {
      init() {
        console.log("INIT")
        let properties : ContextMenuProperties = {
          items : startProperties.items,
          labels : startProperties.labels,
          onClickType : startProperties.onClickType,
        };
        return properties;
      },
      apply(tr : Transaction, prev : ContextMenuProperties) {
        if(tr.getMeta(ContextMenuPluginKey)) {
          console.log("APPLY")
          let newProperties = tr.getMeta(ContextMenuPluginKey);
          if(newProperties) {
            return newProperties;
          }
        }
        return prev;
      },
    },
    props: {
      handleClickOn(editorView : EditorView, pos : number, node : Node, nodePos : number, event : MouseEvent, direct : boolean) {
console.log("CLICK")
        let pstate = this.getState(editorView.state);
        //closeContextMenuView(editorView)

        if((pstate.onClickType != ContextMenuClickType.SingleClickLeft) && (pstate.onClickType != ContextMenuClickType.SingleClickRight)) return false;
        if((pstate.onClickType == ContextMenuClickType.SingleClickLeft) && (event.button !== 0)) return false;
        if((pstate.onClickType == ContextMenuClickType.SingleClickRight) && (event.button !== 2)) return false;

        return openContextMenuView(editorView, event);
      },
      handleClick(editorView: EditorView, pos: number, event: MouseEvent) {
        return closeContextMenuView(editorView);
      },
      handleDoubleClickOn(editorView : EditorView, pos : number, node : Node, nodePos : number, event : MouseEvent, direct : boolean) {
        let pstate = this.getState(editorView.state);
        //closeContextMenuView(editorView)

        if((pstate.onClickType != ContextMenuClickType.DoubleClickLeft) && (pstate.onClickType != ContextMenuClickType.DoubleClickRight)) return false;
        if((pstate.onClickType == ContextMenuClickType.DoubleClickLeft) && (event.button !== 0)) return false;
        if((pstate.onClickType == ContextMenuClickType.DoubleClickRight) && (event.button !== 2)) return false;

        return openContextMenuView(editorView, event);
      },
      handleDoubleClick(editorView: EditorView, pos: number, event: MouseEvent) {
        return closeContextMenuView(editorView);
      },
      handleTripleClickOn(editorView : EditorView, pos : number, node : Node, nodePos : number, event : MouseEvent, direct : boolean) {
        let pstate = this.getState(editorView.state);
        //closeContextMenuView(editorView)

        if((pstate.onClickType != ContextMenuClickType.TripleClickLeft) && (pstate.onClickType != ContextMenuClickType.TripleClickRight)) return false;
        if((pstate.onClickType == ContextMenuClickType.TripleClickLeft) && (event.button !== 0)) return false;
        if((pstate.onClickType == ContextMenuClickType.TripleClickRight) && (event.button !== 2)) return false;

        return openContextMenuView(editorView, event);
      },
      handleTripleClick(editorView: EditorView, pos: number, event: MouseEvent) {
        return closeContextMenuView(editorView);
      },
    },
  });

}

function closeContextMenuView(editorView : EditorView) : boolean {
  let cmv = contextMenuView(editorView);
  if(cmv.isOpen()) {
    cmv.close();
    return true;
  }

  return false;
}

function openContextMenuView(editorView : EditorView, event : MouseEvent) : boolean {
  let cmv = contextMenuView(editorView);
  if(cmv.isOpen()) {
    cmv.close();
    return true;
  }
  var rect = editorView.dom.getBoundingClientRect();
  cmv.position(rect.left - editorView.dom.offsetLeft, rect.top - editorView.dom.offsetTop, event);
  cmv.open();
  return true;
}

export function contextMenuView(editorView : EditorView) : ContextMenuView {
  for(const view of editorView.pluginViews) {
    if(view instanceof ContextMenuView) {
      return view;
    }
  }

  return null;
}

export function contextMenuProperties(state : EditorState) : ContextMenuProperties {
  for(const plugin of state.plugins) {
    if(plugin.key.startsWith(ContextMenuPluginKey)) {
      let pstate = plugin.getState(state)
      if(pstate)
        return pstate;
    }
  }

  return defaultContextMenuProperties;
}

class ContextMenuView {
  private view : EditorView;
  private options : ContextMenuProperties;
  private updateMenuItems : any;
  private menuDom : HTMLElement;
  private opened : boolean;

  constructor(view: EditorView, options : ContextMenuProperties) {
    this.view = view;
    this.options = options;
    this.render();
    this.update(view, null);
    this.close();
  }

  render() {
    const menu = createContextMenu(this.options.items, this.options.labels);
    let tcr = menu.render(this.view);
    this.updateMenuItems = tcr.update;
    this.menuDom = tcr.dom;
    this.view.dom.parentNode.insertBefore(this.menuDom, this.view.dom);
  }

  position(parentX : number, parentY : number, event : MouseEvent) {
    //console.dir(event)
    this.menuDom.style.top = event.pageY - parentY + 'px';
    this.menuDom.style.left = event.pageX - parentX + 'px';
  }

  open() {
    this.opened = true;
    setDomInvisible(this.menuDom, !this.opened);
  }

  close() {
    this.opened = false;
    setDomInvisible(this.menuDom, !this.opened);
  }

  isOpen() : boolean {
    return this.opened;
  }

  update(view: EditorView, prevState: EditorState) {
    this.updateMenuItems(view.state);
  }

  destroy() {
    this.menuDom.remove();
  }

}


export declare type ContextMenuItem = (editorView: EditorView) => TCR;

function createContextMenu(items : Toolbar, labels : {}) : ContextMenu {
  return new ContextMenu(items, labels);
}

class ContextMenu {
  items : Toolbar;
  constructor(items : Toolbar, labels : {}) {
    this.items = items;
  }

  render(view: EditorView) : TCR {
    const updates = [];
    const dom = ContextMenu.contextMenuDom();

    let itemAdded = false;
    for(let toolbarGroup of this.items) {
      if(itemAdded) {
        dom.append(ContextMenu.getContextMenuSeperatorDom());
        itemAdded = false;
      }
      for(let toolbarItem of toolbarGroup) {
        if(typeof toolbarItem === 'string') {
          //TODO
console.log("TODO: Context menu toolbar string items")
        } else if (typeof toolbarItem === 'function') {
          let tcr = toolbarItem(view);
          dom.append(tcr.dom);
          updates.push(tcr.update);
          itemAdded = true;
        } else if (typeof toolbarItem === 'object') {
          //TODO
console.log("TODO: Context menu toolbar object items")
        }

      }


    }

    return {
      update(state) {
          updates.forEach((update) => {
              update(state);
          });
      },
      dom,
    }

  }

  private static contextMenuDom() : HTMLElement {
    const menuDom : HTMLElement = document.createElement('div');
    menuDom.classList.add(CONTEXT_MENU_CLASSNAME);
    //menuDom.style.minWidth="100%";
    menuDom.style.width="auto";

    return menuDom;
  }

  private static getContextMenuSeperatorDom() : HTMLElement {
      const seperatorDom : HTMLElement = document.createElement('div');
      seperatorDom.className = CONTEXT_MENU_SEPERATOR_CLASSNAME;
      return seperatorDom;
  };
}
