import { Component, OnInit } from '@angular/core';
import { Video } from '../../interfaces/video';
import { ProductUpdate } from '../../interfaces/productupdate';
import {MatDialog} from '@angular/material/dialog';

import { VideoPanelDictionaryComponent } from '../dictionary-components/video-panel-dictionary/video-panel-dictionary.component';


@Component({
  selector: 'app-help-panel',
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.css']
})
export class HelpPanelComponent implements OnInit {

   videos: Array<Video> = [
    {title:"How to use the dictionary", description:"Learn how to use the dictionary", thumbnail:"assets/img/screenshot_video.jpg"},

  ]
  productupdates: Array<ProductUpdate> = [
    {title: "Update: New terminology added to the database", date: "16-05-2019", description: "Terminology from the concrete industry. Examples from this dataset: Vicat needle, acid scoured concrete, blast-furnace cement  "},
    {title: "Update: New terminology added to the database", date: "22-02-2019", description: "We added new Criminal Law terminology English, Dutch, German, French and Spanish to our database. Examples from this dataset: challenge (to the judge), facts (of the charge), urge a witness to speak the truth "},
    {title:"Euroglot language platform in private beta", date: "03-01-2019", description:"Euroglot language platform is now in private beta"},

  ]

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }
  openModal() {
    const dialogRef = this.dialog.open(VideoPanelDictionaryComponent, {
      width: '900px',
      data: {fromHelpPanel:true}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
