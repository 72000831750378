import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import { environment, varEnvironment } from '../../environments/environment';
import { HttpService } from '../services/http.service';

const API_URL = '://' + environment.API_IP + ":" + environment.PORT_CONFIG + environment.ENDPOINT_CONFIG + environment.CONFIG

@Injectable()
export class ConfigurationService {
  private showHelp: boolean;
  private configLoaded: boolean = false;
  private sourceLang: string;
  private targetLang: string;
  private countryTargetLang: string;
  private countrySourceLang: string;
  private defaultSourceLang: string;
  private defaultTargetLang: string;
  private screenLang: string;
  private dictionarySearch: string;
  private caseSensitive: boolean;
  private diacriticSensitive: boolean;

  requestOptions: {} = {withAuth: true};

  screenLanguageChangedEmitter : EventEmitter<null> = new EventEmitter();
  sourceLanguageChangedEmitter : EventEmitter<null> = new EventEmitter();
  targetLanguageChangedEmitter : EventEmitter<null> = new EventEmitter();
  sourceCountryChangedEmitter : EventEmitter<null> = new EventEmitter();
  targetCountryChangedEmitter : EventEmitter<null> = new EventEmitter();
  
  constructor(
    private translate: TranslateService,
    private httpClient: HttpService
  ) {
    translate.addLangs(['en', 'nl','de','fr','es', 'it']);
    translate.setDefaultLang("en");
    this.clearConfig();

    //Get configuration from database and silently fail if for ex. no tokens are present
    this.handleInitialConfig().catch(error=>{});
  }

  handleInitialConfig() : Promise<any> {
    return new Promise((resolve, reject)=>{

      if(this.configLoaded) {
        resolve();
        return;
      }

      //console.log("Handle initial config request")
      this.requestConfig().then(resolved=>{
        if(this.configLoaded) {
          resolve();
          return;
        }

        //console.log("Initial config request successful")
        //Set default source and target language if they are not set in the database
        if (this.defaultSourceLang == undefined || this.defaultSourceLang == "") {
          this.defaultSourceLang = this.screenLang;
        }
        if (this.defaultTargetLang == undefined || this.defaultTargetLang == "") {
          this.defaultTargetLang = "en";
          if (this.defaultSourceLang == "en") {
            this.defaultTargetLang = "nl";
          }
        }

        //Set current source and target language from the defaults
        this.sourceLang = this.defaultSourceLang;
        this.targetLang = this.defaultTargetLang;

        this.configLoaded = true;
        resolve();
      }).catch(error => {
        reject(error);
      })
    })
  }

  clearConfig() {
    this.configLoaded = false;
    this.sourceLang = "";
    this.targetLang = "";
    this.defaultSourceLang = "";
    this.defaultTargetLang = "";
    this.dictionarySearch = "";
    this.caseSensitive = false;
    this.diacriticSensitive = false;
    this.showHelp = true;

    let browserLang = this.translate.getBrowserLang();
    if (browserLang.match(/en|es|nl|de|it|fr/)) {

      this.setScreenLanguage(browserLang);       
    } else {
      this.setScreenLanguage("en");
    }
    this.screenLanguageChangedEmitter.emit();
  }

  //Source country
  setSourceCountry(sourceCountry: string): string {
    if(this.countrySourceLang != sourceCountry){
      this.countrySourceLang = sourceCountry;
      this.sourceCountryChangedEmitter.emit();
      return this.countrySourceLang
    }
  }

  getSourceCountry(): string{
    return this.countrySourceLang;
  }


  //target country
  setTargetCountry(targetCountry: string): string {
    if(this.countryTargetLang != targetCountry){
      this.countryTargetLang = targetCountry;
      this.targetCountryChangedEmitter.emit();
      return this.countryTargetLang
    } 
  }

  getTargetCountry(): string {
    return this.countryTargetLang;
  }


  //Source language
  setSourceLanguage(sourceLanguage: string): string {
    if (this.sourceLang != sourceLanguage) {
      this.sourceLang = sourceLanguage;
      //Erase dictionary search when source language changes
      this.dictionarySearch = "";

      this.sourceLanguageChangedEmitter.emit();
    }

    //console.log("this is the sourcelang " + this.sourceLang)

    return this.sourceLang
  }

  getSourceLanguage(): string{
    return this.sourceLang
  }

  //Target language
  setTargetLanguage(targetLanguage: string): string {
    if (this.targetLang != targetLanguage) {
      this.targetLang = targetLanguage

      this.targetLanguageChangedEmitter.emit();
    }

    //console.log("this is the targetlang " + this.targetLang)
    return this.targetLang
  }

  getTargetLanguage(): string{
    return this.targetLang
  }

  //Source language
  setDefaultSourceLanguage(sourceLanguage: string): string {
    this.defaultSourceLang = sourceLanguage
    //console.log("this is the default sourcelang " + this.defaultSourceLang)

    return this.defaultSourceLang
  }

  getDefaultSourceLanguage(): string{
    return this.defaultSourceLang
  }

  //Target language
  setDefaultTargetLanguage(targetLanguage: string): string {
    this.defaultTargetLang = targetLanguage
    //console.log("this is the default targetlang " + this.defaultTargetLang)
    return this.defaultTargetLang
  }

  getDefaultTargetLanguage(): string{
    return this.defaultTargetLang
  }

  //Screen Language
  setScreenLanguage(screenLanguage: string): string {
    let changed = screenLanguage != this.screenLang;

    this.screenLang = screenLanguage
    //console.log("this is the screenlanguage " + this.screenLang)
    this.translate.use(this.screenLang);

    if(changed) {
      this.screenLanguageChangedEmitter.emit();
    }

    return this.screenLang
  }

  getScreenLanguage(): string{
    return this.screenLang
  }

  //Dictionary search
  setDictionarySearch(value: string): string {
    this.dictionarySearch = value

    return this.dictionarySearch
  }

  getDictionarySearch(): string {
    return this.dictionarySearch
  }

  //Case sensitive
  setCaseSensitive(value: boolean): boolean {
    this.caseSensitive = value
    return this.caseSensitive
  }

  getCaseSensitive(): boolean {
    return this.caseSensitive
  }

  setDiacriticSensitive(value: boolean): boolean {
    this.diacriticSensitive = value
    return this.diacriticSensitive
  }
  
  getDiacriticSensitive(): boolean {
    return this.diacriticSensitive
  }

  setShowHelp(value: boolean): boolean{
    this.showHelp = value;
    return this.showHelp
  }

  getShowHelp(): boolean{
    return this.showHelp
  }


  storeConfig() : Promise<any> {
    let body = {
      "screen_language": this.screenLang,
      "source_language": this.defaultSourceLang,
      "target_language": this.defaultTargetLang,
      "show_help": this.showHelp
    }

    //console.log("PATCH request to config with PATCH data:" + JSON.stringify(body))
    return new Promise((resolve, reject)=>{
      this.httpClient.patch(varEnvironment.schema + API_URL, body, this.requestOptions).subscribe(
        data => this.handleStoreConfigSuccess(data),
        error => { reject(error) },
        () =>  { resolve() }
      )
    });
  }

  requestConfig() : Promise<any> {
    let body = {};

    return new Promise((resolve, reject)=>{
      this.httpClient.patch(varEnvironment.schema + API_URL, body, this.requestOptions).subscribe(
        data => this.handleStoreConfigSuccess(data),
        error => { reject(error) },
        () =>  { resolve() }
      )
    });
  }

  handleStoreConfigSuccess(data){
    //console.log("config response" + JSON.stringify(data))

    this.setScreenLanguage(data.screen_language);
    this.setDefaultSourceLanguage(data.source_language);
    this.setDefaultTargetLanguage(data.target_language);
    this.setShowHelp(data.show_help);
  }
}

