import { Component, OnInit } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIcon} from '@angular/material/icon';
import{UserStatusService} from '../../services/user-status.service';
import {PaymentService, PurchaseProduct, Purchase} from '../../services/payment.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthServiceEuroglot, LoggedInEvent} from '../../services/auth.service';
import {Router} from '@angular/router';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Multilingual Dictionary', cols: 1, rows: 1 },
          { title: 'Machine Translation Editor', cols: 1, rows: 1 },
        ];
      }

      return [
        { title: 'Card 1', cols: 2, rows: 1, contentfree: 'Euroglot Online is a platform with multiple translation tools. Right now, you will find here a dictionary and an Machine Translation editor, but a lot more is to come. Right now we are working on a CAT tool so you can work with your translations in a productive and structured way. The ability to upload your own terminology and glossaries that integrate with the dictionaries and the Machine Translations. ', contentpremium: 'Euroglot Online is a platform with multiple translation tools. Right now, you will find here a dictionary and an Machine Translation editor, but a lot more is to come. Right now we are working on a CAT tool so you can work with your translations in a productive and structured way. The ability to upload your own terminology and glossaries that integrate with the dictionaries and the Machine Translations. '  },
        { title: 'Multilingual Dictionary', contentfree: 'With a free account you can translate a limited amount of words and expressions between English, German, French, Dutch, Spanish and Italian. Do you want to translate expressions, proverbs and specialised terminology and do you want to get more hits, just take a Premium subscription', contentpremium:'Euroglot is a conceptual dictionary for English, German, Dutch, French, Spanish and Italian. Look up translations of words, expressions, proverbs and Legal, Technical, Medical, Financial, Chemical and IT terminology.', cols: 1, rows: 1 },
        { title: 'Machine Translation Editor', contentfree:'Translate entire sentences and paragraphs of texts using Machine Translation based on neural networks. Check and enrich your translation using the built-in dictionaries by hovering each word. Translate a paragraph of text for free and see the results. For the best results, make sure each sentence begins with a capital letter and ends with a period', contentpremium: 'Translate entire sentences and paragraphs of texts using Machine Translation based on neural networks. Check and enrich your translation using the built-in dictionaries by hovering each word. For the best results, make sure each sentence begins with a capital letter and ends with a period.', cols: 1, rows: 1 },
      ];
    })
  );


  isFreeUser: boolean = true;
  color = 'primary';
  mode = 'determinate';
  value = 50;
  bufferValue:number = 75;
  //products loading completed 
  isCompleted: boolean = false;
  isDisabled: boolean = false;
  deeplcredits: number;
  
  
  //array to push products in
  arrayProduct = [];

  constructor(
    private userStatus: UserStatusService, 
    private paymentservice: PaymentService,
    private authService: AuthServiceEuroglot,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private snackBar: MatSnackBar) {
      this.isFreeUser = this.userStatus.getFreeUser();
      this.deeplcredits = this.userStatus.getMTBalance();
  
      
      //console.log("Is free user?" + this.isFreeUser + "credits" + this.deeplcredits)
      this.getProduct(6);
      
      
     }

  ngOnInit() {
   
  }
  getProduct(id){
    this.paymentservice.getProduct(id).subscribe(
      data => this.handleGetProductSuccess(data),
      error => this.handleGetProductError(error),
      () => this.isCompleted = true
    )
  }

  handleGetProductSuccess(data){
    this.arrayProduct.push(data.data);
    //console.log("PRODUCT DATA" + JSON.stringify(this.arrayProduct))
    if(data.data.purchase_limit == -1){
      this.isDisabled = true; 
    }


  }

  handleGetProductError(error){
    console.log(error.message);
  }
  freeCreditsPurchase(productnr, productdescription){
    //console.log("productnr" + productnr)
    let myPurchase: Purchase = [];
    let myProduct: PurchaseProduct = {amount: 1, id: productnr, product:productdescription}
    myPurchase.push(myProduct)
    //console.log("purchase"+ JSON.stringify(myPurchase))
    this.paymentservice.purchaseFreeCredits(myPurchase).subscribe(
      data => this.handlePurchaseFreeCreditsSuccess(data),
      error => this.handlePurchaseFreeCreditsError(error)
    )
  }
  handlePurchaseFreeCreditsSuccess(data){
    //console.log("free purcahse data" + JSON.stringify(data))

    this.authService.refreshTheToken();
    this.router.navigate(['translate']);
    
    if(data.data.message == "Error while creating payment data: Purchase of product %!d(MISSING), '%!s(MISSING)' is not allowed due to purchase limit"){
      //this.snackBar.open("You can use this product only once", 'OK', {duration: 3000});
    }
    

  }

  handlePurchaseFreeCreditsError(error){
    console.log(error)
  }

   

}
