<div class="table-responsive">
    <table class="fixed_header responsive-table table table-striped table-hover">
        <thead>
          <tr class="thesaurus-row thesaurus-text">{{ 'THESAURUS.THESAURUS' | translate }}</tr>
          <tr class="thesaurus-row">
            <th class="thesaurus-cell">{{ 'THESAURUS.SOURCELANGUAGE' | translate }} </th>
            <th class="thesaurus-cell">{{ 'THESAURUS.TARGETLANGUAGE' | translate }}  </th>
          </tr>
        </thead>
      
        <tbody class="scroll-it">
          <ng-container *ngFor="let thesaurus of thesauri let i=index">
            <tr class="thesaurus-row" *ngIf="!filtered(i)">
              <td class="thesaurus-cell">         
                <i class="info-icon material-icons" style="cursor:pointer" (click)="showContextMenu($event, sourceLanguage, conceptid, conceptAttributes, dictionary, thesaurus.data)">info</i>
                <p class="thesaurus_lemmas">{{thesaurus.data.lemma}}</p> 
              </td>
              <td class="thesaurus-cell">
                <ul *ngFor="let translation of thesauriTranslations[i].data.lemmas">
                  <li>
                    <i class="info-icon material-icons" style="cursor:pointer" (click)="showContextMenu($event, targetLanguage, conceptid, thesauriTranslations[i].data.concept_attributes, dictionary, translation.data)">info</i>
                    <p class="translation_lemmas">{{translation.data.lemma}}</p> </li>
                </ul>
              </td>
            </tr>
          </ng-container>
        </tbody>  
    </table> 
</div>

