<app-navbar></app-navbar>
<mat-drawer-container class="example-container">
    <mat-drawer mode="side" opened fxHide.sm  >
      <left-navbar></left-navbar>  
    </mat-drawer>
    <mat-drawer-content>
        <div class="translation-area">
            <div class="container-fluid">
              <div class="white-space"></div>
              <div class="row">
                <div class="col-sm-12 account-container">
                  <div class="card">
                     <div class="card-header-tab card-header">
                      <h5>Account</h5>
                     </div>
                     <div class="card-body">
                         <div class="row">
                           <div class="col">
                               <p>Membership and Billing</p>
                           </div>
                           <div class="col">
                               <p>{{customerEmail}}</p>
                                            
                           </div>
                           <div class="col">
                             <!--<button mat-flat-button color="primary">change email</button>-->
                             
                           </div>
                         </div>
                         <div class="row">
                           <div class="col">
           
                           </div>
                           <div class="col">
                           </div>
                           <div class="col">
                               <!--<button mat-flat-button color="primary">change password</button> -->
                               
                           </div>
                         </div>
                         <!--<div class="row">
                           <div class="col">
           
                           </div>
                           <div class="col">
                               <mat-divider></mat-divider>
                               
                           </div>
                           <div class="col">
           
                         </div>
                    
                        </div> -->
                       <!-- <div class="row">
                           <div class="col">
           
                           </div>
                           <div class="col">
                             <p><mat-icon>credit_card</mat-icon>  **********567</p>                    
                           </div>
                           <div class="col">
                               <button mat-flat-button color="primary" class="payment">Update payment info</button>
                               <button mat-flat-button color="primary" class="payment">Payment details</button>
                               
                               
                          </div>
                      </div> -->
                         <mat-divider></mat-divider>
                         <div class="row">
                             <div class="col">
                               <div class="spacer"></div>
                               <p>Plan details</p>
                             </div>
                             <div class="col">
                               <div class="spacer"></div>  
                               <div *ngIf="!freeUser" class="paid-user-plan">
                                 <p>Premium Subscription</p>
                               </div>
                               <div *ngIf="freeUser" class="free-user-plan">
                                 <p>Free Plan</p>  
                               </div>
                             </div>
                             <div class="col">
                               <div class="spacer"></div>  
                               <div *ngIf="!freeUser" class="paid-user-button">
                                 <button mat-flat-button color="warn">Cancel Subscription</button>
                               </div>
                               <div *ngIf="freeUser" class="paid-user-button">
                                 <button (click)="openPaymentDialog(3, 'Premium Subscription')" mat-flat-button color="warn">Go Premium</button>
                               </div>
                             </div>
                      
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row">
                            <div class="col">
                             <div class="spacer"></div>     
                             <p>User management</p>
                            </div>
                            <div class="col">
                               
                           </div>
                           <div class="col">
                                 
                           </div>
                          </div>
                          
                             
                </div>
              </div>
            </div>
           
           </div>    
           </div>
           </div>
    
    </mat-drawer-content>
        
</mat-drawer-container>  



