import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';

const API_URL = "https://droplet1.euroglot.nl/textstat/"

@Injectable({
  providedIn: 'root'
})
export class TextstatService {
  requestOptions: {} = {withAuth: true, withContentType: 'application/x-www-form-urlencoded', withCharset: 'utf-8' };
  text: string;


  constructor(private http:HttpService) { }

  getPassiveVoiceCount(language, sentence){
    let body = {
      "language" : language,
      "sentence" : sentence

    }

    return this.http.post<JSON>(API_URL + "passivevoice", body, this.requestOptions)
  }

  getReadingInfo(language, text){
    console.log("text" + text)
    let body = {
      "language": language,
      "text": text
    }
    console.log("body" + JSON.stringify(body))

    return this.http.post<JSON>(API_URL + "textinfo", body, this.requestOptions)
  }
}
