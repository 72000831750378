<the-context-menu
(evokeShowGrammar)="evokeShowGrammar"
(evokeUpdateGrammar)="evokeUpdateGrammar"
id="context_menu">
</the-context-menu>

<div id="supplemental-panel">
    <div class="supplemental-panel supplemental-{{dictionary}}-panel">

        <div *ngFor="let concept of concepts; let cindex = index">
          <div *ngIf="concept.data.dictionary == dictionary && !filtered(dictionary, concept.data.id)">

            <mat-accordion>
              <mat-expansion-panel #collapsibleProgrammatically id="concept_card_{{concept.data.id}}"
                (opened)="collapsibleOpened(concept.data.id)" (closed)="collapsibleClosed(concept.data.id)">
              <mat-expansion-panel-header>
                <mat-panel-title style="padding:8px">
                  <div class="header_wordtype">
                    <p class="header_wordtype_p">{{currentResourceMaps.wordTypeResources.get(concept.data.lemmas[0].data.word_type)}}</p>
                  </div>
                  <div class="lang-icon"><img [src]="sourceFlag" /> </div>
                    <div class="header_synonyms header_synonym_{{hi}}" *ngFor="let lemmaObj of concept.data.lemmas; let hi = index">
                        <p *ngIf="(hi==0) && concept.data.lemmas.length > 1" class="header_synonym_p_first header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
                        <p *ngIf="(hi != 0) && (hi<concept.data.lemmas.length-1)" class="header_synonym_p_middle header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
                        <p *ngIf="hi==concept.data.lemmas.length-1" class="header_synonym_p_last header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
                    </div>
                    <div *ngIf="concept.data.lemmas.length > 1" class="header_synonyms_end_bracket">)</div>
                    <br>
                    <div class="lang-icon"><img [src]="targetFlag" /> </div>
                    <div class="header_translations header_translation_{{hi}}" *ngFor="let transObj of translations[cindex].data.lemmas; let hi = index">
                        <p *ngIf="(hi==0) && translations[cindex].data.lemmas.length > 1" class="header_translation_p_first header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                        <p *ngIf="(hi != 0) && (hi<translations[cindex].data.lemmas.length-1)" class="header_translation_p_middle header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                        <p *ngIf="hi==translations[cindex].data.lemmas.length-1" class="header_translation_p_last header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                    </div>
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="translations">
                <div class="best-translations" *ngFor="let lemmaObj of getTranslation(concept.data.dictionary, concept.data.id).data.lemmas">
                  <div *ngIf="lemmaObj.data.important_translation">
                    <div class="best-translation">
                      {{parseAttributes(lemmaObj)}}
                      <i class="info-icon material-icons" (click)="showContextMenu($event, targetLanguage, concept.data.id, concept.data.concept_attributes, concept.data.dictionary, lemmaObj.data)"  style="cursor:pointer">info</i>
                      {{lemmaObj.data.lemma}}
                    </div>
                  </div>
                </div>
              </div>

              <thesaurus id="thesaurus_{{concept.data.id}}" [sourceLanguage]="sourceLanguage" [targetLanguage]="targetLanguage" [conceptid]="concept.data.id" [dictionary]="concept.data.dictionary" [contextMenu]="contextMenu"></thesaurus>
              <button mat-raised-button color="primary" (click)="closeCollapsible(collapsibleProgrammatically, concept.data.id)">Close</button>
            </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

    </div>


    <div *ngFor="let concept of referenceConcepts; let cindex = index">
        <div *ngIf="concept.data.dictionary == dictionary && !filtered(dictionary, concept.data.id)">

          <mat-accordion>
            <mat-expansion-panel #collapsibleProgrammatically id="concept_card_{{concept.data.id}}"
              (opened)="collapsibleOpened(concept.data.id)" (closed)="collapsibleClosed(concept.data.id)">
            <mat-expansion-panel-header>
              <mat-panel-title style="padding:8px">
                <div class="header_wordtype">
                  <p class="header_wordtype_p">{{currentResourceMaps.wordTypeResources.get(concept.data.lemmas[0].data.word_type)}}</p>
                </div>
                <div class="lang-icon"><img [src]="sourceFlag" /></div>
                <div class="header_synonyms header_synonym_{{hi}}" *ngFor="let lemmaObj of concept.data.lemmas; let hi = index">
                    <p *ngIf="(hi==0) && concept.data.lemmas.length > 1" class="header_synonym_p_first header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
                    <p *ngIf="(hi != 0) && (hi<concept.data.lemmas.length-1)" class="header_synonym_p_middle header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
                    <p *ngIf="hi==concept.data.lemmas.length-1" class="header_synonym_p_last header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
                </div>
                <div *ngIf="concept.data.lemmas.length > 1" class="header_synonyms_end_bracket">)</div>
                <br>
                <div class="lang-icon"><img [src]="targetFlag" /></div>
                <div class="header_translations header_translation_{{hi}}" *ngFor="let transObj of referenceTranslations[cindex].data.lemmas; let hi = index">
                    <p *ngIf="(hi==0) && referenceTranslations[cindex].data.lemmas.length > 1" class="header_translation_p_first header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                    <p *ngIf="(hi != 0) && (hi<referenceTranslations[cindex].data.lemmas.length-1)" class="header_translation_p_middle header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                    <p *ngIf="hi==referenceTranslations[cindex].data.lemmas.length-1" class="header_translation_p_last header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="translations">
              <div class="best-translations" *ngFor="let lemmaObj of getTranslation(concept.data.dictionary, concept.data.id).data.lemmas">
                <div *ngIf="lemmaObj.data.important_translation">
                  <div class="best-translation">
                    {{parseAttributes(lemmaObj)}}
                   <i class="info-icon material-icons" (click)="showContextMenu($event, targetLanguage, concept.data.id, concept.data.concept_attributes, concept.data.dictionary, lemmaObj.data)"  style="cursor:pointer">info</i>
                    {{lemmaObj.data.lemma}}
                  </div>
                </div>
              </div>
            </div>

            <thesaurus id="thesaurus_{{concept.data.id}}" [sourceLanguage]="sourceLanguage" [targetLanguage]="targetLanguage" [conceptid]="concept.data.id" [dictionary]="concept.data.dictionary" [contextMenu]="contextMenu"></thesaurus>
            <button mat-raised-button color="primary" (click)="closeCollapsible(collapsibleProgrammatically, concept.data.id)">Close</button>
          </mat-expansion-panel>
          </mat-accordion>

        </div>
    </div>
</div>
