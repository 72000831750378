




<mat-progress-bar  *ngIf="searching" mode="indeterminate"></mat-progress-bar>

<div class="synonyme-table">
  <mat-form-field id="select-synonym">
    <mat-label>Select a word and check this dropdown</mat-label>
    <mat-select [(value)]="selectedWord" ([ngModel])="selectedWord" [formControl]="conceptControl" (selectionChange)="selectionChanged($event.value)">
      <mat-option [value]="-1">Show all</mat-option>
      <mat-option *ngFor="let conjugatedConcept of this.conjugatedTranslations let concept = index" [value]="concept" >
        <div class="header_synonyms_select header_synonym_{{hi}}" *ngFor="let synonym of conjugatedConcept.synonyms; let hi = index">
          <p *ngIf="(hi==0) && conjugatedConcept.synonyms.length > 1" class="header_synonym_p_first header_synonym_p_{{hi}}"><b>{{synonym}}</b></p>
          <p *ngIf="(hi != 0) && (hi < conjugatedConcept.synonyms.length-1)" class="header_synonym_p_middle header_synonym_p_{{hi}}">{{synonym}}</p>
          <p *ngIf="hi==conjugatedConcept.synonyms.length-1" class="header_synonym_p_last header_synonym_p_{{hi}}">{{synonym}}</p>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
<table class="table synonym_table">
  <thead>
    <tr>
      <th scope="col">Suggestions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let conjugatedConcept of this.conjugatedTranslations let concept = index" id="{{concept}}" class="conjugated-translation-{{translationsEnabled[concept]}}" >
      <td class="synonym_table_column synonym_table_source_column">
        <div class="header_synonyms_table header_synonym_{{hi}}" *ngFor="let synonym of conjugatedConcept.synonyms; let hi = index">
          <p *ngIf="(hi==0) && conjugatedConcept.synonyms.length > 1" class="header_synonym_p_first header_synonym_p_{{hi}}"><b>{{synonym}}</b></p>
          <p *ngIf="(hi != 0) && (hi < conjugatedConcept.synonyms.length-1)" class="header_synonym_p_middle header_synonym_p_{{hi}}">{{synonym}}</p>
          <p *ngIf="hi==conjugatedConcept.synonyms.length-1" class="header_synonym_p_last header_synonym_p_{{hi}}">{{synonym}}</p>
        </div>
      </td>
      <td class="synonym_table_column synonym_table_target_column">
        <div class="header_translations_table header_translation_{{hi}}" *ngFor="let translation of conjugatedConcept.translations; let hi = index">
          <p *ngIf="hi%3==0" class="header_translation_c_0 header_translation_p_{{hi}}">{{translation}}</p>
        </div>
      </td>
      <td class="synonym_table_column synonym_table_target_column">
        <div class="header_translations_table header_translation_{{hi}}" *ngFor="let translation of conjugatedConcept.translations; let hi = index">
          <p *ngIf="hi%3==1" class="header_translation_c_1 header_translation_p_{{hi}}">{{translation}}</p>
        </div>
      </td>
      <td class="synonym_table_column synonym_table_target_column">
        <div class="header_translations_table header_translation_{{hi}}" *ngFor="let translation of conjugatedConcept.translations; let hi = index">
          <p *ngIf="hi%3==2" class="header_translation_c_2 header_translation_p_{{hi}}">{{translation}}</p>
        </div>
      </td>
    </tr>
  </tbody>
</table>

</div>
  <!--<mat-form-field>
    <mat-label>Select a word and check this dropdown</mat-label>
    <mat-select [(value)]="selectedWord" [formControl]="conceptControl" (selectionChange)="selectionChanged($event.value)">
      <mat-option></mat-option>
      <mat-optgroup *ngFor="let concept of this.conceptGroup" [label]="concept.data.lemma"> {{synoniemen}}
        <mat-option *ngFor="let lemma of concept.data.lemmas" [value]="lemma.data.lemma">
          {{lemma.data.lemma}}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field> -->
