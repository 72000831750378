import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';

@Injectable()
export class ClipboardService {
    private dom: Document;

    //initialize clipboard service. This service is tightly coupled to the browser DOM. By injecting the "document" reference rather than trying to reference it
    // globally, it looks like you are trying to lower the tight coupling.
    constructor ( @Inject(DOCUMENT) dom: Document){

        this.dom = dom;
    }

    public copy(value: string){
        
        var promise = new Promise( 
            (resolve,reject): void => {
                var textarea = null;

                try {
                    textarea = this.dom.createElement("textarea");
                    textarea.style.height = "0px";
                    textarea.style.left = "-100px";
                    textarea.style.opacity = "0";
                    textarea.style.position = "fixed";
                    textarea.style.top = "0px";
                    this.dom.body.appendChild(textarea);

                    textarea.value = value;
                    textarea.select();

                    this.dom.execCommand("copy");

                    resolve(value);
                } finally {
                    if (textarea && textarea.parentNode){
                        textarea.parentNode.removeChild(textarea)
                    }
                }

            }

        );

        return (promise);
    }


}
