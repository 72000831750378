import { Injectable } from '@angular/core';
import {JwtDecoderService} from '../services/jwtdecoder.service';

@Injectable({
  providedIn: 'root'
})
export class UserStatusService {
  private tokenChangedSubscription;

  private allowedDictionaries: string;
  private mtBalance: number;

  constructor(private jwtDecoder: JwtDecoderService) {
    this.tokenChangedSubscription = this.jwtDecoder.tokenChangedEmitter.subscribe({
      next: () => {
        this.tokenChanged()
      }
    })
    this.tokenChanged()
  }

  tokenChanged() {
    this.allowedDictionaries = this.jwtDecoder.getDecodedAuthTokenField('ego_dictionaries_allowed');
    this.mtBalance = this.jwtDecoder.getDecodedAuthTokenField('deepl_credits');
  }

  getDictionaryAllowed(dictionaryName : string) : boolean {
    return this.allowedDictionaries.includes(dictionaryName);
  }

  getDictionariesAllowed() : string {
    return this.allowedDictionaries;
  }

  getMTBalance() {
    return this.mtBalance;
  }

  getFreeUser(){
    var chemicdb = this.getDictionaryAllowed("chemicdb");
    var legaldb = this.getDictionaryAllowed("legaldb");
    var technidb = this.getDictionaryAllowed("technidb");
    var medicdb = this.getDictionaryAllowed("medicdb");
    var tradedb = this.getDictionaryAllowed("tradedb");
    var proverbdb = this.getDictionaryAllowed("proverbdb");
    var computdb = this.getDictionaryAllowed("computdb");

    if((chemicdb) && (legaldb) && (technidb) && (medicdb) && (tradedb) && (proverbdb) && (computdb)){
      return false;
    }
    return true;
  }
}
