<form [formGroup]="form">
<mat-dialog-content>
    <div id="wrapper">

        <div style="display: flex; flex-direction: column; align-items: left;">
            <mat-form-field>
                <input formControlName="name" matInput placeholder="Enter the name">
    
            </mat-form-field>
         
        </div>

    </div>



   

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="btn red darken-1 white-text" >Cancel</button>
    <button mat-button [mat-dialog-close]="true" class="btn blue darken-1 white-text"  (click)="createTerminology(name.value)" cdkFocusInitial>Save</button>
</mat-dialog-actions>
</form>