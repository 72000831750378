/*import XRegExp from 'xregexp';

const IsAlphaNumeric = ch => {
  return ch.match(/^[a-z0-9]+$/iu) !== null;
}

const IsAlphaNumericDiacritic = ch => {
  return XRegExp.match(ch, XRegExp('[\\p{Latin}0-9]'), 'one') !== null;
}*/

/*interface TextElement {
  contents: string;
  position: number;
  highlightable: boolean;
}*/

interface TranslatorSectionNode {
  word : string,
  alts : string,
  col : number,
}

export function filterDeeplData(data) {
  let sourceData = [];
  let targetData = [];

  for(let sentence of data.sentences) {
    let pos = 0;
    let s = new Array<TranslatorSectionNode>();
    for(let marker of sentence.source_markers) {
      if(marker.begin > pos) {
        //Characters between two markers
        let ele : TranslatorSectionNode = {
          col : -1,
          word : sentence.source_sentence.substr(pos, marker.begin - pos),
          alts : ""
        }
        s.push(ele);
      }
      //The word of a marker
      let ele : TranslatorSectionNode = {
        col : marker.alignment,
        word : sentence.source_sentence.substr(marker.begin, marker.end - marker.begin),
        alts : ""
      }
      s.push(ele);
      pos = marker.end;
    }
    if(pos < sentence.source_sentence.length) {
      //End of sentence
      let ele : TranslatorSectionNode = {
        col : -1,
        word : sentence.source_sentence.substr(pos),
        alts : ""
      }
      s.push(ele);
    }

    pos = 0;
    let t = new Array<TranslatorSectionNode>();
    for(let marker of sentence.target_markers) {
      if(marker.begin > pos) {
        //Characters between two markers
        let ele : TranslatorSectionNode = {
          col : -1,
          word : sentence.target_sentence.substr(pos, marker.begin - pos),
          alts : ""
        }
        t.push(ele);
      }
      //The word of a marker
      let ele : TranslatorSectionNode = {
        col : marker.alignment,
        word : sentence.target_sentence.substr(marker.begin, marker.end - marker.begin),
        alts : ""
      }
      t.push(ele);
      pos = marker.end;
    }
    if(pos < sentence.target_sentence.length) {
      //End of sentence
      let ele : TranslatorSectionNode = {
        col : -1,
        word : sentence.target_sentence.substr(pos),
        alts : ""
      }
      t.push(ele);
    }

    sourceData.push(s);
    targetData.push(t);
  }


  return { sourceData, targetData };
}



/*export function deeplSplit(text : string): TextElement[] {
  let textElements = new Array<TextElement>();

  let textPos = 0;
  let charSearch = IsAlphaNumericDiacritic(text[0]);
  for(let i = 1; i < text.length; i++) {
    if(IsAlphaNumericDiacritic(text[i]) != charSearch) {
      let textElement = {
        contents: text.slice(textPos, i),
        position : textPos,
        highlightable: charSearch
      }
      textElements.push(textElement);
      textPos = i;
      charSearch = !charSearch;
    }
  }

  //Last element
  let textElement = {
    contents: text.slice(textPos, text.length),
    position: textPos,
    highlightable: charSearch
  }
  textElements.push(textElement);

  return textElements;
}*/
