<app-navbar></app-navbar>
<mat-drawer-container class="example-container">

    <mat-drawer mode="side" opened fxHide.sm  >
        
      <left-navbar></left-navbar>  
    </mat-drawer>
    <mat-drawer-content>
        <div class="translation-area">
            <div class="container-fluid">
                <div class="white-space"></div>
                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">
                        <mat-tab-group class="tabgroup">
                           <!-- <mat-tab label="Product updates"> 
                              <mat-card class="productupdate-card" *ngFor="let productupdate of productupdates">
                                <mat-card-title>{{productupdate.title}}</mat-card-title>
                                <div class="date">{{productupdate.date}}</div>
          
                                <mat-card-content class="productupdate-description">
                                </mat-card-content >
                                {{productupdate.description}}
                              </mat-card>
          
                            </mat-tab> -->
                            <mat-tab label="Videos">
                              <mat-card class="video-card" *ngFor="let video of videos">
                                  <mat-card-title>{{video.title}}</mat-card-title>
                                  <mat-card-content>
                                    <div class="file">
                                       <a class="open-video" (click)="openModal()"><img src="{{video.thumbnail}}" width="150"></a>
                                    </div>
                                    <div class="description">
                                        <p>
                                            {{video.description}}
                                        </p>
                                    </div>
                                     
                                    </mat-card-content>
          
                              </mat-card>
          
                            
                            </mat-tab>
                            <mat-tab label="FAQ"> 
                                <mat-accordion >
                                    <mat-expansion-panel class="faq-accordion">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title>
                                          Why I cannot play all sounds?
                                        </mat-panel-title>
                                       
                                      </mat-expansion-panel-header>
                                         <p>80.000 entries are spoken and recorded by native speakers. For all other entries, we used the Speech Recognitions Web API. Only Google Chrome and Firefox support the Speech Recognition Web API. It is not supported for IE or MS Edge. In order to hear all sounds, please use this app in Chrome or Firefox</p>
                                     
                                    </mat-expansion-panel>
                                    
                                  </mat-accordion>
          
                            </mat-tab>
                          </mat-tab-group>
                    </div>
                    <div class="col-sm-3"></div>
          
          
                   
                </div>
          
            </div>
          
          
          </div>
          
    </mat-drawer-content>

</mat-drawer-container>


