import { Component, /*OnChanges, SimpleChanges,*/ Input, ViewChildren, ViewChild, ChangeDetectorRef, QueryList, Injector } from '@angular/core';
import { BasePanel } from '../base-panel';
import { ThesaurusComponent } from '../thesaurus/thesaurus.component';
import { MenuContextComponent } from '../../menu-context/menu-context.component';
import {MatExpansionPanel} from '@angular/material/expansion';

//import * as $ from 'jquery';



const dictionary: string = "maindb"

@Component({
  selector: 'overview-panel',
  templateUrl: './overview-panel.component.html',
  styleUrls: ['./overview-panel.component.css']
})

export class OverviewPanelComponent extends BasePanel {

  @ViewChildren(ThesaurusComponent) thesaurusChildren: QueryList<ThesaurusComponent>;
  @ViewChild(MenuContextComponent) contextMenu: MenuContextComponent;
  @ViewChildren('collapsibleProgrammatically') collapsibleChildren: QueryList<MatExpansionPanel>;
  @ViewChild(MatExpansionPanel, {static: true}) matExpansionPanelElement: MatExpansionPanel;



  @Input() concepts: any[];
  @Input() translations: any[];
  //@Input() searching: boolean;
  evokeUpdateGrammar;
  evokeShowGrammar;


  constructor(cd: ChangeDetectorRef, injector: Injector) {
    //console.log("Initializing overview panel (" + dictionary + ")")
    super(cd, injector, dictionary);
   }

   ngOnInit() {
    super.ngOnInit();

  }



  //get translation of a concept
  getTranslation(dictionary, id, transgroups){

    for(var i=0; this.translations.length; i++){
      var translation = this.translations[i]
      //console.log("T.data.id " + t.data.id + " id " + id)
      if((translation.data.id == id ) && (translation.data.dictionary == dictionary) && (translation.data.trans_groups == transgroups)){
        return translation
      }
    }

    return null
  }

}
