    <mat-dialog-content>
        <div class="form-container">
            <input type="text" matInput placeholder="word" [(ngModel)]="lemmaDialogData.word.word" [value]="lemmaDialogData.word.word" class="form-control">
          
            <mat-select [(ngModel)]="lemmaWordType">
                <mat-option *ngFor="let wordtype of wordtypes; let i = index" [value]="i">{{wordtype}}</mat-option>
            </mat-select>

            <mat-select [(ngModel)]="lemmaDialogData.word.gender">
                <mat-option *ngFor="let gender of genders; let i = index" [value]="i">{{gender}}</mat-option>
            </mat-select>
                        
            <input type="text" matInput placeholder="attributes" [(ngModel)]="lemmaDialogData.word.attributes" [value]="lemmaDialogData.word.attributes" class="form-control">
            
            <input type="text" matInput placeholder="triggers" [(ngModel)]="lemmaDialogData.word.triggers" [value]="lemmaDialogData.word.triggers" class="form-control">

        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
          <button type= "submit" mat-raised-button (click)="onCloseConfirm()">CONFIRM</button>
          <button mat-raised-button (click)="onCloseCancel()">CANCEL</button>
      </mat-dialog-actions>

