
import { Component,ChangeDetectorRef, Injector } from '@angular/core';
import { SupplementPanelComponent } from '../supplement-panel/supplement-panel.component';

const dictionary: string = "tradedb"

@Component({
  selector: 'financial-panel',
  templateUrl: '../supplement-panel/supplement-panel.component.html',
  styleUrls: ['../supplement-panel/supplement-panel.component.css']
  //templateUrl: './financial-panel.component.html',
  //styleUrls: ['./financial-panel.component.css']
})

export class FinancialPanelComponent extends SupplementPanelComponent {

  constructor(cd:ChangeDetectorRef, injector: Injector) { 

    super(cd, injector, dictionary);

  }

}
