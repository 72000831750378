import { Component, OnInit, Input } from '@angular/core';
import { SpeechSyntesisService } from '../../services/speech-syntesis.service';

@Component({
  selector: 'synthetic-sound',
  templateUrl: './synthetic-sound.component.html',
  styleUrls: ['./synthetic-sound.component.css']
})
export class SyntheticSoundComponent implements OnInit {
  @Input() lemma: string;
  @Input() language: string;


  constructor(private speechSynthesis: SpeechSyntesisService) { }

  ngOnInit() {
  }

  onClick(){
    this.speechSynthesis.sayIt(this.lemma, this.language);
  }
}
