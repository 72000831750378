import { Mark, Fragment, NodeType } from "prosemirror-model";


export const NEWLINE : string = "\n\r";

export type AttrsType = any;
export const emptyAttrs = Object.create(null);
export type MarkType = Mark[];

export interface IJSONDocNode {
    type: NodeType;
    content?: Fragment;
    text?: string;
    attrs?: AttrsType;
    marks?: MarkType;
    copy(content?);
    sameMarkup(other : IJSONDocNode) : boolean;
    merge(next : IJSONDocNode) : boolean;
    toJSON() : IJSONDocJSON;
}

export class JSONDocContent extends Array<IJSONDocJSON> {
  hasContent() : boolean {
    for(let n of this) {
      if(n.hasContent())
        return true;
    }
    return false;
  }
}

export interface IJSONDocJSON {
  type: string;
  content?: JSONDocContent;
  text?: string;
  attrs?: AttrsType;
  marks?: MarkType;

  copy(content?);
  //setReadOnly(flag : boolean) : void;
  //setSource(flag : boolean) : void;
  sameMarkup(other : IJSONDocJSON) : boolean;
  merge(next : IJSONDocJSON) : boolean;
  hasContent() : boolean;
}

export function compareDeep(a, b) {
  if (a === b) return true
  if (!(a && typeof a == "object") ||
      !(b && typeof b == "object")) return false
  let array = Array.isArray(a)
  if (Array.isArray(b) != array) return false
  if (array) {
    if (a.length != b.length) return false
    for (let i = 0; i < a.length; i++) if (!compareDeep(a[i], b[i])) return false
  } else {
    for (let p in a) if (!(p in b) || !compareDeep(a[p], b[p])) return false
    for (let p in b) if (!(p in a)) return false
  }
  return true
}
