<div class="row">
    <div class="col">
        <mat-toolbar>
            <span class="logo"><img class="m-logo" src="/assets/img/multiglot.png" width="70px" height="70px"><b>MULTI</b>GLOT</span>
        </mat-toolbar>
    </div>
</div>
<div class="row">
    <div class="col">
       
     <img class= "translation-img" src="assets/img/frontimage.jpg" width="800" height="500">

    </div>
    <div class="col">
      <div class="row">
        <div class="sign-in-box">

            <h3>Get started!</h3>
            <p>Create your account</p>

            <form class="sign-in-box">
                <mat-form-field class="sign-in-field" appearance="outline">
                    <input matInput placeholder="Email" [(ngModel)]="email" name="email">
                </mat-form-field>
            
                <mat-form-field class="sign-in-field" appearance="outline">
                    <input type="text" matInput placeholder="User name" [(ngModel)]="username" name="username">
                </mat-form-field>
                <!--<div class="sign-in-field">
                    <div class="login-google-box">
                        <a class="google-login" (click)="registerWithGoogle()"><img src="https://img.icons8.com/color/48/000000/google-logo.png" height="25px" width="25px"> Register with Google</a>
                    </div>
 
               </div>-->
                <div class="sign-in-field">
                    <button (click)="onRegisterSubmit()" class="sign-in-button" mat-stroked-button color="primary">Register</button>
                </div>
              
            </form>
        </div>
        <div class="sign-up-notification">
            <h3>I already have an account</h3>
            <p><a [routerLink]="['/login']">Log in to your account</a></p>
        </div>
      </div>
    </div>

</div>

