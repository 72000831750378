<context-menu [useBootstrap4]="true">

  <ng-template contextMenuItem *ngIf="hasGrammar" >
    <grammar [conceptid]="conceptid" [focus]="focus" [language]="language" [dictionary]="dictionary"></grammar>
  </ng-template>
  <ng-template contextMenuItem *ngIf="hasPronounce" >
      <souffleur [link]="souffleurlink" [lemma]="lemma"></souffleur>
  </ng-template>
  <ng-template contextMenuItem *ngIf="!hasPronounce" >
    <synthetic-sound [lemma]="lemma" [language]="language"></synthetic-sound>
  </ng-template>
  <ng-template contextMenuItem *ngIf="hasCopyToClipboard" >
    <copy-clipboard [lemma]="lemma"></copy-clipboard>
  </ng-template>
  <ng-template contextMenuItem *ngIf="hasFindInDictionary" >
    <dictionary-search [lemma]="lemma" [language]="language"></dictionary-search>
  </ng-template>
  <!--<ng-template contextMenuItem *ngIf="hasFindInTranslationMemory" >
    <translation-memory-search [lemma]="lemma" [language]="language"></translation-memory-search>
  </ng-template> -->
  <ng-template contextMenuItem divider="true"></ng-template>

  <ng-template contextMenuItem >
      <attributes [attributeData]="attributeList"></attributes>
  </ng-template>

</context-menu>
