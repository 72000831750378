<app-navbar></app-navbar>
<mat-drawer-container class="example-container">
  <mat-drawer mode="side" opened fxHide.sm  >
    <left-navbar></left-navbar>
  </mat-drawer>
  <mat-drawer-content>
    <div id="wrapper">
      <div class="row">
        <div class="col-sm-1">
          
        </div>
    
        <div class="col-sm-10">
    
          <table mat-table [dataSource]="dictionary_data" class="mat-elevation-z8">
    
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
    
      <!-- Amount Column -->
      <ng-container matColumnDef="concepts">
        <th mat-header-cell *matHeaderCellDef> Amount of Terms </th>
        <td mat-cell *matCellDef="let element"> {{element.concepts}} </td>
      </ng-container>
    
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
              <a class="btn blue darken-1 white-text"  [routerLink]="['/concepts/', element.name]">Edit </a>
            </td>
        </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    
    
        </div>
        <div class="col-sm-1">
          
          </div>
        </div>
    </div>

  </mat-drawer-content>

</mat-drawer-container>





