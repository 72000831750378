import { EventEmitter } from '@angular/core';
import { Schema, Node as ProsemirrorNode, NodeSpec, MarkSpec } from 'prosemirror-model';
import {nodes as basicNodes, marks as basicMarks} from 'ngx-editor';

export const colMarkType = "col";

const colMark : MarkSpec = {
  attrs: {
    spanClass: { default: ''},
    sentence: { default: -1 },
    section: { default: -1 },
    col: { default: -1 },
    alt_translations: { default: false },
  },
  parseDOM: [
    {
      tag: 'span',
      getAttrs: (dom: HTMLElement) => {
        return {
          spanClass: dom.getAttribute('class') || '',
          sentence: dom.getAttribute('sentence') || -1,
          section: dom.getAttribute('section') || -1,
          col: dom.getAttribute('col') || -1,
          alt_translations: dom.getAttribute('alt_translations') || false
        };
      }
    }
  ],
  toDOM(node: ProsemirrorNode) {
    return ['span', {
      'class': node.attrs.spanClass,
      'col': node.attrs.col,
      'sentence': node.attrs.sentence,
      'section': node.attrs.section,
      'alt_translations': node.attrs.alt_translations
    }, 0];
  }
};

const codeBlock: NodeSpec = {
  group: 'block',
  attrs: {
    text: { default: '' },
    language: { default: 'text/javascript' }
  },
  parseDOM: [{
    tag: 'pre',
    getAttrs: (dom: HTMLElement) => {
      return {
        text: dom.textContent,
        language: dom.getAttribute('data-language') || 'text/plain'
      };
    }
  }
  ],
  toDOM(node: ProsemirrorNode) {
    return ['pre', { 'data-language': node.attrs.language }, node.attrs.text];
  }
};

export interface DocNodeAttrs {
  divClass?: string,
  disabled?: boolean
  source?: boolean
}

const docNode : NodeSpec = {
  attrs: {
    divClass: { default: 'jsonDoc' },
    disabled: { default: false},
    source: { default: true },
  },
  content: "block+",
  marks: "",
  parseDOM: [
    {
      tag:  'div',
      getAttrs: (dom: HTMLElement) => {
        return {
          disabled: dom.getAttribute('disabled') || false,
          source: dom.getAttribute('source') || true,
        };
      }
    }
  ],
  toDOM(node: ProsemirrorNode) {
    return ['div', {
      'disabled' : node.attrs.disabled,
      'source' : node.attrs.source,
    }, 0];
  }
}

basicNodes.doc = docNode;
const nodes = Object.assign(
    {},
    basicNodes,
    { code_mirror: codeBlock },
);

const marks = Object.assign(
    {},
    basicMarks,
    { col: colMark },
);

const schema = new Schema({
    nodes,
    marks:marks,
});

export default schema;
