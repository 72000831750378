<div class="wrapper">
 <div class="row">
     <div class="container">
         <h2 class="status-header">Status Page</h2>

        <mat-card class="allservices-container" *ngIf="isRunningAuth && isRunningDicts && isRunningMt">
            <p>All systems are operational</p>
        </mat-card>

        <mat-card class="available-container" *ngIf="isRunningAuth">
            {{AuthStatusText}}
        </mat-card>
        <mat-card class="unavailable-container" *ngIf="!isRunningAuth">
            {{AuthStatusText}}
        </mat-card>
        <mat-card class="available-container" *ngIf="isRunningDicts">
            {{DictStatusText}}
        </mat-card>
        <mat-card class="unavailable-container" *ngIf="!isRunningDicts">
            {{DictStatusText}}
        </mat-card>
        <mat-card class="available-container" *ngIf="isRunningMt">
            {{MlProxyStatusText}}
        </mat-card>
        <mat-card class="unavailable-container" *ngIf="!isRunningMt">
            {{MlProxyStatusText}}
        </mat-card>

        <mat-card class="status-information" *ngIf="!isRunningAuth && !isRunningDicts && !isRunningMt">
            <p>If all services appear to be offline, it could be that your firewall is blocking our services. In this case, please contact us.</p>
        </mat-card>

    </div>
 </div>


</div>