import { Injectable, EventEmitter } from '@angular/core';
import { environment, varEnvironment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { Observable } from 'rxjs';
import {Merger2Service, DictionaryInfosEvent} from './merger2.service';
import { race } from 'rxjs/internal/observable/race';

export interface ConceptImportBody {
  concept_id: number,
  word_type: string,
  concept_attributes: Array<string>,
  translations: Array<WordList>
}

export interface ConceptLemmaImportBody {
  word: string,
  variant: number,
  focus: number,
  gender: string;
  word_type: string;
  attributes: string[];
  triggers: string[];
}


export interface WordList {
  language: string,
  words: Array<Word>
}

export interface Word {
  word: string,
  variant: number,
  word_type: number,
  gender: number,
  attributes: Array<string>
}

export class ImporterConnectedEvent {
  data : string;
  constructor(data : string) {
    this.data = data
  }
}

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  requestOptions: {} = {withAuth: true};
  private dictionaryInfosEventSubscription;
  connectedEmitter : EventEmitter<ImporterConnectedEvent> = new EventEmitter();
  dictName: string;
  apiURL: string;

  constructor(private merger2Service: Merger2Service, private http:HttpService) { 
    this.dictionaryInfosEventSubscription = merger2Service.dictionaryInfosEmitter.subscribe({
			next: (event: DictionaryInfosEvent) => {
					this.handleDictionaryInfosEvent(event);
			}
    })
  }

  connect(dictName: string){
    this.dictName = dictName;
    let port = this.merger2Service.getImportPort(this.dictName);
    if(port!=0){
      this.setApiURL(port);
    }
  }
  handleDictionaryInfosEvent(event){
    let port = this.merger2Service.getImportPort(this.dictName);
    if(port!=0){
      this.setApiURL(port);
    }
  }
  setApiURL(port: number) {
    this.apiURL = varEnvironment.schema + '://' + environment.API_IP + ":"+ port + environment.ENDPOINT_IMPORT + "/" + this.dictName
    console.log("URL" + this.apiURL)
    var myEvent = new ImporterConnectedEvent(this.apiURL);
    this.connectedEmitter.emit(myEvent)
  }

  updateConcept(/*conceptId: number,*/ body:ConceptImportBody):Observable<JSON>{
    if(this.apiURL == undefined){
      return null;
    }
    console.log("BODY" + JSON.stringify(body))
    let URL = this.apiURL + environment.ENDPOINT_UPDATE_CONCEPT + "/" ;//+ conceptId + "/?" + environment.IMPORT_ARGS;
    console.log("URL" + URL)
    return this.http.patch(URL, body, this.requestOptions)
  }

  updateConceptLemma(language: string, conceptid: number, body: ConceptLemmaImportBody): Observable<JSON>{
    if(this.apiURL == undefined){
      return null;
    }
    console.log("BODY" + JSON.stringify(body));
    let URL = this.apiURL + environment.ENDPOINT_UPDATE_WORD + "/" + language + "/" + conceptid + "/";
    console.log("URL" + URL);
    return this.http.patch(URL, body, this.requestOptions);
  }

  newConcept(body:ConceptImportBody):Observable<JSON> {
    if(this.apiURL == undefined){
      return null;
    }
    console.log("BODY" + JSON.stringify(body));
    let URL = this.apiURL + environment.ENDPOINT_CONCEPT + "/";
    console.log("URL" + URL);
    return this.http.post(URL, body, this.requestOptions);
  }

  newConceptLemma(language: string, conceptid: number, body: ConceptLemmaImportBody): Observable<JSON>{
    if(this.apiURL == undefined){
      return null;
    }
    console.log("BODY" + JSON.stringify(body));
    let URL = this.apiURL + environment.ENDPOINT_WORD + "/" + language + "/" + conceptid + "/";
    console.log("URL" + URL);
    return this.http.post(URL, body, this.requestOptions);
  }


  deleteConceptLemma(language: string, conceptid: number, focus: number): Observable<JSON>{
    if(this.apiURL == undefined){
      return null;
    }
    let URL = this.apiURL + environment.ENDPOINT_DELETE_WORD + "/" + language + "/" + conceptid + "/" + focus +"/";
    console.log("URL" + URL);
    return this.http.get(URL, this.requestOptions);
  }

  deleteConcept(conceptid:number): Observable<JSON>{
    if(this.apiURL == undefined){
      return null;
    }
    let URL = this.apiURL + environment.ENDPOINT_DELETE_CONCEPT + "/" + conceptid + "/";
    console.log("URL" + URL);
    return this.http.get(URL, this.requestOptions);
  }


}




