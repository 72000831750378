import { Component, OnInit } from '@angular/core';
import {AuthServiceEuroglot, LoggedInEvent} from '../../services/auth.service';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
  selector: 'left-navbar',
  templateUrl: './left-navbar.component.html',
  styleUrls: ['./left-navbar.component.css']
})
export class LeftNavbarComponent implements OnInit {
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);

  loggedIn : boolean =  false;

  private loggedInSubscription;
  private refreshedSubscription;

  constructor(
    private authService:AuthServiceEuroglot,
    private router:Router
  ) {
      this.loggedInSubscription = authService.loggedInEmitter.subscribe({
        next: (event: LoggedInEvent) => {
            this.handleLogin(event)
        }
      })
      this.refreshedSubscription = authService.refreshedEmitter.subscribe({
        next: (event: LoggedInEvent) => {
            this.handleRefresh(event)
        }
      })

      this.loggedIn = authService.checkLogin()
    }

  ngOnInit() {
  }

  handleLogin(event : LoggedInEvent) {
    this.loggedIn = event.loggedIn
  }

  handleRefresh(event : LoggedInEvent) {
    this.loggedIn = event.loggedIn
  }

}
