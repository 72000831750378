import { Injectable } from '@angular/core';
import {Http, Headers} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../environments/environment'
import 'rxjs/add/operator/map';

@Injectable()
export class GrammarCheckerService {
  private LanguageURL = "https://languagetool.org/api/v2/languages";
  private PostTextURL = "https://languagetool.org/api/v2/check"
  private PostTextLangArgument ="language="
  private PostTextArgument = "text="
  private TextQuery: string;
  private language: string;
  private textLanguage: string;

  constructor(private http:Http) {

  }

  checkText(query){
    return this.http.get(this.PostTextURL + "?" + this.PostTextLangArgument + this.textLanguage + "&" + this.PostTextArgument + query).map(res => res.json());

  }

  getTextLanguage(textLang){
    this.textLanguage = textLang
    //console.log("TEXTLANGUAGE" + this.textLanguage)
    return this.textLanguage

  }

  getLanguages(){
    return this.http.get(this.LanguageURL).map(res => res.json());
  }

}
