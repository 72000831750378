import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {UpdateLemmaFieldsComponent} from '../update-lemma-fields/update-lemma-fields.component';
import {PostNewLemmaComponent} from '../post-new-lemma/post-new-lemma.component';
import {PostNewConceptComponent} from '../post-new-concept/post-new-concept.component';
import {MatDialog} from "@angular/material/dialog";
import { ResourceService, ResourceMapInterface } from '../../../shared-services/resource.service';
import {ConfigurationService} from '../../../services/configuration.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ExportServiceService, ExporterConnectedEvent} from '../../../services/dicts-services/export-service.service';
import {ImportService, ImporterConnectedEvent} from '../../../services/dicts-services/import.service';
import { UpdateConceptFieldsComponent } from '../update-concept-fields/update-concept-fields.component';


@Component({
  selector: 'app-edit-dict',
  templateUrl: './edit-dict.component.html',
  styleUrls: ['./edit-dict.component.css']
})
export class EditDictComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent:PageEvent;
  numberOfConcepts: number;
  concepts: any[];
  dictname: string;
  currentPageIndex: number;
  currentPageSize: number;
  displayedColumns: string[] = ['nl', 'de', 'en', 'fr', 'es', 'it', 'modified_by'];
  currentResourceMaps: ResourceMapInterface;
  private importerConnectedEventSubscription;

  private exporterConnectedEventSubscription;
  constructor(private dialog: MatDialog, private configurationService: ConfigurationService, private resourceService: ResourceService, private route: ActivatedRoute, private router: Router, private exportService: ExportServiceService, private importService: ImportService) {

    this.exporterConnectedEventSubscription = this.exportService.connectedEmitter.subscribe({
			next: (event: ExporterConnectedEvent) => {
					this.handleExporterConnectedEvent(event);
			}
    })
    this.importerConnectedEventSubscription = this.importService.connectedEmitter.subscribe({
			next: (event: ImporterConnectedEvent) => {
					this.handleImporterConnectedEvent(event);
			}
    })
  }


  ngOnInit() {
    //console.log("pag" + this.paginator)
    this.route.params.subscribe( params => this.setRouteParams(params));
    this.resourceService.currentResourceMaps.subscribe(currentResourceMap => this.currentResourceMaps = currentResourceMap);

  }
  ngAfterViewInit(){
    this.currentPageIndex = this.paginator.pageIndex;
    this.currentPageSize = 5; //this.paginator.pageSize;
    }

  changePage(event?:PageEvent){
    if(event == undefined) {
      return;
    }

    var fetch = false;
    if(event.pageIndex != this.currentPageIndex) {
      this.currentPageIndex = event.pageIndex;
      fetch = true;
    }

    if(event.pageSize != this.currentPageSize) {
      this.currentPageSize = event.pageSize;
      fetch = true;
    }

    if(fetch) {
      this.fetchConcepts();
    }

    //console.log("Event" + event);
    return event;
  }

  connect(){
    this.exportService.connect(this.dictname);
    this.importService.connect(this.dictname);
  }


  handleExporterConnectedEvent(event){
    this.exportService.getDictionaryInfo().subscribe(
      data =>  this.handleDictionaryInfo(data),
      error => this.handleDictionaryError(error),
      () => {}
    );
  }

  handleImporterConnectedEvent(event){

  }

  handleDictionaryInfo(data){
    this.numberOfConcepts = data.data.concepts;
    this.fetchConcepts();
  }

  handleDictionaryError(error){
    console.log(error);
  }

  fetchConcepts() {
    var first = this.currentPageIndex * this.currentPageSize + 1
    var last = first + this.currentPageSize -1;
    //console.log("first" + this.currentPageIndex + "last" + this.currentPageSize )

    if(last > this.numberOfConcepts) {
      last = this.numberOfConcepts;
    }
    this.exportService.getConcepts(first, last).subscribe(
      data =>  this.handleConcepts(data),
      error => this.handleConceptsError(error),
      () => {}
    );
  }

  handleConcepts(data){
    this.concepts = data.data.translations;
    this.fetchWordTypesandAttributes(this.concepts);
    //console.log("CONCEPTS" + JSON.stringify(this.concepts))
  }

  handleConceptsError(error){
    console.log(error);
  }

  setRouteParams(params) {
    //console.log("PARAMS: " + JSON.stringify(params))
     if (params['dictname']) {
       this.dictname = params['dictname'];
       this.connect();
     }

  }

  editConceptFields(conceptId: number){
    for(let concept of this.concepts){
      if(conceptId == concept.concept_id){
        let wordType = concept.word_type;
        let attributes = concept.concept_attributes;
        let dialogRef = this.dialog.open(UpdateConceptFieldsComponent, {
          width: '600px',
          data: {conceptId, wordType, attributes}
        })
        this.dialog.afterAllClosed.subscribe(
          data =>  this.handleAllDialogsClosed(data)
        );
      }
    }
  }

  editLemmaFields(conceptId: number, language: string, focus: number){
    for(let concept of this.concepts) {
      if(concept.concept_id == conceptId) {
        for(let wordGroup of concept.translation) {
          if(wordGroup.language == language) {
            for(let word of wordGroup.words) {
              if(word.focus == focus) {
                let conceptWordType = concept.word_type;
                let dialogRef = this.dialog.open(UpdateLemmaFieldsComponent, {
                  width: '600px',
                  data: {conceptId, conceptWordType, language, word}
                });
              }
              this.dialog.afterAllClosed.subscribe(
                data =>  this.handleAllDialogsClosed(data)
              );
            }
          }
        }
      }
    }
  }

  handleAllDialogsClosed(data) {
    //console.log("Update edit dict data");
    //this.router.onSameUrlNavigation.
    this.fetchConcepts();
  }

  postNewLemma(conceptId, language){
    for(let concept of this.concepts){
      if(conceptId == concept.concept_id){
        let conceptwordType = concept.word_type;

        let dialogRef = this.dialog.open(PostNewLemmaComponent, {
          width: '600px',
          data: {conceptId, conceptwordType, language}

        })
        this.dialog.afterAllClosed.subscribe(
          data =>  this.handleAllDialogsClosed(data)
        );
      }
    }
  }

  deleteLemma(conceptId: number, language: string, focus: number){
    this.importService.deleteConceptLemma( language, conceptId, focus).subscribe(
      data => this.handleDeleteLemma(data),
      error => this.handleDeleteLemmaError(error)
    )
  }

  handleDeleteLemma(data){
    this.fetchConcepts();
  }
  handleDeleteLemmaError(error){
    console.log(error);
  }

  getLanguageName(lang: string): string {
    switch(lang) {
      case "nl":
        return "Dutch";
      case "en":
        return "English";
      case "de":
        return "German";
      case "fr":
        return "French";
      case "es":
        return "Spanish";
      case "it":
        return "Italian";
      default:
        return "Unknown";
    }
  }

  fetchWordTypesandAttributes(concepts: any[]) {
    var screenLanguage = this.configurationService.getScreenLanguage();
    for(let concept of concepts) {
      let wt = concept.word_type
      if(wt == undefined){
        return;
      }
      this.resourceService.resourceWordType(wt, screenLanguage)
      let attributes = concept.concept_attributes;
      this.resourceService.attributes(attributes, screenLanguage)

    }


  }

  addNewConcept(){

    let dialogRef = this.dialog.open(PostNewConceptComponent, {
      width: '600px',
      data: {}

    })
    this.dialog.afterAllClosed.subscribe(
      data =>  this.handleAllDialogsClosed(data)
    );

  }

  deleteConcept(conceptId){
    this.importService.deleteConcept(conceptId).subscribe(
      data => this.handleDeleteConcept(data),
      error => this.handleDeleteConceptError(error)
    )


  }
  handleDeleteConcept(data){
    this.fetchConcepts();
  }

  handleDeleteConceptError(error){
    console.log(error)
  }


}
