import { Component, Input } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration.service';
import { DictionaryService } from '../../../services/dictionary.service';
import { MenuContextComponent } from '../../menu-context/menu-context.component'



@Component({
  selector: 'thesaurus',
  templateUrl: './thesaurus.component.html',
  styleUrls: ['./thesaurus.component.css']
})
export class ThesaurusComponent {
  @Input() contextMenu: MenuContextComponent;

  @Input() sourceLanguage: string;
  @Input() targetLanguage: string;
  @Input() conceptid: number;
  @Input() dictionary: string;

  thesauri: any[];
  conceptAttributes: string;
  thesauriTranslations: any[];
  searching: boolean = false;
  thesaurusLoaded: boolean = false;



  constructor(
    private configurationService:ConfigurationService,
    private dictionaryService:DictionaryService
    ) {}

    showContextMenu($event, language, conceptid, conceptAttributes, dictionary, item){
      //console.log("EVENT" + $event + JSON.stringify(language) + JSON.stringify(item) + "CONCEPTID" + conceptid )
      this.contextMenu.onContextMenu($event, language, conceptid, conceptAttributes, dictionary, item);
    }


    loadThesaurus(){
      if(this.thesaurusLoaded == false && this.searching == false){
        //console.log("LOADING THESAURUS " + this.conceptid)
        this.searching = true;
        let sourceLang = this.configurationService.getSourceLanguage();
        let targetLang = this.configurationService.getTargetLanguage();

        this.dictionaryService.makeThesaurus(sourceLang, targetLang, this.dictionary, this.conceptid).subscribe(
          data => this.handleThesaurusSuccess(data),
          error => this.handleThesaurusError(error),
          () => { this.searching = false;  }

        )

      }

    }
    handleThesaurusSuccess(data){
      //console.log("JSON" + JSON.stringify(data));
      this.thesauri = data.data.source.data.lemmas;
      this.conceptAttributes = data.data.source.data.concept_attributes;
      //console.log("CONC ATT" + JSON.stringify(this.conceptAttributes))
      this.thesauriTranslations = data.data.translations;
      this.thesaurusLoaded = true;
      //console.log("Loaded " + data.data.source.data.id)
    }
    handleThesaurusError(error){
      console.log(error);
    }

    filtered(i: number): boolean {
      let thesaurus = this.thesauri[i];
      if((thesaurus == undefined) || (thesaurus.data.lemma == "") || (thesaurus.data.lemma == "xxx")) {
        return true;
      }
      let translationLemmas = this.thesauriTranslations[i].data.lemmas;
      if((translationLemmas == undefined) || (translationLemmas.length == 0)){
        return true;
      }
      if((translationLemmas.length == 1) && (translationLemmas[0].data.lemma == "xxx")){
        return true;
      }
      if((translationLemmas.length == 1) && (translationLemmas[0].data.lemma == "")){
        return true;
      }
      return false;
    }
};
