import { CommonModule } from '@angular/common';

import { Component, forwardRef, ViewChildren, ViewChild, AfterViewInit, Input, Inject, Injector, ChangeDetectorRef, QueryList } from '@angular/core';
import { BaseTopLevel } from '../../dictionary-components/base-toplevel';
import { MergerService } from '../../../services/merger.service';
import { SearchService, searchOptionsInterface} from '../../../shared-services/search.service';
import { ResourceService } from '../../../shared-services/resource.service';
import { ThesaurusComponent } from '../../dictionary-components/thesaurus/thesaurus.component'
import { GrammarPanelComponent } from '../../dictionary-components/grammar-panel/grammar-panel.component';
import { SpeechSyntesisService } from '../../../services/speech-syntesis.service';
import { Subscription } from 'rxjs';
import { TimerObservable } from 'rxjs/Observable/TimerObservable';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import { SearchLemmas } from '../../../interfaces/searchlemmas';
import { MatSidenav } from '@angular/material/sidenav';
//import * as $ from 'jquery';
import { Languages } from '../../../interfaces/languages';
import { ActivatedRoute } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import {MatDialog} from '@angular/material/dialog';

import { VideoPanelDictionaryComponent } from '../../dictionary-components/video-panel-dictionary/video-panel-dictionary.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'dictionary',
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DictionaryComponent),
        multi: true
    }
  ],
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.css']
})



export class DictionaryComponent extends BaseTopLevel implements AfterViewInit, ControlValueAccessor  {
  onChange: any = () => {}
  onTouch: any = () => {}
  val = "";



  @ViewChild(VideoPanelDictionaryComponent) videopaneldictionary: VideoPanelDictionaryComponent;
  @ViewChild(GrammarPanelComponent) grammarpanelcomponent: GrammarPanelComponent;
  @ViewChildren(ThesaurusComponent) children: QueryList<ThesaurusComponent>;
  @ViewChild('grammarModal') grammarModal: MatDialog;
  @ViewChild('grammarPanel') grammarPanel: GrammarPanelComponent;
  @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;
  @Input() case_tooltip: string = "Click to exclude case sensitive results";
  @Input() diacritic_tooltip: string ="Click to exclude diacritics from search result";

  showGrammarPanel: boolean;

  browserLang = navigator.language;

  concepts: any[];
  reference_concepts: any[];
  reference_translations: any[];
  translations: any[];
  switchLemma: string  = "";
  haveDictionaryResults: boolean = true;
  languages = Languages;

  forms: any[];

  searching: boolean = false;
  surroundSearching: boolean = false;
  autocompleteInput: string = "";
  autocompleteTimerSubscription: Subscription = null;

  notPresent: boolean = false;

  showCloseButton: boolean = false;
  showContextMenu: boolean = false;
  showSurroundings: boolean = false;
  showRadioGroupOverview: boolean = false;
  showRadioGroupExpression: boolean = false;
  showRadioGroupProverb: boolean = false;
  showRadioGroupMedical: boolean = false;
  showRadioGroupTechnical: boolean = false;
  showRadioGroupLegal: boolean = false;
  showRadioGroupIt: boolean = false;
  showRadioGroupFinancial: boolean = false;
  showRadioGroupChemical: boolean = false;
  showRadioGroupRecognized: boolean =false;
  showPanel: number = 0;

  surroundingsValue: string;

  sourceLanguage: string;
  targetLanguage: string;

  currentSearchSourceLanguage: string;
  currentSearchTargetLanguage: string;

  //selectedForm: string;
  searchLemmas: SearchLemmas;
  searchService = this.SearchService
  radioGroupForm: FormGroup;
  caseToggle: boolean;
  diacriticToggle: boolean;
  constructor(cd: ChangeDetectorRef,
    injector: Injector,
    private FormBuilder: FormBuilder,
    private mergerService:MergerService,
    private resourceService:ResourceService,
    private speechSyntesisService: SpeechSyntesisService,
    public dialog: MatDialog,
    private cookieService: CookieService,
    @Inject(SearchService) private SearchService,
    private route: ActivatedRoute
  ) {
      super(cd, injector)
      //console.log("Initializing dictionary component");

      this.searchService.currentSearch.subscribe(searchOptions => {
        this.search(searchOptions);
      });
  }

  ngOnDestroy():void{
    //console.log("OnDestroy called")
  }


  ngOnInit(): void {
    super.ngOnInit()
    this.radioGroupForm = this.FormBuilder.group({
      'model': 1
    });
  }

  navOpen(event):any {
    this.sidenav.toggle();
    console.log(this.sidenav.opened)
  }

  openModal(){
    const dialogRef = this.dialog.open(VideoPanelDictionaryComponent);

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });

  }


  writeValue(value: any){
    this.val = value
  }
  registerOnChange(fn: any){
    this.onChange = fn
  }
  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  setRouteParams(params) {
   // console.log("PARAMS: " + JSON.stringify(params))

    if (params['sourceLanguage']) {
      this.sourceLanguage = params['sourceLanguage'];
      this.configurationService.setSourceLanguage(this.sourceLanguage);
    }
    if (params['targetLanguage']) {
      this.targetLanguage = params['targetLanguage'];
      this.configurationService.setTargetLanguage(this.targetLanguage);
    }

    if (params['search']) {
      this.surroundingsValue = params['search'];
      this.searchTranslations();
    } else if (params['restoreSearch']) {
      this.surroundingsValue = this.configurationService.getDictionarySearch();
      this.searchTranslations();
    }
  }

  ngAfterViewInit(){}

  clear() {
    //Data
    this.concepts = [];
    this.reference_concepts = [];
    this.reference_translations = [];
    this.translations = [];
    //this.switchLemma = "";
    if(this.searchLemmas != undefined) {
      this.searchLemmas.clear();
    }
    //Interface
    this.surroundingsValue = "";
    this.forms = [];
    this.showPanel = 0;
    this.showRadioGroupOverview = false;
    this.showRadioGroupExpression = false;
    this.showRadioGroupProverb = false;
    this.showRadioGroupMedical = false;
    this.showRadioGroupTechnical = false;
    this.showRadioGroupLegal = false;
    this.showRadioGroupIt = false;
    this.showRadioGroupFinancial = false;
    this.showRadioGroupChemical = false;
    this.showRadioGroupRecognized = false;
  }

  switchLanguages(){
    //console.log("CALLED switchLanguegaesd")
    let sourceLang = this.configurationService.getSourceLanguage();
    let targetLang = this.configurationService.getTargetLanguage();

    if(sourceLang == targetLang) {
      return;
    }

    this.configurationService.setSourceLanguage(targetLang);
    this.configurationService.setTargetLanguage(sourceLang);

    this.sourceLanguage = targetLang;
    this.targetLanguage = sourceLang;

    //console.log("switchlemma" + this.switchLemma)
    if (this.switchLemma != "")
    {
      this.surroundingsValue = this.switchLemma;
      this.loadAutocomplete(this.switchLemma, true);
    }
  }

  onConfigLoaded() {
    let today = new Date()
    let year = today.getFullYear();
    let month = today.getMonth();
    let day = today.getDate();
    let expiry = new Date(year + 1, month, day);


   // let expires = new Date().setDate(today.getDate() + 365)
    //console.log("EXP" + expiry)
    let key = "show_dictionary_help";
    let value = "off";
    let opts: CookieOptions = {
      expires: expiry
    }
    let showHelp = this.configurationService.getShowHelp() && (this.cookieService.get('show_dictionary_help') == undefined);
    if(showHelp){
      this.openModal();
      this.cookieService.put(key, value, opts);
    }

    this.sourceLanguage = this.configurationService.getSourceLanguage();
    this.targetLanguage = this.configurationService.getTargetLanguage();
    this.route.queryParams.subscribe( params => this.setRouteParams(params));
    this.caseToggle = this.configurationService.getCaseSensitive();
    this.diacriticToggle = this.configurationService.getDiacriticSensitive();

    //console.log("SHOWHELP" + showHelp + "SL" + this.sourceLanguage +"TL" + this.targetLanguage);

    this.refresh();
  }

  search(searchOptions: searchOptionsInterface) {
    //console.log("Receive search options: " + JSON.stringify(searchOptions))
    if (searchOptions == undefined) {
      return;
    }

    let sourceLang = this.configurationService.getSourceLanguage();
    if (searchOptions.language != sourceLang) {
      this.switchLemma = searchOptions.lemma;
      this.switchLanguages()
    } else {
      this.surroundingsValue = searchOptions.lemma;
      this.loadAutocomplete(searchOptions.lemma, true);
    }

  }

  //make a translation
  searchTranslations(){
    if (this.surroundingsValue == undefined || this.surroundingsValue == "") {
        return;
    }
    this.searching = true;
    this.showSurroundings = false;
    this.stopAutoComplete();
    if(this.searchLemmas == undefined) {
      if (this.surroundingsValue == undefined) {
        return
      }
      this.loadAutocomplete(this.surroundingsValue, true)
    } else {
      this.loadTranslations()
    }
  }

  loadTranslations() {
    if((this.searchLemmas == undefined ) || (this.searchLemmas.lemmas.length == 0)){
      return;
    }
    this.matAutocompleteTrigger.closePanel();

    let sourceLanguage = this.configurationService.getSourceLanguage();
    let targetLanguage = this.configurationService.getTargetLanguage();
    let caseSensitive = this.configurationService.getCaseSensitive();
    let diacriticSensitive = this.configurationService.getDiacriticSensitive();
    return this.mergerService.makeTranslationsPOST(sourceLanguage, targetLanguage, this.searchLemmas, caseSensitive, diacriticSensitive).subscribe(
      data => this.handleTranslationSuccess(data),
      error => this.handleTranslationError(error),
      () => {this.searching = false}
    )

  }


  fetchWordTypes(concepts: any[]) {
    var screenLanguage = this.configurationService.getScreenLanguage();
    for(let concept of concepts) {
      let wt = concept.data.lemmas[0].data.word_type
      if(wt == undefined){
        return;
      }
      this.resourceService.resourceWordType(wt, screenLanguage)
    }

  }


  //handle translation data
  handleTranslationSuccess(data){

    //Set search languages (for flags, grammar, etc.)
    this.currentSearchSourceLanguage = this.configurationService.getSourceLanguage();
    this.currentSearchTargetLanguage = this.configurationService.getTargetLanguage();
    //Store search value
    this.configurationService.setDictionarySearch(this.surroundingsValue);

    //Store concepts data
    this.translations = data.data.translations;
    this.concepts = data.data.concepts;
    this.fetchWordTypes(this.concepts);
    //console.log("THECONCEPTS" + JSON.stringify(this.concepts))
    //Store reference concepts data
    this.reference_concepts = data.data.reference_concepts;
    this.fetchWordTypes(this.reference_concepts);
    this.reference_translations = data.data.reference_translations;

    //console.log("CONCEPTS" + JSON.stringify(this.concepts))

    //See if we have results and calculate language switch lemma
    if((this.translations.length > 0) && (this.translations[0].data.lemmas.length > 0)) {
      this.haveDictionaryResults = true;
      this.switchLemma = this.translations[0].data.lemmas[0].data.lemma;
    } else if((this.reference_translations.length > 0) && (this.reference_translations[0].data.lemmas.length > 0)) {
      this.haveDictionaryResults = true;
      this.switchLemma = this.reference_translations[0].data.lemmas[0].data.lemma;
    } else {
      this.haveDictionaryResults = false
    }

    //console.log("First Translation: " + this.switchLemma);

    //Calculate shown panels
    this.isEmptyObjectOverview(this.concepts);
    this.isEmptyObjectExpression(this.reference_concepts);

    var concepts = this.concepts.concat(this.reference_concepts);
    var translations = this.translations.concat(this.reference_translations);
    this.isEmptyDictionary(concepts, translations);

    //If translations for panel still exist, keep current panel open
    var val = this.radioGroupForm.value.model
    if (!this.radioGroupShown(val)) {
      //Make first shown panel active
      val = this.findFirstRadioGroupShown();
      this.radioGroupForm.setValue({
        'model': val
      });
    }
    this.setShowPanel(val);

    this.refresh()
  }
  //handle translation error
  handleTranslationError(error){
    console.log(error)
    this.switchLemma = "";
  }

  radioGroupShown(panel: number) {
    switch(panel) {
      case 1:
      return this.showRadioGroupOverview;
      case 2:
      return this.showRadioGroupRecognized;
      case 3:
      return this.showRadioGroupExpression;
      case 4:
      return this.showRadioGroupProverb;
      case 5:
      return this.showRadioGroupChemical;
      case 6:
      return this.showRadioGroupMedical;
      case 7:
      return this.showRadioGroupTechnical;
      case 8:
      return this.showRadioGroupLegal;
      case 9:
      return this.showRadioGroupFinancial;
      case 10:
      return this.showRadioGroupIt;
    }

    return false;
  }

  findFirstRadioGroupShown(): number {
    if (this.showRadioGroupOverview) {
      return 1;
    }
    if (this.showRadioGroupRecognized) {
      return 2;
    }
    if (this.showRadioGroupExpression) {
      return 3;
    }
    if (this.showRadioGroupProverb) {
      return 4;
    }
    if (this.showRadioGroupChemical) {
      return 5;
    }
    if (this.showRadioGroupMedical) {
      return 6;
    }
    if (this.showRadioGroupTechnical) {
      return 7;
    }
    if (this.showRadioGroupLegal) {
      return 8;
    }
    if (this.showRadioGroupFinancial) {
      return 9;
    }
    if (this.showRadioGroupIt) {
      return 10;
    }

    return 0;
  }

  isEmptyDictionary(concepts: any[], translations: any[]){
    this.showRadioGroupFinancial = false;
    this.showRadioGroupLegal = false;
    this.showRadioGroupTechnical = false;
    this.showRadioGroupChemical = false;
    this.showRadioGroupMedical = false;
    this.showRadioGroupIt = false;
    this.showRadioGroupProverb = false;
    let firstShownGroup = 0;
    for(let i = 0; i < concepts.length; i++){
      let concept = concepts[i];
      let translation = translations[i];
      //Skip concepts without translation
      if (translation.data.lemmas.length == 0) {
        continue;
      }
      //console.log("DICT" + JSON.stringify(concept.data.dictionary))
      switch(concept.data.dictionary) {
        case "tradedb":
        this.showRadioGroupFinancial = true;
        break;
        case "legaldb":
        this.showRadioGroupLegal = true;
        break;
        case "technidb":
        this.showRadioGroupTechnical = true;
        break;
        case "chemicdb":
        this.showRadioGroupChemical = true;
        break;
        case "medicdb":
        this.showRadioGroupMedical = true;
        break;
        case "computdb":
        this.showRadioGroupIt = true;
        break;
        case "proverbdb":
        this.showRadioGroupProverb = true;
        break;
        default:
        break;
      }
    }
  }



  isEmptyObjectOverview(concepts: any[]){
    this.showRadioGroupOverview = false;
    this.showRadioGroupRecognized = false;
    for(let concept of concepts){
      if(concept.data.dictionary == "maindb") {
        if(concept.data.recognized_form) {
          this.showRadioGroupRecognized = true;
        } else {
          this.showRadioGroupOverview = true;
        }
      }
    }
  }

  isEmptyObjectExpression(concepts: any[]){
    this.showRadioGroupExpression = false;
    for(let concept of concepts){
      if(concept.data.dictionary == "maindb") {
        this.showRadioGroupExpression = true;
      }
    }
  }

  inputExistsInSurroundings(centerWord, inputWord, index){
    this.notPresent = false;
    //hoe die index hier gebruiken?
    if(centerWord == this.surroundingsValue){
      //console.log("INPUT the same as surroundings")
    } else {
      this.notPresent = true;
      //console.log("INPUT not the same as surrounding")
    }
  }


  //if there is a response put the response in a lemmas var.
  handleAutocomplete(data, lookup: boolean){
    this.forms = data.data.forms
    let center: number = data.data.center_word_position
    this.searchLemmas = this.getSearchLemmas(this.forms[center].data);

    //console.log("DATA" + JSON.stringify(data.data))
    //console.log("SEARCHLEMMA" + JSON.stringify(this.forms[center].data.form + "CENTER" + center))
    this.inputExistsInSurroundings(this.forms[center].data.form, this.surroundingsValue, center)


    if (lookup) {
      this.loadTranslations();
    }
  }
  //if the response is an error, output the error
  handleAutocompleteError(error){
    console.log(error);
  }

  selectItem(item) {
   //console.log("ITEM" + JSON.stringify(item))
   this.surroundingsValue = item.data.form
   this.searchLemmas = this.getSearchLemmas(item.data);

   this.loadAutocomplete(item.data.form, false);
  }


  triggerAutocomplete(query: string) {
    this.autocompleteInput = query;

    this.stopAutoComplete();
    this.searchLemmas = undefined;

    if (this.autocompleteInput == "") {
      return;
    }

    let timer = TimerObservable.create(1000);
    this.autocompleteTimerSubscription = timer.subscribe( t => {
      this.loadAutocomplete(this.autocompleteInput, false)
      this.stopAutoComplete();
    });
  }

  stopAutoComplete() {
    if(this.autocompleteTimerSubscription != null) {
      this.autocompleteTimerSubscription.unsubscribe();
      this.autocompleteTimerSubscription = null;
    }
  }

  //load the surroundings to get autocdictionaryServiceomplete function
  loadAutocomplete(query: string, lookup: boolean) {
    this.showSurroundings = true;
    let sourceLang = this.configurationService.getSourceLanguage();
    let caseSensitive = this.configurationService.getCaseSensitive();
    let diacriticSensitive = this.configurationService.getDiacriticSensitive();
    return this.mergerService.search(sourceLang, query, caseSensitive, diacriticSensitive).subscribe(
      data =>  this.handleAutocomplete(data, lookup),
      error => this.handleAutocompleteError(error),
      () => {
        //this.surroundSearching = false;
      }
    )
  }

  getSearchLemmas(surroundData: any): SearchLemmas {
    var searchLemmas = new SearchLemmas();

    for(let lemma of surroundData.lemmas) {
      searchLemmas.lemmas.push(lemma.data.lemma)
      searchLemmas.form_texts.push(surroundData.form);
      /*if (lemma.data.recognized_form) {
        searchLemmas.recognized_forms.push(true)
      } else {
        searchLemmas.recognized_forms.push(false)
      }*/
    }
    //console.log("SEARCH" + JSON.stringify(searchLemmas))
    return searchLemmas
  }



  sourceLanguageSelected(sourceLang){
    this.switchLemma = "";
    //Save source lamguage in configuration
    this.configurationService.setSourceLanguage(sourceLang);
  }

  sourceLanguageChanged() {
    let newLang = this.configurationService.getSourceLanguage()

    if (newLang != this.currentSearchSourceLanguage) {
      //Clear
      this.clear();

      this.sourceLanguage = newLang;
    }

    return newLang;
  }


  targetLanguageSelected(targetLang){
    //Save target lamguage in configuration
    this.configurationService.setTargetLanguage(targetLang);
  }

  targetLanguageChanged() {
    let newLang = this.configurationService.getTargetLanguage()

    if (newLang != this.currentSearchTargetLanguage) {
      //Re-translate
      this.loadTranslations();

      this.targetLanguage = newLang;
    }

    return newLang;
  }



  toggleContextMenu($event: MouseEvent, item: any){

        $event.preventDefault();
        $event.stopPropagation();
      }

  setShowPanel(panel) {
    this.showPanel = panel;
  }



  toggleCloseButton(){
    this.showCloseButton = true;
  }



  callThesaurus(id){
    this.children.forEach(function(element){
      if(element.conceptid == id){
        element.loadThesaurus();
      }
    })
  }



  closeThesaurus(id){

    /*$(function(){
       //console.log("close thesaurus")
     })*/
  }

  caseToggleClicked() {
    if(this.caseToggle){
      this.case_tooltip = "Click to exclude case sensitive results";
    } else {
      this.case_tooltip = "Click to include case sensitive results"
    }
    this.caseToggle = this.configurationService.setCaseSensitive(!this.caseToggle);
    //console.log("C Clicked!" + this.caseToggle);
  }

  diacriticToggleClicked() {
    if(this.diacriticToggle){
      this.diacritic_tooltip = "Click to exclude diacritics from search result";
    } else {
      this.diacritic_tooltip = "Click to include diacritics in search result";
    }
    this.diacriticToggle = this.configurationService.setDiacriticSensitive(!this.diacriticToggle);
    //console.log("D Clicked!" + this.diacriticToggle)
  }

  sendToEuroglot() {
    //Future function for sending word suggestions to LS.
  }


}
