import { Injectable } from '@angular/core';
import { environment, dictionaries, varEnvironment } from '../../environments/environment';
import { HttpService } from '../services/http.service';
import { Observable } from 'rxjs';
import { SearchLemmas } from '../interfaces/searchlemmas';



import 'rxjs/add/operator/map';
import 'rxjs/operator/delay';
import 'rxjs/operator/mergeMap';
import 'rxjs/operator/switchMap';

const PORTS = environment.PORTS_DICTIONARY.split(',');
const NAMES = environment.DB_NAMES.split(',');
const TRANSLATE_ARGS = environment.TRANSLATE_ARGS;
const THESAURUS_ARGS = environment.THESAURUS_ARGS;
const SURROUNDINGS_ARGS = environment.SURROUNDINGS_ARGS;
const REFERENCE_ARGS = environment.REFERENCE_ARGS;
const LOCAL_RECURSE = environment.LOCAL_RECURSE;
const CASE_INSENSITIVE = environment.CASE_INSENSITIVE;
const DIACRITIC_INSENSITIVE = environment.DIACRITIC_INSENSITIVE;



const API_URL: Array<string> = [
  '://' + environment.API_IP + ":" + PORTS[dictionaries.MAINDB] + environment.ENDPOINT_DICTIONARY + "/" + NAMES[dictionaries.MAINDB],
  '://' + environment.API_IP + ":" + PORTS[dictionaries.CHEMICDB] + environment.ENDPOINT_DICTIONARY + "/" + NAMES[dictionaries.CHEMICDB] ,
  '://' + environment.API_IP + ":" + PORTS[dictionaries.COMPUTDB] + environment.ENDPOINT_DICTIONARY + "/" + NAMES[dictionaries.COMPUTDB] ,
  '://'+ environment.API_IP + ":" + PORTS[dictionaries.LEGALDB] + environment.ENDPOINT_DICTIONARY + "/" + NAMES[dictionaries.LEGALDB] ,
  '://' + environment.API_IP + ":" + PORTS[dictionaries.MEDICDB] + environment.ENDPOINT_DICTIONARY + "/" + NAMES[dictionaries.MEDICDB] ,
  '://'+ environment.API_IP + ":" + PORTS[dictionaries.TECHNIDB] + environment.ENDPOINT_DICTIONARY + "/" + NAMES[dictionaries.TECHNIDB] ,
  '://' + environment.API_IP + ":" + PORTS[dictionaries.TRADEDB] + environment.ENDPOINT_DICTIONARY + "/" + NAMES[dictionaries.TRADEDB] ,
  '://' + environment.API_IP + ":" + PORTS[dictionaries.PROVERBDB] + environment.ENDPOINT_DICTIONARY + "/" + NAMES[dictionaries.PROVERBDB] ,
]
const MT_THESAURUS_API = '://' + environment.API_IP + ":" + environment.PORT_MERGE + environment.ENDPOINT_MERGE;
const MT_RECOGNIZER_API = '://' + environment.API_IP + ":" + environment.PORT_RECOGNIZER + environment.ENDPOINT_RECOGNIZER + environment.FORMS;

@Injectable()
export class DictionaryService  {
  private TranslateArguments: string = TRANSLATE_ARGS;
  private ThesaurusArguments: string = THESAURUS_ARGS;
  private SurroundingsArguments: string = SURROUNDINGS_ARGS;
  private ReferenceArguments: string = REFERENCE_ARGS;
  private Recurse: string = LOCAL_RECURSE;
  private Case_insensitive: string = CASE_INSENSITIVE;
  private Diacritic_insensitive: string = DIACRITIC_INSENSITIVE;

  queryWord: string;

  requestOptions: {} = {withAuth: true};

  constructor(
    private http:HttpService,
  ) {
  }

  recognizeForm(lang: string, query: string): Observable<JSON> {
    return this.http.get(MT_RECOGNIZER_API + lang + "/" + encodeURIComponent(query) + "?" + environment.ALL_RECURSE, this.requestOptions)
  }


  getConceptsMT(lang: string, query: string): Observable<JSON> {
    //console.log("This is the query" + query)
    return this.http.get(varEnvironment.schema + MT_THESAURUS_API + environment.CONCEPTS + lang + "/" + encodeURIComponent(query) + "?" + environment.THESAURUS_MT_ARGS, this.requestOptions)
  }

  getConcepts(sourceLang: string, dictionary: string, query): Observable<JSON> {
    //console.log(sourceLang)
    return this.http.get(varEnvironment.schema + API_URL[this.dictPos(dictionary)] + environment.CONCEPTS + sourceLang + "/" + encodeURIComponent(query) + "?" + this.Recurse, this.requestOptions )
  }

  //make a translation
  makeTranslation(sourceLang: string, targetLang: string, dictionary: string, query): Observable<JSON> {
    return this.http.get(varEnvironment.schema + API_URL[this.dictPos(dictionary)] + environment.TRANSLATE + sourceLang + "/" + targetLang + "/" + encodeURIComponent(query) + "?" + this.TranslateArguments + "&" + this.Recurse + "&" + this.ReferenceArguments, this.requestOptions)
  }

  //make (1 or multiple) translation
  makeTranslationsGET(sourceLang: string, targetLang: string, dictionary: string, lemmas: string[], caseSensitive: boolean, diacriticsSensitive: boolean): Observable<JSON> {
    let joinedLemmas: string = lemmas.join("##")
    var args: string = "";
    if (!caseSensitive) {
      args += "&" + this.Case_insensitive;
    }
    if (!diacriticsSensitive) {
      args += "&" + this.Diacritic_insensitive
    }
    return this.http.get(varEnvironment.schema + API_URL[this.dictPos(dictionary)] + environment.TRANSLATE + sourceLang + "/" + targetLang + "/" + encodeURIComponent(joinedLemmas) + "?" + this.TranslateArguments + "&" + this.Recurse + "&" + this.ReferenceArguments + args, this.requestOptions)
  }

  //make (1 or multiple) translation
  makeTranslationsPOST(sourceLang: string, targetLang: string, dictionary: string, lemmas: SearchLemmas, caseSensitive: boolean, diacriticsSensitive: boolean): Observable<Object> {
    var args: string = "";
    if (!caseSensitive) {
      args += "&" + this.Case_insensitive;
    }
    if (!diacriticsSensitive) {
      args += "&" + this.Diacritic_insensitive
    }

    return this.http.post(varEnvironment.schema + API_URL[this.dictPos(dictionary)] + environment.TRANSLATE + sourceLang + "/" + targetLang + "?" + this.TranslateArguments + "&" + this.Recurse + "&" + this.ReferenceArguments + args, JSON.stringify(lemmas), this.requestOptions)
  }

  makeThesaurus(sourceLang: string, targetLang: string, dictionary: string, query): Observable<JSON> {
    //console.log("API_URL" + API_URL[this.dictPos(dictionary)] + environment.THESAURUS + sourceLang + "/" + targetLang + "/" + encodeURIComponent(query) + "?" + this.ThesaurusArguments + "&" + this.Recurse, this.requestOptions)
    return this.http.get(varEnvironment.schema + API_URL[this.dictPos(dictionary)] + environment.THESAURUS + sourceLang + "/" + targetLang + "/" + encodeURIComponent(query) + "?" + this.ThesaurusArguments + "&" + this.Recurse, this.requestOptions)
  }

  //get surroundings
  public search(sourceLang: string, dictionary: string, term: string, caseSensitive: boolean, diacriticsSensitive: boolean): Observable<JSON> {

    var args: string = "";
    if (!caseSensitive) {
      args += "&" + this.Case_insensitive;
    }
    if (!diacriticsSensitive) {
      args += "&" + this.Diacritic_insensitive
    }

    return this.http.get(varEnvironment.schema + API_URL[this.dictPos(dictionary)] + environment.SURROUNDINGS + sourceLang + "/" + encodeURIComponent(term) + "?" + this.SurroundingsArguments + "&" + this.Recurse + args, this.requestOptions)
  }

  private dictPos(dictionaryName: string): number {
    for (let n = 0; n < NAMES.length; n++) {
      if (NAMES[n] == dictionaryName) {
        return n;

      }

    }

    return -1
  }


}
