<div>


 <mat-dialog-content>
   <div *ngIf="modalData[0].id != 3">
      <h2 class="center">Buy extra characters</h2>
      <p>After we have processed your payment you will see the extra characters on your balance the next time you log in.</p>
   </div>
   <div *ngIf="modalData[0].id == 3">
      <h3 class="center">Upgrade to Premium</h3>
      <p>With a <b>Premium subscription</b> you have unlimited access to all specialised dictionaries and 25.000 characters which is around <b>6 pages per month</b> available for Machine Translation for only € 130,25 excl. VAT per year!</p>
   </div>

   <form [formGroup]="detailsForm">
      <mat-vertical-stepper [linear]="isLinear" #stepper>
        <mat-step [stepControl]="detailsForm">
          <ng-template matStepLabel>Fill out your details</ng-template>
          <div class="info-container">
          <mat-form-field>
              <input formControlName="companyName" matInput placeholder="Enter your company name">
          </mat-form-field>
          <mat-form-field>
              <input formControlName="name" matInput placeholder="Enter your name">
          </mat-form-field>
          <mat-form-field>
              <input matInput  placeholder="Enter your email" formControlName="email" required>
              <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-checkbox class="example-margin" formControlName="selectedEuCompany">EU Company not situated in The Netherlands</mat-checkbox>
          <mat-form-field *ngIf="(selectedEuCompany.value != false)" class="example-full-width">
           <input matInput placeholder="Enter VAT Number" formControlName="vatNumber"  value="">
          </mat-form-field>
          <h5 class="text-center">Choose your payment method</h5>
          <mat-radio-group class="tp-radio-group" formControlName="paymentSelected">
            <mat-radio-button class="tp-radio-button"
               *ngFor="let provider of providerValues" [value]="provider">
               {{getName(provider)}}

               <mat-form-field *ngIf="(provider == paymentSelected.value) && (provider == 'apple') && (providerDetailsFieldName(provider)!= null)" class="example-full-width">
                  <!--<input matInput placeholder="{{providerDetailsPlaceholder(provider)[0]}}" name="providerDetailsFieldName(provider)[0]" value=""> -->
                </mat-form-field>

               <mat-form-field *ngIf="(provider == paymentSelected.value) && (provider == 'paypal') && (providerDetailsFieldName(provider)!= null)" class="example-full-width">
                  <input formControlName="paymentDescription" matInput placeholder="{{providerDetailsPlaceholder(provider)[0]}}" name="providerDetailsFieldName(provider)[0]" value="">
                </mat-form-field>

                <mat-form-field *ngIf="(provider == 'ideal') && (provider==paymentSelected.value) && (providerDetailsFieldName(provider)!= null)">
                  <mat-select formControlName="paymentIssuer">
                    <mat-option *ngFor="let bank of dutchBankValues"  [value]="bank">
                      {{getBankName(bank)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="(provider == 'kbc') && (provider==paymentSelected.value) && (providerDetailsFieldName(provider)!= null)">
                    <mat-select formControlName="paymentIssuer">
                      <mat-option *ngFor="let bank of kbcIssuers"  [value]="bank">
                        {{bank}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="(provider == 'kbc') && (provider==paymentSelected.value) && (providerDetailsFieldName(provider) != null)">
                    <input formControlName="paymentDescription"  matInput placeholder="{{providerDetailsPlaceholder(provider)[1]}}" name="providerDetailsFieldName(provider)[1]" value="">
                  </mat-form-field>

            </mat-radio-button>
         </mat-radio-group>
         </div>
          <div>
            <button class="btn blue darken-1 white-text" mat-button matStepperNext [disabled]="!detailsForm.valid">Next</button>
          </div>
        </mat-step>
        <mat-step [stepControl]="detailsForm">

          <ng-template matStepLabel >Your order details</ng-template>
            <div class="credentials">
              <table>
                <tr>
                  <td>Company name:</td>
                  <td>{{companyName.value}}</td>
                </tr>
                <tr>
                  <td>Vat-number:</td>
                  <td>{{vatNumber.value}}</td>
                </tr>
                <tr>
                  <td>Name:</td>
                  <td>{{name.value}}</td>
                </tr>
                <tr>
                  <td>E-mail:</td>
                  <td>{{email.value}}</td>
                </tr>
              </table>

            </div>
            <div class="product_info">
              <h5>Product Info:</h5>
              <p>Product:{{modalData[0].product}}</p>
              <mat-checkbox formControlName="acceptanceCheckbox">Accept our terms and conditions</mat-checkbox>
            </div>

          <div>
            <button class="btn blue darken-1 white-text" mat-button matStepperPrevious>Back</button>
            <button class="btn blue darken-1 white-text" [disabled]="(!acceptanceCheckbox.value) || (!email.valid) || (!name.valid)"  mat-button matStepperNext (click)="onCloseConfirm()">Pay</button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </form>

  </mat-dialog-content>

</div>
