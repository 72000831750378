import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthServiceEuroglot, LoggedInEvent} from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  private loggedIn : boolean = false;
  private loggedInSubscription;
  private refreshedSubscription;

  constructor(private authService:AuthServiceEuroglot, private router:Router){
		this.loggedInSubscription = authService.loggedInEmitter.subscribe({
			next: (event: LoggedInEvent) => {
					this.handleLogin(event)
			}
    })
    this.refreshedSubscription = authService.refreshedEmitter.subscribe({
      next: (event: LoggedInEvent) => {
          this.handleRefresh(event)
      }
    })

    this.loggedIn = authService.checkLogin()
  }

  handleLogin(event : LoggedInEvent) {
    this.loggedIn = event.loggedIn
  }

  handleRefresh(event : LoggedInEvent) {
    this.loggedIn = event.loggedIn
  }

  canActivate(){
    if(this.loggedIn){
      //console.log('GUARD LOGGED IN')
      return true;
    } else {
      //console.log('GUARD NOT LOGGED IN')
      this.router.navigate(['/login']);
      return false;
    }
  }


}
