import { Document, Paragraph, Packer, TextRun } from 'docx';


export class DocumentCreator{
 public create(translation){
     //console.log("translation" + translation)
     const document = new Document();
     document.addSection({
         children: [
            new Paragraph({
                text: translation,
            })
         ]
     })

 
     
     
     
     return document

 }
}
