import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment, varEnvironment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}
if (window.location.protocol == 'https:') {
  varEnvironment.schema = 'https';
}
console.log("schema" + window.location.protocol)


platformBrowserDynamic().bootstrapModule(AppModule);
