
import { Mark, Node, Fragment } from "prosemirror-model";
import { IJSONDocJSON, NEWLINE } from "./jsondoc_interface";

export function getTextFromJSONDoc(doc : IJSONDocJSON):string {
  var text = "";
  switch(doc.type){
    case "doc": {
      break;
    }
    case "paragraph": {
      break;
    }
    case "text": {
      if(doc.marks && doc.marks.length > 0){
        //text += marksToTag(doc.marks);
      }
      text += doc.text
      if(doc.marks && doc.marks.length > 0){
        //text += endMarksToTag(doc.marks)
      }
      return text;
    }
    case "hard_break": {
      text += NEWLINE
      break;
    }
    case "heading": {
      break;
    }
    case "bullet_list": {
      break;
    }
    case "ordered_list": {
      break;
    }
    case "list_item": {
      break;
    }
    case "horizontal_rule": {
      break;
    }
    default: {
      return "[error]";
    }
  }
  if(doc.marks && doc.marks.length > 0){
    //text += marksToTag(doc.marks);
  }
  if(doc.content){
  for(const node of doc.content){
    text += getTextFromJSONDoc(node);
  }

  }
  if(doc.marks && doc.marks.length > 0){
    //text += endMarksToTag(doc.marks)
  }


  switch(doc.type){
    case "doc": {
      break;
    }
    case "paragraph": {
      text += NEWLINE
      break;
    }
    case "heading": {
      text += NEWLINE
      break;
    }
    case "bullet_list": {
      break;
    }
    case "ordered_list": {
      break;
    }
    case "list_item": {
      break;
    }
    default: {
      return "[error]";
    }
  }

  return text;
}

export function getHTMLFromJSONDoc(doc : IJSONDocJSON):string {
  var html = "";
  switch(doc.type){
    case "doc": {
      break;
    }
    case "paragraph": {
      break;
    }
    case "text": {
      //var text = ""
      if(doc.marks && doc.marks.length > 0){
        html += marksToTag(doc.marks);
      }
      html += doc.text
      if(doc.marks && doc.marks.length > 0){
        html += endMarksToTag(doc.marks)
      }
      return html;
    }
    case "hard_break": {
      html += "<br>"
      break;
    }
    case "heading": {
      html += headingsToTag(doc.attrs.level)
      break;
    }
    case "bullet_list": {
      html += "<ul>"
      break;
    }
    case "ordered_list": {
      html += "<ol>"
      break;
    }
    case "list_item": {
      html += "<li>"
      break;
    }
    case "horizontal_rule": {
      html += "<hr>"
      return html;
    }
    default: {
      return "[error]";
    }
  }
  if(doc.marks && doc.marks.length > 0){
    html += marksToTag(doc.marks);
  }
  if(doc.content){
  for(const node of doc.content){
    html += getHTMLFromJSONDoc(node);
  }

  }
  if(doc.marks && doc.marks.length > 0){
    html += endMarksToTag(doc.marks)
  }


  switch(doc.type){
    case "doc": {
      break;
    }
    case "paragraph": {
      html += NEWLINE
      break;
    }
    case "heading": {
      html += this.endHeadingsToTag(doc.attrs.level)
      html += NEWLINE
      break;
    }
    case "bullet_list": {
      html += "</ul>"
      break;
    }
    case "ordered_list": {
      html += "</ol>"
      break;
    }
    case "list_item": {
      html += "</li>"
      break;
    }
    default: {
      return "[error]";
    }
  }

  return html;
}

function marksToTag(marks : Mark[]):string{
  //console.log("marks" + JSON.stringify(marks))
  var html = "";
  for (let [key,value] of marks) {
    //console.log("mark" + JSON.stringify(key))
    if(key == "strong"){
      html += "<b>";
    }
    if(key == "em"){
      html += "<i>";
    }
  }
  return html;
}

function endMarksToTag(marks : Mark[]):string{
  var html = "";
  for (let [key,value]  of marks) {
    if(key == "em"){
      html += "</i>";
    }
    if(key == "strong"){
      html += "</b>";
    }
  }
  return html;
}

function headingsToTag(level : number) : string{
  return "<h" + level + ">"
}

function endHeadingsToTag(level : number) : string{
  return "</h" + level + ">"
}

export class TextSections extends Array<TextSection> {
  getText() : string[] {
    var strs : string[] = [];
      for(const section of this) {
      strs.push(section.toString());
    }

    return strs;
  }
}

export class TextSection {
  private textNodes : string[];

  constructor(nodes? : string[]) {
      this.textNodes = nodes;
      if(nodes == null) {
        this.textNodes = [];
      }
  }

  addNode(n : string) {
    this.textNodes.push(n);
  }

  merge(s : TextSection) {
    this.textNodes.push(...s.textNodes);
  }

  length() : number {
      return this.textNodes.length
  }

  toString() : string {
    var s : string = "";
    for(let i = 0; i < this.textNodes.length; i++) {
      s += this.textNodes[i];
    }
    return s;
  }
}

export function getTextSectionsFromJSONDoc(doc : IJSONDocJSON) : TextSections | String {
  var addLevel : boolean = false;
  switch(doc.type){
    case "doc": {
      break;
    }
    case "paragraph": {
      addLevel = true;
    break;
    }
    case "text": {
      return doc.text;
    }
    case "hard_break": {
      return NEWLINE;
    }
    case "heading": {
      addLevel = true;
    break;
    }
    case "bullet_list": {
      break;
    }
    case "ordered_list": {
      break;
    }
    case "list_item": {
      addLevel = true;
      break;
    }
    case "horizontal_rule": {
      break;
    }
    case "image": {
      break;
    }
    default: {
      return "[Error: Unknown type (" + doc.type + ")]";
    }
  }

  if(doc.content){
    var sections : TextSections;
    if (addLevel) {
      sections = new TextSections();
      sections.push(new TextSection());
    }

    for(let i = 0; i < doc.content.length; i++){
      let node = doc.content[i];
      var found = getTextSectionsFromJSONDoc(node);
      if(found == null) {
        continue;
      }
      if(typeof found === 'string') {
        if(!addLevel) {
          return "[Error: Can't add text segments to type (" + doc.type + ")]";
        }
        sections[sections.length - 1].addNode(<string>found);
      } else if(Array.isArray(found) && found[0] instanceof TextSection ) {
        if((sections == null) || (sections.length == 0)) {
          sections = found;
        } else if(sections[0].length() == 0) {
          sections[sections.length - 1].merge(found[0])
          found.shift()
          sections.push(...found);
        } else {
          sections.push(...found);
        }
      } else {
        return "[Error determining found type]"
      }
    }

    //console.dir(found)
    return sections;
  }

  return null;
}
