import { Component,ChangeDetectorRef, Injector } from '@angular/core';
import { SupplementPanelComponent } from '../supplement-panel/supplement-panel.component';

const dictionary: string = "legaldb"

@Component({
  selector: 'legal-panel',
  templateUrl: '../supplement-panel/supplement-panel.component.html',
  styleUrls: ['../supplement-panel/supplement-panel.component.css']
})

export class LegalPanelComponent extends SupplementPanelComponent {

  constructor(cd:ChangeDetectorRef, injector: Injector) { 

    super(cd, injector, dictionary);

  }

}
