import { Component, OnInit } from '@angular/core';
import {StatusPageService} from '../../services/status-page.service';
import { Renderer2 } from '@angular/core';


@Component({
  selector: 'app-status-page',
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.scss']
})
export class StatusPageComponent implements OnInit {
  AuthStatusText:string;
  DictStatusText: string;
  MlProxyStatusText: string;
  isRunning: boolean = false;
  isRunningAuth: boolean = false;
  isRunningDicts: boolean = false;
  isRunningMt: boolean = false;



  constructor(private renderer: Renderer2, private statusService:StatusPageService ) { 

  }

  ngOnInit(): void {
    this.showStatusAuth();
    this.showStatusDicts();
    this.showStatusMlProxy();


  }



  showStatusAuth(){
    this.statusService.getApiStatusAuth().subscribe(
      data => this.showStatusAuthSuccess(data),
      error => this.showStatusAuthError(error)
    )
  }

  showStatusAuthSuccess(data){
    if(data.status == 200){
      this.isRunningAuth = true;
      this.AuthStatusText = "The authentication service is up and running";
    }
    
    
  }
  showStatusAuthError(error){
    if(error){
      console.log(this.isRunningAuth)
      this.isRunningAuth = false;
      this.AuthStatusText = "The authentication service is not available:" + error;

    }
  }

  showStatusDicts(){
    this.statusService.getApiStatusDictionaries().subscribe(
      data => this.showStatusDictsSuccess(data),
      error => this.showStatusDictsError(error)
    )
  }
  showStatusDictsSuccess(data){
    if(data.status == 200){
      this.isRunningDicts = true;
      this.DictStatusText = "The dictionary services are up and running";
    }
  }
  showStatusDictsError(error){
    this.isRunningDicts = false;
    if(error){
      this.DictStatusText = "The dictionary services are not available:" + error;
    }
  }

  showStatusMlProxy(){
    this.statusService.getApiStatusMlProxy().subscribe(
      data => this.showStatusMlProxySuccess(data),
      error => this.showStatusMlProxyError(error)
    )
  }
  showStatusMlProxySuccess(data){
    if(data.status == 200){
      this.isRunningMt = true;
      this.MlProxyStatusText = "The machine translation services are up and running";
    }
  }
  showStatusMlProxyError(error){
    if(error){
      this.isRunningMt = false;
      this.MlProxyStatusText = "The Machine translation service is not available: " + error;
    }
  }




}
