import { Component, ChangeDetectorRef, Injector, ViewChild } from '@angular/core';
import { BaseTopLevel } from '../base-toplevel';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Languages } from '../../../interfaces/languages';
import {FormGroup, FormControl,  FormBuilder} from '@angular/forms';
import {UserStatusService} from '../../../services/user-status.service';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {CreateTerminologyDialogComponent} from '../../terminology-components/create-terminology-dialog/create-terminology-dialog.component';
import {TerminologyService} from '../../../services/terminology.service';
//import {AddTerminologyComponent} from '../../../components/terminology-components/add-terminology/add-terminology.component';
import { ActivatedRoute, Router } from '@angular/router';

export interface ListElement {
  name: string;
  hash: string;
  owner: string;
  concepts: number;
}

export interface myDictionary {
  icon: string,
  concept: string,
  synonyms: string[]

}


@Component({
  selector: 'app-dictionary-settings',
  templateUrl: './dictionary-settings.component.html',
  styleUrls: ['./dictionary-settings.component.css']
})


export class DictionarySettingsComponent extends BaseTopLevel {
  @ViewChild('MatTable') table: MatTable<any>
  items:Array<myDictionary> = [
    {icon:"assets/icons/nl.png", concept:"blabl", synonyms:["blab", "blahj"]},
    {icon:"assets/icons/en.png", concept:"blabl_enf", synonyms:["blab_enf", "blahj_en"]},
    {icon:"assets/icons/de.png", concept:"blabl_enfr", synonyms:["blab_enf", "blahj_en"]},
    {icon:"assets/icons/fr.png", concept:"blabl_enfr", synonyms:["blab_enf", "blahj_en"]},
    {icon:"assets/icons/es.png", concept:"blabl_enfr", synonyms:["blab_enf", "blahj_en"]},
    {icon:"assets/icons/it.png", concept:"blabl_enfr", synonyms:["blab_enf", "blahj_en"]},




  ]
  langForm: FormGroup;
  hash: string;
  showOverview:boolean = true;
  showPanel: boolean = false;
  screenLanguageControl = new FormControl;
  sourceLanguageControl = new FormControl;
  targetLanguageControl = new FormControl;
  showHelpControl = new FormControl;
  elementList;
  displayedColumns: string[] = ['name', 'hash', 'concepts', 'actions'];
  dataSource;

  mtBalance: number;
  hasBalance: boolean;
  dictionariesAllowed: string;

  languages = Languages;

  constructor(cd: ChangeDetectorRef,
    injector: Injector,
    private formbuilder: FormBuilder,
    private userStatusService: UserStatusService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private terminologyService: TerminologyService,
    private route: ActivatedRoute,
    private router: Router

  )
  {
    super(cd, injector);
    //console.log("Initializing dictionary settings component")
    this.route.params.subscribe( params => console.log("params" + JSON.stringify(params)))

  }

  ngOnInit() {

    super.ngOnInit()
    this.langForm = new FormGroup({
      screenlanguage: this.screenLanguageControl,
      sourcelanguage: this.sourceLanguageControl,
      targetlanguage: this.targetLanguageControl,
      showHelp: this.showHelpControl
    })
    this.checkUserService();
    this.getList();

  }

  setRouteParams(params){
    params[params]
  }

  deleteTerminology(hash){
    //console.log("hash"+ hash)
    this.terminologyService.deleteTerminologyDB(hash).subscribe(
      data => this.deleteTerminologySuccess(data),
      error => this.deleteTerminologyError(error)
    )
  }
  deleteTerminologySuccess(data){
    //console.log("delete" + JSON.stringify(data))
    this.getList();
  }

  deleteTerminologyError(error){
    console.log(error)
  }

  getList(){
    this.terminologyService.getList().subscribe(
      data => this.getListSuccess(data),
      error => this.getListError(error)
    )
  }
  getListSuccess(data){
    //console.log("data" + JSON.stringify(data.data));
    this.elementList = data.data;
    this.dataSource = new MatTableDataSource(this.elementList);

  }

  getListError(error){

  }

  openTerminologyDialog(){

    let dialogRef = this.dialog.open(CreateTerminologyDialogComponent, {
      width: "600px",
      data: null
    })
    dialogRef.afterClosed().subscribe(result => this.refreshRows() )
  }

  refreshRows(){
    this.dataSource = [...this.elementList];

  }


  checkUserService(){
    this.mtBalance = this.userStatusService.getMTBalance()
    this.hasBalance = this.mtBalance > 0;
    this.dictionariesAllowed = this.userStatusService.getDictionariesAllowed()
  }

  onConfigLoaded() {
    this.showHelpControl.setValue(this.configurationService.getShowHelp());
    this.screenLanguageControl.setValue(this.configurationService.getScreenLanguage());
    this.sourceLanguageControl.setValue(this.configurationService.getDefaultSourceLanguage());
    this.targetLanguageControl.setValue(this.configurationService.getDefaultTargetLanguage());
    this.refresh();
  }

  sourceLanguageChanged() {
    ; //Do nothing... This function is triggered when the source language is changed in the configuration service
  }

  targetLanguageChanged() {
    ; //Do nothing... This function is triggered when the target language is changed in the configuration service
  }

  setConfig(){

    this.configurationService.setScreenLanguage(this.screenLanguageControl.value);
    this.configurationService.setDefaultSourceLanguage(this.sourceLanguageControl.value);
    this.configurationService.setDefaultTargetLanguage(this.targetLanguageControl.value);
    this.configurationService.setShowHelp(this.showHelpControl.value);

    this.configurationService.storeConfig().then(result=>{
      this.snackBar.open('Your settings have been saved', 'OK', {duration:3000});
      this.screenLanguageControl.setValue(this.configurationService.getScreenLanguage());
      this.sourceLanguageControl.setValue(this.configurationService.getDefaultSourceLanguage());
      this.targetLanguageControl.setValue(this.configurationService.getDefaultTargetLanguage());
      this.showHelpControl.setValue(this.configurationService.getShowHelp());

    }, error=>{
      this.snackBar.open('Error saving your configuration: ' + JSON.stringify(error.message), 'OK', {duration:3000});
    });



  }

  dictionaryDetailsPlaceholder(dictionary): string[]{

   switch(dictionary){

     case "maindb":
     return ["Base dictionaries"]

     case "proverbdb":
     return ["Proverbs"]

     case "chemicdb":
     return ["Chemical dictionary"]

     case "computdb":
     return ["IT dictionary"]

     case "medicdb":
     return ["Medical dictionary"]

     case "tradedb":
     return ["Financial/Trade dictionary"]

     case "technidb":
     return ["Technical dictionary"]
   }

   return null
 }

 addTerminology(hash){
   this.hash = hash;
   this.showOverview = false;
   this.setRouteParams(hash)
   //this.router.navigate(['/dictionary-settings', this.hash])

 }

 newEntry(){
   this.showPanel = true;
   this.items.push( {icon:"assets/icons/nl.png", concept:null, synonyms:[]},
   {icon:"assets/icons/en.png", concept:null, synonyms:[]},
   {icon:"assets/icons/de.png", concept:null, synonyms:[]},
   {icon:"assets/icons/fr.png", concept:null, synonyms:[]},
   {icon:"assets/icons/es.png", concept:null, synonyms:[]},
   {icon:"assets/icons/it.png", concept:null, synonyms:[]},)
 }




}
