<app-navbar></app-navbar>

<mat-drawer-container class="example-container">
        <mat-drawer mode="side" opened fxHide.sm  >
            <left-navbar></left-navbar>
        </mat-drawer>
        <mat-drawer-content>
                <div class="translation-area">
                        <div class="container-fluid">
                            <div class="white-space"></div>
                            <div class="row">
                              <div class="col-sm-2">

                              </div>
                              <div class="col-sm-8">
                                <div class="spacer"></div>
                                <div class="card" *ngIf='orderStatus == "canceled" '>
                                  <h1 class="center">Payment Canceled</h1>

                                   <div class="card-body">
                                     <div class="thank-you-icon">
                                         <mat-icon class="iconss">error</mat-icon>
                                     </div>
                                     <div class="thank-you-text">
                                         <p>You (probably) have cancelled your payment. In case this was a mistake, we'll be happy to receive your order again</p>
                                     </div>
                                     <div class="social-buttons">
                                         <a href="" class="fa fa-facebook"></a>
                                         <a href="https://twitter.com/Euroglot" class="fa fa-twitter"></a>

                                       </div>


                                   </div>

                               </div>
                                <div class="card" *ngIf='orderStatus == "failed" '>
                                  <h1 class="center">Oops something went wrong: Payment failed</h1>

                                   <div class="card-body">
                                     <div class="thank-you-icon">
                                         <mat-icon class="iconss">error</mat-icon>
                                     </div>
                                     <div class="thank-you-text">
                                         <p>Something went wrong, we failed to process your payment. Please try to make a new purchase.</p>
                                     </div>
                                     <div class="social-buttons">
                                         <a href="" class="fa fa-facebook"></a>
                                         <a href="https://twitter.com/Euroglot" class="fa fa-twitter"></a>

                                       </div>


                                   </div>

                               </div>
                                <div class="card" *ngIf='orderStatus == "expired" '>
                                  <h1 class="center">Oops something went wrong: Payment expired</h1>

                                   <div class="card-body">
                                     <div class="thank-you-icon">
                                         <mat-icon class="iconss">error</mat-icon>
                                     </div>
                                     <div class="thank-you-text">
                                         <p>Something went wrong, it took too long to handle your payment. Your transaction was not processed. Please try to make a new purchase.</p>
                                     </div>
                                     <div class="social-buttons">
                                         <a href="" class="fa fa-facebook"></a>
                                         <a href="https://twitter.com/Euroglot" class="fa fa-twitter"></a>

                                       </div>


                                   </div>

                               </div>
                                <div class="card" *ngIf='orderStatus == "pending" '>
                                  <h1 class="center">Payment pending</h1>

                                   <div class="card-body">
                                     <div class="thank-you-icon">
                                         <mat-icon class="iconss">error</mat-icon>
                                     </div>
                                     <div class="thank-you-text">
                                         <p>Please give us some time to process your order</p>
                                     </div>
                                     <div class="social-buttons">
                                         <a href="" class="fa fa-facebook"></a>
                                         <a href="https://twitter.com/Euroglot" class="fa fa-twitter"></a>

                                       </div>


                                   </div>

                               </div>


                                <div class="card" *ngIf='orderStatus == "paid"'>
                                   <h1 class="center">Thank you for your order</h1>

                                    <div class="card-body">
                                      <div class="thank-you-icon">
                                          <mat-icon class="iconss">done</mat-icon>
                                      </div>
                                      <div class="thank-you-text">
                                          <p>Thank you for your payment! Logout and login in order to see your new balance and use your extra product features</p>
                                      </div>
                                      <div class="social-buttons">
                                          <a href="" class="fa fa-facebook"></a>
                                          <a href="https://twitter.com/Euroglot" class="fa fa-twitter"></a>

                                        </div>


                                    </div>

                                </div>


                              </div>
                              <div class="col-sm-2">

                              </div>

                            </div>
                        </div>

                      </div>


        </mat-drawer-content>



</mat-drawer-container>
