<the-context-menu
(evokeShowGrammar)="evokeShowGrammar"
(evokeUpdateGrammar)="evokeUpdateGrammar"
id="context_menu">
</the-context-menu>

    <div id="recognized-panel">

        <div *ngFor="let concept of concepts; let cindex = index">
          <div *ngIf="concept.data.dictionary == dictionary && concept.data.recognized_form">

            <mat-accordion>
              <mat-expansion-panel #collapsibleProgrammatically id="concept_card_{{concept.data.id}}"
                (opened)="collapsibleOpened(concept.data.id)" (closed)="collapsibleClosed(concept.data.id)">
              <mat-expansion-panel-header>
                <mat-panel-title style="padding:8px">
                  <div class="header_wordtype">
                    <p class="header_wordtype_p">{{currentResourceMaps.wordTypeResources.get(concept.data.lemmas[0].data.word_type)}}</p>
                  </div>

                    <div class="lang-icon"><img [src]="sourceFlag" /></div>
                    <div class="header_synonyms header_synonym_{{hi}}" *ngFor="let lemmaObj of concept.data.lemmas; let hi = index">
                        <p *ngIf="(hi==0) && concept.data.lemmas.length > 1" class="header_synonym_p_first header_synonym_p_{{hi}}">{{lemmaObj.data.lemma}}</p>
                        <p *ngIf="(hi != 0) && (hi<concept.data.lemmas.length-1)" class="header_synonym_p_middle header_synonym_p_{{hi}}"> {{lemmaObj.data.lemma}}</p>
                        <p *ngIf="hi==concept.data.lemmas.length-1" class="header_synonym_p_last header_synonym_p_{{hi}}"> {{lemmaObj.data.lemma}}</p>
                    </div>
                    <div *ngIf="concept.data.lemmas.length > 1" class="header_synonyms_end_bracket">)</div>
                  <br>
                  <div class="lang-icon"><img [src]="targetFlag" /></div>
                  <div class="header_translations header_translation_{{hi}}" *ngFor="let transObj of translations[cindex].data.lemmas; let hi = index">
                      <p *ngIf="(hi==0) && translations[cindex].data.lemmas.length > 1" class="header_translation_p_first header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                      <p *ngIf="(hi != 0) && (hi<translations[cindex].data.lemmas.length-1)" class="header_translation_p_middle header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                      <p *ngIf="hi==translations[cindex].data.lemmas.length-1" class="header_translation_p_last header_translation_p_{{hi}}">{{transObj.data.lemma}}</p>
                  </div>
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="translations">

                <conjugated-translations class="best-translations" [conceptid]="concept.data.id" [dictionary]="concept.data.dictionary" [sourceForm]="sourceForm" [focus]="concept.data.lemmas[0].data.focus">
                </conjugated-translations>

              </div>
              <thesaurus id="thesaurus_{{concept.data.id}}" [sourceLanguage]="sourceLanguage" [targetLanguage]="targetLanguage" [conceptid]="concept.data.id" [dictionary]="concept.data.dictionary" [contextMenu]="contextMenu"></thesaurus>
              <button mat-raised-button color="primary" (click)="closeCollapsible(collapsibleProgrammatically, concept.data.id)">Close</button>
             </mat-expansion-panel>
            </mat-accordion>

          </div>
        </div>

    </div>
