import { Component, Input, ViewChildren, ViewChild, QueryList, ChangeDetectorRef, OnInit, Injector } from '@angular/core';
import { BasePanel } from '../base-panel';
import { ThesaurusComponent } from '../thesaurus/thesaurus.component';
import { MenuContextComponent } from '../../menu-context/menu-context.component';
import {MatExpansionPanel} from '@angular/material/expansion';


const dictionary = "maindb";


@Component({
  selector: 'expressions-panel',
  templateUrl: './expressions-panel.component.html',
  styleUrls: ['./expressions-panel.component.css']
})
export class ExpressionsPanelComponent extends BasePanel {
  @ViewChildren(ThesaurusComponent) thesaurusChildren: QueryList<ThesaurusComponent>;
  @ViewChild(MenuContextComponent) contextMenu: MenuContextComponent;
  @ViewChildren('collapsibleProgrammatically') collapsibleChildren: QueryList<MatExpansionPanel>;

  @Input() expressions: any[]
  @Input() translations: any[];
  //@Input() searching: boolean;
  evokeUpdateGrammar;
  evokeShowGrammar;


  constructor(cd: ChangeDetectorRef, injector: Injector) {
    //console.log("Initializing expressions panel (" + dictionary + ")")
    super(cd, injector, dictionary);
   }

   ngOnInit() {
    super.ngOnInit()
    //this.getWordTypeResources(this.expressions);
  }

  getTranslationExpression(dictionary, id, transgroups){
    for(var i=0; this.translations.length; i++){
      var expr_translation = this.translations[i]
      if((expr_translation.data.dictionary == dictionary )&&(expr_translation.data.id == id )&&(expr_translation.data.trans_groups == transgroups)){
        return expr_translation
      }
    }
    return null



  }
}
