import { Injectable } from '@angular/core';
import { environment, varEnvironment } from '../../environments/environment';
import { HttpService } from '../services/http.service';

const API_URL = '://'+ environment.API_IP + ":"+ environment.PORT_TERMINOLOGY + environment.ENDPOINT_TERMINOLOGY;


@Injectable({
  providedIn: 'root'
})
export class TerminologyService {
  requestOptions: {} = {withAuth: true};


  constructor(private http: HttpService) { }

  createTerminologyDB(name){
    return this.http.get(varEnvironment.schema + API_URL + environment.CREATE_TERMINOLOGY + "/" + name, this.requestOptions)

  }

  deleteTerminologyDB(hash){
    return this.http.get(varEnvironment.schema + API_URL + environment.DELETE_TERMINOLOGY + "/" + hash, this.requestOptions)

  }

  getList(){
    return this.http.get(varEnvironment.schema + API_URL + environment.TERMINOLOGY_LIST + "/", this.requestOptions)
  }


}
