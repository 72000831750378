import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { environment, varEnvironment } from '../../environments/environment';
import { Observable } from 'rxjs';

const API_URL =  '://' + environment.API_IP + ":" + environment.PORT_SOUFFLEUR + environment.ENDPOINT_SOUFFLEUR + environment.SOUFFLEUR;

@Injectable()
export class SouffleurService {
  private souffleurURL: string = varEnvironment.schema + API_URL
  
  requestOptions: {withAuth: true};

  constructor(private http:HttpService) { }

  
  getSouffleur(souffleurid, language): Observable<JSON> {
    return this.http.get(this.getSouffleurURL(souffleurid, language), this.requestOptions)
  }

  getSouffleurURL(souffleurid, language){
    //console.log("LANGUAGESOUFFLEUR" + this.souffleurURL + language + "/" + souffleurid)
    return this.souffleurURL + language + "/" + souffleurid
  }

}
