import { Directive } from '@angular/core';
import { EventEmitter } from '@angular/core';

//import the application components and services
import { ClipboardService } from "../shared-services/copytoclipboard.service";

@Directive({
  selector: '[clipboard]',
  inputs:['value: clipboard'],
  outputs:[
    'copyEvent: clipboardCopy',
    'errorEvent: clipboardError',
  ],
  host: {
    "(click)":"copyToClipboard()"
  }
})
export class ClipboardDirective {
  public copyEvent: EventEmitter<string>;
  public errorEvent: EventEmitter<Error>;
  public value: string;

  private clipboardService: ClipboardService;

  //initialize the clipboard directive
  constructor(clipboardService: ClipboardService) { 
    this.clipboardService = clipboardService;
    this.copyEvent = new EventEmitter();
    this.errorEvent = new EventEmitter();
    this.value = "";
  }

  //copy the value-input to the clipboard. Emits success or error event
  public copyToClipboard(){

    this.clipboardService
      .copy(this.value)
      .then(
        (value:string): void => {
          this.copyEvent.emit(value);
        }
      )
      .catch(
        (error:Error): void => {
          this.errorEvent.emit(error);
        }
      )

  }

}
