 <mat-card >
  <div class="accordion">
 <mat-accordion id="concept-container" class="concept-container" >
    
    <mat-expansion-panel *ngFor="let item of items; let i = index"  (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="img-item">
             <img [src]="item.icon"> 
            </div>
          <div class="concept-title">
                <input class="outline-box" value="{{item.concept}}" >

          </div>
          
            
        </mat-panel-title>
        <!--<mat-panel-description>
          Currently I am {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description> -->
      </mat-expansion-panel-header>
      <p>{{item.synonyms[0]}}</p>
      <p>{{item.synonyms[1]}}</p>

    </mat-expansion-panel>
      
  </mat-accordion>
</div>
  
</mat-card>