import { Component, OnInit } from '@angular/core';
import {AuthServiceEuroglot, LoggedInEvent} from '../../services/auth.service';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  email: string;
  username: string;
  hashSupplied: boolean = false;

  new_password: string;
  retype_password: string;
  private loggedInSubscription;


  constructor(private authService: AuthServiceEuroglot,
    private router:Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar) {
    this.loggedInSubscription = this.authService.loggedInEmitter.subscribe({
			next: (event: LoggedInEvent) => {
					this.handleLogin(event)
			}
		})
   }

  ngOnInit() {
    this.isLoggedIn();
    let username = this.route.snapshot.queryParams["username"];
    let hash = this.route.snapshot.queryParams["hash"];
    if(username != "") {
      if ((hash == undefined) || (hash == "")) {
        this.hashSupplied = false;
      } else {
        this.hashSupplied = true;
      }
      this.username = username;
    }

  }

  isLoggedIn(){
    if (this.authService.checkLogin()) {
			this.snackBar.open('You are already logged in', 'OK', {duration:3000});
			this.router.navigate(['dictionary']);
    }
	}

  handleLogin(event : LoggedInEvent) {
		if (event.loggedIn) {
			this.snackBar.open('You are logged in successfully', 'OK', {duration:3000});
			this.router.navigate(['dictionary']);
		} else {
			this.snackBar.open('Cannot log in, credentials are invalid', 'OK', {duration:3000});
			this.router.navigate(['/forgot-password']);
		}
  }

  ForgotPasswordSubmit(){
    //console.log("Submit email address " + this.email + " and username" + this.username + "to obtain link in email to reset password")
    this.authService.forgotPasswordEgOAuthMail(this.username, this.email).subscribe(
      data => this.handleForgotPasswordSuccess(data),
		  error => this.handleForgotPasswordError(error),
		  () => null
    );
  }

  forgotPasswordSubmitFreeAccount(){
    this.authService.forgetPasswordLoginAuthMail(this.username, this.email).subscribe(
      data => this.handleForgotPasswordFreeAccountSuccess(data),
      error => this.handleForgotPaswordFreeAccountError(error),
      () => null
    )
  }

  handleForgotPasswordFreeAccountSuccess(data){
    if(data.success){
      //console.log("data success" + JSON.stringify(data))
      this.snackBar.open('Email succesfully sent', 'OK', {duration:3000});
      this.router.navigate(['/forgot-password']);
    } else {
      //console.log("data fail" + JSON.stringify(data))
      this.snackBar.open(data.data.message, 'OK', {duration: 3000});
    }
  }

  handleForgotPaswordFreeAccountError(error){
    this.snackBar.open(error, 'OK', {duration: 3000});
  }

  handleForgotPasswordSuccess(data) {
    //console.log("data"+ JSON.stringify(data));
    if(data.success){
      this.snackBar.open('Email succesfully sent', 'OK', {duration:3000});
      this.router.navigate(['/forgot-password']);

    }
    if(data.data.message == "username and e-mail combination not found"){
        //console.log("cannot be found")
        this.forgotPasswordSubmitFreeAccount();
    }
      this.snackBar.open(data.data.message, 'OK', {duration: 3000});
  }


  handleForgotPasswordError(error) {
    console.log(error);
    this.snackBar.open('Something went wrong', 'OK', {duration: 3000});
  }

  changePassword(){

    if((this.new_password == undefined) || this.new_password == "") {
      return
    }

    if(this.new_password != this.retype_password) {
      this.snackBar.open('Please supply equal passwords', 'OK', {duration: 3000});
      return
    }

    let hash = this.route.snapshot.queryParams["hash"];
    const user = {
      username: this.username,
      password: this.new_password,
      resetHash: hash
    }
   // console.log("user from changepassword" + JSON.stringify(user))
    this.authService.resetPassword(user)

    //console.log("USERNAME" + this.username + "New Password" + this.new_password + hash)


  }

}
