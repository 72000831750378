
<div class="navbar-fixed">

  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span class="brand-logo"><img src="/assets/img/euroglot-logo.png" width="150px">Content Assistent</span>
      <!--<i class="material-icons open-menu" (click)="openSideNav()" [matTooltipPosition]="position.value" matTooltip="Click for full screen">menu</i> -->



      <span class="example-spacer"></span>
      <button *ngIf="isFreeUser" mat-raised-button color="primary" (click)="openPaymentScreen(3,'Premium Subscription')">Go Premium</button>


      <div fxLayout="row" fxHide fxShow.gt-sm>

          <button *ngIf="loggedIn" [routerLinkActive]="['active']" [routerLinkActiveOptions] = "{exact:true}" mat-button [matMenuTriggerFor]="menu"><mat-icon>account_circle</mat-icon></button>
          <mat-menu #menu="matMenu">
            <!--<button [routerLink]="['/account']" *ngIf="loggedIn" [routerLinkActive]="['active']" [routerLinkActiveOptions] = "{exact:true}" mat-menu-item>Account</button>-->
            <button (click)="onLogoutClick()" *ngIf="loggedIn" [routerLinkActive]="['active']" [routerLinkActiveOptions] = "{exact:true}" mat-menu-item>{{ 'NAVBAR.LOGOUT' | translate }}</button>
          </mat-menu>
      </div>

    </mat-toolbar-row>

</mat-toolbar>
<mat-menu x-position="before" #menu>
        <button fxShow fxHide.gt-sm [routerLink]="['/dictionary']" *ngIf="loggedIn" [routerLinkActive]="['active']" [routerLinkActiveOptions] = "{exact:true}" mat-menu-item>Dictionary</button>
        <button [routerLink]="['/account']" *ngIf="loggedIn" [routerLinkActive]="['active']" [routerLinkActiveOptions] = "{exact:true}" mat-menu-item>Account</button>
        <button (click)="onLogoutClick()" *ngIf="loggedIn" [routerLinkActive]="['active']" [routerLinkActiveOptions] = "{exact:true}" mat-menu-item>{{ 'NAVBAR.LOGOUT' | translate }}</button>
</mat-menu>


</div>
