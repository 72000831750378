import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {AuthServiceEuroglot, LoggedInEvent} from '../../services/auth.service';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationService } from '../../services/configuration.service';
import { AuthService } from "angularx-social-login";
import { UserStatusService } from '../../services/user-status.service';
import { PaymentDialogComponent } from '../account-components/payment-dialog/payment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseProduct, Purchase } from '../../services/payment.service';
import {FormControl} from '@angular/forms';
import {TooltipPosition} from '@angular/material/tooltip';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Output() open: EventEmitter<boolean> = new EventEmitter()
  isFreeUser: boolean = true;
  loggedIn: boolean = false;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new FormControl(this.positionOptions[3]);

  private loggedInSubscription;
  private refreshedSubscription;

  constructor(
    private authService:AuthServiceEuroglot,
    private configurationService:ConfigurationService,
    private router:Router,
    public snackBar: MatSnackBar,
    private socialAuth: AuthService,
    private userStatus: UserStatusService,
    private dialog: MatDialog
  ) {
    this.loggedInSubscription = authService.loggedInEmitter.subscribe({
      next: (event: LoggedInEvent) => {
          this.handleLogin(event)
      }
    })
    this.refreshedSubscription = authService.refreshedEmitter.subscribe({
      next: (event: LoggedInEvent) => {
          this.handleRefresh(event)
      }
    })

    this.loggedIn = authService.checkLogin()
  }

  openSideNav(){
    this.open.emit(true);
  }

  ngOnInit() {
    //let credits = this.jwtdecoder.getDecodedAuthTokenField('deepl_credits');
    //this.isPremium = credits != undefined && credits > 0;
    this.isFreeUser = this.userStatus.getFreeUser()
    //console.log("STATUS " + this.isFreeUser)
  }

  ngAfterViewInit(): void {

      //console.log("JQUERY IS READY")
      //$(".dropdown-button").dropdown();


  }

  handleLogin(event : LoggedInEvent) {
    //console.log('NAVBAR LOGIN EVENT')
    this.loggedIn = event.loggedIn
  }

  handleRefresh(event : LoggedInEvent) {
    //console.log('NAVBAR REFRESH EVENT')
    this.loggedIn = event.loggedIn
  }

  openPaymentScreen(productnr, productdescription){
    let myPurchase: Purchase = []
    let myProduct: PurchaseProduct = {amount:1, id: productnr, product: productdescription}
    myPurchase.push(myProduct)
    let dialogRef = this.dialog.open(PaymentDialogComponent, {
      width: "600px",
      data: myPurchase
    })
  }

  onLogoutClick(){
    this.authService.logout().then(
      data => this.handleLogoutSuccess()
    ).catch(
      error => this.handleLogoutError(error)
    );
  }

  handleLogoutSuccess() {
    this.socialAuth.signOut();

    this.snackBar.open('You are now logged out', 'Undo', {duration:3000});
    this.loggedIn = false;
    this.configurationService.clearConfig();
    this.router.navigate(['/login']);
  }

  handleLogoutError(error) {
    this.snackBar.open('Something went wrong: ' + error, 'Undo', {duration: 3000});
  }

}
